<template>
    <div class="filter-list">
        <template v-if="panelProducers.length || panelCustomers.length">
            <div class="list-title">FILTERS</div>
            <v-divider />
            <div v-if="panelProducers.length && isShowProducers" class="d-flex flex-column gap-5 px-5 pb-4">
                <div class="d-flex align-center">
                    <v-checkbox class="ma-0 pa-0" dense hide-details v-model="showProducers" @change="(value) => aggregateVisibilityChanged('producers', value)" />
                    <div :class="`filters-title ${!showProducers ? 'text--disabled' : ''}`">
                        Aggregate Producers <span v-if="showProducers">{{ getProducersLength }}</span>
                    </div>
                </div>
                <v-expansion-panels v-if="showProducers">
                    <v-expansion-panel v-for="(item, i) in panelProducers" :key="i">
                        <v-expansion-panel-header>
                            {{ item.name }}
                            <template v-slot:actions> </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <ul v-if="item.options" class="option-list">
                                <li v-for="(option, i) in item.options" :key="i" @click="selectFilter('producers', item.shortName, option)">
                                    <div :style="option.style || ''">
                                        {{ option.name }}
                                    </div>
                                </li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div v-if="panelCustomers.length  && isShowCustomers" class="d-flex flex-column gap-5 px-5 pb-4">
                <div class="d-flex align-center">
                    <v-checkbox class="ma-0 pa-0" dense hide-details v-model="showCustomers" @change="(value) => aggregateVisibilityChanged('customers', value)" />
                    <div :class="`filters-title ${!showCustomers ? 'text--disabled' : ''}`">
                        Aggregate Customers <span v-if="showCustomers">{{ getCustomersLength }}</span>
                    </div>
                </div>
                <v-expansion-panels v-if="showCustomers">
                    <v-expansion-panel v-for="(item, i) in panelCustomers" :key="i">
                        <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <ul v-if="item.options" class="option-list">
                                <li v-for="(option, i) in item.options" :key="i" @click="selectFilter('customers', item.shortName, option)">
                                    <div :style="option.style || ''">
                                        {{ option.name }}
                                    </div>
                                </li>
                            </ul>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <v-divider />
        </template>
        <div class="list-title">LEGEND</div>
        <v-divider />
        <map-legend class="px-5" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MapLegend from '@/components/map/legend';
import Btn from '@/components/lib/btn.vue';

export default {
	props: {
		isShowCustomers: {
			type: Boolean,
			default: false
		},
		isShowProducers: {
			type: Boolean,
			default: false
		},
		selectedMarker: {
			type: Object,
			default: null
		}
	},
    data() {
        return {
            showProducers: true,
            showCustomers: true,
            panelProducers: [],
            panelCustomers: [],
        };
    },
    mounted() {
        this.initFilters();
    },
	watch: {
		selectedMarker: {
			handler(newValue) {
				if (newValue) {
					this.updateFilters(this.getFilteredMapAggregates);
				} else {
					this.updateFilters(this.getMapAggregates)
				}
			}
		}
	},
    methods: {
        async initFilters() {
            const getPlantTypes = this.$store.dispatch('filters/getPlantTypes');
            const getGeologies = this.$store.dispatch('filters/getGeologies');
            const getApplications = this.$store.dispatch('filters/getApplications');
            const getProducersGroups = this.$store.dispatch('filters/getProducersGroups');
            const getAggregatesProducerCompanies = this.$store.dispatch('filters/getAggregatesProducerCompanies', { marketId: this.market.id });
            const getCustomerCompanies = this.$store.dispatch('filters/getCustomerCompanies', { marketId: this.market.id });
            const getMarketSegments = this.$store.dispatch('filters/getMarketSegments');
            const getCustomersGroups = this.$store.dispatch('filters/getCustomersGroups');

            await Promise.all([
                getPlantTypes,
                getGeologies,
                getApplications,
                getProducersGroups,
                getAggregatesProducerCompanies,
                getCustomerCompanies,
                getMarketSegments,
                getCustomersGroups,
            ]);

            this.setFilterPanel(this.panelProducers, this.$store.getters['filters/getPlantType'], 'plantType', 'Plant Type');
            this.setFilterPanel(this.panelProducers, this.$store.getters['filters/getGeologies'], 'geology', 'Geology');
            this.setFilterPanel(this.panelProducers, this.$store.getters['filters/getApplications'], 'primaryApplication', 'Primary Application');
            this.setFilterPanel(this.panelProducers, this.$store.getters['filters/getProducersGroups'], 'group', 'Group');
            this.setFilterPanel(this.panelProducers, this.$store.getters['filters/getAggregatesProducerCompanies'], 'company', 'Company');

            // IDK WTF IS id='24', old code, dude
            this.setFilterPanel(
                this.panelCustomers,
                this.$store.getters['filters/getMarketSegments'].filter((item) => item.id !== 24),
                'marketSegment',
                'Market Segment',
            );
            this.setFilterPanel(this.panelCustomers, this.$store.getters['filters/getCustomersGroups'], 'group', 'Group');
            this.setFilterPanel(this.panelCustomers, this.$store.getters['filters/getAggregatesProducerCompanies'], 'company', 'Company');
        },
        setFilterPanel(panel, filters, shortName, name) {
            const options = filters.filter((filter) => this.getMapAggregates.some((aggregate) => aggregate[shortName] && aggregate[shortName].name === filter.name));

            if (options && options.length) {
				panel.push({ name, options, shortName });
            }
        },
		updateFilterPanel(data, filters, shortName) {
			return filters.filter((filter) => data.some((aggregate) => aggregate[shortName] && aggregate[shortName].name === filter.name));
		},
		updateFilters(data) {
			this.panelCustomers[0].options = [...this.updateFilterPanel(data, this.$store.getters['filters/getCustomersGroups'], 'group')];
			this.panelCustomers[1].options = [...this.updateFilterPanel(data, this.$store.getters['filters/getAggregatesProducerCompanies'], 'company')];

			this.panelProducers[0].options = [...this.updateFilterPanel(data, this.$store.getters['filters/getPlantType'], 'plantType')];
			this.panelProducers[1].options = [...this.updateFilterPanel(data, this.$store.getters['filters/getGeologies'], 'geology')];
			this.panelProducers[2].options = [...this.updateFilterPanel(data, this.$store.getters['filters/getApplications'], 'primaryApplication')];
			this.panelProducers[3].options = [...this.updateFilterPanel(data, this.$store.getters['filters/getProducersGroups'], 'group')];
			this.panelProducers[4].options = [...this.updateFilterPanel(data, this.$store.getters['filters/getAggregatesProducerCompanies'], 'company')];
		},
		selectFilter(aggregate, type, option) {
            if (!this.getSelectedFilter.some((x) => x.aggregate === aggregate && x.type === type && x.option && x.option.name === option.name)) {
                this.$store.commit('filters/saveFilter', { aggregate, type, option });
                this.$emit('startFiltering');
            }
        },
        aggregateVisibilityChanged(aggregate, value) {
            if (value) {
				this.$store.commit('filters/removeFilter', {
                    option: {
                        name: `${aggregate}Hide`,
                    },
                });
			} else {
				this.$store.commit('filters/saveFilter', {
                    aggregate: 'all',
                    type: 'aggregateHide',
                    option: {
                        name: `${aggregate}Hide`,
                    },
                });
                this.$store.commit('filters/deleteFilterByAggregate', aggregate);
            }
            this.$emit('startFiltering');
        },
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
        ...mapGetters('producersAndCustomersMap', {
            getMapAggregates: 'getMapAggregates',
			getFilteredMapAggregates: 'getFilteredMapAggregates',
        }),
        getProducersLength() {
            return this.$store.getters['producersAndCustomersMap/getProducersLength'];
        },
        getCustomersLength() {
            return this.$store.getters['producersAndCustomersMap/getCustomersLength'];
        },
        getSelectedFilter() {
            return this.$store.getters['filters/getSelectedFilter'];
        },
    },
    components: {
        Btn,
        MapLegend,
    },
};
</script>

<style lang="scss" scoped>
.filter-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    width: 250px;
    max-height: 600px;
    background: white;
    overflow: scroll;
    box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.09);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    .list-title {
        font-weight: 500;
        text-align: center;
    }

    .filters-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.114286px;
        color: #0e3f66;

        span {
            opacity: 0.4;
        }
    }

    ul.option-list {
        padding: 0px 5px 0px 5px;
        list-style-type: none;
        max-height: 320px;
        overflow-y: scroll;
        user-select: none;

        li {
            text-align: center;
            margin-bottom: 12px;
            color: rgb(112, 112, 112);
            background: rgb(233, 233, 233);
            border-radius: 4px;
            cursor: pointer;

            div {
                word-break: break-all;
                padding: 5px;
                border-radius: 4px;
            }
        }
    }
}

.v-expansion-panels {
    gap: 5px;

    .v-expansion-panel {
        min-height: 20px;

        &::before {
            box-shadow: none;
        }

        &:not(:first-child) {
            &::after {
                border-top: none;
            }
        }

        .v-expansion-panel-header {
            padding: 0;
            min-height: auto;
            padding-bottom: 10px;
        }
    }
}
</style>
