<template>
    <base-layout :style="{ 'background-color': backgroundColor }" :fill-height="isEmptyAggregateProducers">
        <v-container fluid>
            <template v-if="isEmptyAggregateProducers">
                <empty-aggregate-producers></empty-aggregate-producers>
            </template>
            <template v-else>
                <div class="aggregates-producers pt-3">
                    <v-row justify="center" align="center">
                        <v-col cols="12">
                            <div class="d-flex align-center aggregates-producers-top">
                                <div>
                                    <span class="title regalBlue--text">Aggregates Producers</span>
                                    <div class="sub-title martinique--text pt-2">
                                        For optimal results add all active and potential supply points in the market
                                    </div>
                                </div>
								<div class="split-menu-box__results pl-5 d-flex align-center ml-auto mr-0">
									<div class="d-flex align-center">
										<v-progress-circular
											:size="60"
											:width="15"
											:rotate="-90"
											:color="'#0E3F66'"
											:value="typeof producerRate == 'number' ? producerRate : 0"
										>
										</v-progress-circular>
										<div>
											<p>
												Completion rate
											</p>
											<span v-if="typeof producerRate == 'number'">
												{{ producerRate }} %
						  					</span>
										</div>
									</div>
									<div class="results-box__data ml-15">
										<div class="d-flex align-center">
											<v-progress-circular
												:size="60"
												:width="15"
												:rotate="-90"
												:color="levelOfConfidence < 50 ? '#F7685B' : levelOfConfidence > 80 ? '#2ED47A' : '#FA771E'"
												:value="typeof levelOfConfidence == 'number' ? levelOfConfidence : 0"
											></v-progress-circular>
											<div class="pl-7">
												<p>
													Level of Confidence
												</p>
												<span v-if="typeof levelOfConfidence == 'number'">
													{{ levelOfConfidence }} %
												</span>
											</div>
										</div>
									</div>
								</div>
                                <div class="import-exel">
                                    <ImportExcel @onImport="onImportAggregateProducers" :preset="0"></ImportExcel>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <add-producer />
                            <producer-table />
                        </v-col>
                    </v-row>
                </div>
            </template>
        </v-container>
    </base-layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import EmptyAggregateProducers from '@/components/aggregatesProducers/EmptyAggregateProducers.vue';
import AddProducer from '../../components/aggregatesProducers/AddProducer.vue';
import ProducerTable from '../../components/aggregatesProducers/ProducerTable.vue';

import ImportExcel from '@/components/importExcel/index.vue';

import { cloneDeep } from 'lodash';

export default {
    name: 'aggregateProducers',
    components: {
        EmptyAggregateProducers,
        AddProducer,
        ProducerTable,
        ImportExcel,
    },
    data() {
        return {
            isEmptyAggregateProducers: false,
			producerRate: 0,
			levelOfConfidence: 0
		};
    },
    watch: {
        isLoadedAvailableMarkets() {
            if (this.market === null) {
                this.$router.push({ path: '/' });
            }
        },
		async market() {
			await this.getRate()
		},
    },
    computed: {
        ...mapGetters('marketAssessment', {
            isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
            market: 'market',
        }),
        ...mapGetters('aggregatesProducers', {
            applications: 'applications',
        }),
		marketId() {
			return Number(localStorage.getItem('marketId'));
		},
        defaultApplicationID() {
            let defaultId;
            this.applications.forEach((element) => {
                if (element.name == 'n/a') {
                    defaultId = element.id;
                }
            });
            return defaultId;
        },
        backgroundColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
        },
    },


	async mounted(){
		await this.getRate()
	},

    methods: {
        ...mapMutations('importExcelDialog', {
            setIsDataImporting: 'setIsDataImporting',
        }),
        ...mapActions('aggregatesProducers', {
            importAggregateProducers: 'importAggregateProducers',
        }),
        ...mapActions('geocoding', {
            searchLocationWithResult: 'searchLocationWithResult',
        }),
		getRate(){
			this.producerRate = this.$store.dispatch('aggregatesProducers/getAggregatesProducersRate').then(() => {
				this.producerRate = ''
				this.producerRate = Math.trunc(this.$store.getters['aggregatesProducers/producersRate']*100);
			});
			this.levelOfConfidence = this.$store.dispatch('marketSegment/getLevelOfConfidence').then(() => {
				this.levelOfConfidence = ''
				this.levelOfConfidence = Math.trunc(this.$store.getters['marketSegment/levelOfConfidence']);
			});
		},
        getLocationPlace(data) {
            if (data === undefined) {
                return null;
            }

            let place = null;

            const { context } = data;

            if (context === undefined) {
                return null;
            }

            for (var i = 0; i < context.length; i++) {
                if (context[i].id.split('.')[0] === 'place') {
                    return context[i].text;
                } else if (context[i].id.split('.')[0] === 'locality') {
                    return context[i].text;
                } else {
                    place = data.place_name;
                }
            }
            return place;
        },
        onImportAggregateProducers(values) {
            this.setIsDataImporting(true);

            const data = cloneDeep(values);
            const aggregateProducers = [];

            let customIndex = 0;

            data.forEach(async (x) => {
                let latitude = 0;
                let longitude = 0;

                if (x.latitude && parseFloat(x.latitude)) {
                    latitude = parseFloat(x.latitude);
                }

                if (x.longitude && parseFloat(x.longitude)) {
                    longitude = parseFloat(x.longitude);
                }

                let annualProductionVolume = 0;

                if (x.annualProductionVolume) {
                    const indexOfT = x.annualProductionVolume
                        .toString()
                        .toLowerCase()
                        .indexOf('t');
                    const indexOfM = x.annualProductionVolume
                        .toString()
                        .toLowerCase()
                        .indexOf('m');

                    if (indexOfT !== -1) {
                        x.annualProductionVolume = x.annualProductionVolume.toString().substring(0, indexOfT);
                    }

                    if (indexOfM !== -1) {
                        x.annualProductionVolume = x.annualProductionVolume.toString().substring(0, indexOfM);
                    }

                    annualProductionVolume = parseFloat(x.annualProductionVolume) ? parseFloat(x.annualProductionVolume) : 0;
                }

                let reserves = 0;

                if (x.reserves) {
                    const indexOfT = x.reserves
                        .toString()
                        .toLowerCase()
                        .indexOf('t');

                    if (indexOfT !== -1) {
                        x.reserves = x.reserves.toString().substring(0, indexOfT);
                    }

                    reserves = parseFloat(x.reserves) ? parseFloat(x.reserves) : 0;
                }

                if (x.plantLocationAddress) {
                    const result = await this.searchLocationWithResult(x.plantLocationAddress.toString());

                    if (result[0]) {
                        x.plantLocationAddress = this.getLocationPlace(result[0]);

                        if (x.plantLocationAddress.includes(',')) {
                            x.plantLocationAddress = x.plantLocationAddress.substring(0, x.plantLocationAddress.indexOf(','));
                        }

                        latitude = result[0].geometry.coordinates[1];
                        longitude = result[0].geometry.coordinates[0];
                    }
                }

                aggregateProducers.push({
                    comment: x.comment ? x.comment.toString() : null,
                    companyName: x.companyName ? x.companyName.toString() : null,
                    address: x.plantLocationAddress ? x.plantLocationAddress.toString() : null,
                    latitude: latitude,
                    longitude: longitude,
                    annualProduction: annualProductionVolume,
                    reserves: reserves,
                    applicationId: this.defaultApplicationID,
                    geologyType: x.geologyType ? x.geologyType.toString() : null,
                });

                customIndex++;

                if (customIndex == data.length) {
                    this.importAggregateProducers({
                        marketId: this.market.id,
                        aggregateProducers: aggregateProducers,
                    }).then((result) => {
                        if (result) {
                            location.reload();
                        } else {
                            this.setIsDataImporting(false);
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 32px !important;
    line-height: 38px !important;
    letter-spacing: -0.114286px !important;
}

.sub-title {
    font-size: 15px !important;
    line-height: 22px !important;
}

.aggregates-producers-top{
	padding: 15px 20px;
	background: #FFFFFF;
	box-shadow: 0 0 10px rgba(110, 140, 163, 0.15);
	border-radius: 5px;
	margin-bottom: 25px;
	position: relative;
}

.import-exel{
	position: absolute;
	right: 250px;
	top: 127px;
}
</style>

