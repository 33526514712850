<template>
    <MglMap :accessToken="accessToken" :mapStyle="mapStyle + codeStyle[changeStyle]" :center="markerCenter" :zoom="zoom" @load="onMapLoaded" @click="addMarker">
        <MglMarker v-for="(marker, i) in markersCoordinates" :key="i" :coordinates="marker.coordinates" @click="(e) => clickMarker(e, marker)" color="blue" class="ma-marker">
            <div slot="marker" v-if="marker.iconStyle"
				 :class="[marker.sameLoc ? 'sameLoc' : '', 'cust-icon', marker.iconStyle]"
				 :style="[
					 marker.plantType && marker.aggregatesProducerGeologies.length && marker.plantType.id === 4 ?
					  {'border-radius': '100% !important'} : {},
					 marker.aggregatesProducerGeologies ?
					  marker.aggregatesProducerGeologies[0].geologySubType ?
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 && marker.plantType.id === 4 ?
					  {'background-color': '#ff8939 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 && marker.plantType.id === 5 ?
					  {'background-color': '#ff8939 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 && marker.plantType.id === 8 ?
					  {'background-color': '#ff8939 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 ?
					  {'border-color': 'transparent transparent #ff8939 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 1 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 7 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 8 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 6 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 5 ?
					  {'background-color': '#9e9e9e !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 ?
					  {'background-color': '#9e9e9e !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 1 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 5 ?
					  {'background-color': '#0d3f66 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 7 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					   marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 8 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 6 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 ?
					  {'background-color': '#0d3f66 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 4 && marker.plantType.id === 5 ?
					  {'background-color': '#22b363 !important'} :
					   marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 4 && marker.plantType.id === 8 ?
					  {'background-color': '#22b363 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 4 ?
					  {'border-color': 'transparent transparent #22b363 transparent !important'} :
					  {} :
					  {} :
					  {}
					  ]
					"

				 @mouseenter="active = marker.id" @mouseleave="active = null"
			>
                <img v-if="marker.plantType && marker.aggregatesProducerGeologies.length && marker.plantType.id === 4" src="@/assets/map/recycle.svg" />
                <img v-if="marker.iconImg" :src="marker.iconImg" class="icon-img" />
				<div v-if="marker.id === active && hover && marker.type !== 'customers'" class="hoverBlock">
					{{ marker.name }}
				</div>

<!--				marker.iconStyle ? [geologyColors(2).textColor + '&#45;&#45;text'] : ''-->
<!--				<div v-if="marker.plantType.id === 2">{{ marker.aggregatesProducerGeologies[0] }}</div>-->
<!--				<div v-if="marker.aggregatesProducerGeologies">-->
<!--					<div v-if="marker.aggregatesProducerGeologies[0].geologySubType">-->
<!--						{{ marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId }}-->
<!--					</div>-->
<!--				</div>-->

            </div>
        </MglMarker>
    </MglMap>
</template>

<script>
import config from '@/configs/map-config';
import * as turf from '@turf/turf';
import { MglMap, MglMarker } from 'vue-mapbox';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import { getGeologyTypeColors } from '@/core/helpers/geologyTypeColorHelper';

export default {
    props: {
        markers: {
            type: Array,
        },
        center: {
            type: Array,
        },
        popup: {
            type: Boolean,
            default: false,
        },
        zoom: {
            type: Number,
            default: 8,
        },
        type: {
            type: String, //'single', 'list'
            default: 'default',
        },
        addingNewMarkers: {
            type: Boolean,
            default: true,
        },
        hover: {
            type: Boolean,
        },
        boundary: {
            type: Array,
        },
        changeStyle: {
            type: String,
            default: 'light',
        },
        drawRoutes: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            accessToken: config.accessToken, // your access token. Needed if you using Mapbox maps
            mapStyle: config.mapStyle, // your map style
            codeStyle: {
                light: 'light-v9',
                streets: 'streets-v11',
                satellite: 'satellite-v9',
            },
            markerCenter: [],
            markersCoordinates: [],
            draw: null,
            removeMarker: false,
            active: false,
            mapLayers: [],
        };
    },
    created() {
        // We need to set mapbox-gl library here in order to use it in template
        this.mapbox = null;
	},
    mounted() {
        this.markerCenter = this.center && this.center.length ? this.center : [-77.03238901390978, 38.913188059745586];
        this.markersCoordinates = this.markers && this.markers.length ? this.markers : [];
	},
    watch: {
        markers(markers) {
            this.markersCoordinates = markers && markers.length ? markers : [];
            this.updateMarkers();
        },
    },
    methods: {
		geologyColors(geologyTypeId) {
			return getGeologyTypeColors(geologyTypeId);
		},
        updateMarkers() {
            if (this.mapLayers && this.mapLayers.length) {
                this.mapLayers.forEach((layer) => {
                    this.map.removeLayer(layer);
                    this.map.removeSource(layer);
                });
                this.mapLayers = [];
            }

            if (!this.drawRoutes || this.markersCoordinates.length < 2) return;

            const baseMarker = this.markersCoordinates[this.markersCoordinates.length - 1];
            const linkedMarkersCoordinates = this.markersCoordinates.slice(0, -1);

            linkedMarkersCoordinates.forEach((markerCoordiates, index) => {
                this.getRoute(baseMarker.coordinates, markerCoordiates.coordinates)
                    .then((routes) => {
                        if (routes && routes.length) {
                            this.addRouteLayer(index, routes);
                        }
                    })
                    .catch((error) => {
                        console.error(`Fetch route error (${baseMarker.coordinates};${markerCoordiates.coordinates})`, error);
                    });
            });
        },
        addRouteLayer(id, coordinates) {
            const routeLayerId = `route-${id}`;
            this.map.addLayer({
                id: routeLayerId,
                type: 'line',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: coordinates,
                        },
                    },
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': '#2EA2FF',
                    'line-width': 2,
                    'line-opacity': 0.8,
                },
            });
            this.mapLayers.push(routeLayerId);
        },
        async getRoute(start, dest) {
            return new Promise((resolve, reject) => {
                const coordinatesQuery = `${start.join(',')};${dest.join(',')}`;
                const directionsQuery = `${config.directionsApi}/${coordinatesQuery}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`;
                fetch(directionsQuery)
                    .then((mapboxResponse) => {
                        if (!mapboxResponse.ok) throw new Error(mapboxResponse.statusText);
                        return mapboxResponse.json();
                    })
                    .then((mapboxResponseData) => {
                        let route = [];
                        if (mapboxResponseData && mapboxResponseData.routes && mapboxResponseData.routes.length) {
                            route = mapboxResponseData.routes[0].geometry.coordinates;
                        }
                        resolve(route);
                    })
                    .catch((error) => reject(error));
            });
        },
        onMapLoaded(event) {
            this.map = event.map;
            // or just to store if you want have access from other components
            // this.$store.map = event.map;
            this.initalized(this.map);
        },
        initalized(map) {
            const modes = MapboxDraw.modes;
            modes.static = StaticMode;
            this.draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: false,
                    trash: false,
                },
                modes: modes,
            });
            map.addControl(this.draw);

            if (this.boundary) {
                const geometry = {
                    type: 'Polygon',
                    coordinates: [this.boundary],
                };
                this.draw.changeMode('static');
                this.draw.add(geometry);
            }
        },
        addMarker(e) {
            if (this.type !== 'list') {
                this.$emit('addMarker', e.mapboxEvent.lngLat);
            }
            const coordinates = [];
            if (!this.draw.getSelected().features.length && !this.removeMarker && this.addingNewMarkers) {
                coordinates.push(e.mapboxEvent.lngLat.lng);
                coordinates.push(e.mapboxEvent.lngLat.lat);
                const circle = this.drawByNewMarket(coordinates);
                const marker = {
                    id: this.markersCoordinates.length + 1,
                    coordinates,
                    polygonCoordinates: circle.geometry,
                    popup: {
                        name: 'Marker',
                    },
                };
                if (this.type === 'single') {
                    this.markersCoordinates = [marker];
                } else if (this.type === 'default') {
                    this.markersCoordinates.push(marker);
                }

                this.$store.commit('location/setLocation', this.markersCoordinates);
                this.$emit('markersCoordinates', this.markersCoordinates);
            }
            this.removeMarker = false;
        },
        clickMarker(e, marker) {
            if (!this.popup) {
                if (this.type !== 'list') {
                    const coordinate = [];
                    coordinate.push(e.marker.getLngLat().lng);
                    coordinate.push(e.marker.getLngLat().lat);
                    const diff = this.markersCoordinates.find((marker) => marker.coordinates[0] === coordinate[0] && marker.coordinates[1] === coordinate[1]);
                    if (diff) {
                        this.markersCoordinates = this.markersCoordinates.filter((marker) => marker.id !== diff.id);
                    }
                    e.marker.remove();
                } else {
                    this.$emit('addMarker', e.marker.getLngLat());
                }
            }
            this.$emit('clickMarker', marker);
            this.removeMarker = true;
        },
        drawByNewMarket(coordinates) {
            const radius = 5;
            const options = { steps: 30, units: 'kilometers' };
            return turf.circle(coordinates, radius, options);
        },
    },
    components: {
        MglMap,
        MglMarker,
    },
};
</script>

<style lang="scss">
.mgl-map-wrapper {
    position: relative;
    width: 100%;
}
.cust-icon {
    width: 20px;
    height: 20px;
    text-align: center;
    background: darkblue;
    fill: #fff;
    position: absolute;
    top: 0;

    &.bordx {
        background: #7e0027;
    }

    &.darkslategray {
        background: #353535;
    }

    &.circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
		z-index: 9;
    }
    &.pentagon {
		height: 22px !important;
		width: 22px !important;
        //border-width: 10px 6px 0 !important;
        //border-style: solid !important;
        //border-color: #000000 transparent !important;
        //background: transparent !important;
		//
        //&:after {
        //    content: '';
        //    position: absolute;
        //    height: 0;
        //    width: 0;
        //    top: -18px;
        //    left: -6px;
        //    border-width: 0 10px 8px;
        //    border-style: solid;
        //    border-color: transparent transparent black !important;
        //}
		//
        &:before {
            top: 1px !important;
            right: 2px !important;
        }

		clip-path: polygon( 50% 0, 100% 38%, 81% 100%, 19% 100%, 0 38%);

    }
    &.triangle {
        width: 0;
        height: 0;
        border-style: solid !important;
        border-width: 0 10px 16px 10px !important;
        border-color: transparent transparent orange transparent !important;
        background: transparent !important;

        &.grey {
            border-color: transparent transparent grey transparent !important;
        }
        &.darkblue {
            border-color: transparent transparent darkblue transparent !important;
        }
        &.green {
            border-color: transparent transparent green transparent !important;
        }
        &.black {
            border-color: transparent transparent black transparent !important;
        }
    }

    &.bordeaux::before {
        content: 'A';
        position: absolute;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background: #7e0027;
        right: -4px;
        top: -4px;
        font-size: 7px;
        font-weight: 500;
        display: block;
        line-height: 11px;
        text-align: center;
        color: #fff;
    }
    &.lighter_grey::before {
        content: 'C';
        position: absolute;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background: #bcbcbc;
        right: -4px;
        top: -4px;
        font-size: 7px;
        font-weight: 500;
        display: block;
        line-height: 11px;
        text-align: center;
        color: #000;
    }
    &.bordeaux_lighter_grey:before {
        content: '';
        position: absolute;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background: #bcbcbc;
        background: linear-gradient(to left, #7e0027 50%, #bcbcbc 50%);
        right: -4px;
        top: -4px;
    }
    &.pb_app:before {
        content: 'PB';
        position: absolute;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        background: #0b538c;
        right: -4px;
        top: -4px;
        font-size: 7px;
        font-weight: 500;
        display: block;
        line-height: 11px;
        text-align: center;
        color: #fff;
    }

    img.icon-img {
        margin-top: 4px;
        width: 18px;
    }

    .hoverBlock {
        position: absolute;
        top: -30px;
        width: max-content;
        padding: 5px 10px;
        background: #ffffff;
        font-weight: 500;
        border-radius: 4px;
    }
}
</style>
