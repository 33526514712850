<template>
    <section>
        <producers-customers-map></producers-customers-map>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import ProducersCustomersMap from '@/components/map/producersCustomersMap';

export default {
    name: 'ReportMap',
    components: {
        ProducersCustomersMap,
    },
    data() {
        return {};
    },
    mounted() {},
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
