<template>
  <v-card class="ml-3" max-width="800">
    <v-container fluid pa-10>
      <v-row justify="start" class="pb-5">
        <div class="title bismark--text">Validate formula for aggregate content in final product</div>
      </v-row>
      <v-row justify="start" class="pb-8">
        <div class="d-flex align-center content bermudaGray--text">
          <template v-if="isAsphaltSegment">
            <span style="text-transform: uppercase !important;">Tons of rapp per 1 t of asphalt:</span>
            <v-text-field
              v-model="tonsOfRappPerUnit"
              :disabled="isLoadingData"
              class="market-segments-editable-text-field-ton-per-unit px-2"
              background-color="whiteLilac"
              type="number"
              hide-details
              solo
              dense
              @input="onTonsOfRappPerUnitChanged"
            ></v-text-field>
          </template>
        </div>
      </v-row>
      <v-row justify="start" class="pb-8">
        <div class="d-flex align-center content bermudaGray--text">
          <template v-if="isReadyMixConcreteSegment">
            <span style="text-transform: uppercase !important;">Tons of aggregates per {{market.metric.volume}} of concrete:</span>
          </template>
          <template v-if="isAsphaltSegment">
            <span style="text-transform: uppercase !important;">Tons of virgin aggregates per 1 t of asphalt:</span>
          </template>
          <v-text-field
            v-model="tonsOfAggregatesPerUnit"
            :disabled="isLoadingData"
            class="market-segments-editable-text-field-ton-per-unit px-2"
            background-color="whiteLilac"
            type="number"
            hide-details
            solo
            dense
            @input="onTonsOfAggregatesPerUnitChanged"
          ></v-text-field>
          <span style="text-transform: uppercase !important;">from which</span>
        </div>
      </v-row>
      <v-row justify="center" class="pb-8">
        <v-data-table class="market-segments-formula-table" :headers="formulaHeaders" item-key="id" hide-default-footer>
          <template v-slot:body>
            <tbody>
              <template v-if="isLoadingData">
                <tr>
                  <td colspan="2" class="сapeCod--text text-no-wrap">
                    <div><v-progress-linear color="regalBlue" indeterminate></v-progress-linear></div>
                    <div class="pt-5 text-center">Loading... Please wait</div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditCoarseAggregatesPercent">
                        <div>
                          <v-text-field
                            v-model="comparableCoarseAggregatesPercent"
                            @keydown.enter="
                              (coarseAggregatesPercent = comparableCoarseAggregatesPercent),
                                (isEditCoarseAggregatesPercent = !isEditCoarseAggregatesPercent)
                            "
                            @blur="
                              (coarseAggregatesPercent = comparableCoarseAggregatesPercent),
                                (isEditCoarseAggregatesPercent = !isEditCoarseAggregatesPercent)
                            "
                            :disabled="isSavingData"
                            class="market-segments-editable-text-field-percent"
                            background-color="whiteLilac"
                            type="number"
                            suffix="%"
                            hide-details
                            solo
                            dense
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-else>
                        <span
                          @click="
                            (comparableCoarseAggregatesPercent = coarseAggregatesPercent),
                              (isEditCoarseAggregatesPercent = !isEditCoarseAggregatesPercent)
                          "
                          >{{ coarseAggregatesPercent }}%</span
                        >
                      </template>
                    </div>
                  </td>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditFineAggregatesPercent">
                        <div>
                          <v-text-field
                            v-model="comparableFineAggregatesPercent"
                            @keydown.enter="
                              (fineAggregatesPercent = comparableFineAggregatesPercent), (isEditFineAggregatesPercent = !isEditFineAggregatesPercent)
                            "
                            @blur="
                              (fineAggregatesPercent = comparableFineAggregatesPercent), (isEditFineAggregatesPercent = !isEditFineAggregatesPercent)
                            "
                            :disabled="isSavingData"
                            class="market-segments-editable-text-field-percent"
                            background-color="whiteLilac"
                            type="number"
                            suffix="%"
                            hide-details
                            solo
                            dense
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-else>
                        <span
                          @click="
                            (comparableFineAggregatesPercent = fineAggregatesPercent), (isEditFineAggregatesPercent = !isEditFineAggregatesPercent)
                          "
                        >
                          {{ fineAggregatesPercent }}%
                        </span>
                      </template>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
      <v-row justify="start" class="pb-8">
        <div class="d-flex align-center">
          <template v-if="isReadyMixConcreteSegment">
            <span style="text-transform: uppercase !important;" class="content bermudaGray--text">Cement content per {{market.metric.volume}}</span>
          </template>
          <template v-if="isAsphaltSegment">
            <span style="text-transform: uppercase !important;" class="content bermudaGray--text">Binder content per 1 t</span>
          </template>
          <v-text-field
            v-model="contentPerUnit"
            class="market-segments-editable-text-field-content-per-unit px-2"
            background-color="whiteLilac"
            type="number"
            :suffix="market.metric.mass"
            hide-details
            solo
            dense
          ></v-text-field>
        </div>
      </v-row>
      <v-row justify="end">
        <v-btn @click="onCancelEditFormula()" :disabled="isCancelDisabled || isLoadingData || isSavingData" class="mr-5" text>
          <span class="cancel-text" :class="{ 'regalBlue--text': !isCancelDisabled && !isLoadingData && !isSavingData }">Cancel</span>
        </v-btn>
        <v-btn
          @click="onSaveMarketSegment()"
          :disabled="isLoadingData"
          :loading="isSavingData"
          :dark="!isLoadingData"
          color="mangoTango"
          width="150px"
        >
          <span class="accept-text white--text">Accept</span>
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { MarketSegmentType } from '@/core/enums/marketSegmentType';

export default {
  name: 'Formula',
  props: {
    currentTabIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoadingData: false,
      isSavingData: false,

      formulaHeaders: [
        {
          text: 'COARSE AGGREGATES',
          align: 'center',
          sortable: false,
          value: '',
        },
        {
          text: 'FINE AGGREGATES',
          align: 'center',
          sortable: false,
          value: '',
        },
      ],
      tonsOfAggregatesPerUnit: 0,
      tonsOfRappPerUnit: 0,
      coarseAggregatesPercent: 0,
      comparableCoarseAggregatesPercent: 0,
      fineAggregatesPercent: 0,
      comparableFineAggregatesPercent: 0,
      contentPerUnit: 0,
      isEditCoarseAggregatesPercent: false,
      isEditFineAggregatesPercent: false,
      marketSegmentType: MarketSegmentType,
    };
  },

  mounted() {
    this.isLoadingData = true;
    setTimeout(() => {
      if (
        this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit === null ||
        this.marketSegments[this.currentTabIndex].tonsOfRappPerUnit === null ||
        this.marketSegments[this.currentTabIndex].coarseAggregatesPercent === null ||
        this.marketSegments[this.currentTabIndex].fineAggregatesPercent === null ||
        this.marketSegments[this.currentTabIndex].contentPerUnit === null
      ) {
        if (this.isReadyMixConcreteSegment) {
          this.tonsOfAggregatesPerUnit = 2;
          this.coarseAggregatesPercent = 45;
          this.fineAggregatesPercent = 55;
          this.contentPerUnit = 300;
        }
        if (this.isAsphaltSegment) {
          this.tonsOfAggregatesPerUnit = 0.95;
          this.tonsOfRappPerUnit = 0;
          this.coarseAggregatesPercent = 65;
          this.fineAggregatesPercent = 35;

          switch (this.market.metric.id) {
            case 1: // Metric
              this.contentPerUnit = 50;
              break;
            case 2: // Imperial
              this.contentPerUnit = 112;
              break;
            default:
              this.contentPerUnit = 0;
          }
        }
      } else {
        this.tonsOfAggregatesPerUnit = this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit;
        this.tonsOfRappPerUnit = this.marketSegments[this.currentTabIndex].tonsOfRappPerUnit ?? 0;
        this.coarseAggregatesPercent = this.marketSegments[this.currentTabIndex].coarseAggregatesPercent;
        this.fineAggregatesPercent = this.marketSegments[this.currentTabIndex].fineAggregatesPercent;
        this.contentPerUnit = this.marketSegments[this.currentTabIndex].contentPerUnit;
      }
      this.isLoadingData = false;
    }, 500);
  },
  watch: {
    coarseAggregatesPercent() {
      if (this.coarseAggregatesPercent > 100) {
        this.coarseAggregatesPercent = 100;
      }
      if (this.coarseAggregatesPercent + this.fineAggregatesPercent !== 100) {
        this.fineAggregatesPercent = 100 - this.coarseAggregatesPercent;
      }
    },
    fineAggregatesPercent() {
      if (this.fineAggregatesPercent > 100) {
        this.fineAggregatesPercent = 100;
      }
      if (this.fineAggregatesPercent + this.coarseAggregatesPercent !== 100) {
        this.coarseAggregatesPercent = 100 - this.fineAggregatesPercent;
      }
    },
  },
  computed: {
    ...mapGetters('marketAssessment', {
      market: 'market',
    }),
    ...mapGetters('marketSegment', {
      marketSegments: 'marketSegments',
    }),
    isReadyMixConcreteSegment() {
      if (this.marketSegments.length) {
        if (this.marketSegments[this.currentTabIndex]) {
          return [this.marketSegmentType.ReadyMixConcrete].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
        }
        return false;
      }
      return false;
    },
    isAsphaltSegment() {
      if (this.marketSegments.length) {
        if (this.marketSegments[this.currentTabIndex]) {
          return [this.marketSegmentType.Asphalt].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
        }
        return false;
      }
      return false;
    },
    isCancelDisabled() {
      return (
        this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit === null ||
        this.marketSegments[this.currentTabIndex].tonsOfRappPerUnit === null ||
        this.marketSegments[this.currentTabIndex].coarseAggregatesPercent === null ||
        this.marketSegments[this.currentTabIndex].fineAggregatesPercent === null ||
        this.marketSegments[this.currentTabIndex].contentPerUnit === null
      );
    },
  },
  methods: {
    ...mapMutations('marketSegment', {
      updateMarketSegmentValueByIndex: 'updateMarketSegmentValueByIndex',
    }),
    ...mapActions('marketSegment', {
      saveMarketSegment: 'saveMarketSegment',
    }),
    onCancelEditFormula() {
      this.$emit('cancel-edit-formula');
    },
    onTonsOfAggregatesPerUnitChanged(value) {
      const parsedValue = parseFloat(value);
      const parsedTonsOfRappPerUnit = parseFloat(this.tonsOfRappPerUnit);
      this.updateContentPerUnit(parsedValue, parsedTonsOfRappPerUnit);
    },
    onTonsOfRappPerUnitChanged(value) {
      const parsedValue = parseFloat(value);
      const parsedTonsOfAggregatesPerUnit = parseFloat(this.tonsOfAggregatesPerUnit);
      this.updateContentPerUnit(parsedTonsOfAggregatesPerUnit, parsedValue);
    },
    updateContentPerUnit(tonsOfAggregatesPerUnit, tonsOfRappPerUnit) {
      if(this.isReadyMixConcreteSegment) return;
      if (tonsOfAggregatesPerUnit === undefined || tonsOfRappPerUnit === undefined) return;

      let calculatedContent = 0;
      switch (this.market.metric.id) {
        case 1: // Metric
          calculatedContent = 1000 - ((tonsOfAggregatesPerUnit + tonsOfRappPerUnit) * 1000);
          break;
        case 2: // Imperial
          calculatedContent = 2240 - ((tonsOfAggregatesPerUnit + tonsOfRappPerUnit) * 2240);
          break;
        default:
          calculatedContent = 0;
      }

      this.contentPerUnit = Math.ceil(calculatedContent) >= 0 ? calculatedContent : 0;
    },
    onSaveMarketSegment() {
      this.isSavingData = true;
      this.saveMarketSegment({
        id: this.marketSegments[this.currentTabIndex].id,
        segmentId: this.marketSegments[this.currentTabIndex].segmentId,
        marketId: this.marketSegments[this.currentTabIndex].marketId,
        tonsOfAggregatesPerUnit: parseFloat(this.tonsOfAggregatesPerUnit),
        tonsOfRappPerUnit: parseFloat(this.tonsOfRappPerUnit),
        coarseAggregatesPercent: parseFloat(this.coarseAggregatesPercent),
        fineAggregatesPercent: parseFloat(this.fineAggregatesPercent),
        contentPerUnit: parseFloat(this.contentPerUnit),
      }).then((res) => {
        if (res) {
          this.updateMarketSegmentValueByIndex({
            index: this.currentTabIndex,
            tonsOfAggregatesPerUnit: parseFloat(this.tonsOfAggregatesPerUnit),
            tonsOfRappPerUnit: parseFloat(this.tonsOfRappPerUnit),
            coarseAggregatesPercent: parseFloat(this.coarseAggregatesPercent),
            fineAggregatesPercent: parseFloat(this.fineAggregatesPercent),
            contentPerUnit: parseFloat(this.contentPerUnit),
          });
          this.$emit('accept-edit-formula');
        } else {
          this.isSavingData = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: -0.114286px !important;
}

.content {
  font-size: 13px !important;
  line-height: 15px !important;
  letter-spacing: 1.21333px !important;
}

.cancel-text {
  font-size: 13px !important;
  line-height: 22px !important;
}

.accept-text {
  font-size: 15px !important;
  line-height: 22px !important;
}

.row-min-height {
  min-height: 38px !important;
}
</style>
