<template>
	<div style="margin-top: -30px;">
		<v-row>
			<v-col cols="3" class="py-1 pr-16">
				<v-text-field
					v-model="search"
					label="Search..."
					background-color="white"
					class="other-segments-table-search"
					outlined
					clearable
					dense
				>
					<template v-slot:prepend-inner>
						<img src="@/assets/market_segments/search.svg">
					</template>
				</v-text-field>
			</v-col>
			<v-col cols=2 offset="7" class="d-flex justify-end py-1">
				<v-btn @click="refreshSplit" large text
					   class="btn refresh"
					   color="white"
				>
					Refresh Standart Split
				</v-btn>
			</v-col>
		</v-row>

		<div class="table-container">
			<v-data-table
				v-model="selectedAggregatesProducers"
				class="aggregates-producers-table"
				:style="{ 'background-color': backgroundColor }"
				:headers="aggregatesProducersHeaders"
				:items="aggregatesProducersData"
				height="65vh"
				:single-select="false"
				:items-per-page="-1"
				:mobile-breakpoint="-1"
				:loading="isLoadingData"
				:search="search"
				item-key="id"
				show-select
				fixed-header
				hide-default-footer
			>
				<template v-slot:progress>
					<v-progress-linear class="progress-loading" color="regalBlue" indeterminate></v-progress-linear>
					<div class="pt-5 text-center">Loading... Please wait</div>
				</template>
				<template v-slot:body="{ items }">
					<tbody>
					<template v-if="aggregatesProducersData.length === 0 && !isLoadingData">
						<tr>
							<td class="no-data" :colspan="aggregatesProducersHeaders.length" style="border-left: 1px solid #e2e9f9 !important">
								<div class="text-center">No data</div>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr v-for="item in items" :key="item.id + getUniqueID">
							<td class="td-checkbox">
								<v-checkbox v-model="selectedAggregatesProducers" :value="item" style="margin: 0px; padding: 0px" class="pl-2" color="regalBlue" hide-details />
								<span
									:style="{ height: calculateProgress(item) + '% !important' }"
									class="line"
									@click="(item.showprogres = !item.showprogres), calcProgress(item)"
								></span>
								<span class="progress" v-show="item.showprogres">{{ item.progress }}%</span>
							</td>
							<td
								:style="{
                                    backgroundColor: !item.company || item.company.name === 'n/a' || item.company.name === '-' ? '#ffede1 !important' : '',
                                }"
								class="company-name-text сapeCod--text text-no-wrap"
							>
								<div class="d-flex align-center justify-center">
									<template v-if="item.isEditCompany">
										<v-combobox
											v-model="item.company"
											@blur="(item.company.name = searchCompany), closeEditTextField(item, 'companyName')"
											@keydown.enter="(item.company.name = searchCompany), closeEditTextField(item, 'companyName')"
											:items="companyItems"
											:style="{ 'display': item.isEditCompany ? 'block':'none' }"
											:id="`companyName${item.id}`"
											:loading="item.isSearchingCompanies"
											:search-input.sync="searchCompany"
											class="editable-text-field"
											background-color="whiteLilac"
											color="regalBlue"
											item-color="regalBlue"
											item-text="name"
											item-value="id"
											label="Search for a company..."
											hide-details
											hide-selected
											solo
										></v-combobox>
									</template>
									<template v-else>
                                        <span
											v-if="item.company.name.length"
											@click="(item.searchCompany = item.company.name),openEditTextField(item, 'companyName')"
											class="text-truncate"
										>
                                            {{ item.company.name }}
                                        </span>
										<span v-else @click="(item.searchCompany = item.company.name), openEditTextField(item, 'companyName')">-</span>
									</template>


									<template v-if="!item.comment || (item.comment && !item.comment.length)">
										<img
											@click="(item.isEditComment = !item.isEditComment), updateAggregatesProducer(item)"
											src="@/assets/comment/comment_plus.svg"
											class="cursor-pointer pl-3"
										/>
									</template>
									<template v-else>
										<v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
											<template v-slot:activator="{ on, attrs }">
												<img
													@click="(item.isEditComment = !item.isEditComment)"
													v-bind="attrs"
													v-on="on"
													src="@/assets/comment/comment_completed.svg"
													class="cursor-pointer pl-5"
												/>
											</template>
											<span>{{ item.comment }}</span>
										</v-tooltip>
									</template>
									<v-dialog :key="item.isEditComment" v-model="item.isEditComment" persistent max-width="800px">
										<v-card>
											<v-card-title>
												<v-spacer></v-spacer>
												<v-btn @click="item.isEditComment = false" small text fab>
													<img src="@/assets/icons/close.svg" />
												</v-btn>
											</v-card-title>
											<v-card-text class="pb-0">
												<v-container>
													<v-row justify="center" class="pb-8">
														<span class="comment-title regalBlue--text">Comment</span>
													</v-row>
													<v-row justify="center">
														<v-col>
															<v-textarea
																v-model="item.comment"
																class="market-segments-comment-text-area"
																background-color="ghostWhite"
																hide-details
																solo
															>
															</v-textarea>
														</v-col>
													</v-row>
													<v-row justify="center" class="pt-5">
														<v-col cols="4">
															<v-btn
																@click="(item.isEditComment = false), updateAggregatesProducer(item)"
																color="mangoTango"
																large
																block
															>
																<span class="comment-save-button white--text">Save</span>
															</v-btn>
														</v-col>
													</v-row>
												</v-container>
											</v-card-text>
											<v-card-actions>
												<v-container>
													<v-row justify="center" class="pb-5">
														<v-btn @click="item.isEditComment = false" text>
															<span class="comment-cancel-button regalBlue--text"> Cancel </span>
														</v-btn>
													</v-row>
												</v-container>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</div>

							</td>
							<td
								:style="{
                                    backgroundColor: !item.plantName || item.plantName === 'n/a' || item.plantName === '-' ? '#ffede1 !important' : '',
                                }"
								class="normal-text сapeCod--text"
							>
								<div class="column-width d-flex align-center justify-center ">
									<template>
										<v-text-field
											v-model="item.plantName"
											@blur="closeEditTextField(item, 'plantName')"
											@keydown.enter="closeEditTextField(item, 'plantName')"
											class="editable-text-field textFields"
											:style="{ 'display': item.isEditPlantName ? 'block':'none' }"
											:id="`plantName${item.id}`"
											hide-details
											solo
											dense
										></v-text-field>
										<span v-if="item.plantName.length"
											  @click="openEditTextField(item, 'plantName')"
											  :style="{ 'display': !item.isEditPlantName ? 'block':'none' }"
											  class="text-truncate">{{ item.plantName }}
										</span>
									</template>
								</div>
							</td>

							<td
								:style="{
                                    backgroundColor: !item.group || groups.map((x) => x.id).includes(item.group.id) === false ? '#ffede1 !important' : '',
                                }"
								class="normal-text сapeCod--text text-no-wrap"
							>
								<div class="d-flex align-center justify-center" :style="{ overflow: 'hidden' }">
									<v-select
										v-model="item.group"
										:items="groups"
										:background-color="!item.group || groups.map((x) => x.id).includes(item.group.id) === false ? '#ffede1' : ''"
										class="group-select"
										color="regalBlue"
										item-color="regalBlue"
										item-text="name"
										item-value="id"
										return-object
										hide-details
										dense
										solo
										@change="updateAggregatesProducer(item)"
									>
										<template v-slot:selection="{ item, index }">
											<div class="d-flex align-center aggregates-producers-group-name">
												<img src="@/assets/icons/user_active.svg" v-if="item.isMyGroup" class="mr-2">
												{{ item.name }}
											</div>
										</template>
										<template v-slot:item="{ item, index }">
											<div class="d-flex align-center justify-space-between" style="width: 100%;">
												{{ item.name }}
												<img src="@/assets/icons/user_active.svg" v-if="item.isMyGroup" class="ml-3">
											</div>

										</template>
									</v-select>
								</div>
							</td>
							<td
								:style="{ backgroundColor: !item.address || item.address === 'n/a' ? '#ffede1 !important' : '' }"
								class="normal-text сapeCod--text text-no-wrap"
								@click.stop
							>
								<div class="column-width d-flex align-center justify-center" :style="{ maxWidth: '260px' }">
									<template v-if="openTextLocation && item.id === openTextLocation">
										<input-location
											style="background-color: #ffffff"
											:placeholder="'Search address'"
											:classEl="
                                                (aggregatesProducersData[aggregatesProducersData.length - 1].id === item.id ||
                                                    aggregatesProducersData[aggregatesProducersData.length - 2].id === item.id) &&
                                                aggregatesProducersData.length <= 2
                                                    ? 'sm-el'
                                                    : aggregatesProducersData[aggregatesProducersData.length - 1].id === item.id ||
                                                      aggregatesProducersData[aggregatesProducersData.length - 2].id === item.id
                                                    ? 'last-el'
                                                    : ''
                                            "
											@setLocation="locationSelected(item, $event)"
										/>
									</template>
									<template v-else>
                                        <span class="text-truncate">
                                            <img @click="item.showMap = !item.showMap" src="@/assets/map/map_point.svg" style="width: 15px" class="mr-3" />
                                            <span
												@click="
                                                    openTextLocation = item.id;
                                                    item.isEditLocation = !item.isEditLocation;
                                                "
											>
                                                {{ item.address }}
                                            </span>
                                        </span>
									</template>
									<v-dialog :key="item.longitude" v-model="item.showMap" persistent class="map-modal" max-width="85%">
										<v-card>
											<v-card-title>
												<v-spacer></v-spacer>
												<v-btn @click="item.showMap = false" small text fab>
													<img src="@/assets/icons/close.svg" />
												</v-btn>
											</v-card-title>


											<v-card-text>
												<v-container>
													<div class="mb-10 map-modal-search">
														<span class="text-truncate">
																<span>
																	{{ item.address }}
																</span>
															</span>
														<div class="d-flex">
															<img src="@/assets/market_segments/search.svg">
															<input-location
																:key="item.showMap"
																style="background-color: #ffffff"
																:placeholder="'Search address'"
																:classEl="
															(aggregatesProducersData[aggregatesProducersData.length - 1].id === item.id ||
																aggregatesProducersData[aggregatesProducersData.length - 2].id === item.id) &&
															aggregatesProducersData.length <= 2
																? 'sm-el'
																: aggregatesProducersData[aggregatesProducersData.length - 1].id === item.id ||
																  aggregatesProducersData[aggregatesProducersData.length - 2].id === item.id
																? 'last-el'
																: ''
                                            			"
																@setLocation="locationSelected(item, $event)"
															/>
														</div>

													</div>

													<map-location
														:key="item.longitude"
														:type="'single'"
														:showMapStyle="true"
														:boundary="item.address === 'n/a'"
														:listMarkers="[{ longitude: item.longitude, latitude: item.latitude }]"
														@location="locationSelected(item, $event)"
													/>
												</v-container>
											</v-card-text>

										</v-card>
									</v-dialog>
								</div>
							</td>
							<td class="normal-text сapeCod--text text-center">
								<span class="text-truncate">
									<span v-if="item.latitude">
										{{ item.latitude }}
									</span>
								</span>
							</td>
							<td class="normal-text сapeCod--text text-center">
								<span class="text-truncate">
									<span v-if="item.longitude">
										{{ item.longitude }}
									</span>
								</span>
							</td>
							<td :style="{ backgroundColor: !item.plantType ? '#ffede1 !important' : '' }" class="normal-text сapeCod--text text-no-wrap">
								<div class="d-flex align-center justify-center">
									<v-select
										v-model="item.plantType"
										:items="plantTypes"
										:background-color="item.plantType ? plantTypeColors(item.plantType.id).backgroundColor : '#ffede1 !important'"
										class="tag-select"
										color="regalBlue"
										item-color="regalBlue"
										item-text="name"
										item-value="id"
										return-object
										hide-details
										dense
										solo
										@change="updateAggregatesProducer(item)"
									>
										<template
											:class="item.plantType ? [plantTypeColors(item.plantType.id).textColor + '--text'] : ''"
											slot="selection" slot-scope="data">{{ data.item.shortName }}</template>
										<template
											:class="item.plantType ? [plantTypeColors(item.plantType.id).textColor + '--text'] : ''"
											slot="item" slot-scope="data">{{ data.item.name }}</template>
									</v-select>
								</div>
							</td>
							<td :style="{ backgroundColor: !item.geology ? '#ffede1 !important' : '' }" class="normal-text сapeCod--text text-no-wrap">
								<div class="d-flex align-center justify-center">
									<v-select
										v-model="item.geology"
										:items="geologySubTypes"
										:background-color="item.geology ? geologyColors(item.geology.geologyTypeId).backgroundColor : '#ffede1 !important'"
										:class="item.geology ? [geologyColors(item.geology.geologyTypeId).textColor + '--text'] : ''"
										class="text-truncate"
										color="regalBlue"
										item-color="regalBlue"
										item-text="name"
										item-value="id"
										return-object
										hide-details
										dense
										solo
										@change="updateAggregatesProducer(item), showGeologyWarning()"
									>
										<template slot="selection" slot-scope="data">{{ data.item.name }}</template>
										<template slot="item" slot-scope="data">{{ data.item.name }}</template>
									</v-select>
								</div>
							</td>
							<td class="normal-text сapeCod--text">
								<div class="column-width d-flex align-center justify-center">
									<template>
										<v-text-field
											min="0"
											type="number"
											single-line
											oninput="this.value = Math.abs(this.value)"
											v-model="item.annProdVol"
											@blur="closeEditTextField(item, 'annProdVol')"
											@keydown.enter="closeEditTextField(item, 'annProdVol')"
											:id="`annProdVol${item.id}`"
											:style="{ 'display': item.isEditAnnProdVol ? 'block':'none' }"
											class="editable-text-field textFields"
											hide-details
											solo
											dense
										></v-text-field>
										<span
											  @click="openEditTextField(item, 'annProdVol')"
											  :style="{ 'display': !item.isEditAnnProdVol ? 'block':'none' }"
											  class="text-truncate">{{ item.annProdVol }}
										</span>
									</template>
								</div>
							</td>
							<td
								:style="{
                                    backgroundColor: item.soldVolPercent <= 0 ? '#ffede1 !important' : '',
                                }"
								class="normal-text сapeCod--text"
							>
								<div class="column-width d-flex align-center justify-center">
									<template>
										<v-text-field
											v-model="item.soldVolPercent"
											min="0"
											max="100"
											oninput="this.value = Math.abs(this.value)"
											type="number"
											@blur="closeEditTextField(item, 'soldVolPercent')"
											@keydown.enter="closeEditTextField(item, 'soldVolPercent')"
											:id="`soldVolPercent${item.id}`"
											:style="{ 'display': item.isEditSoldVolPercent ? 'block':'none' }"
											@keyup="item.soldVolPercent = item.soldVolPercent > 100 ? '100' : item.soldVolPercent"
											class="editable-text-field textFields"
											hide-details
											solo
											dense
										></v-text-field>
										<span
											@click="openEditTextField(item, 'soldVolPercent')"
											:style="{ 'display': !item.isEditSoldVolPercent ? 'block':'none' }"
											class="text-truncate">{{ toFormatNumber(item.soldVolPercent) }} %
										</span>
									</template>
								</div>
							</td>
							<td
								:style="{
                                    backgroundColor: roundNumber((item.soldVolPercent * item.annProdVol) / 100) <= 0 ? '#ffede1 !important' : '',
                                }"
								class="normal-text сapeCod--text disabled-text"
							>
								<div class="column-width d-flex align-center justify-center">
									<template>
										<span class="text-truncate">{{ toFormatNumber(roundNumber((item.soldVolPercent * item.annProdVol) / 100)) }} t</span>
									</template>
								</div>
							</td>
							<td
								:style="{
                                    backgroundColor: !item.reserves || item.reserves <= 0 ? '#ffede1 !important' : '',
                                }"
								class="normal-text сapeCod--text"
							>
								<div class="column-width d-flex align-center justify-center">
									<template>
										<v-text-field
											min="0"
											type="number"
											oninput="this.value = Math.abs(this.value)"
											v-model="item.reserves"
											@blur="closeEditTextField(item, 'reserves')"
											@keydown.enter="closeEditTextField(item, 'reserves')"
											:style="{ 'display': item.isEditReserves ? 'block':'none' }"
											:id="`reserves${item.id}`"
											class="editable-text-field textFields"
											hide-details
											solo
											dense
										></v-text-field>
										<span
											@click="openEditTextField(item, 'reserves')"
											:style="{ 'display': !item.isEditReserves ? 'block':'none' }"
											class="text-truncate">{{ toFormatNumber(item.reserves) }} t
										</span>
									</template>
								</div>
							</td>
							<td class="normal-text сapeCod--text text-no-wrap">
								<div class="d-flex align-center justify-center">
									<v-select
										v-model="item.primaryApp"
										:items="applications"
										:background-color="applicationsColors(item.primaryApp).backgroundColor"
										class="tag-select"
										color="regalBlue"
										item-color="regalBlue"
										item-text="name"
										item-value="id"
										return-object
										hide-details
										dense
										solo
										@change="updateAggregatesProducer(item)"
									>
										<template slot="selection" slot-scope="data">{{ data.item.shortName }}</template>
										<template slot="item" slot-scope="data">{{ data.item.name }}</template>
									</v-select>
								</div>
							</td>
							<td class="normal-text сapeCod--text">
								<div class="column-width d-flex align-center justify-center">
									<template>
										<v-text-field
											min="0"
											max="100"
											type="number"
											oninput="this.value = Math.abs(this.value)"
											v-model="item.coarseMaterial.percentage"
											@blur="closeEditTextField(item, 'coarseMaterial')"
											@keydown.enter="closeEditTextField(item, 'coarseMaterial')"
											:style="{ 'display': item.isEditCoarseMaterial ? 'block':'none' }"
											:id="`coarseMaterial${item.id}`"
											class="editable-text-field textFields"
											hide-details
											solo
											dense
										></v-text-field>
										<span
											@click="openEditTextField(item, 'coarseMaterial')"
											:style="{ 'display': !item.isEditCoarseMaterial ? 'block':'none' }"
											class="text-truncate">{{ toFormatNumber(item.coarseMaterial.percentage) }} %
										</span>
									</template>
								</div>
							</td>

							<td class="normal-text сapeCod--text">
								<div class="column-width d-flex align-center justify-center">
									<template>
										<v-text-field
											min="0"
											max="100"
											oninput="this.value = Math.abs(this.value)"
											type="number"
											v-model="item.fineMaterial.percentage"
											@blur="closeEditTextField(item, 'fineMaterial')"
											@keydown.enter="closeEditTextField(item, 'fineMaterial')"
											:style="{ 'display': item.isEditFineMaterial ? 'block':'none' }"
											:id="`fineMaterial${item.id}`"
											class="editable-text-field textFields"
											hide-details
											solo
											dense
										></v-text-field>
										<span
											@click="openEditTextField(item, 'fineMaterial')"
											:style="{ 'display': !item.isEditFineMaterial ? 'block':'none' }"
											class="text-truncate">{{ toFormatNumber(item.fineMaterial.percentage) }} %
										</span>
									</template>
								</div>
							</td>

							<td class="normal-text сapeCod--text">
								<div class="column-width d-flex align-center justify-center">
									<template>
										<v-text-field
											min="0"
											max="100"
											oninput="this.value = Math.abs(this.value)"
											type="number"
											v-model="item.fbMaterial.percentage"
											@blur="closeEditTextField(item, 'fbMaterial')"
											@keydown.enter="closeEditTextField(item, 'fbMaterial')"
											:style="{ 'display': item.isEditFBMaterial ? 'block':'none' }"
											:id="`fbMaterial${item.id}`"
											class="editable-text-field textFields"
											hide-details
											solo
											dense
										></v-text-field>
										<span
											@click="openEditTextField(item, 'fbMaterial')"
											:style="{ 'display': !item.isEditFBMaterial ? 'block':'none' }"
											class="text-truncate">{{ toFormatNumber(item.fbMaterial.percentage) }} %
										</span>
									</template>
								</div>
							</td>
							<td class="normal-text сapeCod--text" :class="[item.otherMaterial.percentage < 0 ? 'error-text' : '']">
								<div class="column-width d-flex align-center justify-center">
									<template>
										<v-text-field
											type="number"
											oninput="this.value = Math.abs(this.value)"
											v-model="item.otherMaterial.percentage"
											@blur="closeEditTextField(item, 'otherMaterial')"
											@keydown.enter="closeEditTextField(item, 'otherMaterial')"
											:style="{ 'display': item.isEditOtherMateriall ? 'block':'none' }"
											:id="`otherMaterial${item.id}`"
											class="editable-text-field textFields"
											hide-details
											solo
											dense
										></v-text-field>
										<span
											@click="openEditTextField(item, 'otherMaterial')"
											:style="{ 'display': !item.isEditOtherMateriall ? 'block':'none' }"
											class="text-truncate">{{ toFormatNumber(item.otherMaterial.percentage) }} %
										</span>
									</template>
								</div>
							</td>

							<td class="normal-text сapeCod--text text-no-wrap" :style="{ minWidth: '290px' }">
								<div class="d-flex align-center justify-center">
									<v-select
										v-model="item.transMode"
										:items="filterTransportTypes()"
										class="group-select"
										color="regalBlue"
										item-color="regalBlue"
										item-text="name"
										item-value="id"
										return-object
										hide-details
										dense
										multiple
										solo
										@change="updateAggregatesProducer(item)"
									></v-select>
								</div>
							</td>
							<td>
								<div class="d-flex align-center justify-center">
									<v-select
										v-model="item.tag"
										:items="filterTags()"
										:background-color="tagColors(item.tag).backgroundColor"
										class="tag-select"
										color="regalBlue"
										item-color="regalBlue"
										item-text="name"
										item-value="id"
										return-object
										hide-details
										dense
										solo
										@change="updateAggregatesProducer(item)"
									>
										<template slot="selection" slot-scope>
                                            <span :class="tagColors(item.tag).textColor + '--text'">
                                                {{ item.tag ? item.tag.name : 'None' }}
                                            </span>
										</template>
									</v-select>
								</div>
							</td>
<!--							<td class="normal-text сapeCod&#45;&#45;text">-->
<!--								<div class="column-width d-flex align-center justify-center">-->
<!--									<template v-if="item.isEditComment">-->
<!--										<v-text-field-->
<!--											v-model="item.comment"-->
<!--											oninput="this.value = Math.abs(this.value)"-->
<!--											@blur="(item.isEditComment = !item.isEditComment), updateAggregatesProducer(item)"-->
<!--											@keydown.enter="(item.isEditComment = !item.isEditComment), updateAggregatesProducer(item)"-->
<!--											class="editable-text-field"-->
<!--											background-color="whiteLilac"-->
<!--											hide-details-->
<!--											solo-->
<!--											dense-->
<!--										></v-text-field>-->
<!--									</template>-->
<!--									<template v-else>-->
<!--										<span v-if="item.comment.length" @click="item.isEditComment = !item.isEditComment" class="text-truncate">{{ item.comment }}</span>-->
<!--										<span v-else @click="item.isEditComment = !item.isEditComment">-</span>-->
<!--									</template>-->
<!--								</div>-->
<!--							</td>-->
						</tr>
					</template>
					</tbody>
				</template>
			</v-data-table>
			<edit-data-toolbar :data-count="selectedAggregatesProducers.length" @cancel="onCancelEditData()" @duplicate="onDuplicateEditData()" @delete="onDeleteEditData()" />
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { cloneDeep, debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getGeologyTypeColors } from '@/core/helpers/geologyTypeColorHelper';
import { getTagColors } from '@/core/helpers/tagColorHelper';
import { getApplicationsColors } from '@/core/helpers/applicationsColorHelper';
import { getPlantTypeColors } from '@/core/helpers/plantTypeColorHelper';

import InputLocation from '../lib/inputLocation';
import MapLocation from '../lib/mapLocation';
import EditDataToolbar from '@/components/editDataToolbar/EditDataToolbar.vue';
import {resetPercentageSplit} from "../../store/aggregatesProducers/actions";

export default {
	name: 'ProducerTable',
	components: {
		InputLocation,
		MapLocation,
		EditDataToolbar,
	},
	data() {
		return {
			rules: {
				loanMin: value => value >= 10 || 'Loan should be above £5000',
				loanMax: value => value <= 100 || 'Max should not be above £50,000',
			},
			search: null,
			isLoadingData: false,
			selectedAggregatesProducers: [],
			aggregatesProducersHeaders: [
				{
					text: 'COMPANY NAME',
					align: 'center',
					sortable: true,
					value: 'company.name',
				},
				{
					text: 'PLANT NAME',
					align: 'center',
					sortable: false,
				},
				{
					text: 'GROUP',
					align: 'center',
					sortable: false,
					value: 'group.name',
				},
				{
					text: 'LOCATION',
					align: 'center',
					sortable: false,
				},
				{
					text: 'LATITUDE',
					align: 'center',
					sortable: false,
				},
				{
					text: 'LONGITUDE',
					align: 'center',
					sortable: false,
				},
				{
					text: 'PLANT TYPE',
					align: 'center',
					sortable: false,
				},
				{
					text: 'GEOLOGY TYPE',
					align: 'center',
					sortable: false,
				},
				{
					text: 'ANNUAL PRODUCTION VOLUME',
					align: 'center',
					sortable: true,
					value: 'annProdVol',
				},
				{
					text: 'VOLUME SOLD IN THE MARKET (%)',
					align: 'center',
					sortable: true,
					value: 'soldVolPercent',
				},
				{
					text: 'VOLUME SOLD IN THE MARKET (T)',
					align: 'center',
					sortable: false,
				},
				{
					text: 'RESERVES',
					align: 'center',
					sortable: true,
					value: 'reserves',
				},
				{
					text: 'PRIMARY APPLICATION',
					align: 'center',
					sortable: false,
				},

				{
					text: 'COARSE MATERIAL',
					align: 'center',
					sortable: false,
				},
				{
					text: 'FINE MATERIAL',
					align: 'center',
					sortable: false,
				},
				{
					text: 'FILL/BASE MATERIAL',
					align: 'center',
					sortable: false,
				},
				{
					text: 'OTHER MATERIAL',
					align: 'center',
					sortable: false,
				},
				{
					text: 'TRANS. MODE TO THE MKT.',
					align: 'center',
					sortable: false,
				},
				{
					text: 'TAGS',
					align: 'center',
					sortable: false,
				},
			],
			aggregatesProducersData: [],
			comparableAggregatesProducersData: [],
			customerPlantId: 1,
			selectedlocation: null,
			locationItems: [],
			searchLocation: null,
			openTextLocation: null,
			searchCompany: null,
			companyItems: [],
			isLoadingEditDataToolbar: true,
		};
	},

	mounted() {
		if (this.market) {
			this.init();
			this.setIsDisplayPaginationToolbar(true);
			this.initPagination();
		}
		document.addEventListener('click', this.outsideClickEventListener);
	},
	watch: {
		searchLocation(val) {
			if (this.searchQueryTimeout) {
				clearTimeout(this.searchQueryTimeout);
			}
			this.searchQueryTimeout = setTimeout(() => {
				this.$store.dispatch('geocoding/searchLocation', val).then(() => {
					this.locationItems = this.$store.getters['geocoding/getLocations'];
					this.searchQueryTimeout = null;
				});
			}, 800);
		},
		searchCompany(val) {
			if (this.searchQueryTimeout) {
				clearTimeout(this.searchQueryTimeout);
			}
			this.searchQueryTimeout = setTimeout(() => {
				axios.post('AggregatesProducers/SearchCompanies', { term: val }).then((res) => {
					this.companyItems = res.data.companies;
					this.searchQueryTimeout = null;
				});
			}, 800);
		},
		pageSize() {
			if (this.aggregatesProducers.length) {
				this.initPagination();
				this.reInitAggregatesProducertData();
			}
		},
		page() {
			this.reInitAggregatesProducertData();
		},
		currentTab(data) {
			this.initPagination();
			this.reInitAggregatesProducertData();
			if (data === 0) {
				this.setIsDisabledEditDataToolbarDeleteButton(false);
			} else {
				this.setIsDisabledEditDataToolbarDeleteButton(true);
			}
		},
		selectedAggregatesProducers(data) {
			if (data.length) {
				this.setIsDisplayEditDataToolbar(true);
			} else {
				this.setIsDisplayEditDataToolbar(false);
			}
		},
		triggerAddData() {
			this.setShowAddProducer(true);
			document.body.classList.add('overflow');
		},
		market() {
			this.setIsDisplayPaginationToolbar(true);
			this.isLoadingData = false;
			this.aggregatesProducersData = [];
			this.comparableAggregatesProducersData = [];
			this.selectedAggregatesProducers = [];
			this.initPagination();
			this.init();
		},
	},
	computed: {
		...mapGetters('paginationToolbar', {
			triggerAddData: 'triggerAddData',
			pageSize: 'pageSize',
			page: 'page',
		}),
		...mapGetters('aggregatesProducers', {
			aggregatesProducers: 'aggregatesProducers',
			companies: 'companies',
			groups: 'groups',
			geologies: 'geologies',
			plantTypes: 'plantTypes',
			tags: 'tags',
			productTypes: 'productTypes',
			applications: 'applications',
			transportTypes: 'transportTypes',
			showAddProducer: 'showAddProducer',
		}),
		...mapGetters('marketAssessment', {
			market: 'market',
		}),
		backgroundColor() {
			return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
		},
		getUniqueID() {
			return uuidv4();
		},
		geologySubTypes() {
			const geologySubTypesArr = [];
			this.geologies.forEach((element) => {
				element.geologySubTypes.forEach((sub) => {
					geologySubTypesArr.push(sub);
				});
			});
			return geologySubTypesArr;
		},
		marketId() {
			return Number(localStorage.getItem('marketId'));
		},
		itemss() {
			return this.locationItems.map((entry) => {
				const Description = entry.text;

				return Object.assign({}, entry, { Description });
			});
		},
	},
	methods: {

		openEditTextField(item, name) {
			const property = this.getPropertyName(name);
			if (property) {
				item[property] = !item[property];
				this.$nextTick(() => {
					const elementId = `${name}${item.id}`;
					document.getElementById(elementId).focus();
				});
				this.updateAggregatesProducer(item);
			}
		},

		closeEditTextField(item, name) {
			const property = this.getPropertyName(name);
			if (property) {
				item[property] = !item[property];
				this.updateAggregatesProducer(item);
			}
		},

		getPropertyName(name) {
			const fieldMappings = {
				plantName: 'isEditPlantName',
				annProdVol: 'isEditAnnProdVol',
				soldVolPercent: 'isEditSoldVolPercent',
				reserves: 'isEditReserves',
				coarseMaterial: 'isEditCoarseMaterial',
				fineMaterial: 'isEditFineMaterial',
				fbMaterial: 'isEditFBMaterial',
				otherMaterial: 'isEditOtherMateriall',
				companyName: 'isEditCompany',
			};
			return fieldMappings[name] || null;
		},

		roundNumber(value, power = 2) {
			return Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
		},
		formatNumber(number, fraction, separator = ' ') {
			let data = number.toLocaleString('en-US');
			data = data.replace(/\./, 'F');
			data = data.replace(/,/g, 'S');
			data = data.replace(/F/, fraction);
			data = data.replace(/S/g, separator);
			return data;
		},
		toFormatNumber(data) {
			return this.formatNumber(data, this.market.separatorType === 0 ? '.' : ',');
		},
		...mapMutations('paginationToolbar', {
			setIsDisplayPaginationToolbar: 'setIsDisplayPaginationToolbar',
			setIsLoadingPaginationToolbar: 'setIsLoadingPaginationToolbar',
			setIsLoadingPaginationToolbarAddData: 'setIsLoadingPaginationToolbarAddData',
			initPagination: 'initPagination',
		}),
		...mapMutations('editDataToolbar', {
			setIsDisplayEditDataToolbar: 'setIsDisplayEditDataToolbar',
			setIsLoadingEditDataToolbar: 'setIsLoadingEditDataToolbar',
			setIsLoadingEditDataToolbarDuplicate: 'setIsLoadingEditDataToolbarDuplicate',
			setIsLoadingEditDataToolbarDelete: 'setIsLoadingEditDataToolbarDelete',
		}),
		...mapMutations('aggregatesProducers', {
			setShowAddProducer: 'setShowAddProducer',
		}),
		...mapActions('aggregatesProducers', {
			getAggregatesProducers: 'getAggregatesProducers',
			getGroups: 'getGroups',
			getGeologies: 'getGeologies',
			getPlantTypes: 'getPlantTypes',
			getTags: 'getTags',
			getProductTypes: 'getProductTypes',
			getApplications: 'getApplications',
			getTransportTypes: 'getTransportTypes',
			removeAggregatesProducers: 'removeAggregatesProducers',
			copyAggregatesProducers: 'copyAggregatesProducer',
			resetPercentageSplit: 'resetPercentageSplit'
		}),
		async init() {
			this.isLoadingData = true;
			const getAggregatesProducers = this.getAggregatesProducers({
				marketId: Number(localStorage.getItem('marketId')),
				page: this.page,
			});
			const getGroups = this.getGroups();
			const getPlantTypes = this.getPlantTypes();
			const getTags = this.getTags();
			const getProductTypes = this.getProductTypes();
			const getGeologies = this.getGeologies();
			const getApplications = this.getApplications();
			const getTransportTypes = this.getTransportTypes();
			await Promise.all([getAggregatesProducers, getGroups, getPlantTypes, getTags, getProductTypes, getGeologies, getApplications, getTransportTypes]);
			this.aggregatesProducersData = this.initAggregatesProcedursData();
			this.comparableAggregatesProducersData = cloneDeep(this.aggregatesProducersData);
			this.isLoadingData = false;
		},
		reInitAggregatesProducertData() {
			this.isLoadingData = true;
			this.setIsLoadingPaginationToolbar(true);
			this.setIsLoadingEditDataToolbar(true);
			this.aggregatesProducersData = [];
			setTimeout(async () => {
				await this.getAggregatesProducers({
					marketId: Number(localStorage.getItem('marketId')),
					page: this.page,
				});
				this.aggregatesProducersData = this.initAggregatesProcedursData();
				this.selectedAggregatesProducers = [];
				this.isLoadingData = false;
				this.setIsLoadingPaginationToolbar(false);
				this.setIsLoadingEditDataToolbar(false);
			}, 500);
		},
		generateUniqueID() {
			return uuidv4();
		},
		locationSelected(item, location) {
			this.aggregatesProducersData.forEach((elem) => {
				if (elem.id === item.id) {
					if (location) {
						elem.location = location;
					}
					elem.latitude = item.location.geometry.coordinates[1];
					elem.longitude = item.location.geometry.coordinates[0];
					let arr = item.location.context;

					for (var i = 0; i < arr.length; i++) {
						if (arr[i].id.split('.')[0] === 'place') {
							return (elem.address = arr[i].text);
						} else if (arr[i].id.split('.')[0] === 'locality') {
							return (elem.address = arr[i].text);
						} else {
							elem.address = item.location.place_name;
						}
					}
				}
			});
			this.updateAggregatesProducer(item);
			this.openTextLocation = null;
			// this.reInitAggregatesProducertData()
		},
		calculateProgress(item) {
			let count = 0;
			if (item.company && item.company.name !== 'n/a') count += 1;
			if (item.group && item.group.name !== 'No Group') count += 1;
			if (item.address && item.address !== 'n/a') count += 1;
			if (item.plantName && item.plantName !== 'n/a') count += 1;
			if (item.plantType) count += 1;
			if (item.geology) count += 1;
			if (item.annProdVol && item.annProdVol !== 0) count += 1;
			if (item.soldVol && item.soldVol !== 0) count += 1;
			if (item.reserves && item.reserves !== 0) count += 1;
			if (item.primaryApp && item.primaryApp.name !== 'n/a') count += 1;
			if (item.transMode) count += 1;
			// if (this.tagsVal) count += 1;
			// if (this.comment) count += 1;
			return Math.round((count / 11) * 100);
		},
		calcProgress(item) {
			let count = 0;
			if (item.company && item.company.name !== 'n/a') count += 1;
			if (item.group && item.group.name !== 'No Group') count += 1;
			if (item.address && item.address !== 'n/a') count += 1;
			if (item.plantName && item.plantName !== 'n/a') count += 1;
			if (item.plantType) count += 1;
			if (item.geology) count += 1;
			if (item.annProdVol && item.annProdVol !== 0) count += 1;
			if (item.soldVol && item.soldVol !== 0) count += 1;
			if (item.reserves && item.reserves !== 0) count += 1;
			if (item.primaryApp && item.primaryApp.name !== 'n/a') count += 1;
			if (item.transMode) count += 1;
			// if (this.tagsVal) count += 1;
			// if (this.comment) count += 1;
			item.progress = Math.round((count / 11) * 100);
		},
		initAggregatesProcedursData() {
			const tableData = [];
			this.aggregatesProducers.forEach((element) => {
				// eslint-disable-next-line prefer-const
				let productTypePercentage = [];
				if (element.aggregatesProducerGeologies[0]) {
					element.aggregatesProducerGeologies[0].primaryApplication.percentageOfProductTypes.forEach((e) => {
						if (e.productTypeId === 4) {
							productTypePercentage.coarse = e;
						}
						if (e.productTypeId === 3) {
							productTypePercentage.fine = e;
						}
						if (e.productTypeId === 2) {
							productTypePercentage.fillBase = e;
						}
						if (e.productTypeId === 1) {
							productTypePercentage.other = e;
						}
					});
				}

				tableData.push({
					id: element.id,
					company: element.investigatedCompany ? element.investigatedCompany : null,
					group: element.group ? element.group : null,
					location: null,
					address: element.address ? element.address : 'n/a',
					latitude: element.latitude ? element.latitude : 50,
					longitude: element.longitude ? element.longitude : 30,
					showMap: false,
					plantName: element.name ? element.name : 'n/a',
					plantType: element.plantType ? element.plantType : null,
					aggregatesProducerGeologyId: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].id : element.id,
					geology: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].geologySubType : null,
					annProdVol: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].annualAggregatesProduction : 0,
					soldVolPercent: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].volumeSoldInMarketPercent : 0,
					soldVol: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].volumeSoldInMarket : 0,
					reserves: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].reserves : 0,
					primaryApp: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].primaryApplication.application : null,
					coarseMaterial: productTypePercentage.coarse ? productTypePercentage.coarse : { percentage: 0, productTypeId: 4 },
					fineMaterial: productTypePercentage.fine ? productTypePercentage.fine : { percentage: 0, productTypeId: 3 },
					fbMaterial: productTypePercentage.fillBase ? productTypePercentage.fillBase : { percentage: 0, productTypeId: 2 },
					otherMaterial: productTypePercentage.other ? productTypePercentage.other : { percentage: 100, productTypeId: 1 },
					transMode: element.transportTypes ? element.transportTypes.map((x) => x.transportType) : [],
					tag: element.aggregatesProducerGeologies[0] ? element.aggregatesProducerGeologies[0].aggregatesProducerTag : null,
					comment: element.comment ? element.comment : '',
					foundedCompanies: [],
					isSearchingCompanies: false,
					isEditCompany: false,
					isEditLocation: false,
					isEditAnnProdVol: false,
					isEditSoldVolPercent: false,
					isEditSoldVol: false,
					isEditReserves: false,
					isEditCoarseMaterial: false,
					isEditFineMaterial: false,
					isEditFBMaterial: false,
					isEditOtherMateriall: false,
					isEditPlantName: false,
					isEditComment: false,
					progress: 0,
					showprogres: false,
				});
			});
			return tableData;
		},
		plantTypeColors(plantTypeId) {
			return getPlantTypeColors(plantTypeId);
		},
		geologyColors(geologyTypeId) {
			return getGeologyTypeColors(geologyTypeId);
		},
		tagColors(tag) {
			return getTagColors(tag);
		},
		applicationsColors(application) {
			return getApplicationsColors(application);
		},
		filterTransportTypes(item) {
			const transportTypes = [];
			// transportTypes.push({ id: null, name: 'None' });
			return transportTypes.concat(this.transportTypes);
		},
		filterTags() {
			const tags = [];
			tags.push({ id: null, index: null, name: 'None' });
			return tags.concat(this.tags);
		},
		updateAggregatesProducer(row) {
			this.calcProgress(row);
			row.annProdVol = Number.isInteger(Number(row.annProdVol)) ? Number(row.annProdVol) : Number(row.annProdVol).toFixed(2);
			row.reserves = Number.isInteger(Number(row.reserves)) ? Number(row.reserves) : Number(row.reserves).toFixed(2);
			row.soldVolPercent = Number.isInteger(Number(row.soldVolPercent)) ? Number(row.soldVolPercent) : Number(row.soldVolPercent).toFixed(2);
			row.coarseMaterial.percentage = Number.isInteger(Number(row.coarseMaterial.percentage))
				? Number(row.coarseMaterial.percentage)
				: Number(Number(row.coarseMaterial.percentage).toFixed(2));
			row.fineMaterial.percentage = Number.isInteger(Number(row.fineMaterial.percentage))
				? Number(row.fineMaterial.percentage)
				: Number(Number(row.fineMaterial.percentage).toFixed(2));
			row.fbMaterial.percentage = Number.isInteger(Number(row.fbMaterial.percentage))
				? Number(row.fbMaterial.percentage)
				: Number(Number(row.fbMaterial.percentage).toFixed(2));
			row.otherMaterial.percentage = Number(Number(100 - row.fbMaterial.percentage - row.fineMaterial.percentage - row.coarseMaterial.percentage).toFixed(2));
			const productTypePercentageArr = [];

			productTypePercentageArr.push({
				productTypeId: row.coarseMaterial.productTypeId ? row.coarseMaterial.productTypeId : 4,
				percent: row.coarseMaterial.percentage ? Number(row.coarseMaterial.percentage) : 0,
			});
			productTypePercentageArr.push({
				productTypeId: row.fineMaterial.productTypeId ? row.fineMaterial.productTypeId : 3,
				percent: row.fineMaterial.percentage ? Number(row.fineMaterial.percentage) : 0,
			});
			productTypePercentageArr.push({
				productTypeId: row.fbMaterial.productTypeId ? row.fbMaterial.productTypeId : 2,
				percent: row.fbMaterial.percentage ? Number(row.fbMaterial.percentage) : 0,
			});
			productTypePercentageArr.push({
				productTypeId: row.otherMaterial.productTypeId ? row.otherMaterial.productTypeId : 1,
				percent: row.otherMaterial.percentage ? Number(row.otherMaterial.percentage) : 0,
			});

			axios
				.post('AggregatesProducers/SaveAggregatesProducer', {
					aggregatesProducerId: row.id,
					marketId: Number(localStorage.getItem('marketId')),
					groupId: row.group ? row.group.id : null,
					plantTypeId: row.plantType ? row.plantType.id : null,
					transportTypeIds: row.transMode ? row.transMode?.map((x) => x.id) : null,
					companyName: row.company.name,
					plantName: row.plantName,
					latitude: row.latitude ? row.latitude : 50,
					longitude: row.longitude ? row.longitude : 30,
					address: row.address,
					comment: row.comment,
				})
				.then(() => {
					axios
						.post('AggregatesProducers/SaveAggregatesProducerGeology', {
							aggregatesProducerGeologyId: row.aggregatesProducerGeologyId ? row.aggregatesProducerGeologyId : null,
							aggregatesProducerId: row.id ? row.id : null,
							aggregatesProducerTagId: row.tag ? row.tag.id : null,
							applicationId: row.primaryApp ? row.primaryApp.id : null,
							geologySubTypeId: row.geology ? row.geology.id : null,
							marketId: Number(localStorage.getItem('marketId')),
							reserves: row.reserves ? Number(row.reserves) : 0,
							annualAggregatesProduction: row.annProdVol ? Number(row.annProdVol) : 0,
							volumeSoldInMarketPercent: row.soldVolPercent ? Number(row.soldVolPercent) : 0,
							productTypePercentage: productTypePercentageArr,
						})
						.catch((err) => {
							Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
						});
				})
				.catch((err) => {
					Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
				});
		},
		onCancelEditData() {
			this.selectedAggregatesProducers = [];
		},
		onDuplicateEditData() {
			this.isLoadingEditDataToolbar = true;
			this.setIsLoadingEditDataToolbarDuplicate(true);
			const selectedAggregatesProducerIds = this.selectedAggregatesProducers.map((item) => {
				return item.id;
			});
			const copiedAggregatesProducers = [];
			selectedAggregatesProducerIds.forEach((id) => {
				this.copyAggregatesProducers({
					aggregatesProducerId: id,
					marketId: Number(localStorage.getItem('marketId')),
				}).then((res) => {
					copiedAggregatesProducers.push({ id, copiedId: res });
					if (copiedAggregatesProducers.length === selectedAggregatesProducerIds.length) {
						this.finishDuplicateData(copiedAggregatesProducers);
					}
					this.reInitAggregatesProducertData();
				});
			});
		},
		finishDuplicateData(copiedAggregatesProducers) {
			const data = [];
			this.aggregatesProducersData.forEach((item) => {
				copiedAggregatesProducers.forEach((copiedProducer) => {
					if (copiedProducer.id === item.id) {
						const producer = cloneDeep(item);
						producer.id = copiedProducer.copiedId;
						producer.aggregatesProducerGeologyId = copiedProducer.copiedId;
						producer.plantName = item.plantName + '_copy';
						data.push(producer);
					}
				});
			});
			data.forEach((item) => {
				this.aggregatesProducersData.unshift(item);
			});
			this.comparableAggregatesProducersData = cloneDeep(this.aggregatesProducersData);
			this.selectedAggregatesProducers = [];
			this.isLoadingEditDataToolbar = false;
			this.setIsLoadingEditDataToolbarDuplicate(false);
		},
		async onDeleteEditData() {
			this.isLoadingEditDataToolbar = true;
			this.setIsLoadingEditDataToolbarDelete(true);
			await this.selectedAggregatesProducers.map((item) => {
				this.removeAggregatesProducers({
					aggregatesProducerId: item.id,
					marketId: Number(localStorage.getItem('marketId')),
				});
			});
			this.reInitAggregatesProducertData();
			this.selectedAggregatesProducers = [];
			this.isLoadingEditDataToolbar = false;
			this.setIsLoadingEditDataToolbarDelete(false);
		},
		showGeologyWarning() {
			Vue.toasted.info('Please update production split according to the new geology type');
		},
		outsideClickEventListener() {
			if (this.openTextLocation) {
				this.openTextLocation = null;
			}
		},
		refreshSplit() {
			this.resetPercentageSplit({
				marketId: Number(localStorage.getItem('marketId')),
			}).then((res) => {
				if (res){
					this.reInitAggregatesProducertData();

				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.normal-text {
	font-size: 12px !important;
	line-height: 18px;
}

.number-text {
	font-size: 12px;
	line-height: 22px;
}
.error-text {
	color: #f7685b !important;
	font-weight: 600;
}
.disabled-text {
	color: #6e8ca3 !important;
	font-weight: 400;
}
.refresh{
	background-color: var(--v-regalBlue-base);
	margin: 0 0 30px auto;
	font-size: 15px;
	display: table;
}

.textFields .v-input__slot{

}

//.textFields.v-text-field > .v-input__control > .v-input__slot {
//	border: none !important;
//	background: transparent !important;
//}
//
//.textFields .v-input--is-focused .v-input__slot{
//	border: 1px solid #e0e7ff !important;
//	background: var(--v-whiteLilac-base) !important;
//}

</style>
