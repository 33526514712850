<template>
    <base-layout :style="{ 'background-color': backgroundColor }" :fill-height="isEmptyDashboard">
        <template v-if="isEmptyDashboard">
            <empty-dashboard></empty-dashboard>
        </template>
        <template v-else>
            <div class="d-flex justify-lg-space-between">
                <div>
                    <div class="title regalBlue--text">Dashboard</div>
                </div>
				<div>
					<div class="split-menu-box__results">
						<div class="mb-2">
							<span class="title regalBlue--text" v-if="this.market">
								{{this.market.name == null ?? 'n/a'}}
							</span>
						</div>
						<div class="d-flex align-center">
							<v-progress-circular
								:size="60"
								:width="15"
								:rotate="-90"
								:color="'#0E3F66'"
								:value="typeof producerRateSegment == 'number' ? producerRateSegment : 0">
							</v-progress-circular>
							<div class="pl-5">
								<p>
									Completion rate
								</p>
								<span v-if="typeof producerRateSegment == 'number'">
										{{ producerRateSegment }} %
									</span>
							</div>
						</div>
					</div>

				</div>
				<div>
					<div class="split-menu-box__results">
						<div class="mb-2">
							<span class="title regalBlue--text">Aggregates Producers</span>
						</div>
						<div class="d-flex align-center">
							<v-progress-circular
								:size="60"
								:width="15"
								:rotate="-90"
								:color="'#0E3F66'"
								:value="typeof producerRate == 'number' ? producerRate : 0">
							</v-progress-circular>
							<div>
								<p>
									Completion rate
								</p>
								<span v-if="typeof producerRate == 'number'">
									{{ producerRate }} %
								</span>
							</div>
						</div>
					</div>

				</div>
				<div>
					<div class="split-menu-box__results">
						<div class="mb-2">
							<span class="title regalBlue--text">Key Market Segments</span>
						</div>
						<div class="d-flex align-center">
							<v-progress-circular
								:size="60"
								:width="15"
								:rotate="-90"
								:color="'#0E3F66'"
								:value="typeof producerOverallRateSegment == 'number' ? producerOverallRateSegment : 0">
							</v-progress-circular>
							<div>
								<p>
									Completion rate
								</p>
								<span v-if="typeof producerOverallRateSegment == 'number'">
									{{ producerOverallRateSegment }} %
								</span>
							</div>
						</div>
					</div>
				</div>

				<div>
					<div class="ml-auto d-flex justify-end">
						<v-btn
							@click="visibleRateSidebar=false; openRateMarketVolume()"
							class="split-menu-view py-5"
							width="100"
						>
							<template
								v-if="visibleRateSidebar"
							>
								<img src="@/assets/pagination_toolbar/arrow_open_close.svg" class="mr-2">
								MORE
							</template>
							<v-progress-circular
								v-else
								indeterminate
								color="white"
							></v-progress-circular>
						</v-btn>
					</div>
				</div>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-tabs v-model="currentTabIndex" background-color="whiteLilac" color="mangoTango" slider-color="mangoTango">
                        <v-tab
                            v-for="dataTab in dataTabs"
                            :key="dataTab + getUniqueID"
                            :style="{ 'background-color': backgroundColor }"
                            :disabled="isLoadingData"
                            class="tab-slider-bottom-highlighter"
                            ripple
                        >
                            {{ dataTab }}
                        </v-tab>
                        <v-tab-item v-for="(dataTab, dataTabIndex) in dataTabs" :key="dataTab + getUniqueID" :style="{ 'background-color': backgroundColor }" class="pt-5">
                            <template v-if="currentTabIndex === 0 && currentTabIndex === dataTabIndex">
                                <report-map></report-map>
                            </template>
                            <template v-if="currentTabIndex === 1 && currentTabIndex === dataTabIndex">
                                <data-consolidation></data-consolidation>
                            </template>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </template>

		<template v-if="showRateLeftSidebar">
			<div class="other-segments-table-split-menu">
				<v-btn
					small text fab
					@click="hideRateMarketVolume()"
					class="split-menu-close"
				>
					<img src="@/assets/market_segments/close.svg">
				</v-btn>
				<div class="split-menu-box">
					<div class="split-menu-box__top">
						<span>More information</span>
						<span></span>
					</div>
					<div class="split-menu-box__results d-flex align-center">
						<div class="d-flex align-center">
							<v-progress-circular
								:size="60"
								:width="15"
								:rotate="-90"
								:color="levelOfConfidence < 50 ? '#F7685B' : levelOfConfidence > 80 ? '#2ED47A' : '#FA771E'"
								:value="levelOfConfidence"
							></v-progress-circular>
							<div class="pl-7">
								<p>
									Level of Confidence
								</p>
								<span>
									{{ levelOfConfidence }} %
						  		</span>
							</div>
						</div>
					</div>
					<div class="split-menu-box__table">
						<table>
							<thead>
							<tr>
								<th>Segment name</th>
								<!--								<th>% of total</th>-->
								<th>Demand & Supply</th>
								<th>Average Market</th>
								<th>Market Attract.</th>
								<th>Competitive Position</th>
							</tr>
							</thead>

							<tbody>
							<tr v-for="item in detailedMarketRates.marketSegments" :key="item.name" v-if="item.name !== 'Other segments'">
								<td>
									{{item.name}}
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.demandAndSupplyRate*100) < 50 ? '#F7685B' : Math.trunc(item.demandAndSupplyRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.demandAndSupplyRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.demandAndSupplyRate*100)}}%
						  				</span>
									</div>
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.avarageMarketPriceRate*100) < 50 ? '#F7685B' : Math.trunc(item.avarageMarketPriceRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.avarageMarketPriceRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.avarageMarketPriceRate*100)}}%
						  				</span>
									</div>
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.marketAttractivenessRate*100) < 50 ? '#F7685B' : Math.trunc(item.marketAttractivenessRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.marketAttractivenessRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.marketAttractivenessRate*100)}}%
						  				</span>
									</div>
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.competitivePositionRate*100) < 50 ? '#F7685B' : Math.trunc(item.competitivePositionRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.competitivePositionRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.competitivePositionRate*100)}}%
						  				</span>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</template>

	</base-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import EmptyDashboard from '@/components/dashboard/EmptyDashboard.vue';
import ReportMap from '@/components/dashboard/ReportMap.vue';
import DataConsolidation from '@/components/dashboard/DataConsolidation.vue';
import axios from "axios";

export default {
    name: 'dashboard',
    components: {
        EmptyDashboard,
        ReportMap,
        DataConsolidation,
    },
    data() {
        return {
			visibleRateSidebar: true,
            isEmptyDashboard: false,
            isLoadingData: false,
            currentTabIndex: 0,
			producerRate: 0,
			producerRateSegment: 0,
			producerOverallRateSegment: 0,
			detailedMarketRates: null,
			showRateLeftSidebar: false,
			levelOfConfidence: 0,
			dataTabs: ['Map', 'Analysis'],
		};
    },
	async mounted() {
		if (this.market){
			await this.getRate()
			await this.getRateSegment()
			await this.getOverallRateSegment()
		}
		const tab = this.$route.params.tab;
        if (tab) {
            if (tab === 2) {
                this.currentTabIndex = 1;
            }
        }
	},
    watch: {
        isLoadedAvailableMarkets(value) {
            if (this.market === null) {
                this.$router.push({ path: '/' });
            }
        },
		...mapGetters('marketAssessment', {
			isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
			market: 'market',
		}),
		async market() {
			this.showRateLeftSidebar = false
			if (this.market){
				await this.getRate()
				await this.getRateSegment()
				await this.getOverallRateSegment()
			}
		},
    },
    computed: {
        ...mapGetters('marketAssessment', {
            isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
            market: 'market',
        }),
        backgroundColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
        },
        getUniqueID() {
            return uuidv4();
        },

    },
    methods: {
		hideRateMarketVolume() {
			this.showRateLeftSidebar = false
			this.visibleRateSidebar = true
		},
		openRateMarketVolume() {
			this.detailedMarketRates = null
			this.visibleRateSidebar = false
			axios.get(`CompletionRate/${this.market.id}/GetDetailedMarketRates`).then((res) => {
				this.detailedMarketRates = res.data
				this.showRateLeftSidebar = true
			});
			axios.get(`CompletionRate/${this.market.id}/GetLevelOfConfidence`).then((res) => {
				this.levelOfConfidence = Math.trunc(res.data)
			});
			axios.get(`CompletionRate/${this.market.id}/GetLevelOfConfidence`).then((res) => {
				this.levelOfConfidence = Math.trunc(res.data)
			});
		},

		getRateSegment(){
			this.producerRateSegment = this.$store.dispatch('marketSegment/getMarketSegmentsRate').then(() => {
				this.producerRateSegment = ''
				this.producerRateSegment = Math.trunc(this.$store.getters['marketSegment/producersRate']*100);
			})
		},
		getOverallRateSegment(){
			this.producerRateSegment = this.$store.dispatch('marketSegment/getOverallMarketSegmentsRate').then(() => {
				this.producerOverallRateSegment = ''
				this.producerOverallRateSegment = Math.trunc(this.$store.getters['marketSegment/overallProducersRate']*100);
			})
		},
		getRate(){
			this.producerRate = this.$store.dispatch('aggregatesProducers/getAggregatesProducersRate').then(() => {
				this.producerRate = ''
				this.producerRate = Math.trunc(this.$store.getters['aggregatesProducers/producersRate']*100);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.tab-slider-bottom-highlighter {
    border-bottom: 2px solid #e7f1fd !important;
}
.split-menu-view {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	height: 60px !important;
	color: #ffffff;
	background-color: #0E3F66 !important;
	border-radius: 5px;
	box-shadow: none;
}
</style>
