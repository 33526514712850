<template>
  <div class="tooltip">
    <img src="../../assets/lib/tooltip.svg" />
    <div class="tooltip__info">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
