export default class LocalStage {
  // constructor(propertys, data) {
  //   this.propertys = propertys;
  //   this.data = data;
  // }

  saveData(propertys, data) {
    if (typeof (propertys) === 'object') {
      propertys.forEach((prop, i) => localStorage.setItem(prop, JSON.stringify(data[i])));
    } else {
      localStorage.setItem(propertys, JSON.stringify(data));
    }
  }

  removeData(propertys) {
    if (typeof (propertys) === 'object') {
      propertys.forEach((prop) => localStorage.removeItem(prop));
    } else {
      localStorage.removeItem(propertys);
    }
  }

  getData(property) {
    return JSON.parse(localStorage.getItem(property));
  }
}
