<template>
  <v-card class="ml-3" min-width="800" max-width="1000">
    <v-container fluid pa-10>
      <v-row justify="start" class="pb-4">
        <div class="title bismark--text">Market Attractiveness</div>
        <div class="sub-title bismark--text pt-2">
          Update table below in order to determine overall attractiveness of this market segment. For instructions refer to infotip in each heading
        </div>
      </v-row>
      <v-row justify="center" class="pb-8">
        <v-data-table class="market-segments-market-attractiveness-table" item-key="id" hide-default-header hide-default-footer>
          <template v-slot:header>
            <thead>
              <tr>
                <th
                  v-for="marketAttractivenessHeader in marketAttractivenessHeaders"
                  :key="marketAttractivenessHeader.text"
                  class="other-segments-table-additional-header bismark text-center"
                >
                  <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="d-flex justify-center align-center text-no-wrap text-subtitle-2 lavender--text">
                        {{ marketAttractivenessHeader.text }} <img src="@/assets/market_segments/info.svg" style="width: 28px" class="pl-2" />
                      </div>
                    </template>
                    <span>
                      {{ marketAttractivenessHeader.tooltipText }}
                    </span>
                  </v-tooltip>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:body>
            <tbody>
              <template v-if="isLoadingData">
                <tr>
                  <td :colspan="marketAttractivenessHeaders.length" style="padding: 20px !important" class="сapeCod--text text-no-wrap">
                    <div><v-progress-linear color="regalBlue" indeterminate></v-progress-linear></div>
                    <div class="pt-5 text-center">Loading... Please wait</div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        {{ marketFactorText(marketFactor.SizeAndGrowth) }}
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditSizeAndGrowthPercent">
                        <v-text-field
                          :value="sizeAndGrowthPercent === 0 ? null : sizeAndGrowthPercent"
                          @change="
                            (sizeAndGrowthPercent = parseFloat($event) ? roundNumber($event) : 0),
                              onSaveMarketAttractiveness(marketFactor.SizeAndGrowth)
                          "
                          @keydown.enter="isEditSizeAndGrowthPercent = !isEditSizeAndGrowthPercent"
                          @blur="isEditSizeAndGrowthPercent = !isEditSizeAndGrowthPercent"
                          :disabled="isLoadingData"
                          return-masked-value
                          style="max-width: 100px;"
                          class="market-segments-editable-text-field-value"
                          background-color="whiteLilac"
                          type="number"
                          suffix="%"
                          hide-details
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span class="text-truncate bermudaGray--text" @click="isEditSizeAndGrowthPercent = !isEditSizeAndGrowthPercent">
                          {{ sizeAndGrowthPercent }}
                        </span>
                        <span class="bermudaGray--text pl-2">%</span>
                      </template>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <v-select
                        v-model="sizeAndGrowthRating"
                        @change="onSaveMarketAttractiveness(marketFactor.SizeAndGrowth)"
                        :items="ratingSteps"
                        :disabled="isLoadingData"
                        style="max-width: 70px;"
                        class="market-segments-editable-text-field-without-border"
                        background-color="white"
                        color="regalBlue"
                        item-color="regalBlue"
                        hide-details
                        dense
                        solo
                      >
                      </v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        {{ marketFactorText(marketFactor.MarginAndProfitability) }}
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditMarginAndProfitabilityPercent">
                        <v-text-field
                          :value="marginAndProfitabilityPercent === 0 ? null : marginAndProfitabilityPercent"
                          @change="
                            (marginAndProfitabilityPercent = parseFloat($event) ? roundNumber($event) : 0),
                              onSaveMarketAttractiveness(marketFactor.MarginAndProfitability)
                          "
                          @keydown.enter="isEditMarginAndProfitabilityPercent = !isEditMarginAndProfitabilityPercent"
                          @blur="isEditMarginAndProfitabilityPercent = !isEditMarginAndProfitabilityPercent"
                          :disabled="isLoadingData"
                          return-masked-value
                          style="max-width: 100px;"
                          class="market-segments-editable-text-field-value"
                          background-color="whiteLilac"
                          type="number"
                          suffix="%"
                          hide-details
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span
                          class="text-truncate bermudaGray--text"
                          @click="isEditMarginAndProfitabilityPercent = !isEditMarginAndProfitabilityPercent"
                        >
                          {{ marginAndProfitabilityPercent }}
                        </span>
                        <span class="bermudaGray--text pl-2">%</span>
                      </template>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <v-select
                        v-model="marginAndProfitabilityRating"
                        @change="onSaveMarketAttractiveness(marketFactor.MarginAndProfitability)"
                        :items="ratingSteps"
                        :disabled="isLoadingData"
                        style="max-width: 70px;"
                        class="market-segments-editable-text-field-without-border"
                        background-color="white"
                        color="regalBlue"
                        item-color="regalBlue"
                        hide-details
                        dense
                        solo
                      >
                      </v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        {{ marketFactorText(marketFactor.Opportunity) }}
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditOpportunityPercent">
                        <v-text-field
                          :value="opportunityPercent === 0 ? null : opportunityPercent"
                          @change="
                            (opportunityPercent = parseFloat($event) ? roundNumber($event) : 0), onSaveMarketAttractiveness(marketFactor.Opportunity)
                          "
                          @keydown.enter="isEditOpportunityPercent = !isEditOpportunityPercent"
                          @blur="isEditOpportunityPercent = !isEditOpportunityPercent"
                          :disabled="isLoadingData"
                          return-masked-value
                          style="max-width: 100px;"
                          class="market-segments-editable-text-field-value"
                          background-color="whiteLilac"
                          type="number"
                          suffix="%"
                          hide-details
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span class="text-truncate bermudaGray--text" @click="isEditOpportunityPercent = !isEditOpportunityPercent">
                          {{ opportunityPercent }}
                        </span>
                        <span class="bermudaGray--text pl-2">%</span>
                      </template>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <v-select
                        v-model="opportunityRating"
                        @change="onSaveMarketAttractiveness(marketFactor.Opportunity)"
                        :items="ratingSteps"
                        :disabled="isLoadingData"
                        style="max-width: 70px;"
                        class="market-segments-editable-text-field-without-border"
                        background-color="white"
                        color="regalBlue"
                        item-color="regalBlue"
                        hide-details
                        dense
                        solo
                      >
                      </v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        {{ marketFactorText(marketFactor.Threats) }}
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditThreatsPercent">
                        <v-text-field
                          :value="threatsPercent === 0 ? null : threatsPercent"
                          @change="(threatsPercent = parseFloat($event) ? roundNumber($event) : 0), onSaveMarketAttractiveness(marketFactor.Threats)"
                          @keydown.enter="isEditThreatsPercent = !isEditThreatsPercent"
                          @blur="isEditThreatsPercent = !isEditThreatsPercent"
                          :disabled="isLoadingData"
                          return-masked-value
                          style="max-width: 100px;"
                          class="market-segments-editable-text-field-value"
                          background-color="whiteLilac"
                          type="number"
                          suffix="%"
                          hide-details
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span class="text-truncate bermudaGray--text" @click="isEditThreatsPercent = !isEditThreatsPercent">
                          {{ threatsPercent }}
                        </span>
                        <span class="bermudaGray--text pl-2">%</span>
                      </template>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <v-select
                        v-model="threatsRating"
                        @change="onSaveMarketAttractiveness(marketFactor.Threats)"
                        :items="ratingSteps"
                        :disabled="isLoadingData"
                        style="max-width: 70px;"
                        class="market-segments-editable-text-field-without-border"
                        background-color="white"
                        color="regalBlue"
                        item-color="regalBlue"
                        hide-details
                        dense
                        solo
                      >
                      </v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="bismark сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate white--text">
                        Other
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        {{ marketFactorText(marketFactor.SupplyConsolidation) }}
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditSupplyConsolidationPercent">
                        <v-text-field
                          :value="supplyConsolidationPercent === 0 ? null : supplyConsolidationPercent"
                          @change="
                            (supplyConsolidationPercent = parseFloat($event) ? roundNumber($event) : 0),
                              onSaveMarketAttractiveness(marketFactor.SupplyConsolidation)
                          "
                          @keydown.enter="isEditSupplyConsolidationPercent = !isEditSupplyConsolidationPercent"
                          @blur="isEditSupplyConsolidationPercent = !isEditSupplyConsolidationPercent"
                          :disabled="isLoadingData"
                          return-masked-value
                          style="max-width: 100px;"
                          class="market-segments-editable-text-field-value"
                          background-color="whiteLilac"
                          type="number"
                          suffix="%"
                          hide-details
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span class="text-truncate bermudaGray--text" @click="isEditSupplyConsolidationPercent = !isEditSupplyConsolidationPercent">
                          {{ supplyConsolidationPercent }}
                        </span>
                        <span class="bermudaGray--text pl-2">%</span>
                      </template>
                    </div>
                  </td>
                  <td class="td-min-max-width сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="bermudaGray--text text-truncate">calculated automatically</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        {{ marketFactorText(marketFactor.VerticalIntegration) }}
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditVerticalIntegrationPercent">
                        <v-text-field
                          :value="verticalIntegrationPercent === 0 ? null : verticalIntegrationPercent"
                          @change="
                            (verticalIntegrationPercent = parseFloat($event) ? roundNumber($event) : 0),
                              onSaveMarketAttractiveness(marketFactor.VerticalIntegration)
                          "
                          @keydown.enter="isEditVerticalIntegrationPercent = !isEditVerticalIntegrationPercent"
                          @blur="isEditVerticalIntegrationPercent = !isEditVerticalIntegrationPercent"
                          :disabled="isLoadingData"
                          return-masked-value
                          style="max-width: 100px;"
                          class="market-segments-editable-text-field-value"
                          background-color="whiteLilac"
                          type="number"
                          suffix="%"
                          hide-details
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span class="text-truncate bermudaGray--text" @click="isEditVerticalIntegrationPercent = !isEditVerticalIntegrationPercent">
                          {{ verticalIntegrationPercent }}
                        </span>
                        <span class="bermudaGray--text pl-2">%</span>
                      </template>
                    </div>
                  </td>
                  <td class="td-min-max-width сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="bermudaGray--text text-truncate">calculated automatically</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        {{ marketFactorText(marketFactor.DemandConsolidation) }}
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width white сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <template v-if="isEditDemandConsolidationPercent">
                        <v-text-field
                          :value="demandConsolidationPercent === 0 ? null : demandConsolidationPercent"
                          @change="
                            (demandConsolidationPercent = parseFloat($event) ? roundNumber($event) : 0),
                              onSaveMarketAttractiveness(marketFactor.DemandConsolidation)
                          "
                          @keydown.enter="isEditDemandConsolidationPercent = !isEditDemandConsolidationPercent"
                          @blur="isEditDemandConsolidationPercent = !isEditDemandConsolidationPercent"
                          :disabled="isLoadingData"
                          return-masked-value
                          style="max-width: 100px;"
                          class="market-segments-editable-text-field-value"
                          background-color="whiteLilac"
                          type="number"
                          suffix="%"
                          hide-details
                          solo
                          dense
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span class="text-truncate bermudaGray--text" @click="isEditDemandConsolidationPercent = !isEditDemandConsolidationPercent">
                          {{ demandConsolidationPercent }}
                        </span>
                        <span class="bermudaGray--text pl-2">%</span>
                      </template>
                    </div>
                  </td>
                  <td class="td-min-max-width сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="bermudaGray--text text-truncate">calculated automatically</span>
                    </div>
                  </td>
                </tr>

                <div v-if="!isLoadingData" class="py-2"></div>
                <tr>
                  <td class="сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium">
                        Overall Rating
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate font-weight-medium" :class="[calculateTotalRating === 100 ? 'emerald--text' : 'terraCotta--text']">
                        {{ calculateTotalRating }}&nbsp;%
                      </span>
                    </div>
                  </td>
                  <td class="td-min-max-width сapeCod--text text-no-wrap">
                    <div class="row-min-height d-flex align-center justify-center">
                      <span class="text-truncate"> </span>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
      <v-row>
        <v-btn
          @click="$router.push({ name: 'dashboard', params: { tab: 2 } })"
          :disabled="isLoadingData || isClosingEditMarketAttractiveness"
          :dark="!isLoadingData && !isClosingEditMarketAttractiveness"
          color="mangoTango"
        >
          <span class="go-to-dashboard white--text">Go to Dashboard to see results</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="onCloseMarketAttractiveness()"
          :loading="isClosingEditMarketAttractiveness"
          :disabled="isLoadingData"
          :dark="!isLoadingData"
          color="mangoTango"
          width="150px"
        >
          <span class="close-text white--text">Close</span>
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { MarketSegmentType } from '@/core/enums/marketSegmentType';
import { MarketFactor } from '@/core/enums/marketFactor';
import { getMakretFactorText } from '@/core/helpers/marketFactorHelper';

import NonButtonBlock from '@/components/nonButtonBlock/NonButtonBlock.vue';

export default {
  name: 'MarketAttractiveness',
  components: {
    NonButtonBlock,
  },
  props: {
    currentTabIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoadingData: false,
      isClosingEditMarketAttractiveness: false,

      ratingSteps: [5, 4, 3, 2, 1],

      sizeAndGrowthId: null,
      sizeAndGrowthPercent: 0,
      isEditSizeAndGrowthPercent: false,
      sizeAndGrowthRating: '',

      marginAndProfitabilityId: null,
      marginAndProfitabilityPercent: 0,
      isEditMarginAndProfitabilityPercent: false,
      marginAndProfitabilityRating: '',

      opportunityId: null,
      opportunityPercent: 0,
      isEditOpportunityPercent: false,
      opportunityRating: '',

      threatsId: null,
      threatsPercent: 0,
      isEditThreatsPercent: false,
      threatsRating: '',

      supplyConsolidationId: null,
      supplyConsolidationPercent: 0,
      isEditSupplyConsolidationPercent: false,

      verticalIntegrationId: null,
      verticalIntegrationPercent: 0,
      isEditVerticalIntegrationPercent: false,

      demandConsolidationId: null,
      demandConsolidationPercent: 0,
      isEditDemandConsolidationPercent: false,

      marketSegmentType: MarketSegmentType,
      marketFactor: MarketFactor,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('marketSegment', {
      marketSegments: 'marketSegments',
    }),
    marketAttractivenessHeaders() {
      return [
        {
          text: 'MARKET ATTRACTIVENESS INFLUENCERS',
          tooltipText: 'You can change the influencers in white cells',
        },
        {
          text: 'WEIGHT',
          tooltipText: 'You can change weight of each influencer. Make sure it adds up to 100%',
        },
        {
          text: 'RATING',
          tooltipText: 'Please assign values from 1 to 5 to each item. “5” = High, “1”= Low',
        },
      ];
    },
    calculateTotalRating() {
      return (
        this.sizeAndGrowthPercent +
        this.marginAndProfitabilityPercent +
        this.opportunityPercent +
        this.threatsPercent +
        this.supplyConsolidationPercent +
        this.verticalIntegrationPercent +
        this.demandConsolidationPercent
      );
    },
  },
  methods: {
    ...mapActions('marketSegment', {
      getMarketsAttractiveness: 'getMarketsAttractiveness',
      saveMarketAttractiveness: 'saveMarketAttractiveness',
    }),
    async init() {
      this.isLoadingData = true;
      setTimeout(async () => {
        await this.getMarketsAttractiveness({ marketSegmentId: this.marketSegments[this.currentTabIndex].id }).then((res) => {
          res.forEach((item) => {
            if (item.marketFactor === this.marketFactor.SizeAndGrowth) {
              this.sizeAndGrowthId = item.id;
              this.sizeAndGrowthPercent = item.percent;
              this.sizeAndGrowthRating = item.rating;
            }
            if (item.marketFactor === this.marketFactor.MarginAndProfitability) {
              this.marginAndProfitabilityId = item.id;
              this.marginAndProfitabilityPercent = item.percent;
              this.marginAndProfitabilityRating = item.rating;
            }
            if (item.marketFactor === this.marketFactor.Opportunity) {
              this.opportunityId = item.id;
              this.opportunityPercent = item.percent;
              this.opportunityRating = item.rating;
            }
            if (item.marketFactor === this.marketFactor.Threats) {
              this.threatsId = item.id;
              this.threatsPercent = item.percent;
              this.threatsRating = item.rating;
            }
            if (item.marketFactor === this.marketFactor.SupplyConsolidation) {
              this.supplyConsolidationId = item.id;
              this.supplyConsolidationPercent = item.percent;
            }
            if (item.marketFactor === this.marketFactor.VerticalIntegration) {
              this.verticalIntegrationId = item.id;
              this.verticalIntegrationPercent = item.percent;
            }
            if (item.marketFactor === this.marketFactor.DemandConsolidation) {
              this.demandConsolidationId = item.id;
              this.demandConsolidationPercent = item.percent;
            }
          });
        });
        this.isLoadingData = false;
      }, 500);
    },
    roundNumber(value, power = 2) {
      return Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
    },
    marketFactorText(marketFactor) {
      return getMakretFactorText(marketFactor);
    },
    onSaveMarketAttractiveness(marketFactor) {
      let id = null;
      let percent = null;
      let rating = null;
      if (marketFactor === this.marketFactor.SizeAndGrowth) {
        id = this.sizeAndGrowthId;
        percent = this.sizeAndGrowthPercent;
        rating = this.sizeAndGrowthRating;
      }
      if (marketFactor === this.marketFactor.MarginAndProfitability) {
        id = this.marginAndProfitabilityId;
        percent = this.marginAndProfitabilityPercent;
        rating = this.marginAndProfitabilityRating;
      }
      if (marketFactor === this.marketFactor.Opportunity) {
        id = this.opportunityId;
        percent = this.opportunityPercent;
        rating = this.opportunityRating;
      }
      if (marketFactor === this.marketFactor.Threats) {
        id = this.threatsId;
        percent = this.threatsPercent;
        rating = this.threatsRating;
      }
      if (marketFactor === this.marketFactor.SupplyConsolidation) {
        id = this.supplyConsolidationId;
        percent = this.supplyConsolidationPercent;
      }
      if (marketFactor === this.marketFactor.VerticalIntegration) {
        id = this.verticalIntegrationId;
        percent = this.verticalIntegrationPercent;
      }
      if (marketFactor === this.marketFactor.DemandConsolidation) {
        id = this.demandConsolidationId;
        percent = this.demandConsolidationPercent;
      }
      this.saveMarketAttractiveness({ id, percent, rating, marketFactor, marketSegmentId: this.marketSegments[this.currentTabIndex].id }).then(
        (res) => {
          if (res) {
            if (marketFactor === this.marketFactor.SizeAndGrowth) {
              this.sizeAndGrowthId = res;
            }
            if (marketFactor === this.marketFactor.MarginAndProfitability) {
              this.marginAndProfitabilityId = res;
            }
            if (marketFactor === this.marketFactor.Opportunity) {
              this.opportunityId = res;
            }
            if (marketFactor === this.marketFactor.Threats) {
              this.threatsId = res;
            }
            if (marketFactor === this.marketFactor.SupplyConsolidation) {
              this.supplyConsolidationId = res;
            }
            if (marketFactor === this.marketFactor.VerticalIntegration) {
              this.verticalIntegrationId = res;
            }
            if (marketFactor === this.marketFactor.DemandConsolidation) {
              this.demandConsolidationId = res;
            }
          }
        },
      );
    },
    onCloseMarketAttractiveness() {
      this.isClosingEditMarketAttractiveness = true;
      setTimeout(() => {
        this.isClosingEditMarketAttractiveness = false;
        this.$emit('close-edit-market-attractiveness');
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: -0.114286px !important;
}

.content {
  font-size: 12px !important;
  line-height: 18px !important;
}

.go-to-dashboard,
.close-text {
  font-size: 15px !important;
  line-height: 22px !important;
}

.td-min-max-width {
  width: 100% !important;
  min-width: 250px !important;
  max-width: 250px !important;
}

.row-min-height {
  min-height: 38px !important;
}
</style>
