<template>
  <div
    v-click-outside="handleFocusOut"
    class="mr-select-wrapper"
    :class="[{ 'no-content-mr-select': options.length === 0 }, selectType]"
    @click.stop
    :disabled="disabled"
  >
    <label v-if="label">{{ label }}</label>
    <div @click="openOptions(label)" class="mr-select">
      <img class="first-img pr-2" v-if="value.img" :src="value.img" />
      <img class="second-img" v-if="value.tag" :src="value.tag" />
      <span>{{ value.name || value }}</span>
    </div>
    <div class="options" v-if="show">
      <ul>
        <li v-for="option in options" :key="option.id" @click="select(label, option)">
          <img class="first-img pr-2" v-if="option.img" :src="option.img" />
          <img class="second-img" v-if="option.tag" :src="option.tag" />
          {{ option.name ? option.name : option }}
        </li>
      </ul>
    </div>

	  <v-dialog v-model="isShowCurrencySureDialog" width="512" persistent>
		  <v-card>
			  <v-card-title class="headline regalBlue white--text">
				  Change Currency & number format
			  </v-card-title>
			  <v-card-text class="text-center pt-5">
				  Are you sure you want to change
				  <span class="font-weight-bold">
					  <template v-if="labelCurrency === 'Currency'">currency</template>
					  <template v-else-if="labelCurrency === 'Common decimal separators'">decimal separator</template>
					  <template v-else-if="labelCurrency === 'Metrics'">metric</template>
				  </span>?
			  </v-card-text>
			  <v-divider></v-divider>
			  <v-card-actions>
				  <v-spacer></v-spacer>
				  <v-btn @click="isShowCurrencySureDialog = false" color="mangoTango" text>
					  <span>No</span>
				  </v-btn>
				  <span class="px-2"></span>
				  <v-btn @click="show = false; $emit('selected', selectOption); isShowCurrencySureDialog = false" color="regalBlue" dark>
					  <span class="white--text">Yes</span>
				  </v-btn>
			  </v-card-actions>
		  </v-card>
	  </v-dialog>

  </div>


</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: [String, Object],
    },
    selectType: {
      type: String,
    },
    options: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
		isShowCurrencySureDialog: false,
		selectOption: null,
		labelCurrency: null
    };
  },
  mounted() {
    document.addEventListener('click', this.outsideClickEventListener);
  },
  destroyed() {
    document.removeEventListener('click', this.outsideClickEventListener);
  },
  methods: {
    openOptions(label) {
      if (this.options.length) {
        this.show = true;
		this.labelCurrency = label
      }
    },
    outsideClickEventListener() {
      if (this.show) {
        this.show = false;
		  this.labelCurrency = null
	  }
    },
    select(label, item) {
		if ((label === 'Currency' || label === 'Common decimal separators' || label === 'Metrics') && item.name !== this.value.name && item.name !== this.value) {
			this.isShowCurrencySureDialog = true
			this.selectOption = item
		} else {
			this.show = false;
			this.$emit('selected', item);
		}
    },
    handleFocusOut() {
      this.show = false;
    },
  },
};
</script>
