<template>
	<div>
		<modal class="add-producer" v-if="this.storeState.showAddProducer" @close="closeModal">
			<h3 slot="header" class="title">Add new aggregate producer </h3>
			<div slot="body" class="add-producer-container">
				<div class="add-producer-wrapper" ref="arrrr">
					<div class="mr-input-wrapper autocomplete">
						<label>COMPANY NAME</label>
						<input type="text" v-model="company" @input="debounceSearch($event.target.value)" @blur="focused($event)" />
						<ul v-if="companies.length > 0">
							<li v-for="item in companies" :key="item.id" @click.self="selectCompany(item)">{{ item.name }}</li>
						</ul>
					</div>
					<mr-select :label="'Group'" :value="groupVal" :options="storeState.groups" @selected="groupSelected" />
					<div class="location-wrap">
						<input-location :label="'LOCATION'" @setLocation="locationSelected" :value="address" />
						<span @click="mapChange(), showMap = true, countLatLon+=1"><img src="@/assets/icons/map.svg" /></span>
						<v-dialog v-model="showMap" persistent max-width="1200px">
							<v-card>
								<v-card-title>
									<v-spacer></v-spacer>
									<v-btn @click="showMap = false, countLatLon+=1" small text fab>
										<img src="@/assets/icons/close.svg" />
									</v-btn>
								</v-card-title>
								<v-card-text>
									<v-container>
										<div class="mb-10 map-modal-search">
											<span class="text-truncate">
												<span>
													{{ address }}
												</span>
											</span>

											<div class="d-flex">
												<img src="@/assets/market_segments/search.svg">
												<input-location
													:key="countLatLon"
													style="background-color: #ffffff"
													:placeholder="'Search address'"
													@setLocation="locationSelected($event)"
												/>
											</div>

										</div>

										<map-location
											:key="countLatLon"
											:type="'single'"
											:boundary="true"
											:showMapStyle="true"
											:listMarkers="selectedlocation ? [{ longitude: selectedlocation.center[0], latitude: selectedlocation.center[1] }] : false"
											@location="locationSelected"
										/>
									</v-container>
								</v-card-text>
							</v-card>
						</v-dialog>
					</div>
					<input-text :label="'PLANT NAME'" v-model.lazy="plantName" @focused="focused($event)" />

					<input-location-lan-lot
						:labelLat="'Longitude'"
						:labelLon="'Latitude'"
						@setLocation="locationSelected"
						:latitude="longitude"
						:longitude="latitude"
						:key="countLatLon"
						@wrong-lat-lon="wrongLatLon"
						@search-lat="searchLat"
						@search-lon="searchLon"
					/>

					<!--					<input-text :label="'Latitude'" v-model.lazy="latitude" @focused="focused($event)" />-->
					<!--					<input-text :label="'Longitude'" v-model.lazy="longitude" @focused="focused($event)" />-->

					<div style="width: 295px">
						<mr-select
							:label="'Plant type'"
							:value="plantTypeVal"
							:options="storeState.plantTypes"
							@selected="plantTypeSelected"
							:class="{ typeEmpty: plantTypeEmpty }"
						/>
						<span v-if="plantTypeEmpty" class="typeEmptyError">Please enter a Plant Type</span>
					</div>
					<div style="width: 295px">
						<mr-select
							:label="'Geology Type'"
							:value="geologyTypeVal"
							:options="geologySubTypes"
							@selected="geologyTypeSelected"
							:class="{ typeEmpty: geologySubTypeEmpty }"
						/>
						<span v-if="plantTypeEmpty" class="typeEmptyError">Please enter a Geology Type</span>
					</div>


					<!-- <mr-select
			:label="'Geology'"
			:value="geologyVal"
			:options="storeState.geologies"
			@selected="geologySelected"
		  /> -->
					<div class="standard-split">
						<h3>Standard split</h3>
						<p>Below is a standard production split for selected geology type. Adjust for this plant if needed</p>
						<div class="standard-split-material">
							<input-text
								:type="'Number'"
								:label="'Coarse (>5mm)'"
								:size="'sm'"
								:percent="coarseMaterial"
								:placeholder="'%'"
								:maxNumber="100"
								v-model.lazy="coarseMaterial"
								@focused="focused($event)"
							/>
							<input-text
								:type="'Number'"
								:label="'Fine (<5mm)'"
								:size="'sm'"
								:placeholder="'%'"
								:percent="fineMaterial"
								v-model.lazy="fineMaterial"
								@focused="focused($event)"
							/>
							<input-text :label="'Fill/Base'" :size="'sm'" :placeholder="'%'" v-model.lazy="fbMaterial" @focused="focused($event)" />
							<input-text
								:maxNumber="100 - coarseMaterial - fineMaterial - fbMaterial"
								v-show="otherMaterial != 100"
								:label="'Other'"
								:size="'sm'"
								:placeholder="'%'"
								v-model.lazy="otherMaterial"
								@focused="focused($event)"
							/>
						</div>
					</div>
					<input-text :type="'Number'" :label="'ANNUAL PRODUCTION VOLUME'" :placeholder="'t'" v-model.lazy="annualAggregatesProduction" @focused="focused($event)" />
					<input-text
						:label="'VOLUME SOLD IN THE MARKET (%)'"
						v-model.lazy="volumeSold"
						:type="'Number'"
						:maxNumber="100"
						:placeholder="'%'"
						@focused="focused($event)"
					/>
					<input-text :type="'Number'" :label="'Reserves'" :placeholder="'t'" v-model.lazy="reserves" @focused="focused($event)" />
					<mr-select :label="'Primary application'" :value="primaryApplicationVal" :options="storeState.applications" @selected="applicationsSelected" />
					<mr-select :label="'TRANS. mode to the mkt.'" :value="transportTypesVal" :options="storeState.transportTypes" @selected="transportTypesSelected" />
					<mr-select :label="'Tags'" :value="tagsVal" :options="storeState.tags" @selected="tagsSelected" />
					<div class="mr-input-wrapper mr-textarea-wrapper">
						<label>Comments</label>
						<textarea v-model="comment" @blur="focused($event)"></textarea>
					</div>
					<div class="add-producer-btns">
						<btn @click.native="addProducer()" :btnStyle="'save'" style="width: 200px">Add</btn>
						<btn @click.native="closeModal" style="width: 200px">Сancel </btn>
					</div>
				</div>
			</div>
			<div slot="footer" class="progress-wrap">
				<div class="progress mangoTango" :style="{ width: `${progress}%` }">
					<span>{{ progress }}%</span>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import axios from 'axios';
import Modal from '../lib/modal.vue';
import InputText from '../lib/inputText.vue';
import MrSelect from '../lib/mrSelect.vue';
import Btn from '../lib/btn.vue';
import InputLocation from '../lib/inputLocation';
import InputLocationLanLot from '../lib/inputLocationLanLot';
import MapLocation from '../lib/mapLocation';

export default {
	name: 'AddProducer',
	components: {
		Btn,
		Modal,
		InputText,
		InputLocation,
		InputLocationLanLot,
		MrSelect,
		MapLocation,
	},
	data() {
		return {
			plantTypeEmpty: false,
			geologySubTypeEmpty: false,
			latitudeCustom: '',
			latLonValidation: '',
			longitudeCustom: '',
			countLatLon: 0,
			aggregatesProducerId: '',
			showModal: false,
			company: '',
			groupVal: '',
			groupId: '',
			location: '',
			selectedlocation: null,
			address: '',
			latitude: '',
			longitude: '',
			plantName: '',
			plantTypeVal: '',
			plantTypeId: '',
			geologyVal: '',
			geologyId: '',
			geologyTypeVal: '',
			geologySubTypeId: '',
			annualAggregatesProduction: '',
			volumeSold: '',
			reserves: '',
			primaryApplicationVal: '',
			applicationId: '',
			tags: [],
			comment: '',
			searchQuery: '',
			companies: [],
			productTypesStandartSplits: [],
			coarseMaterial: '',
			fineMaterial: '',
			fbMaterial: '',
			// otherMaterial: '',
			transportTypesVal: 'Truck',
			transportTypesId: 4,
			tagsVal: '',
			tagId: '',
			showOther: false,
			progress: 1,
			// progressCalc: '',
			showMap: false,
		};
	},
	computed: {
		...mapState('aggregatesProducers', {
			storeState: (state) => state,
			otherMaterial() {
				return (100 - this.coarseMaterial - this.fineMaterial - this.fbMaterial).toString();
			},
			reservesRound() {
				return Number.isInteger(Number(this.reserves)) ? this.reserves : Number(this.reserves).toFixed(2);
			},
			annualAggregatesProductionRound() {
				return Number.isInteger(Number(this.annualAggregatesProduction)) ? this.annualAggregatesProduction : Number(this.annualAggregatesProduction).toFixed(2);
			},
		}),
		geologySubTypes() {
			const geologySubTypesArr = [];
			this.storeState.geologies.forEach((element) => {
				element.geologySubTypes.forEach((sub) => {
					geologySubTypesArr.push(sub);
				});
			});
			return geologySubTypesArr;
		},
		defaultApplicationID() {
			let defaultId;
			this.storeState.applications.forEach((element) => {
				if (element.name == 'n/a') {
					defaultId = element.id;
				}
			});
			return defaultId;
		},
	},
	mounted() {
		this.clickListener = () => {
			this.companies = [];
		};
		window.document.addEventListener('click', this.clickListener);
	},
	beforeDestroy() {
		window.document.removeEventListener('click', this.clickListener);
	},
	methods: {
		...mapMutations('aggregatesProducers', {
			setShowAddProducer: 'setShowAddProducer',
		}),
		mapChange () {
			if (this.address === ''){
				this.latitude = ''
				this.longitude = ''
				this.plantName = ''
			}
		},
		wrongLatLon: function(params) {
			if(!params){
				this.address = '';
				this.selectedlocation = false;
			}
			this.latLonValidation = params
			this.calcProgress();
		},
		searchLat: function (params) {
			this.latitudeCustom = params
		},
		searchLon: function (params) {
			this.longitudeCustom = params
		},
		GetData(data) {
			this.personInfo = data;
		},
		toggleBodyClass(addRemoveClass, className) {
			const el = document.body;
			if (addRemoveClass === 'addClass') {
				el.classList.add(className);
			} else {
				el.classList.remove(className);
			}
		},
		closeModal() {
			this.aggregatesProducerId = '';
			this.company = '';
			this.groupVal = '';
			this.groupId = '';
			this.location = '';
			this.selectedlocation = null;
			this.address = '';
			this.latitude = '';
			this.longitude = '';
			this.plantName = '';
			this.plantTypeVal = '';
			this.plantTypeId = '';
			this.geologyVal = '';
			this.geologyId = '';
			this.geologyTypeVal = '';
			this.geologySubTypeId = '';
			this.annualAggregatesProduction = '';
			this.volumeSold = '';
			this.reserves = '';
			this.primaryApplicationVal = '';
			this.applicationId = '';
			this.tags = [];
			this.comment = '';
			this.searchQuery = '';
			this.companies = [];
			this.productTypesStandartSplits = [];
			this.coarseMaterial = '';
			this.fineMaterial = '';
			this.fbMaterial = '';
			this.transportTypesVal = 'Truck';
			this.transportTypesId = 4;
			this.tagsVal = '';
			this.tagId = '';
			this.showOther = false;
			this.progress = 1;
			this.plantTypeEmpty = false;
			this.geologySubTypeEmpty = false;
			this.setShowAddProducer(false);
			this.toggleBodyClass('removeClass', 'overflow');

		},
		getCourseMaterial() {
			this.productTypesStandartSplits.forEach((e) => {
				if (e.displayName === 'Coarse (above 5mm)') {
					this.coarseMaterial = e.percent.toString();
				}
			});
		},
		getFineMaterial() {
			this.productTypesStandartSplits.forEach((e) => {
				if (e.displayName === 'Fine (below 5mm)') {
					this.fineMaterial = e.percent.toString();
				}
			});
		},
		getfbMaterial() {
			this.productTypesStandartSplits.forEach((e) => {
				if (e.displayName === 'Fill/Base') {
					this.fbMaterial = e.percent.toString();
				}
			});
		},
		locationSelected(location) {
			this.selectedlocation = location;
			this.plantName = `${this.company}` + `/ ${this.address}` + `/ ${this.plantTypeVal}`;
			this.latitude = location.center[0].toString()
			this.longitude = location.center[1].toString()

			let arr = location.context;
			for (var i = 0; i < arr.length; i++) {
				if (arr[i].id.split('.')[0] === 'place') {
					return (this.address = arr[i].text);
				} else if (arr[i].id.split('.')[0] === 'locality') {
					return (this.address = arr[i].text);
				} else {
					this.address = location.place_name;
				}
			}

			this.countLatLon+=1
			// this.address = location.place_name;

			// this.plantName = `${this.company} / ${this.address} / ${this.plantTypeVal}`;
			// this.latitude = location.center[0].toString()
			// this.longitude = location.center[1].toString()
			// console.log(1,this.latitude,this.longitude)

			this.calcProgress();

		},
		groupSelected(group) {
			this.groupVal = group.name;
			this.groupId = group.id;
			this.calcProgress();
		},
		plantTypeSelected(plantType) {
			this.plantTypeVal = plantType.name;
			this.plantTypeId = plantType.id;
			this.plantName = `${this.company}` + `/ ${this.address}` + `/ ${this.plantTypeVal}`;
			this.calcProgress();
			if (this.plantTypeId){
				this.plantTypeEmpty = false
			}
		},
		applicationsSelected(applications) {
			this.primaryApplicationVal = applications.name;
			this.applicationId = applications.id;
			this.calcProgress();
		},
		geologyTypeSelected(geology) {
			this.geologyTypeVal = geology.name;
			this.productTypesStandartSplits = geology.productTypesStandartSplits;
			this.geologySubTypeId = geology.id;
			this.getCourseMaterial();
			this.getFineMaterial();
			this.getfbMaterial();
			// this.getOtherMaterial();
			this.calcProgress();
			if (this.geologySubTypeId){
				this.geologySubTypeEmpty = false
			}
		},
		transportTypesSelected(trType) {
			this.transportTypesVal = trType.name;
			this.transportTypesId = trType.id;
			this.calcProgress();
		},
		tagsSelected(tags) {
			this.tagsVal = tags.name;
			this.tagId = tags.id;
			this.calcProgress();
		},
		searchCompanies() {
			axios.post('AggregatesProducers/SearchCompanies', { term: this.searchQuery }).then((res) => {
				this.companies = res.data.companies;
				if (!this.companies || !this.companies.length) {
					this.companies = [{ name: 'Add company' }];
				}
			});
		},
		debounceSearch(val) {
			if (this.searchQueryTimeout) {
				clearTimeout(this.searchQueryTimeout);
			}
			this.searchQueryTimeout = setTimeout(() => {
				this.searchQuery = val;
				this.searchQueryTimeout = null;
				if (!this.searchQuery) {
					this.companies = [{ name: 'Add company' }];
				}
			}, 800);
		},
		selectCompany(val) {
			if (val.name === 'Add company') {
				return;
			}
			this.company = val.name;
			this.plantName = `${this.company}` + `/ ${this.address}` + `/ ${this.plantTypeVal}`;
			this.companies = [];
		},
		addProducer() {
			if (this.productTypesStandartSplits.length) {
				this.productTypesStandartSplits = [
					{ percent: Number(this.coarseMaterial), productTypeId: 4 },
					{ percent: Number(this.fineMaterial), productTypeId: 3 },
					{ percent: Number(this.fbMaterial), productTypeId: 2 },
					{ percent: Number(this.otherMaterial), productTypeId: 1 },
				];
			} else {
				this.productTypesStandartSplits = [
					{ percent: 0, productTypeId: 4 },
					{ percent: 0, productTypeId: 3 },
					{ percent: 0, productTypeId: 2 },
					{ percent: 100, productTypeId: 1 },
				];
			}

			if (!this.plantTypeId){
				this.plantTypeEmpty = true
			}

			if (!this.geologySubTypeId){
				this.geologySubTypeEmpty = true
			}

			if (this.plantTypeId && this.geologySubTypeId){
				axios
					.post('AggregatesProducers/SaveAggregatesProducer', {
						aggregatesProducerId: null,
						marketId: Number(localStorage.getItem('marketId')),
						groupId: this.groupId ? this.groupId : 5,
						plantTypeId: this.plantTypeId ? this.plantTypeId : null,
						transportTypeId: this.transportTypesId ? this.transportTypesId : null,
						companyName: this.company ? this.company : 'n/a',
						plantName: this.plantName ? this.plantName : 'n/a',
						// latitude: this.selectedlocation ? this.selectedlocation.geometry.coordinates[1] : 50,
						// longitude: this.selectedlocation ? this.selectedlocation.geometry.coordinates[0] : 30,
						latitude: this.latLonValidation ? this.longitudeCustom : this.selectedlocation ? this.selectedlocation.geometry.coordinates[1] : 50,
						longitude: this.latLonValidation ? this.latitudeCustom : this.selectedlocation ? this.selectedlocation.geometry.coordinates[0] : 30,
						address: this.address ? this.address : 'n/a',
						comment: this.comment ? this.comment : '',
					})
					.then((res) => {
						this.aggregatesProducerId = res.data.aggregatesProducerId;
						axios
							.post('AggregatesProducers/SaveAggregatesProducerGeology', {
								aggregatesProducerGeologyId: null,
								aggregatesProducerId: this.aggregatesProducerId,
								aggregatesProducerTagId: this.tagId ? this.tagId : null,
								applicationId: this.applicationId ? this.applicationId : this.defaultApplicationID,
								geologySubTypeId: this.geologySubTypeId ? this.geologySubTypeId : null,
								marketId: Number(localStorage.getItem('marketId')),
								reserves: this.reserves ? Number(this.reserves) : 0,
								annualAggregatesProduction: this.annualAggregatesProduction ? Number(this.annualAggregatesProduction) : 0,
								volumeSoldInMarketPercent: this.volumeSold ? Number(this.volumeSold) : 0,
								productTypePercentage: this.productTypesStandartSplits,
							})
							.then((res2) => {
								this.aggregatesProducerGeologyId = res2.data.aggregatesProducerGeologyId;
								location.reload();
								this.closeModal();
							})
							.catch((err) => {
								Vue.toasted.error(err.data[err.data.length - 1] === '.' ? err.data.slice(0, -1) : err.data);
							});
					});
			}

		},
		focused() {
			this.calcProgress();
			if (this.reserves) {
				this.reserves = this.reservesRound;
			}
			if (this.annualAggregatesProduction) {
				this.annualAggregatesProduction = this.annualAggregatesProductionRound;
			}
		},
		calcProgress() {
			let count = 0;
			if (this.company) count += 1;
			if (this.groupVal) count += 1;
			if (this.address) count += 1;
			if (this.plantName) count += 1;
			if (this.plantTypeVal) count += 1;
			if (this.geologyTypeVal) count += 1;
			if (this.annualAggregatesProduction) count += 1;
			if (this.volumeSold) count += 1;
			if (this.reserves) count += 1;
			if (this.primaryApplicationVal) count += 1;
			if (this.transportTypesVal) count += 1;
			// if (this.tagsVal) count += 1;
			// if (this.comment) count += 1;
			this.progress = Math.round((count / 11) * 100);
			// if (this.progress < 20) {
			//   this.progressCalc = 'low';
			// }
			// if (this.progress > 20 && this.progress < 40) {
			//   this.progressCalc = 'pre-middle';
			// }
			// if (this.progress > 40 && this.progress < 60) {
			//   this.progressCalc = 'middle';
			// }
			// if (this.progress > 60 && this.progress < 80) {
			//   this.progressCalc = 'up-middle';
			// }
			// if (this.progress > 80) {
			//   this.progressCalc = 'higt';
			// }
		},
	},
	watch: {
		searchQuery(val, oldVal) {
			if (val !== oldVal && val.length > 2) {
				this.searchCompanies();
			}
		},
		company() {
			let result = '';

			if(this.company){
				result = this.company + ' / ';
			}

			result = result + this.address;

			if(this.plantTypeVal){
				result = result + ' / ' + this.plantTypeVal;
			}

			this.plantName = result;


		},
		address() {
			if (this.address || this.company){
				this.plantName = `${this.company}` + `/ ${this.address}` + `/ ${this.plantTypeVal}`;
			} else {
				this.plantName = "";
			}
			// if(!this.params){
			// 	this.plantName = ''
			// }
		},
	},
};
</script>

<style>
.typeEmpty .mr-select{
	border: 1px solid red;
}
.typeEmptyError{
	color: red;
	position: relative;
	top: -30px;
}
</style>
