<template>
    <section>
        <v-card>
            <v-container fluid pa-10>
                <v-row>
                    <div class="bermudaGray--text title ml-3 pb-2">
                        Complete the table below in order to determine your position against othe competitors within the market
                    </div>
                </v-row>
                <v-row>
                    <div class="bermudaGray--text ml-3">
                        <span class="regalBlue--text font-weight-bold">Step 1: </span>
                        go to "tool sign" and select factors that drive competitive advantage
                    </div>
                </v-row>
                <v-row>
                    <div class="bermudaGray--text ml-3">
                        <span class="regalBlue--text font-weight-bold">Step 2: </span>
                        under the table determine weight of each factor in final calculation (all should add up to 100%)
                    </div>
                </v-row>
                <v-row>
                    <div class="bermudaGray--text ml-3">
                        <span class="regalBlue--text font-weight-bold">Step 3: </span>
                        in the table rank your position related to each factor against competitors from
                        <span style="color: #de350b">Highly Unfavourable</span> to <span style="color: #52875a">Highly Favourable</span>
                    </div>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push({ name: 'dashboard', params: { tab: 2 } })" :disabled="isLoadingData" :dark="!isLoadingData" class="mr-3 mb-6" color="mangoTango">
                        <span class="go-to-dashboard white--text">Go to Dashboard to see results</span>
                    </v-btn>
                </v-row>
                <v-data-table
                    :headers="competitivePositionHeaders"
                    :items="competitivePositionData"
                    :items-per-page="-1"
                    :mobile-breakpoint="-1"
                    class="competitive-position-table"
                    item-key="id"
                    hide-default-header
                    hide-default-footer
                >
                    <template v-slot:header>
                        <thead>
                            <tr>
                                <th
                                    v-for="(competitivePositionHeader, competitivePositionHeaderIndex) in competitivePositionHeaders"
                                    :key="competitivePositionHeader.text + getUniqueID()"
                                    :class="[competitivePositionHeaderIndex === 1 ? 'white' : 'bismark']"
                                    class="competitive-position-table-additional-header text-center"
                                >
                                    <span class="lavender--text">{{ competitivePositionHeader.text }}</span>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" class="competitive-position-table-additional-header white"></th>
                                <template v-for="(competitivePositionHeader, competitivePositionHeaderIndex) in competitivePositionHeaders">
                                    <template v-if="competitivePositionHeaderIndex > 1">
                                        <th :key="competitivePositionHeader.text + getUniqueID()" class="competitive-position-table-additional-header white text-center">
                                            <span class="bismark--text">Rank</span>
                                        </th>
                                    </template>
                                </template>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:body="{ items }">
                        <template v-if="isLoadingData">
                            <tr>
                                <td :colspan="competitivePositionHeaders.length * 2 - 2" class="competitive-position-table-progress-bar сapeCod--text text-no-wrap">
                                    <div><v-progress-linear color="regalBlue" indeterminate></v-progress-linear></div>
                                    <div class="pt-5 text-center">Loading... Please wait</div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tbody>
                                <tr v-if="competitivePositionData.length === 0 && !isLoadingData">
                                    <td :colspan="competitivePositionHeaders.length * 2 - 2" class="whiteLilac">
                                        <div class="text-center">No data</div>
                                    </td>
                                </tr>
                                <tr v-for="item in items" :key="item.id + getUniqueID()">
                                    <td class="td-min-max-width сapeCod--text text-no-wrap">
                                        <div class="d-flex align-center justify-center">
                                            <span class="font-weight-medium text-truncate">
                                                {{ item.companyName }}
                                            </span>
                                        </div>
                                    </td>
                                    <template v-if="item.competitivePositionSegmentSupplies.length === 0">
                                        <td style="border: none !important" class="white"></td>
                                        <td colspan="2" class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    No Segment Types
                                                </span>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td style="border: none !important" class="white"></td>
                                        <template v-for="(competitivePositionSegmentSupply, competitivePositionSegmentSupplyIndex) in item.competitivePositionSegmentSupplies">
                                            <td :key="competitivePositionSegmentSupply.id + getUniqueID()" class="td-min-max-width white сapeCod--text text-no-wrap">
                                                <div class="d-flex align-center justify-center">
                                                    <span class="text-truncate">
                                                        <template v-if="competitivePositionSegmentSupply['isLoading' + competitivePositionSegmentSupply.id + 'Rank']">
                                                            <v-progress-circular color="mangoTango" indeterminate></v-progress-circular>
                                                        </template>
                                                        <template v-else>
                                                            <v-select
                                                                v-model="competitivePositionSegmentSupply.rank"
                                                                @change="
                                                                    onChangeCompetitivePositionSupplyRank(
                                                                        competitivePositionSegmentSupply,
                                                                        competitivePositionSegmentSupplyIndex,
                                                                        $event,
                                                                    )
                                                                "
                                                                :items="competitivePositionRankSteps"
                                                                :background-color="
                                                                    competitivePositionSegmentSupply.rank ? competitivePositionSegmentSupply.rank.color + '25' : 'white'
                                                                "
                                                                style="max-width: 220px;"
                                                                class="market-segments-editable-text-field-without-border"
                                                                color="regalBlue"
                                                                item-color="regalBlue"
                                                                item-text="name"
                                                                item-value="value"
                                                                hide-details
                                                                dense
                                                                solo
                                                            >
                                                                <template v-slot:selection="{ item }">
                                                                    <span :style="{ color: item.color }" class="font-weight-bold">
                                                                        {{ item.name }}
                                                                    </span>
                                                                </template>
                                                            </v-select>
                                                        </template>
                                                    </span>
                                                </div>
                                            </td>
                                        </template>
                                    </template>
                                </tr>
                                <template v-if="competitivePositionData.length && competitivePositionMarketSegmentData.length && !isLoadingData">
                                    <div class="py-2"></div>
                                    <tr>
                                        <td class="bermudaGray--text font-weight-bold white text-center">WEIGHT OF EACH FACTOR</td>
                                        <td style="border: none !important;" class="white"></td>
                                        <td
                                            v-for="competitivePositionMarketSegment in competitivePositionMarketSegmentData"
                                            :key="competitivePositionMarketSegment.id + getUniqueID()"
                                            class="td-min-max-width white сapeCod--text text-no-wrap"
                                        >
                                            <div v-if="!isChangedState" class="d-flex align-center justify-center">
                                                <span class="font-weight-medium text-truncate">
                                                    <template v-if="competitivePositionMarketSegment.isEdit">
                                                        <v-text-field
                                                            :value="competitivePositionMarketSegment.percent === 0 ? null : competitivePositionMarketSegment.percent"
                                                            @keydown.enter="(competitivePositionMarketSegment.isEdit = false), (isChangedState = true), (isChangedState = false)"
                                                            @blur="(competitivePositionMarketSegment.isEdit = false), (isChangedState = true), (isChangedState = false)"
                                                            @change="onChangeCompetitivePositionPercent(competitivePositionMarketSegment, $event)"
                                                            class="market-segments-editable-text-field"
                                                            background-color="whiteLilac"
                                                            type="number"
                                                            suffix="%"
                                                            hide-details
                                                            solo
                                                            dense
                                                        ></v-text-field>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="competitivePositionMarketSegment.isLoading">
                                                            <v-progress-circular color="mangoTango" indeterminate></v-progress-circular>
                                                        </template>
                                                        <template v-else>
                                                            <span @click="(competitivePositionMarketSegment.isEdit = true), (isChangedState = true), (isChangedState = false)">
                                                                {{ toFormatNumber(competitivePositionMarketSegment.percent) }}&nbsp;%
                                                            </span>
                                                        </template>
                                                    </template>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <div class="py-2"></div>
                                    <tr>
                                        <td colspan="2" style="border: none !important;" class="white"></td>
                                        <td :colspan="competitivePositionMarketSegments.length" class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="d-flex align-center justify-center">
                                                <span :class="[calculateTotalPercent() === 100 ? 'emerald--text' : 'terraCotta--text']" class="font-weight-medium text-truncate">
                                                    {{ toFormatNumber(calculateTotalPercent()) }}&nbsp;%
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </template>
                    </template>
                </v-data-table>
                <v-row v-if="!isLoadingData && isDisplayAvailableCompetitorProducersButton && competitorProducers.length && page !== totalPages" class="pl-3">
                    <v-btn
                        @click="(isDisplayAvailableCompetitorProducersButton = false), setIsDisplayPaginationToolbar(false), initAvailableCompetitorProducers()"
                        color="mangoTango"
                    >
                        <span class="white--text">Show all available competitors</span>
                    </v-btn>
                </v-row>
            </v-container>
        </v-card>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { getCompetitivePositionTableHeaders } from '@/core/helpers/competitivePositiontTableHeaderHelper';

export default {
    name: 'CompetitivePosition',
    props: {
        originalMarketSegmentId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isLoadingData: false,
            isDisplayAvailableCompetitorProducersButton: true,

            competitivePositionData: [],
            competitivePositionMarketSegmentData: [],

            competitivePositionRankSteps: [
                { name: 'Highly Unfavourable', value: 1, color: '#de350b' },
                { name: 'Unfavourable', value: 2, color: '#ffab00' },
                { name: 'Equal', value: 3, color: '#c1c7d0' },
                { name: 'Favourable', value: 4, color: '#57d9a3' },
                { name: 'Highly Favourable', value: 5, color: '#52875a' },
            ],

            isChangedState: false,
        };
    },
    mounted() {
        this.$emit('start-loading-data');
        this.setIsDisabledPaginationToolbarAddDataButton(true);
        this.setIsLoadingPaginationToolbar(true);
        this.isLoadingData = true;
        this.initPagination();
        setTimeout(async () => {
            await this.init();
            this.competitivePositionData = this.initCompetitivePositionData(this.competitorProducers);
            this.competitivePositionMarketSegmentData = this.initCompetitivePositionMarketSegmentData(this.competitivePositionMarketSegments);
            this.isLoadingData = false;
            this.setIsLoadingPaginationToolbar(false);
            this.$emit('finish-loading-data');
        }, 500);
    },
    watch: {
        pageSize() {
            if (this.competitorProducers.length) {
                this.initPagination();
                this.reInitCompetitivePositionData();
            }
        },
        page() {
            this.reInitCompetitivePositionData();
        },
    },
    computed: {
        ...mapGetters('paginationToolbar', {
            pageSize: 'pageSize',
            page: 'page',
            totalPages: 'totalPages',
        }),
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
        ...mapGetters('marketSegment', {
            productTypes: 'productTypes',
        }),
        ...mapGetters('competitor', {
            competitorProducers: 'competitorProducers',
            competitivePositionMarketSegments: 'competitivePositionMarketSegments',
        }),
        competitivePositionHeaders() {
            const headers = getCompetitivePositionTableHeaders();
            headers.push({
                text: '',
                sortable: false,
            });
            if (this.competitivePositionMarketSegments.length) {
                this.competitivePositionMarketSegments.forEach((item) => {
                    headers.push({
                        text: item.displayName,
                        align: 'center',
                        value: '',
                    });
                });
            } else {
                headers.push({
                    text: '',
                    align: 'center',
                });
            }
            return headers;
        },
    },
    methods: {
        getUniqueID() {
            return uuidv4();
        },
        roundNumber(value, power = 2) {
            return Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
        },
        formatNumber(number, fraction, separator = ' ') {
            let data = number.toLocaleString('en-US');
            data = data.replace(/\./, 'F');
            data = data.replace(/,/g, 'S');
            data = data.replace(/F/, fraction);
            data = data.replace(/S/g, separator);
            return data;
        },
        toFormatNumber(data) {
            return this.formatNumber(data, this.market.separatorType === 0 ? '.' : ',');
        },
        ...mapMutations('paginationToolbar', {
            setIsDisabledPaginationToolbarAddDataButton: 'setIsDisabledPaginationToolbarAddDataButton',
            setIsDisplayPaginationToolbar: 'setIsDisplayPaginationToolbar',
            setIsLoadingPaginationToolbar: 'setIsLoadingPaginationToolbar',
            initPagination: 'initPagination',
        }),
        ...mapActions('competitor', {
            getCompetitorProducers: 'getCompetitorProducers',
            getAvailableCompetitorProducers: 'getAvailableCompetitorProducers',
            getCompetitivePositionMarketSegments: 'getCompetitivePositionMarketSegments',
            saveCompetitivePositionSegmentSupplier: 'saveCompetitivePositionSegmentSupplier',
            updateValueCompetitivePositionMarketSegment: 'updateValueCompetitivePositionMarketSegment',
        }),
        async init() {
            const getCompetitivePositionMarketSegments = this.getCompetitivePositionMarketSegments({
                marketId: this.market.id,
                marketSegmentId: this.originalMarketSegmentId,
            });
            const getCompetitorProducers = this.getCompetitorProducers({
                marketId: this.market.id,
                originalMarketSegmentId: this.originalMarketSegmentId,
                page: this.page,
            });
            await Promise.all([getCompetitivePositionMarketSegments, getCompetitorProducers]);
        },
        initCompetitivePositionData(data) {
            return data.map((item) => {
                const mappedCompetitivePositionMarketSegments = [];
                this.competitivePositionMarketSegments.forEach((competitivePositionMarketSegment) => {
                    mappedCompetitivePositionMarketSegments.push({
                        ['isLoading' + competitivePositionMarketSegment.id + 'Rank']: false,
                        id: competitivePositionMarketSegment.id,
                        marketSegmentId: competitivePositionMarketSegment.marketSegmentId,
                        marketId: competitivePositionMarketSegment.marketId,
                        competitivePositionSegmentSupplyId: null,
                        investigatedCompanyId: null,
                        rank: null,
                    });
                });
                mappedCompetitivePositionMarketSegments.forEach((mappedCompetitivePositionMarketSegment) => {
                    mappedCompetitivePositionMarketSegment.investigatedCompanyId = item.investigatedCompany.id;
                    item.competitivePositionSegmentSupplies.forEach((competitivePositionSegmentSupply) => {
                        if (competitivePositionSegmentSupply.competitivePositionFactorId === mappedCompetitivePositionMarketSegment.id) {
                            mappedCompetitivePositionMarketSegment.competitivePositionSegmentSupplyId = competitivePositionSegmentSupply.id;
                            mappedCompetitivePositionMarketSegment.rank = this.competitivePositionRankSteps.find((x) => x.value === competitivePositionSegmentSupply.rank);
                        }
                    });
                });
                item.competitivePositionSegmentSupplies = mappedCompetitivePositionMarketSegments;
                return {
                    id: item.investigatedCompany.id,
                    companyName: item.investigatedCompany.name,
                    competitivePositionSegmentSupplies: item.competitivePositionSegmentSupplies,
                };
            });
        },
        initCompetitivePositionMarketSegmentData(data) {
            return data.map((item) => {
                item.percent = item.percent == null ? 0 : item.percent;
                Object.assign(item, {
                    isEdit: false,
                    isLoading: false,
                });
                return item;
            });
        },
        reInitCompetitivePositionData() {
            this.$emit('start-loading-data');
            this.setIsLoadingPaginationToolbar(true);
            this.isLoadingData = true;
            this.competitivePositionData = [];
            setTimeout(async () => {
                await this.getCompetitorProducers({
                    marketId: this.market.id,
                    originalMarketSegmentId: this.originalMarketSegmentId,
                    page: this.page,
                });
                this.competitivePositionData = this.initCompetitivePositionData(this.competitorProducers);
                this.competitivePositionMarketSegmentData = this.initCompetitivePositionMarketSegmentData(this.competitivePositionMarketSegments);
                this.isLoadingData = false;
                this.setIsLoadingPaginationToolbar(false);
                this.$emit('finish-loading-data');
            }, 500);
        },
        initAvailableCompetitorProducers() {
            this.$emit('start-loading-data');
            this.isLoadingData = true;
            this.competitivePositionData = [];
            setTimeout(async () => {
                await this.getAvailableCompetitorProducers({ marketId: this.market.id, originalMarketSegmentId: this.originalMarketSegmentId });
                this.competitivePositionData = this.initCompetitivePositionData(this.competitorProducers);
                this.isLoadingData = false;
                this.$emit('finish-loading-data');
            }, 500);
        },
        onChangeCompetitivePositionPercent(item, data) {
            item.isLoadingData = true;
            this.updateValueCompetitivePositionMarketSegment({ marketId: item.marketId, factorId: item.id, percent: this.roundNumber(data) }).then((res) => {
                if (res) {
                    item.percent = this.roundNumber(data);
                }
                item.isLoadingData = false;
            });
        },
        onChangeCompetitivePositionSupplyRank(item, index, data) {
            item['isLoading' + item.id + 'Rank'] = true;
            this.saveCompetitivePositionSegmentSupplier({
                competitivePositionSegmentSupplyId: item.competitivePositionSegmentSupplyId,
                investigatedCompanyId: item.investigatedCompanyId,
                marketId: item.marketId,
                marketSegmentId: this.originalMarketSegmentId,
                factorId: this.competitivePositionMarketSegments[index].id,
                rank: data,
            }).then((res) => {
                if (res) {
                    item.competitivePositionSegmentSupplyId = res;
                    item.rank = this.competitivePositionRankSteps.find((x) => x.value === data);
                }
                item['isLoading' + item.id + 'Rank'] = false;
            });
        },
        calculateTotalPercent() {
            let percent = 0;
            this.competitivePositionMarketSegments.forEach((competitivePositionMarketSegment) => {
                percent += competitivePositionMarketSegment.percent;
            });
            return percent;
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: -0.114286px !important;
}

.td-min-max-width {
    min-width: 150px;
    max-width: 300px;
}

.go-to-dashboard {
    font-size: 15px !important;
    line-height: 22px !important;
}

.competitive-position-table-progress-bar {
    padding: 15px !important;
    border: 1px solid #e2e9f9 !important;
}
</style>
