<template>
    <div class="aggregate-info-wrapper">
        <div class="header">
            <v-row dense>
                <v-col cols="11">
                    <span class="header-title">
                        <slot name="title" />
                    </span>
                </v-col>
                <v-col cols="1">
                    <div class="close-button" @click="close">x</div>
                </v-col>
            </v-row>

            <div class="d-flex align-center gap-5">
                <div class="self-supply">
                    <span>Self-Supply: <slot name="supply" />%</span>
                </div>
                <span>
                    <slot name="group" />
                </span>
            </div>

            <div class="d-flex gap-7">
                <div class="prod-vol-image">
                    <img src="../../../assets/map/order.svg" />
                </div>

                <div class="text-no-wrap">
                    <span>
                        <slot name="total-vol-title" />
                    </span>
                    <div>
                        <slot name="total-vol" />
                    </div>
                </div>
            </div>

            <slot name="header" />
        </div>
        <v-divider />
        <div class="d-flex flex-column gap-3 pa-2">
            <slot name="body" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'aggregateInfoWrapper',
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.aggregate-info-wrapper {
    height: 100%;
    width: 250px;
    background-color: white;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    .header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 8px;
        background-color: #eef8fe;
        border-top-right-radius: 8px;

        .header-title {
            font-weight: 800;
            font-size: 18px;
            color: #0e3f66;
            word-break: break-word;
        }

        .close-button {
            cursor: pointer;
        }

        .self-supply {
            padding: 2px 10px;
            border-radius: 4px;
            background: #d9ecfc;
            white-space: nowrap;

            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #2ea2ff;
            }
        }

        .prod-vol-image {
            display: flex;
            align-items: center;
            padding: 14px;
            background-color: #ffe7d7;
            border-radius: 10px;
        }
    }
}
</style>
