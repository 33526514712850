<template>
    <div class="market-settings">
        <h2>Market Settings</h2>
        <tooltip>
            <p>
                To facilitate data entry process define and select parameters most applicable to your market.
            </p>
        </tooltip>
        <p class="regalBlue--text">All parameters in this section are required</p>
        <market-settings-block />
    </div>
</template>

<script>
import MarketSettingsBlock from './MarketSettings/index.vue';
import Tooltip from '../lib/tooltip.vue';

export default {
    mounted() {
        const el = document.getElementsByClassName('market-settings')[0];
        const elemHeight = el.offsetHeight;
        const elemToTop = el.getBoundingClientRect().top;
        const heightElemWithTop = elemHeight + elemToTop;

        window.addEventListener('scroll', () => {
            const top = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
            if (top > elemToTop && top < heightElemWithTop) {
                this.$store.commit('generalSettings/stepsProcess', { name: 'Market Settings', status: true });
            } else {
                this.$store.commit('generalSettings/stepsProcess', { name: 'Market Settings', status: false });
            }
        });
    },
    components: {
        MarketSettingsBlock,
        Tooltip,
    },
};
</script>
