<template>
  <div class="delete-item-btn">
    <img src="../../assets/lib/x.svg" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.delete-item-btn {
  cursor: pointer;
}
</style>
