export const getCustomerPlantTableReadyMixConcreateHeaders = () => {
    return [
        {
            text: 'COMPANY NAME',
            align: 'center',
            sortable: true,
            value: 'company.name',
        },
        {
            text: 'GROUP',
            align: 'center',
            sortable: true,
            value: 'group.name',
        },
        {
            text: 'LOCATION',
            align: 'center',
            sortable: false,
            value: 'location',
        },
        {
            text: 'PLANT NAME',
            align: 'center',
            sortable: false,
            value: 'plantName',
        },
        {
            text: 'ANNUAL PRODUCTION',
            align: 'center',
            sortable: false,
            value: '',
        },
        {
            text: 'PLANT TYPE',
            align: 'center',
            sortable: true,
            value: 'plantType.displayName',
        },
		{
			text: 'TAGS',
			align: 'center',
			sortable: false,
			value: 'tag',
		},
        {
            text: 'COARSE AGG SUPPLIER',
            align: 'center',
            sortable: false,
            value: 'aggregateSupplier',
        },
        {
			text: 'FINE AGG SUPPLIER',
            align: 'center',
            sortable: false,
            value: '',
        },

    ];
};

export const getCustomerPlantTableAsplhaltHeaders = () => {
    return [
        {
            text: 'COMPANY NAME',
            align: 'center',
            sortable: true,
            value: 'company.name',
        },
        {
            text: 'GROUP',
            align: 'center',
            sortable: true,
            value: 'group.name',
        },
        {
            text: 'LOCATION',
            align: 'center',
            sortable: false,
            value: 'location',
        },
        {
            text: 'PLANT NAME',
            align: 'center',
            sortable: false,
            value: 'plantName',
        },
        {
            text: 'ANNUAL PRODUCTION',
            align: 'center',
            sortable: false,
            value: '',
        },
        {
            text: 'PLANT TYPE',
            align: 'center',
            sortable: true,
            value: 'plantType.displayName',
        },
		{
			text: 'TAGS',
			align: 'center',
			sortable: false,
			value: 'tag',
		},
        {
            text: 'COARSE AGG SUPPLIER',
            align: 'center',
            sortable: false,
            value: 'aggregateSupplier',
        },
        {
			text: 'FINE AGG SUPPLIER',
            align: 'center',
            sortable: false,
            value: '',
        },
    ];
};

export const getCustomerPlantTableConcreteProductsHeaders = () => {
    return [
        {
            text: 'COMPANY NAME',
            align: 'center',
            sortable: true,
            value: 'company.name',
        },
        {
            text: 'GROUP',
            align: 'center',
            sortable: true,
            value: 'group.name',
        },
        {
            text: 'LOCATION',
            align: 'center',
            sortable: false,
            value: 'location',
        },
        {
            text: 'PLANT NAME',
            align: 'center',
            sortable: false,
            value: 'plantName',
        },
        {
            text: 'ANNUAL VOLUME OF AGGREGATES',
            align: 'center',
            sortable: false,
            value: '',
        },
        {
            text: 'PLANT TYPE',
            align: 'center',
            sortable: true,
            value: 'plantType.displayName',
        },
		{
			text: 'TAGS',
			align: 'center',
			sortable: false,
			value: 'tag',
		},
		{
			text: 'COARSE AGG SUPPLIER',
			align: 'center',
			sortable: false,
			value: 'aggregateSupplier',
		},
		{
			text: 'FINE AGG SUPPLIER',
			align: 'center',
			sortable: false,
			value: '',
		},

    ];
};

export const getCustomerPlantTableRailwayHeaders = () => {
    return [
        {
            text: 'COMPANY NAME',
            align: 'center',
            sortable: true,
            value: 'company.name',
        },
        {
            text: 'GROUP',
            align: 'center',
            sortable: true,
            value: 'group.name',
        },
        {
            text: 'LOCATION',
            align: 'center',
            sortable: false,
            value: 'location',
        },
        {
            text: 'PLANT NAME',
            align: 'center',
            sortable: false,
            value: 'plantName',
        },
        {
            text: 'ANNUAL VOLUME OF AGGREGATES',
            align: 'center',
            sortable: false,
            value: '',
        },
		{
			text: 'TAGS',
			align: 'center',
			sortable: false,
			value: 'tag',
		},
        {
            text: 'AGGREGATE SUPPLIER',
            align: 'center',
            sortable: false,
            value: 'aggregateSupplier',
        },
    ];
};

export const getCustomerPlantTableHeaders = () => {
    return [
        {
            text: 'COMPANY NAME',
            align: 'center',
            sortable: true,
            value: 'company.name',
        },
        {
            text: 'GROUP',
            align: 'center',
            sortable: true,
            value: 'group.name',
        },
        {
            text: 'LOCATION',
            align: 'center',
            sortable: false,
            value: 'location',
        },
        {
            text: 'PLANT NAME',
            align: 'center',
            sortable: false,
            value: 'plantName',
        },
        {
            text: 'ANNUAL VOLUME OF AGGREGATES',
            align: 'center',
            sortable: false,
            value: '',
        },
		{
			text: 'TAGS',
			align: 'center',
			sortable: false,
			value: 'tag',
		},
		{
			text: 'AGGREGATE SUPPLIER',
			align: 'center',
			sortable: false,
			value: 'aggregateSupplier',
		},
		{
			text: 'TOTAL COARSE',
			align: 'center',
			sortable: false,
			value: '',
		},
		{
			text: 'TOTAL FINE',
			align: 'center',
			sortable: false,
			value: '',
		},
		{
			text: 'TOTAL FILL/BASE',
			align: 'center',
			sortable: false,
			value: '',
		},
		{
			text: 'TOTAL OTHER',
			align: 'center',
			sortable: false,
			value: '',
		},

    ];
};
