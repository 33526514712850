<template>
    <MglMap :accessToken="accessToken" :mapStyle="mapStyle" :center="center" :zoom="6" @load="onMapLoaded" @click="clickByMap">
        <MglNavigationControl position="top-right" />
        <MglFullscreenControl position="top-left" />
    </MglMap>
</template>

<script>
import { MglMap, MglNavigationControl, MglFullscreenControl } from 'vue-mapbox';
import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
// import MglGeocoderControl from 'vue-mapbox-geocoder';

export default {
    components: {
        MglMap,
        MglNavigationControl,
        MglFullscreenControl,
        // MglGeocoderControl,
    },
    props: {
        marketBoundary: {
            type: Object,
        },
    },
    data() {
        return {
            accessToken: 'pk.eyJ1IjoianVsaWFnZW9yZ2UiLCJhIjoiY2ticXFmbGtlMWhxbTJybWkyaHE3M2l4dSJ9.oI8unHAYIq9Sh7Qtm8E8mg', // your access token. Needed if you using Mapbox maps
            mapStyle: 'mapbox://styles/mapbox/light-v9', // your map style (mapbox://styles/mapbox/satellite-v9)
            // popupCoordinates: [10, 10],
            coordinates: [-77.03238901390978, 38.913188059745586],
            boundary: null,
            draw: null,
            polygonID: null,
        };
    },
    created() {
        // We need to set mapbox-gl library here in order to use it in template
        this.mapbox = null;
    },
    mounted() {
        if (this.$store.getters['generalSettings/getMarket'] && this.$store.getters['generalSettings/getMarket'].boundary) {
            this.boundary = {
                type: 'Polygon',
                coordinates: [this.$store.getters['generalSettings/getMarket'].boundary.coordinates],
            };
        } else if (JSON.parse(localStorage.getItem('boundary'))) {
            // this.boundary = JSON.parse(localStorage.getItem('boundary'));
            this.boundary = {
                type: 'Polygon',
                coordinates: [JSON.parse(localStorage.getItem('boundary')).coordinates],
            };
        }
        // if (JSON.parse(localStorage.getItem('boundary'))) {
        //   this.boundary = JSON.parse(localStorage.getItem('boundary'));
        // }
    },
    methods: {
        onMapLoaded(event) {
            this.map = event.map;
            this.initalized(this.map);
        },
        initalized(map) {
            this.draw = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true,
                },
            });
            map.addControl(this.draw);
            // initalized polygon
            if (this.boundary) {
                // this.draw.add({
                //   type: 'Polygon',
                //   coordinates: [this.boundary.coordinates]
                // });
                this.draw.add(this.boundary);
            }
        },
        clickByMap() {
            if (this.draw.getSelected().features.length && this.draw.getAll().features.length === 1) {
                // this.boundary = this.draw.getAll().features[0].geometry;
                let m = this.draw.getAll().features[0].geometry;
                const boundary = {
                    coordinates: [],
                };
                this.draw.getAll().features[0].geometry.coordinates.forEach((geometry, i) => {
                    geometry.forEach((geo) => {
                        boundary.coordinates.push([geo[1], geo[0]]);
                    });
                });
                this.boundary = boundary;
                this.sendBoundary();
            }
        },
        sendBoundary() {
            this.$store
                .dispatch('generalSettings/saveMarketBoundary', {
                    marketId: this.$store.getters['generalSettings/getMarketId'],
                    boundary: {
                        coordinates: this.boundary.coordinates,
                        // coordinates: this.boundary.coordinates[0],
                    },
                })
                .then(() => {
                    localStorage.setItem('boundary', JSON.stringify(this.boundary));
                    this.$emit('setBoundery');
                });
        },
    },
    computed: {
        center() {
            // this.coordinates = this.$store.getters['generalSettings/getMarket'].boundary.coordinates[0] ? this.$store.getters['generalSettings/getMarket'].boundary.coordinates[0] : [-77.03238901390978, 38.913188059745586];
            return this.$store.getters['generalSettings/getMarket'] && this.$store.getters['generalSettings/getMarket'].boundary
                ? this.$store.getters['generalSettings/getMarket'].boundary.coordinates[0]
                : this.coordinates;
        },
    },
};
</script>

<style lang="scss" scoped>
.mgl-map-wrapper {
    height: 400px;
    position: relative;
    width: 100%;
}
</style>
