<template>
  <v-btn
    :x-small="isExtraSmall"
    :small="isSmall"
    :large="isLarge"
    :x-large="isExtraLarge"
    :color="color"
    :block="isFillWidth"
    :text="isText"
    :class="blockClass"
    class="pointer-events-none no-button-tag"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'NonButtonBlock',
  props: {
    color: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    blockClass: {
      type: String,
      default: '',
    },
    isFillWidth: {
      type: Boolean,
      default: false,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isExtraSmall() {
      return this.size === 'x-small';
    },
    isSmall() {
      return this.size === 'small';
    },
    isLarge() {
      return this.size === 'large';
    },
    isExtraLarge() {
      return this.size === 'x-large';
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.no-button-tag {
  box-shadow: none;
}
</style>
