<template>
  <v-col class="align-center">
    <v-row justify="center">
      <img src="@/assets/market_segments/empty_market_segments.svg" />
    </v-row>
    <v-row justify="center" class="pt-8">
      <div class="title regalBlue--text">No customers yet</div>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <div class="sub-title martinique--text text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua.
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'EmptyDashboard',
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px !important;
  line-height: 28px;
}

.sub-title {
  font-size: 15px;
  line-height: 22px;
}
</style>
