<template>
    <div class="standard-split">
        <h3>Standard split</h3>
        <tooltip>
            <p>Define standard production split per geology type. You can adjust this split for individual quarries later directly in Aggregates Producers section</p>
        </tooltip>
        <div class="standard-split__labels" v-if="standartSplit.length">
            <div class="label-block" v-for="(label, i) in labels" :key="i">
                <label>{{ label }}</label>
            </div>
        </div>
        <div class="standard-split__form" v-for="(field, i) in standartSplit" :key="field.id">
            <div class="geology-type">
                <p>{{ field.type }}</p>
            </div>
            <input-text
                :type="'number'"
                :size="'sm'"
                :inputType="'percent'"
                :percent="field.percent"
                :maxNumber="100 - field.percent"
                v-model.lazy="field.coarse"
                @focused="focused($event, field, 'coarse', i)"
                @input="inputChange($event, field)"
            />
            <input-text
                :type="'number'"
                :size="'sm'"
                :inputType="'percent'"
                :percent="field.percent"
                v-model.lazy="field.fine"
                @focused="focused($event, field, 'fine', i)"
                @input="inputChange($event, field)"
            />
            <input-text
                :type="'number'"
                :size="'sm'"
                :inputType="'percent'"
                v-model.lazy="field.fillBase"
                @focused="focused($event, field, 'fillBase', i)"
                @input="inputChange($event, field)"
            />
            <input-text :placeholder="'Other'" v-model.lazy="field.name" @focused="focused($event, field)" />
            <input-text
                :type="'number'"
                :size="'sm'"
                :inputType="'percent'"
                v-model.lazy="field.volume"
                @focused="focused($event, field, 'volume', i)"
                @input="inputChange($event, field)"
            />
			<div class="tooltip" v-if="Math.round(Number(field.coarse) + Number(field.fine) + Number(field.fillBase) + Number(field.volume)) !== 100">
				<img src="@/assets/lib/warning.svg" height="24px" style="margin: 0" />
				<div class="tooltip__info">
					<p>Total split is doesn't up to 100%</p>
				</div>
			</div>
        </div>
        <div v-if="!standartSplit.length">
            <p>Choose geology</p>
        </div>
    </div>
</template>

<script>
import InputText from '../../lib/inputText.vue';
import Tooltip from '../../lib/tooltip.vue';

export default {
    data() {
        return {
            labels: ['Geology type', 'Coarse (>5mm)', 'Fine (<5mm)', 'Fill/Base', 'Name', 'Volume'],
            standartSplit: [],
            isStandartSplitInited: false,
        };
    },
    created() {
        this.$store.dispatch('generalSettings/getProductTypes');
        this.initForm();
    },
    methods: {
        initForm() {
            if (localStorage.getItem('standartSplit')) {
                this.$store.commit('generalSettings/saveStandartSplit', JSON.parse(localStorage.getItem('standartSplit')));
            }
        },
        addField(id, name) {
            return {
                id,
                type: name,
                coarse: 0,
                fine: 0,
                fillBase: 0,
                name: 'Other',
                volume: 0,
                percent: 0,
            };
        },
        isInteger(num) {
            return (num ^ 0) === num;
        },
        focused(val, field, prop, index) {
            const sumVal = Number(field.coarse) + Number(field.fillBase) + Number(field.fine) + Number(field.volume);
            if (prop) {
                const res = String(field.percent <= 100 ? val : 100 - (field.percent - field[prop]));
                field[prop] = res;
                if (!this.isInteger(field[prop])) {
                    let int = Number(field[prop]).toFixed(1);
                    field[prop] = String(int);
                }
                this.$forceUpdate();
            }
            this.saveGeologyType(field);
        },
        saveGeologyType(field) {
            if (field.coarse !== null && field.fillBase !== null && field.fine !== null) {
                this.saveGeology();
            }
        },
        saveGeology() {
            const geology = {
                marketId: Number(localStorage.getItem('marketId')),
                geologies: this.standartSplit.map((item) => ({
                    geologySubTypeId: item.id,
                    productTypesStandartSplits: this.getProductTypesStandartSplits(item),
                })),
            };
            this.$store.dispatch('generalSettings/saveGeology', geology);
            this.localSave();
        },
        getProductTypesStandartSplits(field) {
            const productType = this.$store.getters['generalSettings/getProductTypes'];
            const productTypesStandartSplits = productType.map((type) => ({
                productTypeId: type.id,
                percent: Number(this.getPercentByProductTypeName(field, type.name)),
                displayName: type.name === 'Other' ? field.name || 'Other' : type.name,
            }));
            return productTypesStandartSplits;
        },
        getPercentByProductTypeName(field, name) {
            switch (name) {
                case 'Coarse (above 5mm)':
                    return field.coarse;
                case 'Fine (below 5mm)':
                    return field.fine;
                case 'Fill/Base':
                    return field.fillBase;
                case 'Other':
                    return field.volume || 0;
                default:
                    return 0;
            }
        },
        localSave() {
            const data = {
                type: 'standartSplit',
                form: this.standartSplit,
            };
            this.$store.commit('generalSettings/localSave', data);
        },
        inputChange(val, field) {
            field.percent = Number(field.coarse) + Number(field.fillBase) + Number(field.fine) + Number(field.volume);
        },
        checkSteps() {
            if (this.standartSplit.length && this.standartSplit[0].coarse && this.standartSplit[0].fillBase && this.standartSplit[0].fine) {
                this.$emit('checkSteps', { name: 'standardSplit', status: true });
            } else {
                this.$emit('checkSteps', { name: 'standardSplit', status: false });
            }
        },
        initStandartSplit() {
            const standartSplit = [...this.$store.getters['generalSettings/getStandartSplit']];
            if (standartSplit && !this.standartSplit.length) {
                this.standartSplit = standartSplit;
            }
            if (this.isStandartSplitInited) {
                this.updataStandartSplit();
                this.saveGeology();
            }
            this.isStandartSplitInited = true;
        },
        updataStandartSplit() {
            const standartSplitId = this.standartSplit.map((el) => el.id);
            const selectedGeologyOptionsId = this.standardSplitFields.map((el) => el.id);
            if (this.standartSplit.length < this.standardSplitFields.length) {
                const diff = [...standartSplitId.filter((id) => !selectedGeologyOptionsId.includes(id)), ...selectedGeologyOptionsId.filter((id) => !standartSplitId.includes(id))];
                const newGeologyOptions = this.standardSplitFields.filter((option) => diff.find((id) => option.id === id));
                newGeologyOptions.forEach((item) => this.standartSplit.push(this.addField(item.id, item.name)));
            } else {
                const diff = [...selectedGeologyOptionsId.filter((id) => !standartSplitId.includes(id)), ...standartSplitId.filter((id) => !selectedGeologyOptionsId.includes(id))];
                this.standartSplit = this.standartSplit.filter((item) => !diff.find((id) => item.id === id));
            }
        },
    },
    watch: {
        standardSplitFields(options) {
            this.initStandartSplit();
            if (!this.$store.getters['generalSettings/getMarket']) {
                this.checkSteps();
            }
        },
        updateGeology(val) {
            if (val) {
                this.saveGeology();
                this.$store.commit('generalSettings/setUpdateGeology', false);
            }
        },
    },
    computed: {
        standardSplitFields() {
            return this.$store.getters['generalSettings/getSelectedGeologyOptions'];
        },
        updateGeology() {
            return this.$store.getters['generalSettings/updateGeology'];
        },
    },
    components: {
        InputText,
        Tooltip,
    },
};
</script>
