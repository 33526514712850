export const getCenterBoundary = (boundary) => {
    let xArr = boundary.map((el) => el[0]);
    let yArr = boundary.map((el) => el[1]);
    let xMax = Math.max.apply(null, xArr);
    let yMax = Math.max.apply(null, yArr);
    let xMin = Math.min.apply(null, xArr);
    let yMin = Math.min.apply(null, yArr);
    const Xcenter = xMin + ((xMax - xMin) / 2);
    const Ycenter = yMin + ((yMax - yMin) / 2);
    return [Xcenter, Ycenter];
}