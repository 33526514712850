<template>
  <section>
    <v-card class="ml-3" min-width="600" max-width="800">
      <v-container fluid pa-10>
        <v-row justify="start" class="pb-6">
          <div class="title bismark--text">Please select factors that he will be working on</div>
        </v-row>
        <v-row justify="start">
          <div class="content bermudaGray--text">
            <span style="text-transform: uppercase !important;">Factors types</span>
          </div>
        </v-row>
        <v-row class="pb-8">
          <v-autocomplete
            @change="onUpdateOtherMarketSegments"
            v-model="selectedCompetitivePositionMarketSegmets"
            :items="competitivePositionMarketSegmetData"
            :disabled="isLoadingData || isUpdatingMarketSegments || isClosingEditSegmentType"
            :loading="isLoadingData || isUpdatingMarketSegments"
            class="competitive-position-setting-editable-text-field pt-4"
            color="regalBlue"
            background-color="whiteLilac"
            item-color="regalBlue"
            label="Select"
            item-text="displayName"
            item-value="id"
            return-object
            multiple
            dense
            chips
            solo
          >
            <template v-slot:selection="data">
              <v-chip
                @click:close="openConfirmationDialog(
				  data.index,
				  data.item.id,
				  true,
				  getTitleFromRankingData(data.item.id),
				  getTextFromRankingData(data.item.id, data.item.displayName),
				  data.item.id
				)"
                :input-value="data.item.selected"
                style="border-radius: 4px;"
                :class="(hasRankingData(data.item.id) ? 'mangoTango' :'pattensBlue') + ' my-1'"
				:dark="hasRankingData(data.item.id)"
                close
              >
                <div style="max-width: 638px" class="d-flex align-center">
                  <span class="text-truncate">{{ data.item.displayName }}</span>
                </div>
              </v-chip>
			</template>
            <template v-slot:item="data">
              <div :class="{ 'pointer-events-none': isUpdatingMarketSegments }" style="max-width: 680px" class="d-flex align-center" @click="onClickCheckbox(data.item)">
                <v-checkbox
                  v-model="selectedCompetitivePositionMarketSegmets"
                  :value="data.item"
                  style="margin: 0px; padding: 0px;"
                  class="pointer-events-none"
                  color="mangoTango"
                  hide-details
                />
                <span class="text-truncate"> {{ data.item.displayName }}</span>
              </div>
            </template>
            <template v-slot:append-item>
              <div class="pt-2" :class="[isDisplayCreateCompetitivePositionMarketSegment ? 'px-2' : 'px-6']">
                <template v-if="isDisplayCreateCompetitivePositionMarketSegment">
                  <v-text-field
                    v-model="creatingCompetitivePositionMarketSegmentName"
                    @blur="isDisplayCreateCompetitivePositionMarketSegment = false"
                    @change="onCreateMarketSegment()"
                    :loading="isCreatingOtherMarketSegment"
                    class="competitive-position-setting-editable-text-field"
                    background-color="whiteLilac"
                    color="regalBlue"
                    clearable
                    hide-details
                    solo
                    dense
                  ></v-text-field>
                </template>
                <template v-else>
                  <span @click="isDisplayCreateCompetitivePositionMarketSegment = true" class="cursor-pointer font-weight-medium">
                    <img src="@/assets/market_segments/plus.svg" style="width: 10px" class="mr-2" />
                    <span class="regalBlue--text">ADD FACTOR</span>
                  </span>
                </template>
              </div>
            </template>
          </v-autocomplete>

		  <ConfirmationDialog
			@onConfirm="removeSelectedCompetitivePositionMarketSegmet(competitivePositionMarketTempId)"
			:open-confirmation-dialog="showConfirmationDialog"
			@onRefuse="openConfirmationDialog(null, null, false)"
			@clickOutside="openConfirmationDialog(null, null, false)"
		  >
			<template #title>
			  {{ confirmationDialogTitle }}
			</template>

			<template #text>
			  {{ confirmationDialogText }}
			</template>
		  </ConfirmationDialog>
        </v-row>
        <v-row justify="end">
          <v-btn @click="onCloseEditCompetitivePositionSetting()" :loading="isClosingEditSegmentType" color="mangoTango" width="150px" dark>
            <span class="close-text white--text">Close</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

import { REGEX_EMPTY_STRING_PATTERN } from '@/core/consts/const';
import ConfirmationDialog from "@/components/common/dialogs/ConfirmationDialog";

export default {
  name: 'CompetitivePositionSetting',
	components: {ConfirmationDialog},
	props: {
    originalMarketSegmentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoadingData: false,

      creatingCompetitivePositionMarketSegmentName: null,
      isDisplayCreateCompetitivePositionMarketSegment: false,
      isCreatingOtherMarketSegment: false,

      isUpdatingMarketSegments: false,

      isClosingEditSegmentType: false,

      competitivePositionMarketSegmetData: [],
      selectedCompetitivePositionMarketSegmets: [],

	  showConfirmationDialog: false,
	  competitivePositionMarketTempId: null,
	  confirmationDialogTitle: 'Delete Factor',
	  confirmationDialogText: 'Are you sure you want to delete factor',
	  factorId: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('marketAssessment', {
      market: 'market',
    }),
    ...mapGetters('competitor', {
      defaultCompetitivePositionMarketSegments: 'defaultCompetitivePositionMarketSegments',
      competitivePositionMarketSegments: 'competitivePositionMarketSegments',
	  competitorProducers: 'competitorProducers',
    }),
    ...mapGetters('paginationToolbar', {
	  pageSize: 'pageSize',
	  page: 'page',
	  totalPages: 'totalPages',
    }),
  },
  methods: {
    ...mapMutations('competitor', {
      setCompetitivePositionMarketSegments: 'setCompetitivePositionMarketSegments',
    }),
    ...mapActions('competitor', {
      getDefaultCompetitivePositionMarketSegments: 'getDefaultCompetitivePositionMarketSegments',
      getCompetitivePositionMarketSegments: 'getCompetitivePositionMarketSegments',
      addCompetitivePositionMarketSegment: 'addCompetitivePositionMarketSegment',
      createCompetitivePositionMarketSegment: 'createCompetitivePositionMarketSegment',
      deleteCompetitivePositionMarketSegment: 'deleteCompetitivePositionMarketSegment',
	  getCompetitorProducers: 'getCompetitorProducers',
    }),
    async init() {
      this.isLoadingData = true;
      await this.getDefaultCompetitivePositionMarketSegments();
      await this.getCompetitivePositionMarketSegments({ marketId: this.market.id, marketSegmentId: this.originalMarketSegmentId });
      this.competitivePositionMarketSegmetData = this.initCompetitivePositionMarketSegmetData();
      this.selectedCompetitivePositionMarketSegmets = this.competitivePositionMarketSegments;
      this.isLoadingData = false;
    },
    initCompetitivePositionMarketSegmetData() {
      const otherMarketSegmentNames = this.competitivePositionMarketSegments.map((otherMarketSegment) => {
        return otherMarketSegment.displayName;
      });
      const defaultCompetitivePositionMarketSegments = [];
      this.defaultCompetitivePositionMarketSegments.forEach((defaultOtherMarketSegment) => {
        if (!otherMarketSegmentNames.includes(defaultOtherMarketSegment.displayName)) {
          defaultCompetitivePositionMarketSegments.push(defaultOtherMarketSegment);
        }
      });
      return this.competitivePositionMarketSegments.concat(defaultCompetitivePositionMarketSegments);
    },
    removeSelectedCompetitivePositionMarketSegmet(index) {
      this.isUpdatingMarketSegments = true;
      this.deleteCompetitivePositionMarketSegment({
        marketSegmentId: this.factorId,
        marketId: this.market.id,
      }).then(async (res) => {
        if (res) {
          this.selectedCompetitivePositionMarketSegmets.splice(index, 1);
          this.setCompetitivePositionMarketSegments(cloneDeep(this.selectedCompetitivePositionMarketSegmets));
          this.competitivePositionMarketSegmetData = this.initCompetitivePositionMarketSegmetData();
        }
        this.isUpdatingMarketSegments = false;
		this.openConfirmationDialog(null, null, false);
      });
    },
    onCreateMarketSegment() {
      if (this.creatingCompetitivePositionMarketSegmentName && !REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompetitivePositionMarketSegmentName)) {
        this.isCreatingOtherMarketSegment = true;
        this.createCompetitivePositionMarketSegment({
          marketSegmentId: this.originalMarketSegmentId,
          marketId: this.market.id,
          displayName: this.creatingCompetitivePositionMarketSegmentName,
        }).then((res) => {
          if (res) {
            this.competitivePositionMarketSegmetData.push(res);
            this.selectedCompetitivePositionMarketSegmets.push(res);
            this.isDisplayCreateCompetitivePositionMarketSegment = false;
            this.creatingCompetitivePositionMarketSegmentName = null;
          }
          this.isCreatingOtherMarketSegment = false;
        });
      } else {
        Vue.toasted.error('Other market segment name can not be empty');
      }
    },
    async onUpdateOtherMarketSegments() {
      if (!this.isUpdatingMarketSegments) {
        this.isUpdatingMarketSegments = true;
        const otherMarketSegmentIds = this.competitivePositionMarketSegments.map((item) => {
          return item.id;
        });
        const selectedOtherMarketSegmetIds = this.selectedCompetitivePositionMarketSegmets.map((item) => {
          return item.id;
        });
        if (JSON.stringify(otherMarketSegmentIds) === JSON.stringify(selectedOtherMarketSegmetIds)) {
          this.isUpdatingMarketSegments = false;
          return;
        }
        const deleteOtherMarketSegmetIds = otherMarketSegmentIds.filter((id) => {
          return !selectedOtherMarketSegmetIds.includes(id);
        });
        const saveOtherMarketSegmetIds = selectedOtherMarketSegmetIds.filter((id) => {
          return !otherMarketSegmentIds.includes(id);
        });
        const competitivePositionMarketSegments = [];

	    if (deleteOtherMarketSegmetIds.length) {
		  this.competitivePositionMarketTempId = this.competitivePositionMarketSegments.findIndex((item) => item.id === deleteOtherMarketSegmetIds[0]);
		  const competitivePositionMarketItem = this.competitivePositionMarketSegments.find((item) => item.id === deleteOtherMarketSegmetIds[0]);
		  const newConfirmationDialogText = `Are you sure you want to delete factor "${competitivePositionMarketItem.displayName}"`

		  this.openConfirmationDialog(this.competitivePositionMarketTempId, deleteOtherMarketSegmetIds[0], true, 'Delete Factor', newConfirmationDialogText)
	    }
        await Promise.all(competitivePositionMarketSegments);
        const selectedCompetitivePositionMarketSegmets = [];
        saveOtherMarketSegmetIds.forEach((id) => {
          selectedCompetitivePositionMarketSegmets.push(
            this.addCompetitivePositionMarketSegment({
              factorId: id,
              marketSegmentId: this.originalMarketSegmentId,
              marketId: this.market.id,
            }),
          );
        });
        await Promise.all(selectedCompetitivePositionMarketSegmets);
        await this.getCompetitivePositionMarketSegments({ marketId: this.market.id, marketSegmentId: this.originalMarketSegmentId });
        this.competitivePositionMarketSegmetData = this.initCompetitivePositionMarketSegmetData();
        this.selectedCompetitivePositionMarketSegmets = this.competitivePositionMarketSegments;
        this.isUpdatingMarketSegments = false;
      }
    },
    onCloseEditCompetitivePositionSetting() {
      this.isClosingEditSegmentType = true;
      setTimeout(() => {
        this.isClosingEditSegmentType = false;
        this.$emit('close-edit-competitive-position-setting');
      }, 500);
    },
    openConfirmationDialog(id, factorId, value, title, text) {
	  this.showConfirmationDialog = value;
	  this.competitivePositionMarketTempId = id;
	  this.factorId = factorId;

	  if (title) {
	    this.confirmationDialogTitle = title;
	  }
	  if (text) {
	    this.confirmationDialogText = text;
	  }
	},
    onClickCheckbox(id) {
	  this.competitivePositionMarketTempId = id;
	},
	  hasRankingData(id) {
		  let value = false;
		  for (let i = 0; i <= this.competitorProducers.length; i++) {
			  if (this.competitorProducers[i]?.competitivePositionSegmentSupplies.length) {
				  for (let segmentIdx = 0; segmentIdx <= this.competitorProducers[i].competitivePositionSegmentSupplies.length; segmentIdx++) {
				     if (this.competitorProducers[i].competitivePositionSegmentSupplies[segmentIdx]) {
						 if (this.competitorProducers[i].competitivePositionSegmentSupplies[segmentIdx].rank && id === this.competitorProducers[i].competitivePositionSegmentSupplies[segmentIdx].id) {
							 value = true;
						 }
					 }
				  }
			  }
		  }

		  return value
	  },
    getTextFromRankingData(index, text) {
	  if (this.competitorProducers.length) {
	    return  this.hasRankingData(index) ? `“${text}” already has ranking data. Deletion will result in data loss.` : `Are you sure you want to delete factor “${text}”`
	  }
	},
    getTitleFromRankingData(index) {
	  if (this.competitorProducers.length) {
		  return this.hasRankingData(index) ? 'Are you sure you want to delete this factor?' : 'Delete Factor'
	  }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.content {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.close-text {
  font-size: 15px !important;
  line-height: 22px !important;
}
</style>
