<template>
  <div class="mr-input-wrapper" :class="[inputType, size]">
    <label v-if="label">{{ label }}</label>
    <input
      :type="type"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :max="maxNumber"
      ref="inputText"
      @keydown="filterKey"
      @input="$emit('input', checkValueForMaxNumber($event))"
      @blur="$emit('focused', checkValueForMaxNumber($event))"
    />
    <div class="color" v-if="colorPicker"></div>
    <deleteItemBtn v-if="deleteBtn" @click.native="$emit('deleteField')" class="deleteBtn" />
  </div>
</template>

<script>
import deleteItemBtn from './deleteItemBtn.vue';

export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String,
    },
    inputType: {
      type: String,
    },
    size: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    colorPicker: {
      type: Boolean,
    },
    deleteBtn: {
      type: Boolean,
    },
    maxNumber: {
      type: Number,
    },
  },
  mounted() {
    this.$refs.inputText.addEventListener('mousewheel', (e) => e.preventDefault());
  },
  methods: {
    filterKey(e) {
      const key = e.key;
      if (this.type === 'number' && (key === '.' || key === '-')) return e.preventDefault();
      if (e.target.value === '0' && key === '0') {
        return e.preventDefault();
      }
    },
    inputText(val) {
      val = val.replace(/[^0-9]+/g, '');
      this.$emit('input', val);
    },
    checkValueForMaxNumber(event) {
      if (this.type === 'Number') {
        if (parseFloat(event.target.value) > this.maxNumber) {
          event.target.value = this.maxNumber;
        }
        return parseFloat(event.target.value);
      }
      return event.target.value;
    },
  },
  components: {
    deleteItemBtn,
  },
};
</script>
