<template>
	<base-layout :style="{ 'background-color': backgroundColor }">
		<v-container fluid>
			<v-row class="pb-5">
				<v-col cols="12">
					<v-row class="px-3">
						<span class="title regalBlue--text">Companies</span>
						<v-spacer></v-spacer>
						<div class="d-flex">
							<div class="mr-3 pb-1"
								 :class="{ 'administration-companies-active-tab-border': isActiveStatus }"
							>
								<v-btn
									color="activeGreen"
									min-width="30"
									class="px-0"
									@click="onFilerUserStatus('active')"
								>
									<span class="white--text">A</span>
								</v-btn>
							</div>
							<div class="mr-3 pb-1"
								 :class="{ 'administration-companies-active-tab-border': isInactiveStatus }"
							>
								<v-btn
									color="disabledGray"
									min-width="30"
									class="px-0"
									@click="onFilerUserStatus('inactive')"
								>
									<span class="white--text">I</span>
								</v-btn>
							</div>

						</div>
						<v-btn @click="isDisplayCreateCompanyDialog = true" :disabled="isLoadingData" color="mangoTango">
							<img src="@/assets/icons/plus.svg" style="width: 18px" />
							<span class="white--text pl-2">ADD NEW COMPANY</span>
						</v-btn>
					</v-row>
				</v-col>
			</v-row>
			<template v-if="isLoadingData">
				<v-row justify="center" class="pt-10">
					<v-progress-circular color="regalBlue" :width="10" :size="120" indeterminate></v-progress-circular>
				</v-row>
			</template>
			<template v-else>
				<v-row>
					<v-col cols="12" class="pb-15">
						<v-expansion-panels v-model="expandCompanies" multiple>
							<v-expansion-panel v-for="(data, index) in companyData" :key="data.company.id + getUniqueID">
								<v-row class="pa-5 justify-space-between align-center" no-gutters>
									<div class="d-flex align-center">
										<span class="company-name regalBlue--text pr-5">{{ data.company.name }}</span>
										<span class="company-user-count regalBlue--text">{{ data.users.length }}</span>
										<span class="company-user-divide bermudaGray--text">/</span>
										<span class="company-user-limit bermudaGray--text">{{ data.company.limitOfAccounts }}</span>
										<v-btn @click="onSelectCompamy(data.company)" class="ml-5" x-small text fab>
											<img src="@/assets/administration_companies/wrench.svg" class="cursor-pointer" />
										</v-btn>
									</div>
									<!--                                    <v-spacer></v-spacer>-->
									<div class="company-user-datepicker">
										<div>
											<span class="regalBlue--text">
												Subscription Start Date
											</span>
											<date-picker
												v-model="data.company.subscriptionStartDate"
												@change="onEditCompanyStartDate(data)"
												:clearable="false"
												:editable="false"
												class="administration-companies-datepicker"
												valueType="format"
											></date-picker>
										</div>
										<div>
											<span class="regalBlue--text">
												Subscription End Date
											</span>
											<date-picker
												v-model="data.company.subscriptionEndDate"
												@change="onEditCompanyEndDate(data)"
												:clearable="false"
												:editable="false"
												:disabled-date="(date) => date < new Date(data.company.subscriptionStartDate)"
												class="administration-companies-datepicker"
												valueType="format"
											></date-picker>
										</div>
										<div>
											<span class="regalBlue--text">
												Subscription Type
											</span>
											<div class="company-name regalBlue--text pt-1">
												<template v-if="data.company.subscriptionType === 1">Regular</template>
												<template v-else-if="data.company.subscriptionType === 0">Light</template>
												<template v-else>{{data.company.subscriptionType}}</template>
											</div>
										</div>
									</div>

									<div>
										<v-btn @click="(creatingUserAccountCompanyId = data.company.id, creatingUserStartDate = data.company.subscriptionStartDate, creatingUserEndDate = data.company.subscriptionEndDate), (isDisplayCreateUserAccountDialog = true)" text>
											<img src="@/assets/administration_companies/add_user.svg" class="cursor-pointer pr-2" />
											<span class="regalBlue--text">ADD NEW USER</span>
										</v-btn>
										<v-btn v-if="!isExpanded(index)" @click="onExpand(index)" class="ml-5" x-small text fab>
											<img src="@/assets/administration_companies/arrow_down.svg" class="cursor-pointer" />
										</v-btn>
										<v-btn v-if="isExpanded(index)" @click="onRollUp(index)" class="ml-5" x-small text fab>
											<img src="@/assets/administration_companies/arrow_up.svg" class="cursor-pointer" />
										</v-btn>
									</div>

								</v-row>
								<v-expansion-panel-content class="expansion-panel-content pa-0">
									<v-data-table
										:style="{ 'background-color': backgroundColor }"
										:class="{ 'administration-companies-table-disabled-events': isDeletingUserAccount }"
										:headers="administrationCompanyHeaders"
										:items="data.users"
										:single-select="false"
										:items-per-page="-1"
										:mobile-breakpoint="-1"
										:loading="isLoadingData"
										class="administration-companies-table"
										item-key="id"
										hide-default-footer
									>
										<template v-slot:progress>
											<v-progress-linear color="regalBlue" indeterminate></v-progress-linear>
											<div class="pt-5 text-center">Loading... Please wait</div>
										</template>
										<template v-slot:body="{ items }">
											<tbody>
											<tr v-if="data.users.length === 0 && !isLoadingData">
												<td colspan="13">
													<div class="text-center">No data</div>
												</td>
											</tr>
											<tr v-for="user in items" :key="user.id + getUniqueID" :class="{ 'pointer-events-none': isDeletingUserAccount }">
												<td style="width: 250px; max-width: 250px;" class="market-name-text сapeCod--text text-no-wrap">
													<div class="d-flex align-center justify-center">
                                                            <span class="text-truncate">
                                                                {{ (user.firstName + ' ' + user.lastName).trim() }}
                                                            </span>
													</div>
												</td>
												<td style="width: 70px; max-width: 70px;" class="year-name-text сapeCod--text text-no-wrap">
													<div class="d-flex align-center justify-center">
                                                            <span class="text-truncate">
                                                                {{ user.id }}
                                                            </span>
													</div>
												</td>
												<td style="width: 200px; max-width: 200px;">
													<div class="d-flex align-center justify-center">
                                                            <span class="text-truncate">
                                                                {{ user.role }}
                                                            </span>
                                                        </div>
                                                    </td>
													<td style="width: 100px">
														<div class="d-flex align-center justify-center">
															<v-checkbox
																v-if="user.isDeletedStatus"
																v-model="user.isDeletedStatus"
																@click="isShowInactiveUserDialog = true, unUserInfo = user"
																style="margin: 0px; padding: 0px;" class="pl-2" color="mangoTango"
																hide-details
																readonly
															/>
															<v-checkbox
																v-else
																v-model="user.isDeletedStatus"
																@change="onUpdateUserAccount(user)"
																style="margin: 0px; padding: 0px;" class="pl-2" color="mangoTango"
																hide-details
															/>
														</div>
													</td>
													<td>
														<date-picker
															v-model="user.subscriptionStartDate"
															:clearable="false"
															:editable="false"
															@change="onEditSubscriptionStartDate(user, data.company)"
															:disabled-date="(date) => date < new Date(data.company.subscriptionStartDate) || date > new Date(data.company.subscriptionEndDate)"
															class="administration-companies-datepicker"
															valueType="format"
														></date-picker>
													</td>
													<td>
														<date-picker
															v-model="user.subscriptionEndDate"
															:clearable="false"
															:editable="false"
															:disabled-date="(date) => date < new Date(user.subscriptionStartDate) || date > new Date(data.company.subscriptionEndDate)"
															@change="onEditSubscriptionEndDate(user)"
															class="administration-companies-datepicker"
															valueType="format"
														></date-picker>
													</td>
                                                    <td style="width: 350px; max-width: 350px;" class="ownership-name-text сapeCod--text text-no-wrap">
                                                        <div class="d-flex align-center justify-center">
                                                            <span class="text-truncate">
                                                                {{ user.email }}
                                                            </span>
														<v-spacer></v-spacer>
														<!-- <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
							<template v-slot:activator="{ on, attrs }">
							  <v-btn
								v-bind="attrs"
								v-on="on"
								@click="onEditUserAccount(index, user)"
								:disabled="user.isDeletingUserAccount"
								color="regalBlue"
								small
								text
								fab
							  >
								<img src="@/assets/administration_companies/edit_pencil.svg" />
							  </v-btn>
							</template>
							<span>In development</span>
						  </v-tooltip> -->
														<span class="px-1"></span>
														<v-btn
															@click="onEditUserAccount(user)"
															:disabled="user.isDeletingUserAccount"
															color="regalBlue"
															small text fab>
															<img src="@/assets/administration_companies/edit_pencil.svg" />
														</v-btn>
														<!--                                                            <v-btn-->
														<!--                                                                @click="onDeleteUserAccount(index, user)"-->
														<!--                                                                :loading="user.isDeletingUserAccount"-->
														<!--                                                                :color="user.isDeletingUserAccount ? 'mangoTango' : 'regalBlue'"-->
														<!--                                                                small-->
														<!--                                                                text-->
														<!--                                                                fab-->
														<!--                                                            >-->
														<!--                                                                <img src="@/assets/administration_companies/trash.svg" />-->
														<!--                                                            </v-btn>-->
													</div>
												</td>
											</tr>
											</tbody>
										</template>
									</v-data-table>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>
				</v-row>
			</template>
		</v-container>
		<v-dialog v-model="isDisplayCreateCompanyDialog" width="600" persistent>
			<v-card>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn
						@click="
                            (isDisplayCreateCompanyDialog = false),
                                (creatingCompanyName = null),
                                (creatingCompanyFirstName = null),
                                (creatingCompanyLastName = null),
                                (creatingCompanyEmail = null),
                                (creatingCompanyPassword = null),
                                (creatingCompanyShowPassword = false),
                                (creatingCompanyLimitNumberOfAccounts = 10)
                        "
						:disabled="isCreatingCompany"
						small
						text
						fab
					>
						<img src="@/assets/icons/close.svg" />
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid px-10>
						<v-row justify="center" class="pb-8">
							<span class="dialog-title regalBlue--text">Create company</span>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">COMPANY NAME</span>
								<v-text-field
									v-model="creatingCompanyName"
									:disabled="isCreatingCompany"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ADMINISTRATION FIRST NAME</span>
								<v-text-field
									v-model="creatingCompanyFirstName"
									:disabled="isCreatingCompany"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ADMINISTRATION LAST NAME</span>
								<v-text-field
									v-model="creatingCompanyLastName"
									:disabled="isCreatingCompany"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">COMPANY START DATE</span>
								<date-picker
									v-model="creatingCompanyStartDate"
									:clearable="false"
									:editable="false"
									class="administration-companies-datepicker"
									valueType="format"
								></date-picker>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">COMPANY END DATE</span>
								<date-picker
									v-model="creatingCompanyEndDate"
									:clearable="false"
									:editable="false"
									class="administration-companies-datepicker"
									valueType="format"
								></date-picker>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">SUBSCRIPTION TYPE</span>
								<v-select
									v-model="creatingCompanySubscriptionType"
									:items="creatingCompanySubscriptionTypeList"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									item-color="regalBlue"
									hide-details
									dense
									solo
								>
								</v-select>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ADMINISTRATION EMAIL</span>
								<v-text-field
									v-model="creatingCompanyEmail"
									:disabled="isCreatingCompany"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ADMINISTRATION PASSWORD</span>
								<v-text-field
									@click:append="creatingCompanyShowPassword = !creatingCompanyShowPassword"
									v-model="creatingCompanyPassword"
									:disabled="isCreatingCompany"
									:type="creatingCompanyShowPassword ? 'text' : 'password'"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								>
									<template v-slot:append>
                                        <span @click="creatingCompanyShowPassword = !creatingCompanyShowPassword" class="cursor-pointer">
                                            <img v-if="!creatingCompanyShowPassword" src="@/assets/icons/eye.svg" class="pl-1" />
                                            <img v-else src="@/assets/icons/eye_off.svg" class="pl-1" />
                                        </span>
									</template>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">LIMIT NUMBER OF ACCOUNTS</span>
								<v-select
									v-model="creatingCompanyLimitNumberOfAccounts"
									:items="creatingCompanyLimitNumberOfAccountSteps"
									:disabled="isCreatingCompany"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									item-color="regalBlue"
									hide-details
									dense
									solo
								>
								</v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-container>
						<v-row justify="center" class="pb-5">
							<v-col cols="4">
								<v-btn @click="onCreateCompany()" :loading="isCreatingCompany" color="mangoTango" block dark>
                                    <span class="create-company-create-button white--text">
                                        Create
                                    </span>
								</v-btn>
							</v-col>
						</v-row>
						<v-row justify="center" class="pb-5">
							<v-btn
								@click="
                                    (isDisplayCreateCompanyDialog = false),
                                        (creatingCompanyName = null),
                                        (creatingCompanyFirstName = null),
                                        (creatingCompanyLastName = null),
                                        (creatingCompanyEmail = null),
                                        (creatingCompanyPassword = null),
                                        (creatingCompanyShowPassword = false),
                                        (creatingCompanyLimitNumberOfAccounts = 10)
                                "
								class="regalBlue--text"
								:disabled="isCreatingCompany"
								text
							>
                                <span class="create-company-cancel-button">
                                    Cancel
                                </span>
							</v-btn>
						</v-row>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="isDisplayEditCompanyDialog" width="600" persistent>
			<v-card>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn
						@click="(isDisplayEditCompanyDialog = false), (editingCompanyName = null), (editingCompanyLimitNumberOfAccounts = 10), (editingCompanyCompanyId = 0)"
						:disabled="isEditingCompany"
						small
						text
						fab
					>
						<img src="@/assets/icons/close.svg" />
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid px-10>
						<v-row justify="center" class="pb-8">
							<span class="dialog-title regalBlue--text">Company account</span>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">COMPANY NAME</span>
								<v-text-field
									v-model="editingCompanyName"
									:disabled="isEditingCompany"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">SUBSCRIPTION TYPE</span>
								<v-select
									v-model="editingCompanySubscriptionType"
									:items="creatingCompanySubscriptionTypeList"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									item-color="regalBlue"
									hide-details
									dense
									solo
								></v-select>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">LIMIT NUMBER OF ACCOUNTS</span>
								<v-select
									v-model="editingCompanyLimitNumberOfAccounts"
									:items="editingCompanyLimitNumberOfAccountSteps"
									:disabled="isEditingCompany"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									item-color="regalBlue"
									hide-details
									dense
									solo
								>
								</v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-container>
						<v-row justify="center" class="pb-5">
							<v-col cols="4">
								<v-btn @click="onEditCompany()" :disabled="isDeletingCompany" :loading="isEditingCompany" :dark="!isDeletingCompany" color="mangoTango" block>
                                    <span class="edit-company-save-button white--text">
                                        Save
                                    </span>
								</v-btn>
							</v-col>
						</v-row>
						<v-row justify="center" class="pb-5">
							<v-btn @click="isDisplayDeleteCompanyDialog = true" class="regalBlue--text" :disabled="isEditingCompany" :loading="isDeletingCompany" text>
                                <span class="edit-company-delete-button">
                                    Delete company account
                                </span>
							</v-btn>
						</v-row>
						<v-row justify="center" class="pb-5">
							<v-btn
								@click="
                                    (isDisplayEditCompanyDialog = false), (editingCompanyName = null), (editingCompanyLimitNumberOfAccounts = 10), (editingCompanyCompanyId = 0)
                                "
								class="regalBlue--text"
								:disabled="isEditingCompany || isDeletingCompany"
								text
							>
                                <span class="edit-company-cancel-button">
                                    Cancel
                                </span>
							</v-btn>
						</v-row>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="isDisplayCreateUserAccountDialog" width="600" persistent>
			<v-card>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn
						@click="
                            (isDisplayCreateUserAccountDialog = false),
                                (creatingUserAccountFirstName = null),
                                (creatingUserAccountLastName = null),
                                (creatingUserAccountEmail = null),
                                (creatingUserAccountPassword = null),
                                (creatingUserAccountShowPassword = false),
                                (creatingUserAccountRole = 'User'),
                                (creatingUserAccountCompanyId = 0)
                        "
						:disabled="isCreatingUserAccount"
						small
						text
						fab
					>
						<img src="@/assets/icons/close.svg" />
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid px-10>
						<v-row justify="center" class="pb-8">
							<span class="dialog-title regalBlue--text">Create user account</span>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">FIRST NAME</span>
								<v-text-field
									v-model="creatingUserAccountFirstName"
									:disabled="isCreatingUserAccount"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">LAST NAME</span>
								<v-text-field
									v-model="creatingUserAccountLastName"
									:disabled="isCreatingUserAccount"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<!--						<v-row justify="center">-->
						<!--							<v-col cols="10">-->
						<!--								<span class="placeholder-text bermudaGray&#45;&#45;text">SUBSCRIPTION START DATE</span>-->
						<!--								<date-picker-->
						<!--									v-model="creatingUserStartDate"-->
						<!--									class="administration-companies-datepicker"-->
						<!--									valueType="format"-->
						<!--								></date-picker>-->
						<!--							</v-col>-->
						<!--						</v-row>-->
						<!--						<v-row justify="center">-->
						<!--							<v-col cols="10">-->
						<!--								<span class="placeholder-text bermudaGray&#45;&#45;text">SUBSCRIPTION END DATE</span>-->
						<!--								<date-picker-->
						<!--									v-model="creatingUserEndDate"-->
						<!--									class="administration-companies-datepicker"-->
						<!--									valueType="format"-->
						<!--								></date-picker>-->
						<!--							</v-col>-->
						<!--						</v-row>-->
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">EMAIL</span>
								<v-text-field
									v-model="creatingUserAccountEmail"
									:disabled="isCreatingUserAccount"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">PASSWORD</span>
								<v-text-field
									@click:append="creatingUserAccountShowPassword = !creatingUserAccountShowPassword"
									v-model="creatingUserAccountPassword"
									:disabled="isCreatingUserAccount"
									:type="creatingUserAccountShowPassword ? 'text' : 'password'"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									hide-details
									dense
									solo
								>
									<template v-slot:append>
                                        <span @click="creatingUserAccountShowPassword = !creatingUserAccountShowPassword" class="cursor-pointer">
                                            <img v-if="!creatingUserAccountShowPassword" src="@/assets/icons/eye.svg" class="pl-1" />
                                            <img v-else src="@/assets/icons/eye_off.svg" class="pl-1" />
                                        </span>
									</template>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ROLE</span>
								<v-select
									v-model="creatingUserAccountRole"
									:items="creatingUserRoleAccountSteps"
									:disabled="isCreatingUserAccount"
									class="administration-companies-editable-text-field"
									background-color="whiteLilac"
									color="regalBlue"
									item-color="regalBlue"
									hide-details
									dense
									solo
								>
								</v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-container>
						<v-row justify="center" class="pb-5">
							<v-col cols="4">
								<v-btn @click="onCreateUserAccount()" :loading="isCreatingUserAccount" color="mangoTango" block dark>
                                    <span class="create-user-account-create-button white--text">
                                        Create
                                    </span>
								</v-btn>
							</v-col>
						</v-row>
						<v-row justify="center" class="pb-5">
							<v-btn
								@click="
                                    (isDisplayCreateUserAccountDialog = false),
                                        (creatingUserAccountFirstName = null),
                                        (creatingUserAccountLastName = null),
                                        (creatingUserAccountEmail = null),
                                        (creatingUserAccountPassword = null),
                                        (creatingUserAccountShowPassword = false),
                                        (creatingUserAccountRole = 'User'),
                                        (creatingUserAccountCompanyId = 0)
                                "
								class="regalBlue--text"
								:disabled="isCreatingUserAccount"
								text
							>
                                <span class="create-user-account-cancel-button">
                                    Cancel
                                </span>
							</v-btn>
						</v-row>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="isDisplayEditUserAccountDialog" width="600" persistent>
			<v-card>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn @click="
                        (isDisplayEditUserAccountDialog = false),
                            (editUserAccountFirstName = null),
                            (editUserAccountLastName = null),
                            (editUserAccountRole = 'User')
                    " :disabled="isCreatingUserAccount" small text fab>
						<img src="@/assets/icons/close.svg" />
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid px-10>
						<v-row justify="center" class="pb-8">
							<span class="dialog-title regalBlue--text">Edit user account</span>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">FIRST NAME</span>
								<v-text-field v-model="editUserAccountFirstName"
											  class="administration-users-editable-text-field" background-color="whiteLilac"
											  color="regalBlue" hide-details dense solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">LAST NAME</span>
								<v-text-field v-model="editUserAccountLastName"
											  class="administration-users-editable-text-field" background-color="whiteLilac"
											  color="regalBlue" hide-details dense solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ROLE</span>
								<v-select v-model="editUserAccountRole" :items="creatingUserRoleAccountSteps"
										  class="administration-users-editable-text-field"
										  background-color="whiteLilac" color="regalBlue" item-color="regalBlue" hide-details
										  dense solo>
								</v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-4">
					<v-container>
						<v-row justify="center" class="pb-4">
							<v-col cols="4">
								<v-btn @click="onSaveEditUserAccount()" :loading="isCreatingUserAccount"
									   color="mangoTango" block dark>
                                    <span class="create-user-account-create-button white--text">
                                        Save
                                    </span>
								</v-btn>
							</v-col>
						</v-row>
						<v-row justify="center" class="pb-5">
							<v-btn @click="
                                (isDisplayEditUserAccountDialog = false),
                                    (editUserAccountFirstName = null),
                                    (editUserAccountLastName = null),
                                    (editUserAccountRole = 'User')
                            " class="regalBlue--text" text>
                                <span class="create-user-account-cancel-button">
                                    Cancel
                                </span>
							</v-btn>
						</v-row>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="isShowInactiveUserDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Inactive user
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Are you sure you want to delete
					<span class="font-weight-bold">"{{ unUserInfo ? unUserInfo.firstName + ' ' + unUserInfo.lastName : '...' }}"</span>
					user?
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="onCloseUnCheckDialog()" color="mangoTango" text>
						<span>No</span>
					</v-btn>
					<span class="px-2"></span>
					<v-btn @click="onUnCheckGroup()" color="regalBlue" dark>
						<span class="white--text">Yes</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="isDisplayDeleteCompanyDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Delete company
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Are you sure you want to delete company?
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="isDisplayDeleteCompanyDialog = false" color="mangoTango" text>
						<span>No</span>
					</v-btn>
					<span class="px-2"></span>
					<v-btn @click="onDeleteCompany()" color="regalBlue" dark>
						<span class="white--text">Yes</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


	</base-layout>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, findIndex } from 'lodash';
import DatePicker from 'vue2-datepicker';

import { getAdministrationCompanyTableHeaders } from '@/core/helpers/administrationCompanyTableHeaderHelper';
import { UserRole } from '@/core/enums/userRole';
import { REGEX_EMPTY_STRING_PATTERN, REGEX_EMAIL_PATTERN } from '@/core/consts/const';

export default {
	name: 'AdministrationCompany',
	data() {
		return {
			isLoadingData: false,
			isActiveStatus: null,
			isInactiveStatus: null,
			isStatus: '',
			isShowInactiveUserDialog: false,
			isDisplayDeleteCompanyDialog: false,
			unUserInfo: null,
			expandCompanies: [],
			administrationCompanyHeaders: getAdministrationCompanyTableHeaders(),
			companyData: [],
			companyDataSec: [],
			isDisplayCreateCompanyDialog: false,
			isCreatingCompany: false,
			creatingCompanyName: null,
			creatingCompanyFirstName: null,
			creatingCompanyLastName: null,
			creatingCompanyStartDate: null,
			creatingCompanyEndDate: null,
			creatingCompanyEmail: null,
			creatingCompanyPassword: null,
			creatingCompanyShowPassword: false,
			creatingCompanyLimitNumberOfAccounts: 10,
			creatingCompanyLimitNumberOfAccountSteps: [10, 20, 30, 40],

			creatingCompanySubscriptionType: 'Regular',
			creatingCompanySubscriptionTypeList: ['Regular', 'Light'],

			isDisplayEditUserAccountDialog: false,
			isDisplayEditCompanyDialog: false,
			isEditingCompany: false,
			isDeletingCompany: false,
			editingCompanyName: null,
			editingCompanyLimitNumberOfAccounts: 10,
			editingCompanyLimitNumberOfAccountSteps: [10, 20, 30, 40],
			editingCompanyCompanyId: 0,
			editingCompanySubscriptionType: null,
			editingCompanyStartDate: null,
			editingCompanyEndDate: null,

			isDisplayCreateUserAccountDialog: false,
			isCreatingUserAccount: false,
			creatingUserAccountFirstName: null,
			creatingUserAccountLastName: null,
			creatingUserStartDate: null,
			creatingUserEndDate: null,
			creatingUserAccountEmail: null,
			creatingUserAccountPassword: null,
			creatingUserAccountShowPassword: false,
			creatingUserAccountRole: 'User',
			creatingUserRoleAccountSteps: ['CompanyAdmin', 'User'],
			creatingUserAccountCompanyId: 0,

			editUserAccountFirstName: null,
			editUserAccountLastName: null,
			editUserAccountRole: null,
			editUserAccountId: null,
            editUserStartDate: null,
            editUserEndDate: null,

			isDeletingUserAccount: false,

			enumUserRole: UserRole,

			isInitedData: false,
			isInitedPageSize: false,
		};
	},
	components: { DatePicker },
	mounted() {
		if (this.market) {
			this.init();
			this.isInitedData = true;
		}
	},
	watch: {
		isLoadedAvailableMarkets() {
			if (this.market === null) {
				this.$router.push({ path: '/' });
			}
		},
		userRole() {
			if (![this.enumUserRole.Administrator].includes(this.userRole)) {
				this.$router.push({ path: '/' });
			}
		},
		market() {
			if (!this.isInitedData) {
				this.init();
			}
		},
	},
	computed: {
		...mapGetters('account', {
			userRole: 'userRole',
			companies: 'companies',
		}),
		...mapGetters('marketAssessment', {
			isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
			market: 'market',
		}),

		backgroundColor() {
			return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
		},
		getUniqueID() {
			return uuidv4();
		},
	},
	methods: {
		...mapActions('account', {
			getCompanies: 'getCompanies',
			createCompanyAccount: 'createCompanyAccount',
			updateCompany: 'updateCompany',
			deleteCompany: 'deleteCompany',
			register: 'register',
			deleteUser: 'deleteUser',
			editUser: 'editUser',
			updateUserStatus: 'updateUserStatus',

		}),

		async init() {
			this.isLoadingData = true;
			await this.getCompanies();
			this.companyData = this.initCompanyData();
			this.companyDataSec = this.companyData
			for (let index = 0; index < this.companyData.length; index++) {
				this.expandCompanies.push(index);
			}
			if (this.isActiveStatus && !this.isInactiveStatus) {
				this.companyData = this.companyDataSec
				const statusData = this.companyData.map((company) => ({
					users: company.users.filter(item => item.isDeletedStatus === true),
					company: company.company
				}));
				this.companyData = statusData
			}
			if (!this.isActiveStatus && this.isInactiveStatus) {
				this.companyData = this.companyDataSec
				const statusData = this.companyData.map((company) => ({
					users: company.users.filter(item => item.isDeletedStatus === false),
					company: company.company
				}));
				this.companyData = statusData
			}
			if (this.isActiveStatus && this.isInactiveStatus){
				this.companyData = this.companyDataSec
			}
			if (!this.isActiveStatus && !this.isInactiveStatus){
				this.companyData = this.companyDataSec
			}
			this.isLoadingData = false;
		},

		onEditSubscriptionStartDate(user, data) {

			const dates = new Date(user.subscriptionStartDate).setFullYear(new Date(user.subscriptionStartDate).getFullYear() + 1)
			let date = new Date(dates)
			let date2 = new Date(data.subscriptionEndDate)
			let date3 = date2.setDate(date2.getDate() + 1)

			if(dates > date3){
				date = new Date(date3)
			}

			let foundUser = null;

			for (const item of this.companyData) {
				const users = item.users;
				if (users) {
					foundUser = users.find(userItem => userItem.id === user.id);
					if (foundUser) {
						break;
					}
				}
			}

			foundUser.subscriptionEndDate = date.toISOString().split('T')[0]

			if(user.subscriptionStartDate === null){
				foundUser.subscriptionEndDate = null
			}

			this.editUser({
				userId: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				role: user.role,
				subscriptionEndDate: foundUser.subscriptionEndDate,
				subscriptionStartDate: user.subscriptionStartDate,
			}).then((res) => {
				if (res) {
					// this.init();
				}
			})
		},

		onEditSubscriptionEndDate(user) {
			this.editUser({
				userId: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				role: user.role,
				subscriptionEndDate: user.subscriptionEndDate,
				subscriptionStartDate: user.subscriptionStartDate,
			}).then((res) => {
				if (res) {
					// this.init();
				}
			})
		},

		onEditCompanyStartDate(data) {

			const dates = new Date(data.company.subscriptionStartDate).setFullYear(new Date(data.company.subscriptionStartDate).getFullYear() + 1)
			const date = new Date(dates);

			const property = this.companyData.find(obj => obj.company.id === data.company.id);
			property.company.subscriptionEndDate = date.toISOString().split('T')[0]

			if(data.company.subscriptionStartDate === null){
				property.company.subscriptionEndDate = null
			}

			this.updateCompany({
				companyId: data.company.id,
				name: data.company.name,
				limitOfAccounts: data.company.limitOfAccounts,
				subscriptionEndDate: property.company.subscriptionEndDate,
				subscriptionStartDate: data.company.subscriptionStartDate,
				subscriptionType: data.company.subscriptionType
			}).then((res) => {
				this.init();
			});

		},

		onEditCompanyEndDate(data) {
			this.updateCompany({
				companyId: data.company.id,
				name: data.company.name,
				limitOfAccounts: data.company.limitOfAccounts,
				subscriptionEndDate: data.company.subscriptionEndDate,
				subscriptionStartDate: data.company.subscriptionStartDate,
				subscriptionType: data.company.subscriptionType
			}).then((res) => {
				this.init();
			});

		},

		onCloseUnCheckDialog() {
			this.isShowInactiveUserDialog = false
			this.unUserInfo = null
		},

		initCompanyData() {
			return this.companies.map((company) => {
				company.users.forEach((user) => {
					Object.assign(user, {
						isDeletingUserAccount: false,
						isDeletedStatus: !user.isDeleted
					});
				});
				return company;
			});
		},
		onRollUp(data) {
			const index = this.expandCompanies.indexOf(data);
			if (index > -1) {
				this.expandCompanies.splice(index, 1);
			}
		},
		onExpand(data) {
			const index = this.expandCompanies.indexOf(data);
			if (index === -1) {
				this.expandCompanies.push(data);
			}
		},
		isExpanded(data) {
			const index = this.expandCompanies.indexOf(data);
			if (index > -1) {
				return true;
			}
			return false;
		},
		onSelectCompamy(data) {
			this.editingCompanyCompanyId = cloneDeep(data.id);
			this.editingCompanyName = cloneDeep(data.name);
			this.editingCompanyLimitNumberOfAccounts = cloneDeep(data.limitOfAccounts);
			this.isDisplayEditCompanyDialog = true;
			this.editingCompanyStartDate = data.subscriptionStartDate;
			this.editingCompanyEndDate = data.subscriptionEndDate;

			if(data.subscriptionType === 1){
				this.editingCompanySubscriptionType = 'Regular'
			} else if(data.subscriptionType === 0){
				this.editingCompanySubscriptionType = 'Light'
			} else{
				this.editingCompanySubscriptionType = ''
			}
		},
		onCreateCompany() {
			if (
				this.creatingCompanyStartDate &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyStartDate) &&
				this.creatingCompanyEndDate &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyEndDate) &&
				this.creatingCompanyName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyName) &&
				this.creatingCompanyFirstName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyFirstName) &&
				this.creatingCompanyLastName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyLastName) &&
				this.creatingCompanyEmail &&
				REGEX_EMAIL_PATTERN.test(this.creatingCompanyEmail) &&
				this.creatingCompanyPassword &&
				this.creatingCompanyPassword.length >= 8 &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyPassword)
			) {
				this.isCreatingCompany = true;
				this.createCompanyAccount({
					companyName: this.creatingCompanyName,
					firstName: this.creatingCompanyFirstName,
					lastName: this.creatingCompanyLastName,
					subscriptionStartDate: this.creatingCompanyStartDate,
					subscriptionEndDate: this.creatingCompanyEndDate,
					subscriptionType : this.creatingCompanySubscriptionType,
					email: this.creatingCompanyEmail,
					password: this.creatingCompanyPassword,
					limitOfAccounts: this.creatingCompanyLimitNumberOfAccounts,
				}).then((res) => {
					if (res) {
						this.companyData.push({
							company: {
								id: res.companyId,
								name: this.creatingCompanyName,
								limitOfAccounts: this.creatingCompanyLimitNumberOfAccounts,
								subscriptionStartDate: this.creatingCompanyStartDate,
								subscriptionEndDate: this.creatingCompanyEndDate,
								subscriptionType : this.creatingCompanySubscriptionType,
							},
							users: [
								{
									id: res.userId,
									firstName: this.creatingCompanyFirstName,
									lastName: this.creatingCompanyLastName,
									email: this.creatingCompanyEmail,
									role: 'CompanyAdmin',
									companyId: res.companyId,
									subscriptionStartDate: this.creatingCompanyStartDate,
									subscriptionEndDate: this.creatingCompanyEndDate,
									subscriptionType : this.creatingCompanySubscriptionType,
								},
							],
						});

						this.expandCompanies.push(this.companyData.length - 1);
						this.creatingCompanyName = null;
						this.creatingCompanyFirstName = null;
						this.creatingCompanyLastName = null;
						this.creatingCompanyEmail = null;
						this.creatingCompanyPassword = null;
						this.creatingCompanyShowPassword = false;
						this.creatingCompanyLimitNumberOfAccounts = 10;
						this.creatingCompanySubscriptionType = 'Regular';
						this.isDisplayCreateCompanyDialog = false;
					}
					this.isCreatingCompany = false;
				});
			} else {
				if (!this.creatingCompanyName || REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyName)) {
					Vue.toasted.error('Company name can not be empty');
				}
				if (!this.creatingCompanyFirstName || REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyFirstName)) {
					Vue.toasted.error('First name can not be empty');
				}
				if (!this.creatingCompanyLastName || REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyLastName)) {
					Vue.toasted.error('Last name can not be empty');
				}
				if (!this.creatingCompanyStartDate || REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyStartDate)) {
					Vue.toasted.error('Company start date can not be empty');
				}
				if (!this.creatingCompanyEndDate || REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyEndDate)) {
					Vue.toasted.error('Company end date can not be empty');
				}
				if (!this.creatingCompanyEmail) {
					this.isEmailHasError = true;
					Vue.toasted.error('Email field must not be empty');
				}
				if (this.creatingCompanyEmail && !REGEX_EMAIL_PATTERN.test(this.creatingCompanyEmail)) {
					this.isEmailHasError = true;
					Vue.toasted.error('Email not valid');
				}
				if (!this.creatingCompanyPassword || REGEX_EMPTY_STRING_PATTERN.test(this.creatingCompanyPassword)) {
					Vue.toasted.error('Password can not be empty');
				}
				if (this.creatingCompanyPassword && this.creatingCompanyPassword.length < 8) {
					Vue.toasted.error('Password must be at least more than 8 symbols');
				}
			}
		},
		onEditCompany() {
			if (this.editingCompanyName && !REGEX_EMPTY_STRING_PATTERN.test(this.editingCompanyName)) {
				this.isEditingCompany = true;
				this.updateCompany({
					companyId: this.editingCompanyCompanyId,
					name: this.editingCompanyName,
					limitOfAccounts: this.editingCompanyLimitNumberOfAccounts,
					subscriptionStartDate: this.editingCompanyStartDate,
					subscriptionEndDate: this.editingCompanyEndDate,
					subscriptionType: this.editingCompanySubscriptionType
				}).then((res) => {
					if (res) {
						this.companyData.forEach((data) => {
							if (data.company.id === this.editingCompanyCompanyId) {
								data.company.name = this.editingCompanyName;
								data.company.subscriptionType = this.editingCompanySubscriptionType;
								data.company.limitOfAccounts = this.editingCompanyLimitNumberOfAccounts;
							}
						});

						this.editingCompanyName = null;
						this.editingCompanyLimitNumberOfAccounts = 10;
						this.editingCompanyCompanyId = 0;

						this.isDisplayEditCompanyDialog = false;
					}
					this.isEditingCompany = false;
				});
			} else {
				if (!this.editingCompanyName || REGEX_EMPTY_STRING_PATTERN.test(this.editingCompanyName)) {
					Vue.toasted.error('Company name can not be empty');
				}
				if (!this.editingCompanySubscriptionType || REGEX_EMPTY_STRING_PATTERN.test(this.editingCompanySubscriptionType)) {
					Vue.toasted.error('Subscription type can not be empty');
				}
			}
		},
		onDeleteCompany() {
			this.isDeletingCompany = true;
			this.deleteCompany({ companyId: this.editingCompanyCompanyId }).then((res) => {
				if (res) {
					const index = findIndex(this.companyData, (data) => {
						return data.company.id === this.editingCompanyCompanyId;
					});
					if (index !== -1) {
						this.companyData.splice(index, 1);
					}

					this.editingCompanyName = null;
					this.editingCompanyLimitNumberOfAccounts = 10;
					this.editingCompanyCompanyId = 0;

					this.isDisplayEditCompanyDialog = false;
				}
				this.isDeletingCompany = false;
				this.isDisplayDeleteCompanyDialog = false
			});
		},
		onCreateUserAccount() {
			if (
				this.creatingUserAccountFirstName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountFirstName) &&
				this.creatingUserAccountLastName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountLastName) &&
				this.creatingUserAccountEmail &&
				REGEX_EMAIL_PATTERN.test(this.creatingUserAccountEmail) &&
				this.creatingUserAccountPassword &&
				this.creatingUserAccountPassword.length >= 8 &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountPassword)
			) {
				this.isCreatingUserAccount = true;
				this.register({
					companyId: this.creatingUserAccountCompanyId,
					firstName: this.creatingUserAccountFirstName,
					lastName: this.creatingUserAccountLastName,
					email: this.creatingUserAccountEmail,
					password: this.creatingUserAccountPassword,
					role: this.creatingUserAccountRole,
					subscriptionStartDate: this.creatingUserStartDate,
					subscriptionEndDate : this.creatingUserEndDate
				}).then((res) => {
					if (res) {
						this.companyData.forEach((data) => {
							if (data.company.id === this.creatingUserAccountCompanyId) {
								data.users.push({
									id: res,
									firstName: this.creatingUserAccountFirstName,
									lastName: this.creatingUserAccountLastName,
									email: this.creatingUserAccountEmail,
									role: this.creatingUserAccountRole,
									companyId: this.creatingUserAccountCompanyId,
									isDeletingUserAccount: false,
									subscriptionStartDate: this.creatingUserStartDate,
									subscriptionEndDate : this.creatingUserEndDate
								});
							}
						});

						this.creatingUserAccountFirstName = null;
						this.creatingUserAccountLastName = null;
						this.creatingUserAccountEmail = null;
						this.creatingUserAccountPassword = null;
						this.creatingUserAccountShowPassword = false;
						this.creatingUserAccountRole = 'User';
						this.creatingUserAccountCompanyId = 0;

						this.isDisplayCreateUserAccountDialog = false;
					}
					this.isCreatingUserAccount = false;
				});
			} else {
				if (!this.creatingUserAccountFirstName || REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountFirstName)) {
					Vue.toasted.error('First name can not be empty');
				}
				if (!this.creatingUserAccountLastName || REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountLastName)) {
					Vue.toasted.error('Last name can not be empty');
				}
				if (!this.creatingUserAccountEmail) {
					this.isEmailHasError = true;
					Vue.toasted.error('Email field must not be empty');
				}
				if (this.creatingUserAccountEmail && !REGEX_EMAIL_PATTERN.test(this.creatingUserAccountEmail)) {
					this.isEmailHasError = true;
					Vue.toasted.error('Email not valid');
				}
				if (!this.creatingUserAccountPassword || REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountPassword)) {
					Vue.toasted.error('Password can not be empty');
				}
				if (this.creatingUserAccountPassword && this.creatingUserAccountPassword.length < 8) {
					Vue.toasted.error('Password must be at least more than 8 symbols');
				}
			}
		},

		onUnCheckGroup() {
			this.onUpdateUserAccount(this.unUserInfo);
			this.isShowInactiveUserDialog = false
			this.unUserInfo = null
		},

		onUpdateUserAccount(user){
			this.updateUserStatus({
				userId: user.id,
				isDeleted: !user.isDeleted,
			}).then((res) => {
				if (res) {
					this.init();
				}
			});
		},

		onSaveEditUserAccount() {
			if (
				this.editUserAccountFirstName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountFirstName) &&
				this.editUserAccountLastName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountLastName)
			) {
				// this.iseditUserAccount = true;
				this.editUser({
					userId: this.editUserAccountId,
					firstName: this.editUserAccountFirstName,
					lastName: this.editUserAccountLastName,
					role: this.editUserAccountRole,
                    subscriptionStartDate: this.editUserStartDate,
					subscriptionEndDate : this.editUserEndDate
				}).then((res) => {
					if (res) {
						this.init();
						this.editUserAccountFirstName = null;
						this.editUserAccountLastName = null;
						this.editUserAccountRole = 'User';

						this.isDisplayEditUserAccountDialog = false;
					}

				});
			} else {
				if (!this.editUserAccountFirstName || REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountFirstName)) {
					Vue.toasted.error('First name can not be empty');
				}
				if (!this.editUserAccountLastName || REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountLastName)) {
					Vue.toasted.error('Last name can not be empty');
				}
			}
		},

		onEditUserAccount(user) {
            console.log(111, user);
			this.editUserAccountFirstName = user.firstName
			this.editUserAccountLastName = user.lastName
			this.editUserAccountRole = user.role
			this.editUserAccountId = user.id
            this.editUserStartDate = user.subscriptionStartDate
            this.editUserEndDate = user.subscriptionEndDate
			this.isDisplayEditUserAccountDialog = true
		},

		onFilerUserStatus(status){
			this.isStatus = status

			if (this.isStatus === 'active') {
				this.isActiveStatus = !this.isActiveStatus
			}
			if (this.isStatus === 'inactive') {
				this.isInactiveStatus = !this.isInactiveStatus
			}
			if (this.isActiveStatus && !this.isInactiveStatus) {
				this.companyData = this.companyDataSec
				const statusData = this.companyData.map((company) => ({
					users: company.users.filter(item => item.isDeletedStatus === true),
					company: company.company
				}));
				this.companyData = statusData
			}
			if (!this.isActiveStatus && this.isInactiveStatus) {
				this.companyData = this.companyDataSec
				const statusData = this.companyData.map((company) => ({
					users: company.users.filter(item => item.isDeletedStatus === false),
					company: company.company
				}));
				this.companyData = statusData
			}
			if (this.isActiveStatus && this.isInactiveStatus){
				this.companyData = this.companyDataSec
			}
			if (!this.isActiveStatus && !this.isInactiveStatus){
				this.companyData = this.companyDataSec
			}
		},
		onDeleteUserAccount(index, user) {
			user.isDeletingUserAccount = true;
			this.isDeletingUserAccount = true;
			this.deleteUser({ userId: user.id }).then((res) => {
				if (res) {
					const userIndex = this.companyData[index].users.indexOf(user);
					if (userIndex > -1) {
						this.companyData[index].users.splice(userIndex, 1);
					}
				}
				user.isDeletingUserAccount = false;
				this.isDeletingUserAccount = false;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.title {
	font-size: 32px !important;
	line-height: 38px !important;
	letter-spacing: -0.114286px !important;
}

.company-name {
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 24px !important;
	letter-spacing: -0.114286px !important;
}

.company-user-count,
.company-user-divide,
.company-user-limit {
	font-size: 12px !important;
	line-height: 18px !important;
}

.dialog-title {
	font-weight: 500 !important;
	font-size: 32px !important;
	line-height: 38px !important;
}

.placeholder-text {
	font-weight: 500 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	letter-spacing: 1.12px !important;
}

.create-company-create-button,
.edit-company-save-button,
.edit-company-delete-button,
.create-user-account-create-button {
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 22px !important;
}

.create-company-cancel-button,
.edit-company-cancel-button,
.create-user-account-cancel-button {
	font-size: 13px !important;
	line-height: 22px !important;
}

.hovered-row {
	background-color: #e0e7ff !important;
}
</style>
