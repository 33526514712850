export const getGeologyTypeColors = (geologyTypeId) => {
    switch (geologyTypeId) {
        case 1:
            return {
                textColor: 'mangoTango',
                backgroundColor: 'sazerac',
            };
        case 2:
            return {
                textColor: 'darkLiver',
                backgroundColor: 'alto',
            };
        case 3:
            return {
                textColor: 'greeBlue',
                backgroundColor: 'azureishWhite',
            };
        case 4:
            return {
                textColor: 'crayolaGreen',
                backgroundColor: 'tara',
            };
        default:
            return {
                textColor: 'black',
                backgroundColor: 'white',
            };
    }
};
