<template>
    <v-col class="align-center">
        <v-row justify="center">
            <img src="@/assets/dashboard/empty_dashboard.svg" />
        </v-row>
        <v-row justify="center" class="pt-8">
            <div class="title regalBlue--text">No data available</div>
        </v-row>
        <v-row justify="center">
            <v-col cols="6">
                <div class="sub-title martinique--text text-center">
                    Please select and type general settings. Current selection is empty or indicates unavailable data.
                </div>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-5">
            <v-btn color="mangoTango" dark>
                <span class="forgot-password-text pr-2">Start</span>
                <img src="@/assets/icons/arrow_right.svg" />
            </v-btn>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: 'EmptyDashboard',
    data() {
        return {};
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 24px !important;
    line-height: 28px;
}

.sub-title {
    font-size: 15px;
    line-height: 22px;
}
</style>
