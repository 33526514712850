<template>
	<v-dialog
		v-model="openDialog"
		width="584px"
		@click:outside="clickOutside"
	>
		<template #activator="{ on, attrs }">
			<slot
				name="activator"
				v-bind="{ on, attrs }"
			/>
		</template>

		<v-card>
			<v-toolbar
				color="regalBlue"
				dark
				class="d-flex justify-center font-weight-bold"
			>
				<slot name="title" />
			</v-toolbar>

			<v-card-text class="d-flex justify-center pa-16 pb-16 text-center">
				<slot name="text" />
			</v-card-text>

			<v-divider></v-divider>
			<v-card-actions class="d-flex justify-end">
				<v-btn
					outlined
					class="ma-1"
					color="mangoTango"
					@click="onRefuse"
				>
					No
				</v-btn>

				<v-btn
					color="regalBlue"
					class="ma-1"
					dark
					@click="onConfirm"
				>
					Yes
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'ConfirmationDialog',
	props: {
		openConfirmationDialog: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			openDialog: false,
		}
	},
	methods: {
		onConfirm() {
			this.$emit('onConfirm');
			this.openDialog = false;
		},
		onRefuse() {
			this.$emit('onRefuse');
			this.openDialog = false;
		},
		clickOutside() {
			this.$emit('clickOutside');
		}
	},
	watch: {
		openConfirmationDialog: {
			immediate: true,
			handler(newValue) {
				this.openDialog = newValue;
			}
		}
	}
}
</script>

