export const getPlantTypeColors = (plantTypeId) => {
    switch (plantTypeId) {
        case 1:
            return {
                textColor: 'nickel',
                backgroundColor: 'grayNurse',
            };
        case 2:
            return {
                textColor: 'veniceBlue',
                backgroundColor: 'azureishWhite',
            };
        case 3:
            return {
                textColor: 'veniceBlue',
                backgroundColor: 'azureishWhite',
            };
        case 4:
            return {
                textColor: 'crayolaGreen',
                backgroundColor: 'tara',
            };
        case 5:
            return {
                textColor: 'black',
                backgroundColor: 'alto',
            };
        case 6:
            return {
                textColor: 'nickel',
                backgroundColor: 'grayNurse',
            };
        case 7:
            return {
                textColor: 'nickel',
                backgroundColor: 'grayNurse',
            };
        case 8:
            return {
                textColor: 'black',
                backgroundColor: 'alto',
            };
        default:
            return {
                textColor: 'black',
                backgroundColor: 'white',
            };
    }
};
