<template>
    <v-dialog v-model="isDialogShow" width="100%" persistent scrollable>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text>
                <v-icon class="pr-2" color="regalBlue">mdi-microsoft-excel</v-icon>
                <span class="regalBlue--text">IMPORT DATA</span>
            </v-btn>
        </template>
        <v-card class="pa-2">
            <v-card-title class="px-4">
                <span class="regalBlue--text">
                    Import Data of {{ columnPresets[preset].name }}
                    <a :href="columnPresets[preset].templateLink" target="_blank">(template)</a>
                </span>
                <v-spacer></v-spacer>
                <v-btn @click="isFileUploading ? null : (isDialogShow = false)" small icon fab>
                    <img src="@/assets/icons/close.svg" />
                </v-btn>
            </v-card-title>
            <v-card-text class="px-4">
                <v-row justify="center" align="center">
                    <v-col v-if="isFileUploaded === false" cols="12">
                        <template v-if="isFileUploading">
                            <v-progress-linear color="regalBlue" indeterminate></v-progress-linear>
                            <div class="pt-5 text-center">Loading... Please wait</div>
                        </template>
                        <template v-else>
                            <div class="d-flex justify-center">
                                <vue-file-upload
                                    ref="fileUpload"
                                    v-model="uploadedFile"
                                    :chunk-enabled="true"
                                    :multiple="false"
                                    :size="maxFileSize"
                                    :drop="isFileUploading === false"
                                    :disabled="isFileUploading"
                                    class="upload-container"
                                    @input-filter="inputFileFilter"
                                >
                                    <v-layout @click="$refs.fileUpload.active = true" column align-center justify-center fill-height>
                                        <div class="title font-weight-bold regalBlue--text">Import Data</div>
                                        <div class="font-weight-regular regalBlue--text">
                                            Drag your document here or <span class="mangoTango--text">click below to upload</span>
                                        </div>
                                    </v-layout>
                                </vue-file-upload>
                            </div>
                            <div class="d-flex justify-center pt-4">
                                <input @change="handleFileUpload" style="width: 188px" type="file" />
                            </div>
                        </template>
                    </v-col>
                    <v-col v-else cols="12">
                        <div class="d-flex justify-center">
                            <vue-excel-editor v-model="dataJSON" @update="onUpdateDataJSON" :page="20" :row-style="onRowStyle" disable-panel-setting disable-panel-filter>
                                <vue-excel-column
                                    v-for="(columnPreset, index) in columnPresets[preset].data"
                                    :key="index"
                                    :field="columnPreset.field"
                                    :label="columnPreset.label"
                                    width="240px"
                                >
                                </vue-excel-column>
                            </vue-excel-editor>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="uploadedFile.length && isFileUploading === false" @click="resetSelectedFile()" color="mangoTango">
                    <span class="font-weight-normal white--text">Reset selected file</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="isDialogShow = false" :disabled="isFileUploading" text>
                    <span :class="{ 'mangoTango--text': isFileUploading === false }" class="font-weight-normal">Cancel</span>
                </v-btn>
                <span class="px-2"></span>
                <v-btn @click="onCheckImportClick()" :min-width="120" color="regalBlue">
                    <span class="font-weight-normal white--text">Import</span>
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="isConfirmDialogShow" width="400" persistent>
            <v-card>
                <v-card-title class="headline regalBlue white--text"> Confirm action </v-card-title>
                <v-card-text class="subtitle-1 text-center pt-5">
                    Confirm import valid <span class="font-weight-bold success--text">{{ validatedDataJSON.length }}</span> data from
                    <span class="font-weight-bold error--text"> {{ dataJSON.length }}</span
                    >?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="isConfirmDialogShow = false" :disabled="isFileUploading || isDataImporting" color="mangoTango" text>
                        <span>Cancel</span>
                    </v-btn>
                    <span class="px-2"></span>
                    <v-btn @click="onImportClick()" :disabled="uploadedFile.length === 0 || isFileUploading" :loading="isDataImporting" :dark="isDataImporting" color="regalBlue">
                        <span class="white--text">IMPORT</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import vueFileUpload from 'vue-upload-component';
import readXlsxFile from 'read-excel-file';

export default {
    name: 'ImportExcel',
    components: { vueFileUpload },
    props: {
        preset: {
            type: Number,
            required: true,
        },
        marketSegmentId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            isDialogShow: false,
            isConfirmDialogShow: false,

            columnPresets: [
                {
                    name: 'Aggregates Producers',
                    templateLink: 'https://drive.google.com/file/d/1bVj8OXYagZG9suKu-dTS_UHYvg0ESybW/view',
                    data: [
                        { field: 'companyName', label: 'Company Name', rule: '^.*$', specificCondinition: null },
                        {
                            field: 'plantLocationAddress',
                            label: 'Plant Location Address',
                            rule: '^.*$',
                            specificCondinition: 'aggregatesProducerAddresses',
                        },
                        {
                            field: 'latitude',
                            label: 'Latitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        {
                            field: 'longitude',
                            label: 'Longitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        { field: 'geologyType', label: 'Geology Type', rule: '^.*$', specificCondinition: null },
                        { field: 'annualProductionVolume', label: 'Annual Production Volume (t)', rule: '^[0-9]+$', specificCondinition: null },
                        { field: 'reserves', label: 'Reserves (t)', rule: '^[0-9]+$', specificCondinition: null },
                        { field: 'comment', label: 'Comment', rule: null, specificCondinition: null },
                    ],
                },
                {
                    name: 'Ready Mix Concrete',
                    templateLink: 'https://drive.google.com/file/d/1LwuI_aiX4ALrPNMPF0rL16ZSV35wTR1i/view',
                    data: [
                        { field: 'companyName', label: 'Company Name', rule: '^.*$', specificCondinition: null },
                        { field: 'plantLocationAddress', label: 'Plant Location Address', rule: '^.*$', specificCondinition: 'customerAddresses' },
                        {
                            field: 'latitude',
                            label: 'Latitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        {
                            field: 'longitude',
                            label: 'Longitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        { field: 'plantName', label: 'Plant Name', rule: '^.*$', specificCondinition: 'customerPlantNames' },
                        {
                            field: 'annualProductionVolume',
                            label: 'Annual Production Volume of Ready Mix Concrete (m3)',
                            rule: '^[0-9]+$',
                            specificCondinition: null,
                        },
                        { field: 'comment', label: 'Comment', rule: null, specificCondinition: null },
                    ],
                },
                {
                    name: 'Asphalt',
                    templateLink: 'https://drive.google.com/file/d/1MFRbQ8bARWrxzLZsXUU-Em_1l4pRN9p4/view',
                    data: [
                        { field: 'companyName', label: 'Company Name', rule: '^.*$', specificCondinition: null },
                        { field: 'plantLocationAddress', label: 'Plant Location Address', rule: '^.*$', specificCondinition: 'customerAddresses' },
                        {
                            field: 'latitude',
                            label: 'Latitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        {
                            field: 'longitude',
                            label: 'Longitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        { field: 'plantName', label: 'Plant Name', rule: '^.*$', specificCondinition: 'customerPlantNames' },
                        {
                            field: 'annualProductionVolume',
                            label: 'Annual Production Volume of Asphalt (m3)',
                            rule: '^[0-9]+$',
                            specificCondinition: null,
                        },
                        { field: 'comment', label: 'Comment', rule: null, specificCondinition: null },
                    ],
                },
                {
                    name: 'Concrete Products',
                    templateLink: 'https://drive.google.com/file/d/1TMAIAOezRTPfvtQKkYIC56vApozjfY-8/view',
                    data: [
                        { field: 'companyName', label: 'Company Name', rule: '^.*$', specificCondinition: null },
                        { field: 'plantLocationAddress', label: 'Plant Location Address', rule: '^.*$', specificCondinition: 'customerAddresses' },
                        {
                            field: 'latitude',
                            label: 'Latitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        {
                            field: 'longitude',
                            label: 'Longitude',
                            rule: null,
                            specificCondinition: null,
                        },
                        { field: 'plantName', label: 'Plant Name', rule: '^.*$', specificCondinition: 'customerPlantNames' },
                        {
                            field: 'annualProductionVolume',
                            label: 'Annual Production Volume of Concrete Products (m3)',
                            rule: '^[0-9]+$',
                            specificCondinition: null,
                        },
                        { field: 'comment', label: 'Comment', rule: null, specificCondinition: null },
                    ],
                },
            ],

            maxFileSize: 1024 * 1024 * 20,
            uploadedFile: [],
            isFileUploading: false,
            isFileUploaded: false,

            dataJSON: [],
            validatedDataJSON: [],

            onUpdateDataJSONTimer: null,
        };
    },
    async mounted() {
        if (this.marketSegmentId) {
            const getCustomerAddresses = this.getCustomerAddresses({
                marketId: Number(localStorage.getItem('marketId')),
                marketSegmentId: this.marketSegmentId,
            });
            const getCustomerPlantNames = this.getCustomerPlantNames({
                marketId: Number(localStorage.getItem('marketId')),
                marketSegmentId: this.marketSegmentId,
            });

            await Promise.all([getCustomerAddresses, getCustomerPlantNames]);
        } else {
            await this.getAggregatesProducerAddresses({ marketId: Number(localStorage.getItem('marketId')) });
        }
    },
    watch: {
        async marketSegmentId(value) {
            if (value) {
                const getCustomerAddresses = this.getCustomerAddresses({
                    marketId: Number(localStorage.getItem('marketId')),
                    marketSegmentId: this.marketSegmentId,
                });
                const getCustomerPlantNames = this.getCustomerPlantNames({
                    marketId: Number(localStorage.getItem('marketId')),
                    marketSegmentId: this.marketSegmentId,
                });

                await Promise.all([getCustomerAddresses, getCustomerPlantNames]);
            }
        },
        isDialogShow(value) {
            if (value) {
                this.uploadedFile = [];
                this.isFileUploading = false;
                this.isFileUploaded = false;
                this.dataJSON = [];
                this.validatedDataJSON = [];
            }
        },
        uploadedFile(value) {
            if (!value) {
                return;
            }

            if (value.length === 0) {
                return;
            }

            this.onReadXlsxFile(value[0].file);
        },
    },
    computed: {
        ...mapGetters('importExcelDialog', {
            isDataImporting: 'isDataImporting',
            aggregatesProducerAddresses: 'aggregatesProducerAddresses',
            customerAddresses: 'customerAddresses',
            customerPlantNames: 'customerPlantNames',
        }),
    },
    methods: {
        ...mapActions('importExcelDialog', {
            getAggregatesProducerAddresses: 'getAggregatesProducerAddresses',
            getCustomerAddresses: 'getCustomerAddresses',
            getCustomerPlantNames: 'getCustomerPlantNames',
        }),
        ...mapActions('geocoding', {
            searchLocationWithResult: 'searchLocationWithResult',
        }),
        onReadXlsxFile(file) {
            this.isFileUploading = true;

            readXlsxFile(file)
                .then((rows) => {
                    const slicedRows = rows.slice(1);

                    if (!slicedRows) {
                        return;
                    }

                    if (slicedRows.length === 0) {
                        return;
                    }

                    const preset = this.columnPresets[this.preset];

                    slicedRows.forEach((slicedRow) => {
                        let json = {};

                        preset.data.forEach((data, index) => {
                            Object.assign(json, {
                                [data.field]: slicedRow[index],
                            });
                        });

                        this.dataJSON.push(json);
                    });

                    this.isFileUploaded = true;
                })
                .catch((error) => {
                    Vue.toasted.error(error);
                })
                .finally(() => {
                    this.isFileUploading = false;
                });
        },
        handleFileUpload(event) {
            // Filter file format
            if (/\.(xls|xlsx)$/i.test(event.target.files[0].name) === false) {
                Vue.toasted.error(`Can not upload '${event.target.files[0].type}' file format`);
                return;
            }
            // Filter file size
            if (event.target.files[0].size >= this.maxFileSize) {
                Vue.toasted.error(`Max file size ${Math.floor(this.maxFileSize / 1024 / 1000)} megabytes`);
                return;
            }

            this.uploadedFile.push({ file: event.target.files[0] });
        },
        resetSelectedFile() {
            this.uploadedFile = [];
            this.isFileUploading = false;
            this.isFileUploaded = false;
            this.dataJSON = [];
            this.validatedDataJSON = [];
        },
        inputFileFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Filter file format
                if (/\.(xls|xlsx)$/i.test(newFile.name) === false) {
                    Vue.toasted.error(`Can not upload '${newFile.type}' file format`);
                    return prevent();
                }
                // Filter file size
                if (newFile.size >= this.maxFileSize) {
                    Vue.toasted.error(`Max file size ${Math.floor(this.maxFileSize / 1024 / 1000)} megabytes`);
                    return prevent();
                }
            }
            // Create a blob field
            newFile.blob = '';
            const URL = window.URL;
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file);
            }
        },
        getLocationPlace(data) {
            if (data === undefined) {
                return null;
            }

            let place = null;

            const { context } = data;

            if (context === undefined) {
                return null;
            }

            for (var i = 0; i < context.length; i++) {
                if (context[i].id.split('.')[0] === 'place') {
                    return context[i].text;
                } else if (context[i].id.split('.')[0] === 'locality') {
                    return context[i].text;
                } else {
                    place = data.place_name;
                }
            }
            return place;
        },
        onRowStyle(data) {
            let preset = this.columnPresets[this.preset];

            preset.data.forEach(async (x, i) => {
                const element = document.getElementById(`id-${data.$id}-${x.field}`);

                if (element) {
                    if (x.field === 'latitude') {
                        let latitude = data[x.field];
                        let longitude = data['longitude'];

                        if (longitude && latitude && parseFloat(longitude) && parseFloat(latitude)) {
                            const result = await this.searchLocationWithResult(`${parseFloat(longitude)},${parseFloat(latitude)}`);

                            if (result) {
                                const location = this.getLocationPlace(result[0]);

                                if (location) {
                                    data['plantLocationAddress'] = location;

                                    const targetSeachLocationElement = document.getElementById(`id-${data.$id}-plantLocationAddress`);

                                    if (targetSeachLocationElement) {
                                        targetSeachLocationElement.style.backgroundColor = '#ffffff';
                                    }
                                }
                            }
                        }
                    }

                    if (x.rule) {
                        if (
                            (!data[x.field] && data[x.field] !== 0) ||
                            /^\s*$/.test(data[x.field].toString()) ||
                            new RegExp(x.rule).test(data[x.field].toString()) === false ||
                            x.specificCondinition
                                ? !data[x.field]
                                    ? true
                                    : this[x.specificCondinition]
                                    ? this[x.specificCondinition].includes(data[x.field].toString())
                                    : false
                                : false
                        ) {
                            element.style.backgroundColor = '#ffede1';
                        } else {
                            element.style.backgroundColor = '#ffffff';
                        }
                    } else {
                        element.style.backgroundColor = '#ffffff';
                    }
                }
            });
        },
        async onUpdateDataJSON(data) {
            clearTimeout(this.onUpdateDataJSONTimer);

            this.onUpdateDataJSONTimer = setTimeout(() => {
                const preset = this.columnPresets[this.preset];

                data.forEach((value) => {
                    preset.data.forEach(async (x) => {
                        if (value.name === x.field) {
                            const element = document.getElementById(`id-${value.$id}-${x.field}`);

                            if (element) {
                                if (x.rule) {
                                    if (
                                        (!value.newVal && value.newVal !== 0) ||
                                        /^\s*$/.test(value.newVal) ||
                                        new RegExp(x.rule).test(value.newVal) === false ||
                                        x.specificCondinition
                                            ? !value.newVal
                                                ? true
                                                : this[x.specificCondinition]
                                                ? this[x.specificCondinition].includes(value.newVal.toString())
                                                : false
                                            : false
                                    ) {
                                        element.style.backgroundColor = '#ffede1';
                                    } else {
                                        element.style.backgroundColor = '#ffffff';
                                    }
                                } else {
                                    element.style.backgroundColor = '#ffffff';
                                }
                            }

                            return;
                        }
                    });
                });
            }, 100);
        },
        onCheckImportClick() {
            this.validatedDataJSON = [];

            const preset = this.columnPresets[this.preset];

            this.dataJSON.forEach((value) => {
                let valid = true;

                preset.data.forEach((x) => {
                    if (x.rule) {
                        if (
                            (!value[x.field] && value[x.field] !== 0) || /^\s*$/.test(value[x.field]) || new RegExp(x.rule).test(value[x.field]) === false || x.specificCondinition
                                ? !value[x.field]
                                    ? true
                                    : this[x.specificCondinition]
                                    ? this[x.specificCondinition].includes(value[x.field].toString())
                                    : false
                                : false
                        ) {
                            valid = false;
                        }
                    }
                });

                if (valid) {
                    this.validatedDataJSON.push(value);
                }
            });

            if (this.validatedDataJSON.length) {
                this.isConfirmDialogShow = true;
            } else {
                Vue.toasted.error('Any valid data for import!');
            }
        },
        onImportClick() {
            this.$emit('onImport', this.validatedDataJSON);
        },
    },
};
</script>

<style lang="scss" scoped>
.upload-container {
    width: 50%;
    height: 200px;
    background-color: #f7f8fc;
    border: 4px dashed #0e3f66;
    border-radius: 10px;
}
</style>
