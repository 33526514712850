export const getAdministrationCompanyTableHeaders = () => {
    return [
        {
            text: 'USER NAME',
            align: 'center',
            sortable: false,
            value: '',
        },
        {
            text: 'ID',
            align: 'center',
            sortable: true,
            value: 'id',
        },
        {
            text: 'ROLE',
            align: 'center',
            sortable: true,
            value: 'role',
        },
		{
			text: 'ACTIVE',
			align: 'center',
			sortable: false,
			value: '',
		},
		{
			text: 'START DATE',
			align: 'center',
			sortable: false,
			value: '',
		},
		{
			text: 'END DATE',
			align: 'center',
			sortable: false,
			value: '',
		},
        {
            text: 'EMAIL',
            align: 'center',
            sortable: true,
            value: 'email',
        },
    ];
};
