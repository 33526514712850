var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MglMap',{attrs:{"accessToken":_vm.accessToken,"mapStyle":_vm.mapStyle + _vm.codeStyle[_vm.changeStyle],"center":_vm.markerCenter,"zoom":_vm.zoom},on:{"load":_vm.onMapLoaded,"click":_vm.addMarker}},_vm._l((_vm.markersCoordinates),function(marker,i){return _c('MglMarker',{key:i,staticClass:"ma-marker",attrs:{"coordinates":marker.coordinates,"color":"blue"},on:{"click":(e) => _vm.clickMarker(e, marker)}},[(marker.iconStyle)?_c('div',{class:[marker.sameLoc ? 'sameLoc' : '', 'cust-icon', marker.iconStyle],style:([
					 marker.plantType && marker.aggregatesProducerGeologies.length && marker.plantType.id === 4 ?
					  {'border-radius': '100% !important'} : {},
					 marker.aggregatesProducerGeologies ?
					  marker.aggregatesProducerGeologies[0].geologySubType ?
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 && marker.plantType.id === 4 ?
					  {'background-color': '#ff8939 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 && marker.plantType.id === 5 ?
					  {'background-color': '#ff8939 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 && marker.plantType.id === 8 ?
					  {'background-color': '#ff8939 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 1 ?
					  {'border-color': 'transparent transparent #ff8939 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 1 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 7 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 8 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 6 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 && marker.plantType.id === 5 ?
					  {'background-color': '#9e9e9e !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 2 ?
					  {'background-color': '#9e9e9e !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 1 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 5 ?
					  {'background-color': '#0d3f66 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 7 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					   marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 8 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #9e9e9e transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 && marker.plantType.id === 6 ?
					  {'background-color': 'transparent !important', 'border-color': 'transparent transparent #0d3f66 transparent !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 3 ?
					  {'background-color': '#0d3f66 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 4 && marker.plantType.id === 5 ?
					  {'background-color': '#22b363 !important'} :
					   marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 4 && marker.plantType.id === 8 ?
					  {'background-color': '#22b363 !important'} :
					  marker.aggregatesProducerGeologies[0].geologySubType.geologyTypeId === 4 ?
					  {'border-color': 'transparent transparent #22b363 transparent !important'} :
					  {} :
					  {} :
					  {}
					  ]),attrs:{"slot":"marker"},on:{"mouseenter":function($event){_vm.active = marker.id},"mouseleave":function($event){_vm.active = null}},slot:"marker"},[(marker.plantType && marker.aggregatesProducerGeologies.length && marker.plantType.id === 4)?_c('img',{attrs:{"src":require("@/assets/map/recycle.svg")}}):_vm._e(),(marker.iconImg)?_c('img',{staticClass:"icon-img",attrs:{"src":marker.iconImg}}):_vm._e(),(marker.id === _vm.active && _vm.hover && marker.type !== 'customers')?_c('div',{staticClass:"hoverBlock"},[_vm._v(" "+_vm._s(marker.name)+" ")]):_vm._e()]):_vm._e()])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }