<template>
    <base-layout>
        <div class="general-settings" :key="componentKey">
            <h1 class="title">Market Assessment Settings</h1>
            <general-steps />
            <market-details />
            <location v-if="$route.params.newSettings || currentMarket" />
            <market-settings />
<!--            <btn @click.native="saveSettings()" :btnStyle="'save'" style="width: 200px;">Save Changes</btn>-->
        </div>
    </base-layout>
</template>

<script>
import GeneralSteps from '../../components/generalSettings/GeneralSteps.vue';
import MarketDetails from '../../components/generalSettings/MarketDetails.vue';
import Location from '../../components/generalSettings/Location.vue';
import MarketSettings from '../../components/generalSettings/MarketSettings.vue';
import Btn from '../../components/lib/btn.vue';

export default {
    data() {
        return {
            componentKey: 0,
        };
    },
    mounted() {
        if (this.$route.params.newSettings) {
            localStorage.setItem('newSettings', true);
            localStorage.removeItem('marketId');
            this.removeLocalStorage();
            this.removeGeneralSettings();
        } else {
            localStorage.setItem('newSettings', false);
            // localStorage.removeItem('marketId');
            this.removeGeneralSettings();
            this.getMarket(JSON.parse(localStorage.getItem('marketId')));
            // localStorage.setItem('marketId', this.$store.getters['marketAssessment/market'].id);
        }
    },
    watch: {
        marketId(market) {
            if (!this.$route.params.newSettings && JSON.parse(localStorage.getItem('marketId')) !== this.$store.getters['generalSettings/getMarketId']) {
                this.getMarket(market.id);
                localStorage.setItem('marketId', market.id);
            }
        },
    },
    methods: {
        saveSettings() {
            const marketId = this.$store.getters['generalSettings/getMarketId'];
            this.$store.dispatch('generalSettings/saveSettings', { marketId }).then((res) => {
                // this.removeLocalStorage();
                // this.removeGeneralSettings();
                localStorage.setItem('marketId', marketId);
                this.$router.push({ name: 'aggregateProducers' });
            });
        },
        removeLocalStorage() {
            localStorage.removeItem('marketDetails');
            localStorage.removeItem('boundary');
            localStorage.removeItem('groupMarket');
            localStorage.removeItem('standartSplit');
            localStorage.removeItem('transportMode');
        },
        removeGeneralSettings() {
            this.$store.commit('generalSettings/removeSettings');
        },
        getMarket(marketId) {
            this.$store.dispatch('generalSettings/getMarket', { marketId });
            this.forceRerender();
        },
        forceRerender() {
            this.componentKey += 1;
        },
    },
    computed: {
        marketId() {
            return this.$store.getters['marketAssessment/market'];
        },
        currentMarket() {
            return this.$store.getters['generalSettings/getMarket'];
        },
    },
    components: {
        GeneralSteps,
        MarketDetails,
        Location,
        MarketSettings,
        Btn,
    },
};
</script>

<style lang="scss" scoped>
.title {
    margin-bottom: 15px;
}
</style>
