<template>
    <div v-if="!isInitedData || isLoadingData" class="d-flex justify-center align-center">
        <v-progress-circular color="regalBlue" :width="10" :size="150" indeterminate />
    </div>

    <div v-else-if="getMapAggregates.length" class="d-flex flex-column gap-5">
        <filters-list />
        <div class="d-flex flex-row">
            <filters
				@startFiltering="startFiltering"
				:is-show-customers="generationFilterList('producers')"
				:is-show-producers="generationFilterList('customers')"
				:selected-marker="selectedMarker"
			/>

            <div class="flex-grow-1 map-wrapper">
                <Map
                    :center="getFilteredMapAggregates && getFilteredMapAggregates.length ? getFilteredMapAggregates[0].coordinates : getMapAggregates[0].coordinates"
                    :markers="getFilteredMapAggregates ? getModifiedMapMarkers(getFilteredMapAggregates) : getModifiedMapMarkers(getMapAggregates)"
                    :popup="true"
                    :addingNewMarkers="false"
                    :drawRoutes="selectedMarker !== null && selectedMarker !== undefined"
                    @clickMarker="markerSelected"
                />
            </div>

            <div v-if="selectedMarker" class="aggregate-info-container">
                <producer-info v-if="selectedMarker.type === 'producers'" :popup="selectedMarker.popup" @close="unselectMarker" />
                <customers-info v-else :popup="selectedMarker.popup" @close="unselectMarker" />
            </div>
        </div>
    </div>

    <div v-else class="d-flex justify-center align-center">
        <span class="title regalBlue--text">NO MARKERS</span>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import Map from '@/components/map';
import Filters from '@/components/map/filters';
import FiltersList from '@/components/map/filters/filtersList.vue';
import producerInfo from '@/components/map/aggregateInfo/producerInfo.vue';
import customersInfo from '@/components/map/aggregateInfo/customersInfo.vue';

export default {
    name: 'ProducersCustomersMap',
    components: {
        Map,
        Filters,
        FiltersList,
        producerInfo,
        customersInfo,
    },
    data() {
        return {
            isInitedData: false,
            isLoadingData: false,
            selectedMarker: null,
        };
    },
    mounted() {
        if (this.market) {
            this.isInitedData = true;
            this.init();
        }
    },
    watch: {
        market() {
            this.isInitedData = true;
            this.init();
        },
        getMapAggregates(aggregates) {
            this.selectedMarker = null;
            this.$store.commit('filters/clearFilter');
            this.$store.commit('producersAndCustomersMap/setFilteredAggregates', aggregates);
        },
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
        ...mapGetters('producersAndCustomersMap', {
            getMapAggregates: 'getMapAggregates',
            getFilteredMapAggregates: 'getFilteredMapAggregates',
        }),
    },
    methods: {
        ...mapMutations('producersAndCustomersMap', {
            resetAggregates: 'resetAggregates',
        }),
        ...mapActions('producersAndCustomersMap', {
            getMapAggregatesProducers: 'getMapAggregatesProducers',
            getMapCustomers: 'getMapCustomers',
        }),
        async init() {
            this.isLoadingData = true;
            this.resetAggregates();
            const getMapAggregatesProducers = this.getMapAggregatesProducers({ marketId: this.market.id });
			let marketId = this.market.id
			if (this.$route.name === 'marketAssessment'){
				marketId = null
			}
			const getMapCustomers = this.getMapCustomers({ marketId: marketId });
            await Promise.all([getMapAggregatesProducers, getMapCustomers]);
            setTimeout(() => {
                this.isLoadingData = false;
            }, 500);
            this.$store.commit('producersAndCustomersMap/aggregatesLength');
        },
		getModifiedMapMarkers(markers) {
			const copiedFilteredMap = JSON.parse(JSON.stringify(markers));
			const locObjects = {};

			for (let i = 0; i < copiedFilteredMap.length; i++) {
				let currentLoc = copiedFilteredMap[i];
				let locHash = `${currentLoc.longitude.toString()}-${currentLoc.latitude.toString()}`;
				if (locHash.length === 3) {
					continue;
				}

				if (!locObjects.hasOwnProperty(locHash)) {
					locObjects[locHash] = [];
				}

				locObjects[locHash].push(currentLoc);
				if (locObjects[locHash].length > 1) {
					locObjects[locHash][0]['sameLoc'] = true;
				}
			}

			return copiedFilteredMap;
		},
        startFiltering() {
            this.$store.commit('producersAndCustomersMap/startFiltering', this.$store.getters['filters/getSelectedFilter']);
		},
        markerSelected(marker) {
			this.$store.commit('producersAndCustomersMap/setSelectedMarket', marker)
			this.startFiltering()
            this.selectedMarker = marker;
        },
        unselectMarker() {
			this.$store.commit('producersAndCustomersMap/setSelectedMarket', null)
            this.startFiltering();
            this.selectedMarker = null;
        },
		generationFilterList(type) {
			if (!this.selectedMarker) {
				return true;
			} else {
				return this.selectedMarker.type === type || false;
			}
		}
    },
};
</script>
<style lang="scss">
.mapboxgl-canvas {
    height: 100% !important;
}
</style>

<style lang="scss" scoped>
.aggregate-info-container {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.09);
}

.map-wrapper {
	max-height: 600px;
}
</style>
