<template>
    <div class="group-market">
        <h3>Group in the market</h3>
        <tooltip>
            <p>
                Enter names of vertically integrated companies within the market.
            </p>
        </tooltip>
        <div class="names-market">
			<div v-for="(field, i) in nameFields" class="d-flex">
				<input-text
					:key="i"
					:label="`Name #${i + 1}`"
					:disabled="i === 0"
					:colorPicker="true"
					:deleteBtn="field.id && i !== 0"
					v-model.lazy="field.name"
					@focused="focused($event, field)"
					@deleteField="deleteField(field)"
				/>
				<div class="ml-4 d-flex align-end" style="position: relative">
					<span class="pl-2" v-if="!i">My Group</span>
					<v-checkbox
						v-if="field.isMyGroup && field.name"
						v-model="field.isMyGroup"
						@click="isShowUnCheckGroupDialog = true, unCheckGroup = field" color="mangoTango"
						class="pl-2 mb-2"
						hide-details
						readonly
					/>
					<v-checkbox
						v-else-if="field.name === '' || field.name === 'n/a'"
						v-model="field.isMyGroup"
						@click="isShowEmptyGroupDialog = true" color="mangoTango"
						class="pl-2 mb-2"
						hide-details
						readonly
					/>
					<v-checkbox
						v-else
						v-model="field.isMyGroup"
						@change="focused($event, field)"
						class="pl-2 mb-2" color="mangoTango"
						hide-details
					/>
				</div>

			</div>

            <btn style="margin-top: 21px; width: max-content" :btnStyle="'add'" @click.native="addField()">
                <img src="../../../assets/lib/plus.svg" />
                Add
            </btn>
        </div>
        <h3>Aggregates sites in the market</h3>
        <tooltip>
            <p>
                Select most relevant site types.
            </p>
        </tooltip>
        <v-alert v-if="alertError" dense type="warning" dismissible>
            {{ alertError }}
        </v-alert>
        <div class="multiselect-group">
            <MrMultiselect
                :label="'Plant type'"
                :options="optionsPlantType"
                :optionsType="'optionsPlantType'"
                @selectedOptionItem="selectedOptionItem"
                @saveOptions="saveOptions"
                @delete="deleteItem"
            />
            <MrMultiselect
                :label="'Geology'"
                :options="optionsGeology"
                :optionsType="'optionsGeology'"
                @selectedOptionItem="selectedOptionItem"
                @selectedSubOptionItem="selectedSubOptionItem"
                @saveNewGeology="saveNewGeology"
                @deleteSubOption="deleteSubOption"
                @delete="deleteItem"
            />
        </div>
		<v-dialog v-model="isShowDeleteGroupDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Delete group
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Are you sure you want to remove your
					<span class="font-weight-bold">"{{ deletingGroup ? deletingGroup.name : '...' }}"</span>
					group?
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="(isShowDeleteGroupDialog = false), (deletingGroup = null)" color="mangoTango" text>
						<span>No</span>
					</v-btn>
					<span class="px-2"></span>
					<v-btn @click="onDeleteGroup()" color="regalBlue" dark>
						<span class="white--text">Yes</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="isShowDeleteGeologyDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Delete geology
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Are you sure that you want to delete geology
					<span class="font-weight-bold">"{{ deletingGeology && deletingGeology.item ? deletingGeology.item.name : '...' }}"</span>
					?
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="(isShowDeleteGeologyDialog = false), (deletingGeology = null)" color="mangoTango" text>
						<span>No</span>
					</v-btn>
					<span class="px-2"></span>
					<v-btn @click="onDeleteGeology()" color="regalBlue" dark>
						<span class="white--text">Yes</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-dialog v-model="isShowDeleteSelectedSubOptionItemDialog" width="512" persistent>
            <v-card>
                <v-card-title class="headline regalBlue white--text"> 
                    Delete geology
                </v-card-title>
                <v-card-text class="text-center pt-5">
                    Are you sure that you want to delete geology
                    <span class="font-weight-bold">"{{ deletingGeology && deletingGeology.subOption ? deletingGeology.subOption.name : '...' }}"</span>
                    ?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="(isShowDeleteSelectedSubOptionItemDialog = false), (deletingGeology = null)" color="mangoTango" text>
                        <span>No</span>
                    </v-btn>
                    <span class="px-2"></span>
                    <v-btn @click="onDeleteSelectedSubOptionItem()" color="regalBlue" dark>
                        <span class="white--text">Yes</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isShowDeleteSubOptionDialog" width="512" persistent>
            <v-card>
                <v-card-title class="headline regalBlue white--text">
                    Delete geology
                </v-card-title>
                <v-card-text class="text-center pt-5">
                    Are you sure that you want to delete geology
                    <span class="font-weight-bold">"{{ deletingGeology && deletingGeology.subOption ? deletingGeology.subOption.name : '...' }}"</span>
                    ?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="(isShowDeleteSubOptionDialog = false), (deletingGeology = null)" color="mangoTango" text>
                        <span>No</span>
                    </v-btn>
                    <span class="px-2"></span>
                    <v-btn @click="onDeleteSubOption()" color="regalBlue" dark>
                        <span class="white--text">Yes</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isShowDeleteSelectedOptionItemDialog" width="512" persistent>
            <v-card>
                <v-card-title class="headline regalBlue white--text">
                    Delete geology
                </v-card-title>
                <v-card-text class="text-center pt-5">
                    Are you sure that you want to delete geology
                    <span class="font-weight-bold">"{{ deletingGeology ? deletingGeology.name : '...' }}"</span>
                    ?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="(isShowDeleteSelectedOptionItemDialog = false), (deletingGeology = null)" color="mangoTango" text>
                        <span>No</span>
                    </v-btn>
                    <span class="px-2"></span>
                    <v-btn @click="onSelectedOptionItem()" color="regalBlue" dark>
                        <span class="white--text">Yes</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<v-dialog v-model="isShowUnCheckGroupDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Uncheck group
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Are you sure you want to make
					<span class="font-weight-bold">"{{ unCheckGroup ? unCheckGroup.name : '...' }}"</span>
					not your group?
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="onCloseUnCheckDialog()" color="mangoTango" text>
						<span>No</span>
					</v-btn>
					<span class="px-2"></span>
					<v-btn @click="onUnCheckGroup()" color="regalBlue" dark>
						<span class="white--text">Yes</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="isShowEmptyGroupDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Empty group
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Please enter Group Name
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="isShowEmptyGroupDialog = false" color="regalBlue" dark>
						<span class="white--text">Close</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import InputText from '../../lib/inputText.vue';
import Btn from '../../lib/btn.vue';
import MrMultiselect from '../../lib/mrMultiselect.vue';
import Tooltip from '../../lib/tooltip.vue';
import {getOptionsPlantType} from "../../../store/generalSettings/getters";

export default {
    data() {
        return {
            value: null,
            alertError: null,
            isShowDeleteSelectedOptionItemDialog: false,
            isShowDeleteGeologyDialog: false,
            isShowDeleteSelectedSubOptionItemDialog: false,
            isShowDeleteSubOptionDialog: false,
			isShowDeleteGroupDialog: false,
			isShowUnCheckGroupDialog: false,
			isShowEmptyGroupDialog: false,
			deletingGeology: null,
			deletingGroup: null,
			unCheckGroup: null
        };
    },
    async mounted() {
        this.initForm();
        // this.checkSteps();
    },
    methods: {
        initForm() {
            if (!this.$store.getters['generalSettings/getMarket'] && localStorage.getItem('groupMarket')) {
                const nameFields = JSON.parse(localStorage.getItem('groupMarket')).fields;
                this.$store.commit('generalSettings/getLocalNameFields', nameFields);
                this.$store.commit('generalSettings/getLocalPlantTypes', JSON.parse(localStorage.getItem('groupMarket')).optionsPlantType);
                // if (JSON.parse(localStorage.getItem('groupMarket')).optionsGeology.length) {
                //   this.$store.commit('generalSettings/getLocalGeology', JSON.parse(localStorage.getItem('groupMarket')).optionsGeology);
                // }
            }
        },
        addField() {
            // const lastGroup = this.nameFields[this.nameFields.length - 1];
            // if (lastGroup.name !== 'n/a') {
            //   this.focused(null, lastGroup);
            // }
            this.$store.commit('generalSettings/addField');
        },
        deleteField(field) {
			this.deletingGroup = field
			this.isShowDeleteGroupDialog = true
        },
		onDeleteGroup() {
			this.$store.dispatch('generalSettings/removeGroupMarket', this.deletingGroup.id).then(() => this.localSave());
			this.localSave();
			this.isShowDeleteGroupDialog = false;
		},
        selectedOptionItem(option) {
            let isSelected = false;
            let item = this.optionsGeology.find((x) => {
                return x.id === option.id;
            });

            if (item) {
                isSelected = item.selected;
            }

            if (isSelected) {
                this.deletingGeology = option;
                this.isShowDeleteSelectedOptionItemDialog = true;
            } else {
                if (option.geologySubTypes) {
                    this.$store.commit('generalSettings/setOptionGeology', option);
                } else {
                    this.$store.commit('generalSettings/getSaveIdPlantTypes', option);
                    this.checkSteps();
                }
            }
        },
        onSelectedOptionItem() {
            if (this.deletingGeology.geologySubTypes) {
                this.$store.commit('generalSettings/setOptionGeology', this.deletingGeology);
            } else {
                this.$store.commit('generalSettings/getSaveIdPlantTypes', this.deletingGeology);
                this.checkSteps();
            }
            this.isShowDeleteSelectedOptionItemDialog = false;
        },
        selectedSubOptionItem(data) {
            let isSelected = false;
            this.optionsGeology.forEach((element) => {
                let item = element.geologySubTypes.find((x) => {
                    return x.id === data.subOption.id;
                });
                if (item) {
                    isSelected = item.selected;
                }
            });
            if (isSelected) {
                this.deletingGeology = data;
                this.isShowDeleteSelectedSubOptionItemDialog = true;
            } else {
                this.$store.commit('generalSettings/getSubOptionGeology', data);
                this.localSave();
            }
        },
        onDeleteSelectedSubOptionItem() {
            this.$store.commit('generalSettings/getSubOptionGeology', this.deletingGeology);
            this.localSave();
            this.isShowDeleteSelectedSubOptionItemDialog = false;
        },
        deleteItem(option) {
            this.deletingGeology = option;
            this.isShowDeleteGeologyDialog = true;
        },
        onDeleteGeology() {
            if (this.deletingGeology === null) {
                return;
            }

            if (this.deletingGeology.type === 'optionsPlantType') {
                this.$store.commit('generalSettings/getSaveIdPlantTypes', this.deletingGeology.item);
                this.saveOptions(this.deletingGeology.type);
                this.checkSteps();
            } else {
                this.$store.commit('generalSettings/getSubOptionGeology', {
                    option: {
                        id: this.deletingGeology.item.geologyTypeId,
                    },
                    subOption: {
                        id: this.deletingGeology.item.id,
                    },
                });
                this.$store.commit('generalSettings/setUpdateGeology', true);
            }
            this.localSave();
            this.isShowDeleteGeologyDialog = false;
        },
        saveNewGeology(newGeology) {
            this.$store.dispatch('generalSettings/getSaveNewGeology', newGeology);
            this.localSave();
        },
        deleteSubOption(data) {
            this.deletingGeology = data;
            this.isShowDeleteSubOptionDialog = true;
        },
        onDeleteSubOption() {
            this.alertError = null;
            this.$store.dispatch('generalSettings/getRemoveGeologySubType', { subOptionid: this.deletingGeology.subOption.id }).catch((err) => (this.alertError = err.data));
            this.isShowDeleteSubOptionDialog = false;
        },
		onCloseUnCheckDialog() {
			this.isShowUnCheckGroupDialog = false
			this.unCheckGroup = false
		},
		onUnCheckGroup() {
			this.unCheckGroup.isMyGroup = false
			this.editGroupMarketName(this.unCheckGroup);
			this.isShowUnCheckGroupDialog = false
		},
        focused(val, field) {
			if (field.newGroup && typeof val === "string") {
				this.sendGroupMarketName(field);
			} else {
				this.editGroupMarketName(field);
			}
		},
        localSave() {
            const data = {
                type: 'groupMarket',
                form: {
                    fields: this.nameFields,
                    optionsPlantType: this.$store.getters['generalSettings/getSelectedPlantType'],
                    optionsGeology: this.$store.getters['generalSettings/getOptionsGeology'],
                },
            };
            this.$store.commit('generalSettings/localSave', data);
        },
        saveOptions() {
            const option = this.$store.getters['generalSettings/getSelectedPlantType'];
            this.$store.dispatch('generalSettings/savePlantTypes', option);
            this.localSave();
        },
        sendGroupMarketName(field) {
            if (field.name) {
				this.$store.dispatch('generalSettings/addGroupToMarket', {name: field.name, isMyGroup: field.isMyGroup}).then(() => this.localSave());
				delete field.newGroup
			}
		},
        editGroupMarketName(field) {
			setTimeout(() => {
				this.$store.dispatch('generalSettings/updateGroup', { marketGroupId: field.id, name: field.name, isMyGroup: field.isMyGroup }).then(() => this.localSave());
			}, 1500)
        },
        checkSteps() {
            if (this.$store.getters['generalSettings/getSelectedPlantType'].length) {
                this.$emit('checkSteps', { name: 'plantType', status: true });
            } else {
                this.$emit('checkSteps', { name: 'plantType', status: false });
            }
        },
    },
    computed: {
        nameFields() {
            return this.$store.getters['generalSettings/getGroupMarketNames'];
        },
        optionsPlantType() {
            return this.$store.getters['generalSettings/getOptionsPlantType'];
        },
        optionsGeology() {
            return this.$store.getters['generalSettings/getOptionsGeology'];
        },
    },
    components: {
        InputText,
        Btn,
        MrMultiselect,
        Tooltip,
    },
};
</script>
