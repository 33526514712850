<template>
    <div class="general-steps">
        <div class="steps-layout">
            <ul>
                <li v-for="(step, i) in steps" :key="i" :class="step.done ? 'circle-block-done' : step.process ? 'circle-block-process' : 'circle-block'">
                    <span>{{ step.name }}</span>
                    <div class="icon" v-if="!step.done">{{ i + 1 }}</div>
                    <div class="icon" v-else>
                        <img src="../../assets/general_settings/check.svg" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        steps() {
            return this.$store.getters['generalSettings/getSteps'];
        },
    },
};
</script>
