<template>
    <div class="market-details">
        <h2>Market Name & Year</h2>
        <div class="market-details-block">
            <input-text :label="'Market name'" v-model.lazy="form.marketName" @focused="focused" />
            <!-- <mr-select
              :label="'Country'"
              :value="form.country ? form.country.name : ''"
              :options="optionsCountries"
              @selected="countrySelect" /> -->
            <mr-select class="year-select" :label="'Year'" :value="String(form.year)" :options="optionsYear" @selected="yearSelect" />
        </div>
    </div>
</template>

<script>
import LocalStage from '@/utils/LocalStage';
import InputText from '../lib/inputText.vue';
import MrSelect from '../lib/mrSelect.vue';

export default {
    data() {
        return {
            form: {
                marketName: '',
                // country: null,
                year: new Date().getFullYear(),
            },
            // optionsCountries: [],
            optionsYear: this.$store.state.generalSettings.optionsYear,
        };
    },
    mounted() {
        this.initForm();
        this.checkSteps();

        const loc = new LocalStage();
        if (loc.getData('marketId')) {
            this.$store.commit('generalSettings/setMarketId', loc.getData('marketId'));
        }

        const el = document.getElementsByClassName('market-details')[0];
        const elemHeight = el.offsetHeight;
        const elemToTop = el.getBoundingClientRect().top;
        const heightElemWithTop = elemHeight + elemToTop;

        window.addEventListener('scroll', () => {
            const top = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
            if (top > elemToTop && top < heightElemWithTop) {
                this.$store.commit('generalSettings/stepsProcess', { name: 'Market Details', status: true });
            } else {
                this.$store.commit('generalSettings/stepsProcess', { name: 'Market Details', status: false });
            }
        });
    },
    methods: {
        initForm() {
            // this.$store.dispatch('generalSettings/getCountries')
            //   .then(() => {
            //     this.optionsCountries = this.$store.getters['generalSettings/getCountries'];
            //   });
            if (!this.$store.getters['generalSettings/getMarket'] && localStorage.getItem('marketDetails') && !this.$route.params.newSettings) {
                this.form = JSON.parse(localStorage.getItem('marketDetails'));
            } else if (this.$store.getters['generalSettings/getMarket'] && JSON.parse(localStorage.getItem('newSettings'))) {
                this.form.marketName = this.$store.getters['generalSettings/getMarket'].name;
                this.form.year = this.$store.getters['generalSettings/getMarket'].year;
            }
            // else {
            //   this.form = {
            //     marketName: '',
            //     year: new Date().getFullYear(),
            //   }
            // }
            // else {
            //   this.form = JSON.parse(localStorage.getItem('marketDetails'));
            // }
        },
        focused() {
            this.sendSection();
        },
        // countrySelect(country) {
        //   this.form.country = country;
        //   this.localSave();
        // },
        yearSelect(year) {
            this.form.year = String(year);
            this.localSave();
            this.sendSection();
        },
        localSave() {
            const data = {
                type: 'marketDetails',
                form: this.form,
            };
            this.$store.commit('generalSettings/localSave', data);
        },
        sendSection() {
            if (this.form.marketName && this.form.year) {
                const metrics = this.$store.state.generalSettings.metrics;
                let metric = null;
                if (metrics && metrics.length) {
                    metric = metrics[0];
                }
                const form = {
                    marketId: localStorage.getItem('marketId') ? Number(localStorage.getItem('marketId')) : null,
                    marketName: this.form.marketName,
                    // countryId: this.form.country.id,
                    year: Number(this.form.year),
                    metropolitan: '',
                    separatorType: 0,
                    metricId: metric.id,
                };
                this.$store.dispatch('generalSettings/saveMarket', form).then(() => {
                    this.$store.commit('marketAssessment/setMarket', {
                        id: localStorage.getItem('marketId'),
                        name: this.form.marketName,
                        year: Number(this.form.year),
                        metric,
                    });
                    this.$store.commit('marketAssessment/pushAvailableMarkets', {
                        id: localStorage.getItem('marketId'),
                        name: this.form.marketName,
                        year: Number(this.form.year),
                        metric,
                    });
                    this.localSave();
                    this.checkSteps();
                });
            }
        },
        checkSteps() {
            if (this.form.marketName && this.form.year) {
                this.$store.commit('generalSettings/stepsDone', { name: 'Market Details', status: true });
            } else {
                this.$store.commit('generalSettings/stepsDone', { name: 'Market Details', status: false });
            }
        },
    },
    watch: {
        getMarket() {
            if (this.$store.getters['generalSettings/getMarket']) {
                this.form.marketName = this.$store.getters['generalSettings/getMarket'].name;
                this.form.year = this.$store.getters['generalSettings/getMarket'].year;
            }
            // this.form.marketName = this.$store.getters['generalSettings/getMarket'].name;
            // this.form.year = this.$store.getters['generalSettings/getMarket'].year;
        },
    },
    computed: {
        getMarket() {
            return this.$store.getters['generalSettings/getMarket'];
        },
    },
    components: {
        InputText,
        MrSelect,
    },
};
</script>

<style lang="scss">
.mr-select-wrapper {
    &.year-select {
        width: 137px;
    }
}
</style>
