export const getMarketAssessmentTableHeaders = () => {
    return [
        {
            text: 'MARKET NAME',
            align: 'center',
            sortable: true,
            value: 'name',
        },
        {
            text: 'YEAR',
            align: 'center',
            sortable: true,
            value: 'year',
        },
        {
            text: 'STATUS',
            align: 'center',
            sortable: true,
            value: 'status.value',
        },
        {
            text: 'OWNERSHIP',
            align: 'center',
            sortable: false,
            value: '',
        },
        {
            text: 'COLLABORATORS',
            align: 'center',
            sortable: false,
            value: '',
        },
    ];
};
