<template>
	<div class="transport-mode">
		<h3>Currency & number format</h3>
		<div class="first-row">
			<mr-select :label="'Currency'" :value="form.currency" :options="currencyOptions" @selected="selectCurrency" />
			<mr-select :label="'Common decimal separators'" :value="form.separator && form.separator.name" :options="separatorOptios" @selected="selectSeparator" />
			<mr-select :label="'Metrics'" :value="form.metric.name" :options="metricOptions" @selected="selectMetric" />
		</div>
		<h3>Transport mode</h3>
		<tooltip>
			<p> Select most comment transport modes within the market. Estimate related costs. </p>
		</tooltip>
		<div class="transport-mode-labels">
			<div class="label-block" v-for="(label, i) in labels" :key="i">
				<label>{{ label }}</label>
			</div>
		</div>
		<div class="second-row" v-for="(field, i) in getTransportMode" :key="i">
			<mr-select :options="field.type === 'Truck' ? [] : filterTransportTypeOptions" :value="field.type" @click.native="currentField = field" @selected="selectType" />
			<input-chip v-model.lazy="field.roadTransCosts" :chip="form.metric.distance" @focused="focused(field)" />
			<input-chip v-model.lazy="field.loadingCosts" :chip="`${getCurrencySymbol(form.currency)}/TON`" @focused="focused(field)" />
			<input-chip v-model.lazy="field.unloadingCosts" :chip="`${getCurrencySymbol(form.currency)}/TON`" @focused="focused(field)" />
			<btn v-if="getTransportMode.length < transportTypeOptions.length" @click.native="addField()" :btnStyle="'add'">
				<img src="../../../assets/lib/plus.svg" />
				Add
			</btn>
			<delete-btn class="delete-transport" v-if="field.type !== 'Truck'" @click.native="deleteTransportMode(field, i)" />
		</div>

		<v-dialog v-model="isShowDeleteTransportDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Delete transport
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Are you sure you want to delete
					<span class="font-weight-bold">transportation</span>
					type?
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="(isShowDeleteTransportDialog = false), (deleteField = null)" color="mangoTango" text>
						<span>No</span>
					</v-btn>
					<span class="px-2"></span>
					<v-btn @click="removeTransportMode(deleteField.id, deleteFieldIndex)" color="regalBlue" dark>
						<span class="white--text">Yes</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import MrSelect from '../../lib/mrSelect.vue';
import InputChip from '../../lib/inputChip.vue';
import Btn from '../../lib/btn.vue';
import Tooltip from '../../lib/tooltip.vue';
import DeleteBtn from '@/components/lib/deleteItemBtn.vue';

export default {
	data() {
		return {
			labels: ['Type', 'Road trans. costs', 'Loading costs', 'Unloading costs'],
			transportTypeOptions: [],
			metricOptions: this.$store.state.generalSettings.metrics,
			currencyOptions: this.$store.state.generalSettings.currenciesList,
			separatorOptios: this.$store.state.generalSettings.separatorOptios,
			currentField: null,
			deleteField: null,
			deleteFieldIndex: null,
			isShowDeleteTransportDialog: false,

			form: {
				currency: {
					id: 4,
					name: 'U.S. dollar',
					img: './currency/us.svg',
				},
				metric: this.$store.state.generalSettings.metrics[1],
				separator: this.$store.state.generalSettings.separatorOptios[0],
			},
			valFirstField: false,
		};
	},
	mounted() {
		this.$store.dispatch('generalSettings/getTransportTypes').then(() => {
			this.transportTypeOptions = this.$store.getters['generalSettings/getTransportTypes'];
		});
		// this.$store.dispatch('generalSettings/getCurrencies')
		//   .then(() => {
		//     this.currencyOptions = this.$store.getters['generalSettings/getCurrenciesList'];
		//   });
		setTimeout(() => {
			this.initForm();
		}, 1000);

		// this.checkSteps();
	},
	methods: {
		...mapActions('generalSettings', {
			saveMarketCurrencyAndNumberFormat: 'saveMarketCurrencyAndNumberFormat',
			saveMarketMetric: 'saveMarketMetric'
		}),
		initForm() {
			if (this.$store.getters['generalSettings/getMarket']) {
				if (this.$store.getters['generalSettings/getMarket'].currency) {
					const getCurrencyById = (id) => {
						return this.currencyOptions.find((currencyOption) => currencyOption.id === id);
					};
					const currency = this.$store.getters['generalSettings/getMarket'].currency;
					if (currency) {
						this.form.currency = getCurrencyById(currency.id);
					}
					const metric = this.$store.getters['generalSettings/getMarket'].metric;
					if (metric) {
						this.form.metric = metric;
					}
				}
				if (this.$store.getters['generalSettings/getMarket'].separatorType) {
					this.form.separator = this.$store.state.generalSettings.separatorOptios[this.$store.getters['generalSettings/getMarket'].separatorType];
				}
				if (!this.$store.getters['generalSettings/getMarket'].marketTransortModes.length) {
					this.addField();
				}
			} else if (localStorage.getItem('transportMode')) {
				this.form = JSON.parse(localStorage.getItem('transportMode'));
				if (this.form && this.form.transportTypeFields.length) {
					this.$store.commit('generalSettings/saveTransportMode', this.form.transportTypeFields);
				}
			}
			const isNewSettings = this.$route.params.newSettings;
			if (isNewSettings) {
				setTimeout(() => {
					if (localStorage.getItem('marketId')) {
						this.$store
							.dispatch('generalSettings/addTransportMode', {
								marketId: Number(localStorage.getItem('marketId')),
								marketTransportModeId: null,
								transportTypeId: 4,
								loadingCost: 0,
								unloadingCost: 0,
								costPerKm: 0,
							})
							.then((res) => {
								if (res) {
									this.$store.commit('generalSettings/saveTransportMode', [
										{
											id: res,
											loadingCosts: '0',
											marketTransportModeId: 4,
											roadTransCosts: '0',
											type: 'Truck',
											unloadingCosts: '0',
										},
									]);
								}
							});
					} else {
						this.$store.commit('generalSettings/saveTransportMode', [
							{
								loadingCosts: '0',
								marketTransportModeId: 4,
								roadTransCosts: '0',
								type: 'Truck',
								unloadingCosts: '0',
							},
						]);
					}
				}, 1);
			}
		},
		getCurrencySymbol(currency) {
			switch(currency.id) {
				case 1:
				case 2:
				case 4:
					return "$";
				case 3:
					return "€";
				default:
					return "-";
			}
		},
		addField() {
			const newTransportModeField = {
				type: '',
				roadTransCosts: 0,
				loadingCosts: 0,
				unloadingCosts: 0,
			};
			this.$store.commit('generalSettings/newTransportMode', newTransportModeField);
		},
		selectType(type) {
			this.currentField.type = type.name;
			// this.form.transportTypeFields[this.currentField].type = type.name;
			this.focused(this.currentField);
		},
		selectCurrency(currency) {
			this.form.currency = currency;
			if (currency.id === 1) {
				this.form.separator = this.$store.state.generalSettings.separatorOptios[0];
				this.form.metric = this.$store.state.generalSettings.metrics[0]
			}
			if (currency.id === 2) {
				this.form.separator = this.$store.state.generalSettings.separatorOptios[0];
				this.form.metric = this.$store.state.generalSettings.metrics[0]
			}
			if (currency.id === 3) {
				this.form.separator = this.$store.state.generalSettings.separatorOptios[1];
				this.form.metric = this.$store.state.generalSettings.metrics[0]
			}
			if (currency.id === 4) {
				this.form.separator = this.$store.state.generalSettings.separatorOptios[0];
				this.form.metric = this.$store.state.generalSettings.metrics[1]
			}
			this.saveMarketCurrencyAndNumberFormat({
				marketId: Number(localStorage.getItem('marketId')),
				currencyId: this.form.currency.id,
				separatorType: this.form.separator.id,
			});

			this.localSave();
		},
		selectSeparator(separator) {
			this.form.separator = separator;
			this.saveMarketCurrencyAndNumberFormat({
				marketId: Number(localStorage.getItem('marketId')),
				currencyId: this.form.currency.id,
				separatorType: this.form.separator.id,
			});
			this.localSave();
		},
		selectMetric(metric) {
			this.form.metric = metric;
			this.saveMarketMetric({
				metric,
				marketId: Number(localStorage.getItem('marketId'))
			});
			this.localSave();
		},
		focused(field) {
			if (field.type.length && field.loadingCosts !== null && field.roadTransCosts !== null && field.unloadingCosts !== null) {
				const form = {
					marketId: Number(localStorage.getItem('marketId')),
					marketTransportModeId: field.id || null,
					transportTypeId: this.$store.getters['generalSettings/getTransportTypes'].find((item) => item.name === field.type).id,
					loadingCost: Number(field.loadingCosts),
					unloadingCost: Number(field.unloadingCosts),
					costPerKm: Number(field.roadTransCosts),
				};
				field.id
					? this.$store.dispatch('generalSettings/updateTransportMode', form)
					: this.$store.dispatch('generalSettings/addTransportMode', form).then((res) => {
						if (res) {
							field.id = res;
						}
					});
			}
			this.localSave();
			this.checkSteps();
			this.validFirstField();
		},
		deleteTransportMode(field, i) {
			this.isShowDeleteTransportDialog = true
			this.deleteField = field
			this.deleteFieldIndex = i
		},
		removeTransportMode(marketTransportModeId, index) {
			if (marketTransportModeId) {
				this.$store
					.dispatch('generalSettings/removeTransportMode', {
						marketTransportModeId,
						marketId: Number(localStorage.getItem('marketId')),
					})
					.then(() => {
						this.$store.commit('generalSettings/removeTransportModeByIndex', index);
						if (!this.getTransportMode.length) {
							this.addField();
						}
						this.localSave();
					});
			} else {
				this.$store.commit('generalSettings/removeTransportModeByIndex', index);
				if (!this.getTransportMode.length) {
					this.addField();
				}
				this.localSave();
			}
			this.deleteField = null
			this.deleteFieldIndex = null
			this.isShowDeleteTransportDialog = false
		},
		localSave() {
			const data = {
				type: 'transportMode',
				// form: this.form,
				form: {
					transportTypeFields: this.getTransportMode,
					...this.form,
				},
			};
			this.$store.commit('generalSettings/localSave', data);
		},
		checkSteps() {
			if (
				this.getTransportMode[0].type.length &&
				this.getTransportMode[0].loadingCosts &&
				this.getTransportMode[0].roadTransCosts &&
				this.getTransportMode[0].unloadingCosts &&
				this.form.currency.name &&
				this.form.separator.name
			) {
				this.$emit('checkSteps', { name: 'transportMode', status: true });
			} else {
				this.$emit('checkSteps', { name: 'transportMode', status: false });
			}
		},
		validFirstField() {
			this.valFirstField = !!(
				this.getTransportMode[0].type.length ||
				this.getTransportMode[0].loadingCosts ||
				this.getTransportMode[0].roadTransCosts ||
				this.getTransportMode[0].unloadingCosts
			);
		},
	},
	computed: {
		getTransportMode() {
			return this.$store.getters['generalSettings/getTransportMode'];
		},
		filterTransportTypeOptions() {
			const existedSupplierTypes = this.getTransportMode.map((transport) => {
				return transport.type;
			});
			return this.transportTypeOptions.filter((transportTypeOption) => {
				return !existedSupplierTypes.includes(transportTypeOption.name);
			});
		},
	},
	components: {
		MrSelect,
		InputChip,
		Btn,
		Tooltip,
		DeleteBtn,
	},
};
</script>

<style lang="scss">
.first-row,
.second-row {
	margin-bottom: 15px;

	.input-select-distance-wrapper,
	.input-chip-wrapper,
	.mr-select-wrapper {
		margin-right: 20px;
	}

	.delete-transport {
		margin: 9px;
	}
}
</style>
