export const getTagColors = (tag) => {
    if (!tag) {
        return {
            textColor: 'black',
            backgroundColor: 'white',
        };
    }
    switch (tag.index) {
        case 1:
            return {
                textColor: 'pastelRed',
                backgroundColor: 'mistyRose',
            };
        case 2:
            return {
                textColor: 'mangoTango',
                backgroundColor: 'sazerac',
            };
        case 3:
            return {
                textColor: 'crayolaGreen',
                backgroundColor: 'tara',
            };
        case 4:
            return {
                textColor: 'greenBlue',
                backgroundColor: 'azureishWhite',
            };
        case 5:
            return {
                textColor: 'burgundy',
                backgroundColor: 'bizarre',
            };
        default:
            return {
                textColor: 'black',
                backgroundColor: 'white',
            };
    }
};
