<template>
  <section>
	  <v-row>
		  <v-col cols="3" class="py-1 pr-16">
			  <v-text-field
				  v-model="search"
				  label="Search..."
				  background-color="white"
				  class="other-segments-table-search"
				  outlined
				  clearable
				  dense
			  >
				  <template v-slot:prepend-inner>
					  <img src="@/assets/market_segments/search.svg">
				  </template>
			  </v-text-field>
		  </v-col>
		  <v-col cols=2 offset="7" class="d-flex justify-end py-1">
			  <v-btn
				  class="split-menu-view py-5"
				  @click="openTotalMarketVolume()"
			  >
				  <img src="@/assets/market_segments/total.svg" class="mr-2">
				  TOTAL MARKET VOLUME
			  </v-btn>
		  </v-col>
	  </v-row>

    <v-data-table
      :style="{ 'background-color': backgroundColor }"
      :headers="otherSegmentHeaders"
      :items="otherSegmentData"
	  :search="search"
      :items-per-page="-1"
      :mobile-breakpoint="-1"
      :loading="isLoadingData"
      :class="{ 'pb-12': isDisplayAvailableAggregateProducersButton }"
	  height="64vh"
	  class="other-segments-table"
      item-key="id"
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear color="regalBlue" indeterminate></v-progress-linear>
        <div class="pt-5 text-center">Loading... Please wait</div>
      </template>
      <template v-slot:header>
        <thead>
          <tr>
            <th colspan="4" class="other-segments-table-additional-header bismarkBlue text-center">
              <span class="regalBlue--text">Aggregates supplier information</span>
            </th>
            <template v-if="productTypes.length">
              <th colspan="1" class="other-segments-table-empty-th"></th>
              <th :colspan="productTypes.length" class="other-segments-table-additional-header bismarkBlue text-center">
                <span class="regalBlue--text">AGGREGATES VOLUME TO BE ALLOCATED</span>
              </th>
            </template>
<!--            <template v-if="otherMarketSegments.length">-->
<!--              <th colspan="1" style="border: none !important"></th>-->
<!--              <th :colspan="otherMarketSegments.length" class="other-segments-table-additional-header bismark text-center">-->
<!--                <span class="regalBlue&#45;&#45;text">AGGREGATES VOLUME ALLOCATED TO OTHER</span>-->
<!--              </th>-->
<!--            </template>-->
<!--            <th colspan="1" class="other-segments-table-empty-th"></th>-->
<!--            <th-->
<!--              colspan="2"-->
<!--              class="other-segments-table-additional-header bismark text-center"-->
<!--              style="border-right: 1px solid antiFlashWhite !important"-->
<!--            >-->
<!--              <span class="regalBlue&#45;&#45;text">RMC</span>-->
<!--            </th>-->
<!--            <th-->
<!--              colspan="2"-->
<!--              class="other-segments-table-additional-header bismark text-center"-->
<!--              style="border-right: 1px solid antiFlashWhite !important"-->
<!--            >-->
<!--              <span class="regalBlue&#45;&#45;text">ASPHALT</span>-->
<!--            </th>-->
<!--            <th colspan="2" class="other-segments-table-additional-header bismark text-center">-->
<!--              <span class="regalBlue&#45;&#45;text">CONCRETE PRODUCTS</span>-->
<!--            </th>-->
<!--            <th colspan="1" class="other-segments-table-additional-header bismark text-center">-->
<!--              <span class="regalBlue&#45;&#45;text">RAILWAY</span>-->
<!--            </th>-->
<!--            <th-->
<!--              v-for="customHeader in getCustomSegmentHeaders"-->
<!--              :key="customHeader.name + getUniqueID()"-->
<!--              colspan="4"-->
<!--              class="other-segments-table-additional-header bismark text-center"-->
<!--            >-->
<!--              <span class="regalBlue&#45;&#45;text">{{ customHeader.name }}</span>-->
<!--            </th>-->
            <th colspan="1" class="other-segments-table-empty-th"></th>
            <th colspan="2" class="other-segments-table-additional-header bismarkBlue text-center">
              <span class="regalBlue--text">allocated to market segments</span>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-if="otherSegmentData.length === 0 && !isLoadingData">
            <td :colspan="otherSegmentHeaders.length" style="border-top: none !important" class="whiteLilac">
              <div class="text-center">No data</div>
            </td>
          </tr>
          <tr v-for="item in items" :key="item.id + getUniqueID()">
            <td class="td-min-max-width сapeCod--text">
				<div class="d-flex align-center">
				  <span class="font-weight-regular text-truncate">
					  <template v-if="item.companyName">
						  {{ item.companyName }}
                      </template>
                      <template v-else>-</template>
                </span>
				</div>
				<div class="d-flex align-center">
				  <span class="font-weight-regular text-truncate grayWhite--text">
					  <template v-if="item.group">
						  {{ item.group.name }}
                      </template>
                      <template v-else>-</template>
                </span>
				</div>
            </td>
            <td class="td-min-max-width-extra сapeCod--text">
              <div class="d-flex align-center justify-center">
                <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="text-center">
                      <template v-if="item.geologySubType">
						  {{ item.plantName }}
                      </template>
                      <template v-else>{{ item.plantName }}</template>
                    </span>
                  </template>
                  <span>
					  <template v-if="item.plantName">
						  {{ item.plantName }}
                      </template>
                      <template v-else>-</template>
				  </span>
                </v-tooltip>
              </div>
            </td>
            <td class="td-min-max-width сapeCod--text text-no-wrap">
				<div class="d-flex align-center">
					<span class="text-truncate">
                  <template v-if="item.geologySubType">
					  {{ item.geologySubType.name }}
                  </template>
                  <template v-else>-</template>
                </span>
				</div>
				<div class="d-flex align-center">
					<span class="text-truncate grayWhite--text">
					  <template v-if="item.plantType">
						  {{ item.plantType.name }}
					  </template>
					  <template v-else>-</template>
					</span>
				</div>
            </td>
            <td class="td-min-max-width сapeCod--text text-no-wrap">
              <div class="d-flex align-center justify-center">
                <span class="text-truncate"> {{ toFormatNumber(item.volumeSoldInMarket) }}&nbsp;t </span>
              </div>
				<div class="d-flex align-center justify-center">
					<span class="text-truncate grayWhite--text"> Prod: {{ toFormatNumber(item.annualAggregatesProduction) }}&nbsp;t </span>
				</div>
            </td>
            <td v-if="productTypes.length" class="other-segments-table-empty-th"></td>
            <template v-for="productType in productTypes">
              <td :key="productType.name + getUniqueID()" class="td-min-max-width сapeCod--text text-no-wrap"
				  :class="[
                      calculateAggregatesVolumeToBeAllocatedByStandartSplit(item, productType) < 0 ? 'dangerCalculate--bg' : '',
                    ]"
			  >
				  <div class="d-flex align-center justify-center" v-if="item.volumeSoldInMarket || calculateAggregatesVolumeToBeAllocatedByStandartSplit(item, productType)">
                  <span
					  class="font-weight-regular text-truncate"
					  :class="[
                      calculateAggregatesVolumeToBeAllocatedByStandartSplit(item, productType) < 0 ? 'terraCotta--text' : '',
                    ]"
				  >
                    {{ toFormatNumber(calculateAggregatesVolumeToBeAllocatedByStandartSplit(item, productType)) }}&nbsp;t
                  </span>
				  </div>
				  <div class="d-flex align-center justify-center" v-if="item.volumeSoldInMarket">
                  <span
					  class="font-weight-regular text-truncate grayWhite--text"
					  :class="[
                      calculateAggregatesVolumeToBeAllocatedByStandartSplit(item, productType) < 0 ? 'terraCotta--text' : '',
                    ]"
				  >
					  {{ (calculateAggregatesVolumeToBeAllocatedByStandartSplit(item, productType)/item.volumeSoldInMarket*100).toFixed(0) }} %
                  </span>
				  </div>
              </td>
            </template>

			  <td class="other-segments-table-empty-th"></td>
			  <td class="td-min-max-width сapeCod--text text-no-wrap"
				  :class="[
                      !item.volumeSoldInMarket ? '' : (calculateTotalTons(item.customerSuppliers)/item.volumeSoldInMarket*100).toFixed(0) > 100 ? 'dangerCalculate--bg' : '',
                    ]"
			  >
				  <div v-if="item.volumeSoldInMarket" class="d-flex align-center justify-center">
					  <span class="font-weight-medium text-truncate"
							:class="[
                    	!item.volumeSoldInMarket ? '' : (calculateTotalTons(item.customerSuppliers)/item.volumeSoldInMarket*100).toFixed(0) > 100 ? 'terraCotta--text' : '',
                    ]"
					  >
						{{toFormatNumber(calculateTotalTons(item.customerSuppliers)) }} t
					  </span>
				  </div>
				  <div v-if="item.volumeSoldInMarket" class="d-flex align-center justify-center">
					    <span class="font-weight-regular text-truncate text-center grayWhite--text"
							  :class="[
                    	!item.volumeSoldInMarket ? '' : (calculateTotalTons(item.customerSuppliers)/item.volumeSoldInMarket*100).toFixed(0) > 100 ? 'terraCotta--text' : '',
                    ]"
						>
							<template v-if="item.volumeSoldInMarket">
								{{(calculateTotalTons(item.customerSuppliers)/item.volumeSoldInMarket*100).toFixed(0)}}
							</template>
							<template v-else>0</template>
							%
						</span>
				  </div>
			  </td>
			  <td class="td-min-max-width сapeCod--text text-no-wrap">
				  <div class="d-flex align-center justify-center">
					  <v-btn
						  class="split-menu-view"
						  @click="openSidebar(item)"
					  >
						  <img src="@/assets/market_segments/analyze_view.svg">
						  View
					  </v-btn>
				  </div>
			  </td>
          </tr>

        </tbody>
      </template>
    </v-data-table>

	  <template v-if="showLeftSidebar">
		  <div class="other-segments-table-split-menu">
			  <v-btn
				  @click="hideLeftSidebar()"
				  small text fab
				  class="split-menu-close"
			  >
				  <img src="@/assets/market_segments/close.svg">
			  </v-btn>
			  <div class="split-menu-box">
				  <div class="split-menu-box__top">
					  <span>Total Allocated Volume</span>
					  <span>
						  <template v-if="sidebarData.plantName">
							  {{ sidebarData.plantName }}
						  </template>
						  <template v-else>-</template>
					  </span>
				  </div>
				  <div class="split-menu-box__results d-flex align-center justify-space-between">
					  <div class="d-flex align-center">
						  <v-progress-circular
							  :size="60"
							  :width="15"
							  :rotate="-90"
							  color="split-menu-box__progress"
							  :value="(calculateTotalTons(sidebarData.customerSuppliers)/sidebarData.volumeSoldInMarket*100)"></v-progress-circular>
						  <span class="pl-7">
							  <template v-if="sidebarData.volumeSoldInMarket">
								  {{ (calculateTotalTons(sidebarData.customerSuppliers)/sidebarData.volumeSoldInMarket*100).toFixed(0) }}
							  </template>
							  <template v-else>0</template>
							  %
						  </span>
					  </div>
					  <div class="results-box__data">
						  <div>
							  Allocated
							  <span>
								  {{ toFormatNumber(calculateTotalTons(sidebarData.customerSuppliers)) }} t
							  </span>
						  </div>
					  </div>
					  <div class="results-box__data">
						  <div>
							  Total Sales
							  <span>
								  {{ toFormatNumber(sidebarData.volumeSoldInMarket) }} t
							  </span>
						  </div>
					  </div>

				  </div>
				  <div class="split-menu-box__table">
					  <table>
						  <thead>
						  <tr>
							  <th>Segment name</th>
							  <th>Allocated</th>
							  <th>% of total</th>
							  <th>Coarse</th>
							  <th>Fine</th>
							  <th>Base/Fill</th>
							  <th>Other</th>
						  </tr>
						  </thead>

						  <tbody>
						  <tr>
							  <td>Ready Mix</td>
							  <td>
								  <div v-if="calculateFineTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers) + calculateCoarseTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers)"
									   class="d-flex align-center justify-center">
									  <span class="text-truncate">
										  {{ toFormatNumber(calculateFineTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers) + calculateCoarseTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers)) }}
										  t
									  </span>
								  </div>
							  </td>
							  <td>
								  <div v-if="sidebarData.volumeSoldInMarket" class="d-flex align-center justify-center">
									  <span class="text-truncate">
										  <template>
											  {{((calculateFineTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers) + calculateCoarseTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers))/sidebarData.volumeSoldInMarket*100).toFixed(0) }}
										  </template>
										  %
									  </span>
								  </div>
							  </td>
							  <td>
								  <div v-if="calculateCoarseTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers)" class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateCoarseTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers)) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div v-if="calculateFineTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers)" class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateFineTonOfReadyMixConcreteSegment(sidebarData.customerSuppliers)) }} t </span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr>
							  <td>Asphalt</td>
							  <td>
								  <div
									  v-if="calculateFineTonOfAsphaltSegment(sidebarData.customerSuppliers)+ calculateCoarseTonOfAsphaltSegment(sidebarData.customerSuppliers)" class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateFineTonOfAsphaltSegment(sidebarData.customerSuppliers)+ calculateCoarseTonOfAsphaltSegment(sidebarData.customerSuppliers)) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div v-if="sidebarData.volumeSoldInMarket"
									   class="d-flex align-center justify-center">
												  <span class="text-truncate">
													  <template v-if="sidebarData.volumeSoldInMarket">
														  {{ ((calculateFineTonOfAsphaltSegment(sidebarData.customerSuppliers)+ calculateCoarseTonOfAsphaltSegment(sidebarData.customerSuppliers))/sidebarData.volumeSoldInMarket*100).toFixed(0) }}
													  </template>
													  %
												  </span>
								  </div>
							  </td>
							  <td>
								  <div v-if="calculateCoarseTonOfAsphaltSegment(sidebarData.customerSuppliers)"
									   class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateCoarseTonOfAsphaltSegment(sidebarData.customerSuppliers)) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div v-if="calculateFineTonOfAsphaltSegment(sidebarData.customerSuppliers)"
									   class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateFineTonOfAsphaltSegment(sidebarData.customerSuppliers)) }} t </span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr>
							  <td>Concrete Products</td>
							  <td>
								  <div v-if="calculateFineTonOfConcreteProductsSegment(sidebarData.customerSuppliers)+ calculateCoarseTonOfConcreteProductsSegment(sidebarData.customerSuppliers)"
									   class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateFineTonOfConcreteProductsSegment(sidebarData.customerSuppliers)+ calculateCoarseTonOfConcreteProductsSegment(sidebarData.customerSuppliers)) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div v-if="sidebarData.volumeSoldInMarket"
									   class="d-flex align-center justify-center">
												  <span class="text-truncate">
													  <template>
													  	{{ ((calculateFineTonOfConcreteProductsSegment(sidebarData.customerSuppliers) + calculateCoarseTonOfConcreteProductsSegment(sidebarData.customerSuppliers))/sidebarData.volumeSoldInMarket*100).toFixed(0) }}
													  </template>
													  %
												  </span>
								  </div>
							  </td>
							  <td>
								  <div v-if="calculateCoarseTonOfConcreteProductsSegment(sidebarData.customerSuppliers)"
									   class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateCoarseTonOfConcreteProductsSegment(sidebarData.customerSuppliers)) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div v-if="calculateFineTonOfConcreteProductsSegment(sidebarData.customerSuppliers)"
									   class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateFineTonOfConcreteProductsSegment(sidebarData.customerSuppliers)) }} t </span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr>
							  <td>Railway</td>
							  <td>
								  <div v-if="calculateCoarseTonOfRailwaySegment(sidebarData.customerSuppliers)" class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateCoarseTonOfRailwaySegment(sidebarData.customerSuppliers)) }}&nbsp;t </span>
								  </div>
							  </td>
							  <td>
								  <div v-if="sidebarData.volumeSoldInMarket" class="d-flex align-center justify-center">
												  <span class="text-truncate">
													  <template v-if="sidebarData.volumeSoldInMarket">
													  	{{ ((calculateCoarseTonOfRailwaySegment(sidebarData.customerSuppliers))/sidebarData.volumeSoldInMarket*100).toFixed(0) }}
													  </template>
													  %
												  </span>
								  </div>
							  </td>
							  <td>
								  <div v-if="calculateCoarseTonOfRailwaySegment(sidebarData.customerSuppliers)" class="d-flex align-center justify-center">
									  <span class="text-truncate"> {{ toFormatNumber(calculateCoarseTonOfRailwaySegment(sidebarData.customerSuppliers)) }}&nbsp;t </span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr v-for="customHeader in getCustomSegmentHeaders">
							  <td>{{customHeader.name}}</td>
							  <td>
								  <div
									  v-if="calculateCoarseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
											calculateFineTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
											calculateFillBaseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
											calculateOtherTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)"
									  class="d-flex align-center justify-center">
												  <span class="text-truncate">
													  {{ toFormatNumber(
													  calculateCoarseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
													  calculateFineTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
													  calculateFillBaseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
													  calculateOtherTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)
												  )}} t
												  </span>
								  </div>
							  </td>
							  <td>
								  <div
									  v-if="sidebarData.volumeSoldInMarket"
									  class="d-flex align-center justify-center">
												  <span class="text-truncate">
													  <template>
														  {{ ((
														  calculateCoarseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
														  calculateFineTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
														  calculateFillBaseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id) +
														  calculateOtherTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)
													  )/sidebarData.volumeSoldInMarket*100).toFixed(0)}}
													  </template>
													  %
												  </span>
								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()" class="td-min-max-width сapeCod--text text-no-wrap">
								  <div v-if="calculateCoarseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)"
									   class="d-flex align-center justify-center">
												  <span class="text-truncate">
													  {{ toFormatNumber(calculateCoarseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)) }}&nbsp;t
												  </span>
								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()" class="td-min-max-width сapeCod--text text-no-wrap">
								  <div v-if="calculateFineTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)"
									   class="d-flex align-center justify-center">
												  <span class="text-truncate">
													{{ toFormatNumber(calculateFineTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)) }}&nbsp;t
												  </span>
								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()" class="td-min-max-width сapeCod--text text-no-wrap">
								  <div v-if="calculateFillBaseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)"
									   class="d-flex align-center justify-center">
												  <span class="text-truncate">
													{{ toFormatNumber(calculateFillBaseTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)) }}&nbsp;t
												  </span>
								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()" class="td-min-max-width сapeCod--text text-no-wrap">
								  <div v-if="calculateOtherTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)"
									   class="d-flex align-center justify-center">
												  <span class="text-truncate">
													{{ toFormatNumber(calculateOtherTonCustomSegment(sidebarData.customerSuppliers, customHeader.id)) }}&nbsp;t
												  </span>
								  </div>
							  </td>
						  </tr>

						  </tbody>
					  </table>
				  </div>
			  </div>
		  </div>
	  </template>

	  <template v-if="showTotalLeftSidebar">
		  <div class="other-segments-table-split-menu">
			  <v-btn
				  @click="hideTotalMarketVolume()"
				  small text fab
				  class="split-menu-close"
			  >
				  <img src="@/assets/market_segments/close.svg">
			  </v-btn>
			  <div class="split-menu-box">
				  <div class="split-menu-box__top">
					  <span>Total Market Volume</span>
					  <span></span>
				  </div>
				  <div class="split-menu-box__results d-flex align-center justify-space-between">
					  <div class="d-flex align-center">
						  <v-progress-circular
							  :size="60"
							  :width="15"
							  :rotate="-90"
							  color="split-menu-box__progress"
							  :value="((calculateTotalTonOfReadyMixConcreteSegment() + calculateTotalTonOfAsphaltSegment() + calculateTotalTonOfConcreteProductsSegment() + calculateTotalTonCoarseOfRailwaySegment() + calculateTotalAllocatedTons())/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0)"></v-progress-circular>
						  <span class="pl-7">
							  <template v-if="calculateTotalVolumeSoldInTheMarket()">
								  {{ ((calculateTotalTonOfReadyMixConcreteSegment() + calculateTotalTonOfAsphaltSegment() + calculateTotalTonOfConcreteProductsSegment() + calculateTotalTonCoarseOfRailwaySegment() + calculateTotalAllocatedTons())/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0) }}
							  </template>
							  <template v-else>0</template>
							  %
						  </span>
					  </div>
					  <div class="results-box__data">
						  <div>
							  Allocated
							  <span>
								  {{ toFormatNumber(calculateTotalTonOfReadyMixConcreteSegment() + calculateTotalTonOfAsphaltSegment() + calculateTotalTonOfConcreteProductsSegment() + calculateTotalTonOfRailwaySegment() + calculateTotalAllocatedTons()) }}
							  </span>
						  </div>
					  </div>
					  <div class="results-box__data">
						  <div>
							  Total Sales
							  <span>
								  {{toFormatNumber(calculateTotalVolumeSoldInTheMarket())}}
							  </span>
						  </div>
					  </div>

				  </div>
				  <div class="split-menu-box__table">
					  <table>
						  <thead>
						  <tr>
							  <th>Segment name</th>
							  <th>Allocated</th>
							  <th>% of total</th>
							  <th>Coarse</th>
							  <th>Fine</th>
							  <th>Base/Fill</th>
							  <th>Other</th>
						  </tr>
						  </thead>

						  <tbody>
						  <tr>
							  <td>Ready Mix</td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonOfReadyMixConcreteSegment()">
									  <span class="text-truncate">{{toFormatNumber(calculateTotalTonOfReadyMixConcreteSegment())}} t</span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalVolumeSoldInTheMarket()">
									  <span class="text-truncate">
										  <template>
											  {{(calculateTotalTonOfReadyMixConcreteSegment()/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0) }}
										  </template>
										  %
									  </span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonCoarseOfReadyMixConcreteSegment()">
									  <span class="text-truncate">{{toFormatNumber(calculateTotalTonCoarseOfReadyMixConcreteSegment())}} t</span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonFineOfReadyMixConcreteSegment()">
									  <span class="text-truncate">{{toFormatNumber(calculateTotalTonFineOfReadyMixConcreteSegment())}} t</span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr>
							  <td>Asphalt</td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonOfAsphaltSegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonOfAsphaltSegment()) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalVolumeSoldInTheMarket()">
									  <span class="text-truncate">
										  <template>
											  {{ ((calculateTotalTonOfAsphaltSegment())/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0) }}
										  </template>
										  %
									  </span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonCoarseOfAsphaltSegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonCoarseOfAsphaltSegment()) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonFineOfAsphaltSegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonFineOfAsphaltSegment()) }} t </span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr>
							  <td>Concrete Products</td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonOfConcreteProductsSegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonOfConcreteProductsSegment()) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalVolumeSoldInTheMarket()">
									  <span class="text-truncate">
										  <template>
											  {{ ((calculateTotalTonOfConcreteProductsSegment())/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0) }}
										  </template>
										  %
									  </span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonCoarseOfConcreteProductsSegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonCoarseOfConcreteProductsSegment()) }} t</span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonFineOfConcreteProductsSegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonFineOfConcreteProductsSegment()) }} t </span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr>
							  <td>Railway</td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonOfRailwaySegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonOfRailwaySegment()) }}&nbsp;t </span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalVolumeSoldInTheMarket()">
									  <span class="text-truncate">
										  <template>
											  {{ ((calculateTotalTonOfRailwaySegment())/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0) }}
										  </template>
										  %
									  </span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonCoarseOfRailwaySegment()">
									  <span class="text-truncate"> {{ toFormatNumber(calculateTotalTonCoarseOfRailwaySegment()) }}&nbsp;t </span>
								  </div>
							  </td>
							  <td></td>
							  <td></td>
							  <td></td>
						  </tr>
						  <tr v-for="customHeader in getCustomSegmentHeaders">
							  <td>{{customHeader.name}}</td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonOfCustomSegment(customHeader.id)">
									  <span class="text-truncate">
										  {{ toFormatNumber(calculateTotalTonOfCustomSegment(customHeader.id)) }} t
									  </span>
								  </div>
							  </td>
							  <td>
								  <div class="d-flex align-center justify-center" v-if="calculateTotalVolumeSoldInTheMarket()">
									  <span class="text-truncate">
													  <template>
														  {{ ((
														  calculateTotalTonCoarseCustomSegment(customHeader.id) +
														  calculateTotalTonFineCustomSegment(customHeader.id) +
														  calculateTotalTonFillBaseCustomSegment(customHeader.id) +
														  calculateTotalTonOtherCustomSegment(customHeader.id)
													  )/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0)}}
													  </template>
										  %
									  </span>

								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()">
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonCoarseCustomSegment(customHeader.id)">
									  <span class="text-truncate">
										{{ toFormatNumber(calculateTotalTonCoarseCustomSegment(customHeader.id)) }}&nbsp;t
									  </span>
								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()">
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonFineCustomSegment(customHeader.id)">
									  <span class="text-truncate">
										{{ toFormatNumber(calculateTotalTonFineCustomSegment(customHeader.id)) }}&nbsp;t
									  </span>
								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()">
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonFillBaseCustomSegment(customHeader.id)">
									  <span class="text-truncate">
										{{ toFormatNumber(calculateTotalTonFillBaseCustomSegment(customHeader.id)) }}&nbsp;t
									  </span>
								  </div>
							  </td>
							  <td :key="customHeader.name + getUniqueID()">
								  <div class="d-flex align-center justify-center" v-if="calculateTotalTonOtherCustomSegment(customHeader.id)">
									  <span class="text-truncate">
										{{ toFormatNumber(calculateTotalTonOtherCustomSegment(customHeader.id)) }}&nbsp;t
									  </span>
								  </div>
							  </td>
						  </tr>

						  </tbody>
					  </table>
				  </div>
			  </div>
		  </div>
	  </template>

  </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { getOtherSegmentTableHeaders } from '@/core/helpers/otherSegmentTableHeaderHelper';
import { getPlantTypeColors } from '@/core/helpers/plantTypeColorHelper';
import { getGeologyTypeColors } from '@/core/helpers/geologyTypeColorHelper';
import { MarketSegmentType } from '@/core/enums/marketSegmentType';

import NonButtonBlock from '@/components/nonButtonBlock/NonButtonBlock.vue';

export default {
  name: 'OtherSegment',
  components: {
    NonButtonBlock,
  },
  data() {
    return {
		search: null,
		drawer: null,
		isLoadingData: false,
		showLeftSidebar: false,
		showTotalLeftSidebar: false,
		sidebarData: [],
      isDisplayAvailableAggregateProducersButton: true,
      otherSegmentData: [],
    };
  },
  mounted() {
    this.$emit('start-loading-data');
    this.setIsLoadingPaginationToolbar(true);
    this.isLoadingData = true;
    this.initPagination();
    setTimeout(async () => {
      await this.init();
      this.otherSegmentData = this.initOtherSegmentData(this.aggregateProducers);
      this.isLoadingData = false;
      this.setIsLoadingPaginationToolbar(false);
      this.$emit('finish-loading-data');
		}, 500);
  },
  watch: {
    pageSize() {
      if (this.aggregateProducers.length) {
        this.initPagination();
        this.reInitOtherSegmentData();
      }
    },
    page() {
      this.reInitOtherSegmentData();
    },
  },
  computed: {
    ...mapGetters('paginationToolbar', {
      pageSize: 'pageSize',
      page: 'page',
      totalPages: 'totalPages',
    }),
    ...mapGetters('marketAssessment', {
      market: 'market',
    }),
    ...mapGetters('marketSegment', {
      productTypes: 'productTypes',
    }),
    ...mapGetters('supplier', {
      aggregateProducers: 'aggregateProducers',
      otherMarketSegments: 'otherMarketSegments',
    }),
    backgroundColor() {
      return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
    },
    getCoarseProductTypeId() {
      const coarse = this.productTypes.filter((type) => {
        return type.name.toLowerCase().includes('coarse');
      });
      if (coarse.length) {
        return coarse[0].id;
      }
      return 0;
    },
    getFineProductTypeId() {
      const fine = this.productTypes.filter((type) => {
        return type.name.toLowerCase().includes('fine');
      });
      if (fine.length) {
        return fine[0].id;
      }
      return 0;
    },
    getFileBaseProductTypeId() {
      const fine = this.productTypes.filter((type) => {
        return type.name.toLowerCase().includes('fill') || type.name.toLowerCase().includes('base');
      });
      if (fine.length) {
        return fine[0].id;
      }
      return 0;
    },
    getOtherProductTypeId() {
      const fine = this.productTypes.filter((type) => {
        return type.name.toLowerCase().includes('other');
      });
      if (fine.length) {
        return fine[0].id;
      }
      return 0;
    },
    otherSegmentHeaders() {
      const headers = getOtherSegmentTableHeaders();
      if (this.productTypes.length) {
        this.productTypes.forEach((item) => {
          headers.push({
            text: item.name,
            align: 'center',
            sortable: false,
            value: '',
          });
        });
      }
      headers.push({
        text: '',
        align: '',
        sortable: false,
        value: '',
		  class: 'other-segments-table-empty-th',
      });
      headers.push({
        text: 'TONS & %',
        align: 'center',
        sortable: false,
        value: '',
      });
      headers.push({
        text: 'Split by segment',
        align: 'center',
        sortable: false,
        value: '',
      });
      return headers;
    },
    getCustomSegmentHeaders() {
      let headerData = [];
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Custom) {
            headerData.push({ id: item.customerPlant.marketSegment.segment.id, name: item.customerPlant.marketSegment.segment.displayName });
          }
        });
      });
      var uniqueHeaderData = [];
      var distinctHeaderData = [];
      for (let index = 0; index < headerData.length; index++) {
        if (!uniqueHeaderData[headerData[index].id]) {
          distinctHeaderData.push(headerData[index]);
          uniqueHeaderData[headerData[index].id] = 1;
        }
      }
      return distinctHeaderData;
    },
  },
  methods: {
	  openSidebar(item) {
		  this.sidebarData = item
		  this.showLeftSidebar = true
	  },
	  openTotalMarketVolume() {
		  this.showTotalLeftSidebar = true
	  },
	  hideTotalMarketVolume() {
		  this.showTotalLeftSidebar = false
	  },
	  hideLeftSidebar() {
		  this.showLeftSidebar = false
		  this.sidebarData = []
	  },
    getUniqueID() {
      return uuidv4();
    },
    roundNumber(value, power = 2) {
      return Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
    },
    formatNumber(number, fraction, separator = ' ') {
      let data = number.toLocaleString('en-US');
      data = data.replace(/\./, 'F');
      data = data.replace(/,/g, 'S');
      data = data.replace(/F/, fraction);
      data = data.replace(/S/g, separator);
      return data;
    },
    toFormatNumber(data) {
      return this.formatNumber(data, this.market.separatorType === 0 ? '.' : ',');
    },
    ...mapMutations('paginationToolbar', {
      setIsDisplayPaginationToolbar: 'setIsDisplayPaginationToolbar',
      setIsLoadingPaginationToolbar: 'setIsLoadingPaginationToolbar',
      initPagination: 'initPagination',
    }),
    ...mapActions('supplier', {
      getAggregatesProducers: 'getAggregatesProducers',
      getAvailableAggregateProducers: 'getAvailableAggregateProducers',
      getOtherMarketSegments: 'getOtherMarketSegments',
      saveOtherSegmentSupplier: 'saveOtherSegmentSupplier',
    }),

    async init() {
      const getOtherMarketSegments = this.getOtherMarketSegments({ marketId: this.market.id });
      const getAggregatesProducers = this.getAggregatesProducers({
        marketId: this.market.id,
        page: this.page,
      });
      await Promise.all([getOtherMarketSegments, getAggregatesProducers]);
	},
    initOtherSegmentData(data) {
      return data.map((item) => {
        const mappedOtherMarketSegments = [];
        this.otherMarketSegments.forEach((otherMarketSegment) => {
          mappedOtherMarketSegments.push({
            ['isEdit' + otherMarketSegment.id + 'Value']: false,
            ['isLoading' + otherMarketSegment.id + 'Value']: false,
            id: otherMarketSegment.id,
            marketId: otherMarketSegment.marketId,
            otherSegmentSupplyId: null,
            aggregatesProducerGeologyId: null,
            volume: 0,
            segment: otherMarketSegment.segment,
          });
        });
        mappedOtherMarketSegments.forEach((mappedOtherMarketSegment) => {
          mappedOtherMarketSegment.aggregatesProducerGeologyId = item.aggregatesProducerGeologyId;
          item.otherSegmentSupplies.forEach((otherSegmentSupply) => {
            if (otherSegmentSupply.marketSegmentId === mappedOtherMarketSegment.id) {
              mappedOtherMarketSegment.otherSegmentSupplyId = otherSegmentSupply.id;
              mappedOtherMarketSegment.volume = otherSegmentSupply.volume;
            }
          });
        });
        item.otherSegmentSupplies = mappedOtherMarketSegments;
        return {
          id: item.aggregatesProducerId,
          aggregatesProducerGeologyId: item.aggregatesProducerGeologyId,
          companyName: item.investigatedCompany.name,
          plantName: item.name,
          plantType: item.plantType,
          geologySubType: item.geologySubType,
          volumeSoldInMarket: item.volumeSoldInMarket,
          volumeSoldInMarketPercent: item.volumeSoldInMarketPercent,
          annualAggregatesProduction: item.annualAggregatesProduction,
          customerSuppliers: item.customerSuppliers,
          otherSegmentSupplies: item.otherSegmentSupplies,
          percentageOfProductTypes: item.percentageOfProductTypes,
			group: item.group
        };
      });
    },
    reInitOtherSegmentData() {
      this.$emit('start-loading-data');
      this.setIsLoadingPaginationToolbar(true);
      this.isLoadingData = true;
      this.otherSegmentData = [];
      setTimeout(async () => {
        await this.getAggregatesProducers({
          marketId: this.market.id,
          page: this.page,
        });
        this.otherSegmentData = this.initOtherSegmentData(this.aggregateProducers);
        this.isLoadingData = false;
        this.setIsLoadingPaginationToolbar(false);
        this.$emit('finish-loading-data');
      }, 500);
    },
    initAvailableAggregateProducers() {
      this.$emit('start-loading-data');
      this.isLoadingData = true;
      this.otherSegmentData = [];
      setTimeout(async () => {
        await this.getAvailableAggregateProducers({ marketId: this.market.id });
        this.otherSegmentData = this.initOtherSegmentData(this.aggregateProducers);
        this.isLoadingData = false;
        this.$emit('finish-loading-data');
      }, 500);
    },
    plantTypeColors(plantTypeId) {
      return getPlantTypeColors(plantTypeId);
    },
    geologyTypeColors(geologyTypeId) {
      return getGeologyTypeColors(geologyTypeId);
    },
    calculateAggregatesVolumeToBeAllocatedByStandartSplit(data, productType) {
		let totalTon = 0;
		data.customerSuppliers.forEach((item) => {
			item.allocationOfProductTypes.forEach((allocation) => {
				if (allocation.productTypeId === productType.id) {
					totalTon += allocation.volume;
				}
			});
		});
		// data.otherSegmentSupplies.forEach((item) => {
		// 	if (productType.name.toLowerCase().includes(item.segment.displayName.toLowerCase())) {
		// 		totalTon += item.volume;
		// 	}
		// });
		let percent = 0;
		data.percentageOfProductTypes.forEach((item) => {
			if (item.productTypeId === productType.id) {
				percent = item.percentage;
			}
		});
		return this.roundNumber((data.volumeSoldInMarket / 100) * parseInt(percent, 10) - totalTon, 0);
    },
	  calculateAggregatesMaterialByStandartSplit(data, productType){
		let total = 0
		  data.percentageOfProductTypes.forEach((item) => {
			  if (item.productTypeId === productType.id) {
				  total = item.percentage;
			  }
		  });
		return total
	  },
    calculateCoarseTonOfReadyMixConcreteSegment(data) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getCoarseProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateFineTonOfReadyMixConcreteSegment(data) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getFineProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateCoarseTonOfAsphaltSegment(data) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getCoarseProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateFineTonOfAsphaltSegment(data) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getFineProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateCoarseTonOfConcreteProductsSegment(data) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ConcreteProducts) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getCoarseProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateFineTonOfConcreteProductsSegment(data) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ConcreteProducts) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getFineProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateCoarseTonOfRailwaySegment(data) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Railway) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getCoarseProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
	  calculateTotalTons(data) {
		  let totalTon = 0;
		  this.getCustomSegmentHeaders.forEach(items =>
			  data.forEach((item) => {
				  if (item.customerPlant.marketSegment.segment.id === items.id) {
					  item.allocationOfProductTypes.forEach((allocation) => {
						  if (allocation.productTypeId === this.getCoarseProductTypeId) {
							  totalTon += allocation.volume;
						  }
						  if (allocation.productTypeId === this.getFineProductTypeId) {
							  totalTon += allocation.volume;
						  }
						  if (allocation.productTypeId === this.getFileBaseProductTypeId) {
							  totalTon += allocation.volume;
						  }
					  });
				  }
			  }))
		  totalTon+=this.calculateCoarseTonOfReadyMixConcreteSegment(data);
		  totalTon+=this.calculateFineTonOfReadyMixConcreteSegment(data);
		  totalTon+=this.calculateCoarseTonOfAsphaltSegment(data);
		  totalTon+=this.calculateFineTonOfAsphaltSegment(data);
		  totalTon+=this.calculateCoarseTonOfConcreteProductsSegment(data);
		  totalTon+=this.calculateFineTonOfConcreteProductsSegment(data);
		  totalTon+=this.calculateCoarseTonOfRailwaySegment(data);
		  return this.roundNumber(totalTon, 0);
	  },
    calculateCoarseTonCustomSegment(data, id) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.id === id) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getCoarseProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateFineTonCustomSegment(data, id) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.id === id) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getFineProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateFillBaseTonCustomSegment(data, id) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.id === id) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getFileBaseProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateOtherTonCustomSegment(data, id) {
      let totalTon = 0;
      data.forEach((item) => {
        if (item.customerPlant.marketSegment.segment.id === id) {
          item.allocationOfProductTypes.forEach((allocation) => {
            if (allocation.productTypeId === this.getOtherProductTypeId) {
              totalTon += allocation.volume;
            }
          });
        }
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalPercent(data) {
      if (data.annualAggregatesProduction === 0) {
        return data.volumeSoldInMarketPercent;
      }
      if (data.volumeSoldInMarket === 0) {
        return 0;
      }
      return this.roundNumber(
        Math.ceil(
          ((this.calculateTotalTonPredeterminedMarketSegments(data) + this.calculateTotalTonOtherMarketSegment(data) - data.volumeSoldInMarket) /
            data.volumeSoldInMarket) *
            100,
          0,
        ),
      );
    },
    calculateTotalTon(data) {
      return this.roundNumber(
        Math.ceil(
          this.calculateTotalTonPredeterminedMarketSegments(data) + this.calculateTotalTonOtherMarketSegment(data) - data.volumeSoldInMarket,
          0,
        ),
      );
    },
    calculateTotalVolumeSoldInTheMarket() {
      let totalVolume = 0;
      this.aggregateProducers.forEach((item) => {
        totalVolume += item.volumeSoldInMarket;
      });
      return this.roundNumber(totalVolume, 0);
    },
    calculateTotalVolumeProduced() {
      let totalVolume = 0;
      this.aggregateProducers.forEach((item) => {
        totalVolume += item.annualAggregatesProduction;
      });
      return this.roundNumber(totalVolume, 0);
    },
    calculateTotalTonCoarseOfReadyMixConcreteSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonFineOfReadyMixConcreteSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getFineProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonCoarseOfAsphaltSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonFineOfAsphaltSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getFineProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonCoarseOfConcreteProductsSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ConcreteProducts) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonFineOfConcreteProductsSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ConcreteProducts) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getFineProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonCoarseOfRailwaySegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Railway) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonCoarseCustomSegment(id) {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.id === id) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonFineCustomSegment(id) {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.id === id) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getFineProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonFillBaseCustomSegment(id) {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.id === id) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getFileBaseProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonOtherCustomSegment(id) {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.id === id) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getOtherProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateOverallTon() {
      let overallTon = 0;
      this.aggregateProducers.forEach((item) => {
        overallTon +=
          item.volumeSoldInMarket - (this.calculateTotalTonPredeterminedMarketSegments(item) + this.calculateTotalTonOtherMarketSegment(item));
      });
      return this.roundNumber(overallTon, 0);
    },
    calculateTotalTonOfReadyMixConcreteSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ReadyMixConcrete) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId || allocation.productTypeId === this.getFineProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonOfAsphaltSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Asphalt) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId || allocation.productTypeId === this.getFineProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonOfConcreteProductsSegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.ConcreteProducts) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId || allocation.productTypeId === this.getFineProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonOfRailwaySegment() {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.marketSegmentType === MarketSegmentType.Railway) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (allocation.productTypeId === this.getCoarseProductTypeId) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonOfCustomSegment(id) {
      let totalTon = 0;
      this.aggregateProducers.forEach((data) => {
        data.customerSuppliers.forEach((item) => {
          if (item.customerPlant.marketSegment.segment.id === id) {
            item.allocationOfProductTypes.forEach((allocation) => {
              if (
                allocation.productTypeId === this.getCoarseProductTypeId ||
                allocation.productTypeId === this.getFineProductTypeId ||
                allocation.productTypeId === this.getFileBaseProductTypeId ||
                allocation.productTypeId === this.getOtherProductTypeId
              ) {
                totalTon += allocation.volume;
              }
            });
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
	  calculateTotalAllocatedTons() {
		  let totalTon = 0;
		  this.getCustomSegmentHeaders.forEach(items =>
			  this.aggregateProducers.forEach((data) => {
				  data.customerSuppliers.forEach((item) => {
					  if (item.customerPlant.marketSegment.segment.id === items.id) {
						  item.allocationOfProductTypes.forEach((allocation) => {
							  if (
								  allocation.productTypeId === this.getCoarseProductTypeId ||
								  allocation.productTypeId === this.getFineProductTypeId ||
								  allocation.productTypeId === this.getFileBaseProductTypeId ||
								  allocation.productTypeId === this.getOtherProductTypeId
							  ) {
								  totalTon += allocation.volume;
							  }
						  });
					  }
				  });
			  })
		  )
		  return this.roundNumber(totalTon, 0);
	  },
    onChangeOtherSegmentSupplyValue(item, data) {
      if (!parseFloat(data)) {
        data = 0;
      }
      item['isLoading' + item.id + 'Value'] = true;
      this.saveOtherSegmentSupplier({
        otherSegmentSupplyId: item.otherSegmentSupplyId,
        aggregatesProducerGeologyId: item.aggregatesProducerGeologyId,
        marketId: item.marketId,
        marketSegmentId: item.id,
        volume: this.roundNumber(data, 0),
      }).then((res) => {
        if (res) {
          item.otherSegmentSupplyId = res;
          item.volume = this.roundNumber(data, 0);
        }
        item['isLoading' + item.id + 'Value'] = false;
      });
    },
    calculateTotalOtherMarketSegmentByType(id) {
      let totalTon = 0;
      this.otherSegmentData.forEach((item) => {
        item.otherSegmentSupplies.forEach((otherSegmentSupply) => {
          if (otherSegmentSupply.id === id) {
            totalTon += otherSegmentSupply.volume;
          }
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonOtherMarketSegments() {
      let totalTon = 0;
      this.otherSegmentData.forEach((item) => {
        item.otherSegmentSupplies.forEach((otherSegmentSupply) => {
          totalTon += otherSegmentSupply.volume;
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonPredeterminedMarketSegments(data) {
      let totalTon = 0;
      data.customerSuppliers.forEach((item) => {
        item.allocationOfProductTypes.forEach((allocation) => {
          totalTon += allocation.volume;
        });
      });
      return this.roundNumber(totalTon, 0);
    },
    calculateTotalTonOtherMarketSegment(data) {
      let totalTon = 0;
      data.otherSegmentSupplies.forEach((otherSegmentSupply) => {
        totalTon += otherSegmentSupply.volume;
      });
      return this.roundNumber(totalTon, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.td-min-max-width {
  min-width: 150px;
  max-width: 300px;
}

.td-min-max-width-extra {
  min-width: 150px;
  max-width: 500px;
}

.split-menu-view {
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #3065A4;
	background-color: #DBEDFF !important;
	border-radius: 4px;
	box-shadow: none;
}
</style>
