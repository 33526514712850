<template>
  <div class="mr-multiselect-wrapper" @click.stop>
    <label>{{ label }}</label>
    <div class="mr-select" @click="openOptions()">
      <div class="mr-select__tags">
        <ul v-if="selected.length">
          <li v-for="(item, i) in selected" :key="i">
            {{ item.name }}
            <deleteItemBtn @click.stop.native="deleteItem(item)" class="deleteBtn" />
          </li>
        </ul>
      </div>
      <div class="options" v-if="active">
        <ul>
          <li v-for="option in options" :key="option.id" @click="selectedOptionItem(option)" :class="option.geologySubTypes ? 'sub-option' : ''">
            <input type="checkbox" v-model="option.selected" />
            {{ option.name }}
            <ul v-if="option.geologySubTypes">
              <li v-for="subOption in option.geologySubTypes" :key="subOption.id" @click.stop="selectedSubOptionItem(option, subOption)">
                <input type="checkbox" :checked="subOption.selected" />
                {{ subOption.name }}
                <deleteItemBtn v-if="subOption.entityType" @click.stop.native="deleteSubOption(option, subOption)" class="btn-delete-subOption" />
              </li>
              <li @click.stop v-if="option.id === currentField">
                <input type="text" v-model="newGeology" class="new-geology" />
                <btn :btnStyle="'save'" @click.native="saveNewGeology()" style="width: 60px">Save</btn>
              </li>
              <li @click.stop="currentField = option.id">
                + Other
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from './btn.vue';
import deleteItemBtn from './deleteItemBtn.vue';

export default {
  props: {
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    optionsType: {
      type: String,
    },
  },
  data() {
    return {
      active: false,
      currentField: null,
      newGeology: '',
    };
  },
  mounted() {
    document.addEventListener('click', this.outsideClickEventListener);
  },
  methods: {
    openOptions() {
      this.active = true;
    },
    outsideClickEventListener() {
      if (this.active) {
        this.$emit('saveOptions', this.optionsType);
        this.active = false;
      }
    },
    selectedOptionItem(option) {
      this.$emit('selectedOptionItem', option);
    },
    selectedSubOptionItem(option, subOption) {
      this.$emit('selectedSubOptionItem', { option, subOption });
    },
    deleteItem(item) {
      this.$emit('delete', { type: this.optionsType, item });
    },
    saveNewGeology() {
      this.$emit('saveNewGeology', { geologyTypeId: this.currentField, name: this.newGeology });
      this.currentField = null;
      this.newGeology = '';
    },
    deleteSubOption(option, subOption) {
      this.$emit('deleteSubOption', { option, subOption });
    },
  },
  computed: {
    selected() {
      const assembledArray = [];
      if (this.optionsType === 'optionsPlantType') {
        this.options.forEach((item) => {
          if (item.selected) {
            assembledArray.push(item);
          }
        });
      } else if (this.optionsType === 'optionsGeology') {
        this.options.forEach((item) => {
          item.geologySubTypes.forEach((el) => {
            if (el.selected) {
              assembledArray.push(el);
            }
          });
        });
        this.$store.commit('generalSettings/selectedGeologyOptions', assembledArray);
      }
      return assembledArray;
    },
  },
  components: {
    Btn,
    deleteItemBtn,
  },
};
</script>
