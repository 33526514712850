<template>
    <div class="general-location">
        <h2>Market Boundary</h2>
        <p>Find your location and select market boundary by clicking on the map</p>
        <div class="location-wrapp">
            <div class="layout" v-if="!marketId"></div>
            <Map-boundary :marketBoundary="boundary" :key="componentKey" @setBoundery="checkSteps" />
        </div>
    </div>
</template>

<script>
import MapBoundary from './MapBoundary.vue';

export default {
    data() {
        return {
            boundary: null,
            componentKey: 0,
        };
    },
    mounted() {
        const el = document.getElementsByClassName('general-location')[0];
        const elemHeight = el.offsetHeight;
        const elemToTop = el.getBoundingClientRect().top;
        const heightElemWithTop = elemHeight + elemToTop;

        window.addEventListener('scroll', () => {
            const top = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
            if (top > elemToTop && top < heightElemWithTop) {
                this.$store.commit('generalSettings/stepsProcess', { name: 'Boundary', status: true });
            } else {
                this.$store.commit('generalSettings/stepsProcess', { name: 'Boundary', status: false });
            }
        });

        if (this.$store.getters['generalSettings/getMarket'] && this.$store.getters['generalSettings/getMarket'].boundary) {
            this.boundary = this.$store.getters['generalSettings/getMarket'].boundary;
        }
    },
    methods: {
        checkSteps() {
            this.$store.commit('generalSettings/stepsDone', { name: 'Boundary', status: true });
            // if (this.form.marketName && this.form.country && this.form.year) {
            //   this.$store.commit('generalSettings/stepsDone', { name: 'Boundary', status: true });
            // } else {
            //   this.$store.commit('generalSettings/stepsDone', { name: 'Boundary', status: false });
            // }
        },
        forceRerender() {
            this.componentKey += 1;
        },
    },
    watch: {
        currentMarket() {
            this.forceRerender();
        },
    },
    computed: {
        marketId() {
            return this.$store.getters['generalSettings/getMarketId'];
        },
        currentMarket() {
            return this.$store.getters['generalSettings/getMarket'];
        },
    },
    components: {
        MapBoundary,
    },
};
</script>
<style lang="scss">
.location-wrapp {
    position: relative;

    .layout {
        background: rgba(247, 248, 252, 0.65);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        right: 0;
    }
}
</style>
