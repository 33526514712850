<template>
  <v-col class="align-center">
    <v-row justify="center">
      <img src="@/assets/dashboard/empty_dashboard.svg" />
    </v-row>
    <v-row justify="center" class="pt-8">
      <div class="title regalBlue--text">Looks like you have no Market Assessments yet</div>
    </v-row>
    <v-row justify="center" class="pt-5">
      <v-btn
        @click="$router.push({ name: 'generalSettings', params: { newSettings: true } })"
        :class="{ 'pointer-events-none': !isLoadedAvailableMarkets }"
        color="mangoTango"
        dark
      >
        <span class="forgot-password-text pr-2">Start Here</span>
        <img src="@/assets/icons/arrow_right.svg" />
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EmptyMarketAssessment',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('marketAssessment', {
      isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px !important;
  line-height: 28px;
}

.sub-title {
  font-size: 15px;
  line-height: 22px;
}
</style>
