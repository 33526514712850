<template>
	<base-layout :style="{ 'background-color': backgroundColor }">
		<v-container fluid>
			<v-row class="pb-5">
				<v-col cols="12">
					<v-row class="px-3">
						<span class="title regalBlue--text">Users</span>
						<v-spacer></v-spacer>
						<div class="d-flex">
							<div class="mr-3 pb-1"
								 :class="{ 'administration-companies-active-tab-border': isActiveStatus }"
							>
								<v-btn
									color="activeGreen"
									min-width="30"
									class="px-0"
									@click="onFilerUserStatus('active')"
								>
									<span class="white--text">A</span>
								</v-btn>
							</div>
							<div class="mr-3 pb-1"
								 :class="{ 'administration-companies-active-tab-border': isInactiveStatus }"
							>
								<v-btn
									color="disabledGray"
									min-width="30"
									class="px-0"
									@click="onFilerUserStatus('inactive')"
								>
									<span class="white--text">I</span>
								</v-btn>
							</div>

						</div>
						<v-btn @click="isDisplayCreateUserAccountDialog = true" :disabled="isLoadingData"
							   color="mangoTango">
							<img src="@/assets/icons/plus.svg" style="width: 18px" />
							<span class="white--text pl-2">ADD NEW</span>
						</v-btn>
					</v-row>
				</v-col>
			</v-row>
			<template v-if="isLoadingData">
				<v-row justify="center" class="pt-10">
					<v-progress-circular color="regalBlue" :width="10" :size="120" indeterminate></v-progress-circular>
				</v-row>
			</template>
			<template v-else>
				<v-row>
					<v-col cols="12" class="pb-15">
						<v-data-table v-model="selectedUsers" :style="{ 'background-color': backgroundColor }"
									  :class="{ 'administration-users-table-disabled-events': isLoadingEditDataToolbar || isDeletingUserAccount }"
									  :headers="administrationUserHeaders" :items="userData" :single-select="false"
									  :items-per-page="-1" :mobile-breakpoint="-1" :loading="isLoadingData"
									  class="administration-users-table" item-key="id" show-select hide-default-footer>
							<template v-slot:progress>
								<v-progress-linear color="regalBlue" indeterminate></v-progress-linear>
								<div class="pt-5 text-center">Loading... Please wait</div>
							</template>
							<template v-slot:body="{ items }">
								<tbody>
								<tr v-if="userData.length === 0 && !isLoadingData">
									<td colspan="13">
										<div class="text-center">No data</div>
									</td>
								</tr>
								<tr v-for="user in items" :key="user.id + getUniqueID"
									:class="{ 'pointer-events-none': isLoadingEditDataToolbar || isDeletingUserAccount }">
									<td>
										<v-checkbox v-model="selectedUsers" :value="user"
													style="margin: 0px; padding: 0px;" class="pl-2" color="mangoTango"
													hide-details />
									</td>
									<td style="min-width: 100px; max-width: 200px"
										class="market-name-text сapeCod--text text-no-wrap">
										<div class="d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    {{ (user.firstName + ' ' + user.lastName).trim() }}
                                                </span>
										</div>
									</td>
									<td style="min-width: 50px; max-width: 100px"
										class="year-name-text сapeCod--text text-no-wrap">
										<div class="d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    {{ user.id }}
                                                </span>
										</div>
									</td>
									<td style="min-width: 160px; max-width: 200px">
										<div class="d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    {{ user.role }}
                                                </span>
										</div>
									</td>
									<td style="min-width: 100px; max-width: 100px">
										<div class="d-flex align-center justify-center">
											<v-checkbox
												v-if="user.isDeletedStatus"
												v-model="user.isDeletedStatus"
												@click="isShowInactiveUserDialog = true, unUserInfo = user"
												style="margin: 0px; padding: 0px;" class="pl-2" color="mangoTango"
												hide-details
												readonly
											/>
											<v-checkbox
												v-else
												v-model="user.isDeletedStatus"
												@change="onUpdateUserAccount(user)"
												style="margin: 0px; padding: 0px;" class="pl-2" color="mangoTango"
												hide-details
											/>
										</div>

									</td>
									<td style="min-width: 100px; max-width: 200px"
										class="ownership-name-text сapeCod--text text-no-wrap">
										<div class="d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    {{ user.email }}
                                                </span>
											<v-spacer></v-spacer>
											<!--                                                 <v-tooltip content-class="regalBlue&#45;&#45;text" color="ghostWhite" top>-->
											<!--                          <template v-slot:activator="{ on, attrs }">-->
											<!--                            <v-btn-->
											<!--                              v-bind="attrs"-->
											<!--                              v-on="on"-->
											<!--                              @click="onEditUserAccount(index, user)"-->
											<!--                              :disabled="user.isDeletingUserAccount"-->
											<!--                              color="regalBlue"-->
											<!--                              small-->
											<!--                              text-->
											<!--                              fab-->
											<!--                            >-->
											<!--                              <img src="@/assets/administration_companies/edit_pencil.svg" />-->
											<!--                            </v-btn>-->
											<!--                          </template>-->
											<!--                          <span>In development</span>-->
											<!--                        </v-tooltip>-->
											<span class="px-1"></span>
											<v-btn
												@click="onEditUserAccount(user)"
												:disabled="user.isDeletingUserAccount"
												color="regalBlue"
												small text fab>
												<img src="@/assets/administration_companies/edit_pencil.svg" />
											</v-btn>
											<!--												<v-btn-->
											<!--													v-if="userRole === enumUserRole.Administrator || (userRole === enumUserRole.CompanyAdmin)"-->
											<!--													@click="onDeleteUserAccount(index, user)"-->
											<!--													:loading="user.isDeletingUserAccount"-->
											<!--													:color="user.isDeletingUserAccount ? 'mangoTango' : 'regalBlue'"-->
											<!--													small text fab>-->
											<!--													<img src="@/assets/administration_companies/trash.svg" />-->
											<!--												</v-btn>-->
										</div>
									</td>
								</tr>
								</tbody>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</template>
		</v-container>
		<v-dialog v-model="isDisplayCreateUserAccountDialog" width="600" persistent>
			<v-card>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn @click="
                        (isDisplayCreateUserAccountDialog = false),
                            (creatingUserAccountFirstName = null),
                            (creatingUserAccountLastName = null),
                            (creatingUserAccountEmail = null),
                            (creatingUserAccountPassword = null),
                            (creatingUserAccountShowPassword = false),
                            (creatingUserAccountRole = 'User')
                    " :disabled="isCreatingUserAccount" small text fab>
						<img src="@/assets/icons/close.svg" />
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid px-10>
						<v-row justify="center" class="pb-8">
							<span class="dialog-title regalBlue--text">Create user account</span>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">FIRST NAME</span>
								<v-text-field v-model="creatingUserAccountFirstName" :disabled="isCreatingUserAccount"
											  class="administration-users-editable-text-field" background-color="whiteLilac"
											  color="regalBlue" hide-details dense solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">LAST NAME</span>
								<v-text-field v-model="creatingUserAccountLastName" :disabled="isCreatingUserAccount"
											  class="administration-users-editable-text-field" background-color="whiteLilac"
											  color="regalBlue" hide-details dense solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">EMAIL</span>
								<v-text-field v-model="creatingUserAccountEmail" :disabled="isCreatingUserAccount"
											  class="administration-users-editable-text-field" background-color="whiteLilac"
											  color="regalBlue" hide-details dense solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">PASSWORD</span>
								<v-text-field
									@click:append="creatingUserAccountShowPassword = !creatingUserAccountShowPassword"
									v-model="creatingUserAccountPassword" :disabled="isCreatingUserAccount"
									:type="creatingUserAccountShowPassword ? 'text' : 'password'"
									class="administration-users-editable-text-field" background-color="whiteLilac"
									color="regalBlue" hide-details dense solo>
									<template v-slot:append>
                                        <span
											@click="creatingUserAccountShowPassword = !creatingUserAccountShowPassword"
											class="cursor-pointer">
                                            <img v-if="!creatingUserAccountShowPassword" src="@/assets/icons/eye.svg"
												 class="pl-1" />
                                            <img v-else src="@/assets/icons/eye_off.svg" class="pl-1" />
                                        </span>
									</template>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ROLE</span>
								<v-select v-model="creatingUserAccountRole" :items="creatingUserRoleAccountSteps"
										  :disabled="isCreatingUserAccount" class="administration-users-editable-text-field"
										  background-color="whiteLilac" color="regalBlue" item-color="regalBlue" hide-details
										  dense solo>
								</v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-container>
						<v-row justify="center" class="pb-5">
							<v-col cols="4">
								<v-btn @click="onCreateUserAccount()" :loading="isCreatingUserAccount"
									   color="mangoTango" block dark>
                                    <span class="create-user-account-create-button white--text">
                                        Create
                                    </span>
								</v-btn>
							</v-col>
						</v-row>
						<v-row justify="center" class="pb-5">
							<v-btn @click="
                                (isDisplayCreateUserAccountDialog = false),
                                    (creatingUserAccountFirstName = null),
                                    (creatingUserAccountLastName = null),
                                    (creatingUserAccountEmail = null),
                                    (creatingUserAccountPassword = null),
                                    (creatingUserAccountShowPassword = false),
                                    (creatingUserAccountRole = 'User')
                            " class="regalBlue--text" :disabled="isCreatingUserAccount" text>
                                <span class="create-user-account-cancel-button">
                                    Cancel
                                </span>
							</v-btn>
						</v-row>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="isDisplayEditUserAccountDialog" width="600" persistent>
			<v-card>
				<v-card-title>
					<v-spacer></v-spacer>
					<v-btn @click="
                        (isDisplayEditUserAccountDialog = false),
                            (editUserAccountFirstName = null),
                            (editUserAccountLastName = null),
                            (editUserAccountRole = 'User')
                    " :disabled="isCreatingUserAccount" small text fab>
						<img src="@/assets/icons/close.svg" />
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-container fluid px-10>
						<v-row justify="center" class="pb-8">
							<span class="dialog-title regalBlue--text">Edit user account</span>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">FIRST NAME</span>
								<v-text-field v-model="editUserAccountFirstName"
											  class="administration-users-editable-text-field" background-color="whiteLilac"
											  color="regalBlue" hide-details dense solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">LAST NAME</span>
								<v-text-field v-model="editUserAccountLastName"
											  class="administration-users-editable-text-field" background-color="whiteLilac"
											  color="regalBlue" hide-details dense solo></v-text-field>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="10">
								<span class="placeholder-text bermudaGray--text">ROLE</span>
								<v-select v-model="editUserAccountRole" :items="creatingUserRoleAccountSteps"
										  class="administration-users-editable-text-field"
										  background-color="whiteLilac" color="regalBlue" item-color="regalBlue" hide-details
										  dense solo>
								</v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions class="pt-4">
					<v-container>
						<v-row justify="center" class="pb-4">
							<v-col cols="4">
								<v-btn @click="onSaveEditUserAccount()" :loading="isCreatingUserAccount"
									   color="mangoTango" block dark>
                                    <span class="create-user-account-create-button white--text">
                                        Save
                                    </span>
								</v-btn>
							</v-col>
						</v-row>
						<v-row justify="center" class="pb-5">
							<v-btn @click="
                                (isDisplayEditUserAccountDialog = false),
                                    (editUserAccountFirstName = null),
                                    (editUserAccountLastName = null),
                                    (editUserAccountRole = 'User')
                            " class="regalBlue--text" text>
                                <span class="create-user-account-cancel-button">
                                    Cancel
                                </span>
							</v-btn>
						</v-row>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="isShowInactiveUserDialog" width="512" persistent>
			<v-card>
				<v-card-title class="headline regalBlue white--text">
					Inactive user
				</v-card-title>
				<v-card-text class="text-center pt-5">
					Are you sure you want to delete
					<span class="font-weight-bold">"{{ unUserInfo ? unUserInfo.firstName + ' ' + unUserInfo.lastName : '...' }}"</span>
					user?
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="onCloseUnCheckDialog()" color="mangoTango" text>
						<span>No</span>
					</v-btn>
					<span class="px-2"></span>
					<v-btn @click="onUnCheckGroup()" color="regalBlue" dark>
						<span class="white--text">Yes</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<edit-data-toolbar :data-count="selectedUsers.length" @cancel="onCancelEditData()" :onShowDelete="false">
		</edit-data-toolbar>
	</base-layout>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, findIndex } from 'lodash';

import { getAdministrationUserTableHeaders } from '@/core/helpers/administrationUserTableHeaderHelper';
import { UserRole } from '@/core/enums/userRole';
import { REGEX_EMPTY_STRING_PATTERN, REGEX_EMAIL_PATTERN } from '@/core/consts/const';

import EditDataToolbar from '@/components/editDataToolbar/EditDataToolbar.vue';

export default {
	name: 'AdministrationUser',
	components: {
		EditDataToolbar,
	},
	data() {
		return {
			isLoadingData: false,
			isLoadingEditDataToolbar: false,
			isActiveStatus: null,
			isInactiveStatus: null,
			isStatus: '',

			administrationUserHeaders: getAdministrationUserTableHeaders(),
			userData: [],
			userDataSec: [],
			selectedUsers: [],
			isShowInactiveUserDialog: false,
			unUserInfo: null,
			isDisplayEditUserAccountDialog: false,
			isDisplayCreateUserAccountDialog: false,
			isCreatingUserAccount: false,
			creatingUserAccountFirstName: null,
			creatingUserAccountLastName: null,
			creatingUserAccountEmail: null,
			creatingUserAccountPassword: null,
			creatingUserAccountShowPassword: false,
			creatingUserAccountRole: 'User',
			creatingUserRoleAccountSteps: ['CompanyAdmin', 'User'],

			editUserAccountFirstName: null,
			editUserAccountLastName: null,
			editUserAccountRole: null,
			editUserAccountId: null,

			isDeletingUserAccount: false,

			enumUserRole: UserRole,

			isInitedData: false,
			isInitedPageSize: false,
		};
	},
	mounted() {
		if (this.market) {
			this.init();
			this.isInitedData = true;
		}
	},
	watch: {
		isLoadedAvailableMarkets() {
			if (this.market === null) {
				this.$router.push({ path: '/' });
			}
		},
		userRole() {
			if (![this.enumUserRole.Administrator, this.enumUserRole.CompanyAdmin].includes(this.userRole)) {
				this.$router.push({ path: '/' });
			}
		},

		selectedUsers(data) {
			if (data.length) {
				this.setIsDisplayEditDataToolbar(true);
			} else {
				this.setIsDisplayEditDataToolbar(false);
			}
		},
		market() {
			if (!this.isInitedData) {
				this.init();
			}
		},
	},
	computed: {
		...mapGetters('account', {
			userRole: 'userRole',
			users: 'users',
		}),
		...mapGetters('marketAssessment', {
			isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
			market: 'market',
		}),
		backgroundColor() {
			return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
		},
		getUniqueID() {
			return uuidv4();
		},
	},
	methods: {
		onCloseUnCheckDialog() {
			this.isShowInactiveUserDialog = false
			this.unUserInfo = null
		},
		onCancelEditData() {
			this.selectedUsers = [];
		},
		async onDeleteEditData() {
			this.isLoadingEditDataToolbar = true;
			this.setIsLoadingEditDataToolbarDelete(true);
			const selectedUserIds = this.selectedUsers.map((item) => {
				return item.id;
			});
			const users = [];
			selectedUserIds.forEach((id) => {
				users.push(
					this.deleteUser({
						userId: id,
					}),
				);
			});
			await Promise.all([users]);
			for (let index = this.userData.length - 1; index >= 0; index -= 1) {
				if (selectedUserIds.includes(this.userData[index].id)) {
					this.userData.splice(index, 1);
				}
			}
			this.selectedUsers = [];
		},
		...mapMutations('editDataToolbar', {
			setIsDisplayEditDataToolbar: 'setIsDisplayEditDataToolbar',
			setIsLoadingEditDataToolbar: 'setIsLoadingEditDataToolbar',
			setIsLoadingEditDataToolbarDelete: 'setIsLoadingEditDataToolbarDelete',
			setIsDisabledEditDataToolbarDuplicateButton: 'setIsDisabledEditDataToolbarDuplicateButton',
		}),
		...mapActions('account', {
			getUsers: 'getUsers',
			register: 'register',
			deleteUser: 'deleteUser',
			editUser: 'editUser',
			updateUserStatus: 'updateUserStatus',
		}),
		async init() {
			this.isLoadingData = true;
			await this.getUsers();
			this.userData = this.initUserData();
			this.userDataSec = this.userData
			this.selectedUsers = [];
			this.isLoadingData = false;
			if (this.isActiveStatus && !this.isInactiveStatus) {
				this.userData = this.userDataSec
				const statusData = this.userData.filter(item => item.isDeletedStatus === true);
				this.userData = statusData
			}
			if (!this.isActiveStatus && this.isInactiveStatus) {
				this.userData = this.userDataSec
				const statusData = this.userData.filter(item => item.isDeletedStatus === false);
				this.userData = statusData
			}
			if (this.isActiveStatus && this.isInactiveStatus){
				this.userData = this.userDataSec
			}
			if (!this.isActiveStatus && !this.isInactiveStatus){
				this.userData = this.userDataSec
			}
		},
		initUserData() {
			return this.users.map((user) => {
				Object.assign(user, {
					isDeletingUserAccount: false,
					isDeletedStatus: !user.isDeleted
				});
				return user;
			});
		},

		onCreateUserAccount() {
			if (
				this.creatingUserAccountFirstName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountFirstName) &&
				this.creatingUserAccountLastName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountLastName) &&
				this.creatingUserAccountEmail &&
				REGEX_EMAIL_PATTERN.test(this.creatingUserAccountEmail) &&
				this.creatingUserAccountPassword &&
				this.creatingUserAccountPassword.length >= 8 &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountPassword)
			) {
				this.isCreatingUserAccount = true;
				this.register({
					firstName: this.creatingUserAccountFirstName,
					lastName: this.creatingUserAccountLastName,
					email: this.creatingUserAccountEmail,
					password: this.creatingUserAccountPassword,
					role: this.creatingUserAccountRole,
					companyId: this.userData.length ? this.userData[0].companyId : null,
				}).then((res) => {
					if (res) {
						this.userData.push({
							id: res,
							firstName: this.creatingUserAccountFirstName,
							lastName: this.creatingUserAccountLastName,
							email: this.creatingUserAccountEmail,
							role: this.creatingUserAccountRole,
							companyId: this.userData.length ? this.userData[0].companyId : null,
							isDeletingUserAccount: false,
						});

						this.creatingUserAccountFirstName = null;
						this.creatingUserAccountLastName = null;
						this.creatingUserAccountEmail = null;
						this.creatingUserAccountPassword = null;
						this.creatingUserAccountShowPassword = false;
						this.creatingUserAccountRole = 'User';

						this.isDisplayCreateUserAccountDialog = false;
					}

					this.isCreatingUserAccount = false;
				});
			} else {
				if (!this.creatingUserAccountFirstName || REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountFirstName)) {
					Vue.toasted.error('First name can not be empty');
				}
				if (!this.creatingUserAccountLastName || REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountLastName)) {
					Vue.toasted.error('Last name can not be empty');
				}
				if (!this.creatingUserAccountEmail) {
					this.isEmailHasError = true;
					Vue.toasted.error('Email field must not be empty');
				}
				if (this.creatingUserAccountEmail && !REGEX_EMAIL_PATTERN.test(this.creatingUserAccountEmail)) {
					this.isEmailHasError = true;
					Vue.toasted.error('Email not valid');
				}
				if (!this.creatingUserAccountPassword || REGEX_EMPTY_STRING_PATTERN.test(this.creatingUserAccountPassword)) {
					Vue.toasted.error('Password can not be empty');
				}
				if (this.creatingUserAccountPassword && this.creatingUserAccountPassword.length < 8) {
					Vue.toasted.error('Password must be at least more than 8 symbols');
				}
			}
		},

		onSaveEditUserAccount() {
			if (
				this.editUserAccountFirstName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountFirstName) &&
				this.editUserAccountLastName &&
				!REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountLastName)
			) {
				// this.iseditUserAccount = true;
				this.editUser({
					userId: this.editUserAccountId,
					firstName: this.editUserAccountFirstName,
					lastName: this.editUserAccountLastName,
					role: this.editUserAccountRole,

				}).then((res) => {
					if (res) {
						this.init();
						this.editUserAccountFirstName = null;
						this.editUserAccountLastName = null;
						this.editUserAccountRole = 'User';

						this.isDisplayEditUserAccountDialog = false;
					}

				});
			} else {
				if (!this.editUserAccountFirstName || REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountFirstName)) {
					Vue.toasted.error('First name can not be empty');
				}
				if (!this.editUserAccountLastName || REGEX_EMPTY_STRING_PATTERN.test(this.editUserAccountLastName)) {
					Vue.toasted.error('Last name can not be empty');
				}
			}
		},

		onEditUserAccount(user) {
			this.editUserAccountFirstName = user.firstName
			this.editUserAccountLastName = user.lastName
			this.editUserAccountRole = user.role
			this.editUserAccountId = user.id
			this.isDisplayEditUserAccountDialog = true
		},

		onUnCheckGroup() {
			this.onUpdateUserAccount(this.unUserInfo);
			this.isShowInactiveUserDialog = false
			this.unUserInfo = null
		},

		onUpdateUserAccount(user){
			this.updateUserStatus({
				userId: user.id,
				isDeleted: !user.isDeleted,
			}).then((res) => {
				if (res) {
					this.init();
				}
			});
		},

		onFilerUserStatus(status){
			this.isStatus = status
			if (this.isStatus === 'active') {
				this.isActiveStatus = !this.isActiveStatus
			}
			if (this.isStatus === 'inactive') {
				this.isInactiveStatus = !this.isInactiveStatus
			}
			if (this.isActiveStatus && !this.isInactiveStatus) {
				this.userData = this.userDataSec
				const statusData = this.userData.filter(item => item.isDeletedStatus === true);
				this.userData = statusData
			}
			if (!this.isActiveStatus && this.isInactiveStatus) {
				this.userData = this.userDataSec
				const statusData = this.userData.filter(item => item.isDeletedStatus === false);
				this.userData = statusData
			}
			if (this.isActiveStatus && this.isInactiveStatus){
				this.userData = this.userDataSec
			}
			if (!this.isActiveStatus && !this.isInactiveStatus){
				this.userData = this.userDataSec
			}
		},

		onDeleteUserAccount(index, user) {
			user.isDeletingUserAccount = true;
			this.isDeletingUserAccount = true;
			this.setIsLoadingEditDataToolbarDelete(true);
			this.deleteUser({ userId: user.id }).then((res) => {
				if (res) {
					const index = this.userData.indexOf(user);
					if (index > -1) {
						this.userData.splice(index, 1);
					}
				}
				user.isDeletingUserAccount = false;
				this.isDeletingUserAccount = false;
				this.setIsLoadingEditDataToolbarDelete(false);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.title {
	font-size: 32px !important;
	line-height: 38px !important;
	letter-spacing: -0.114286px !important;
}

.company-name {
	font-weight: 500 !important;
	font-size: 20px !important;
	line-height: 24px !important;
	letter-spacing: -0.114286px !important;
}

.company-user-count,
.company-user-divide,
.company-user-limit {
	font-size: 12px !important;
	line-height: 18px !important;
}

.dialog-title {
	font-weight: 500 !important;
	font-size: 32px !important;
	line-height: 38px !important;
}

.placeholder-text {
	font-weight: 500 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	letter-spacing: 1.12px !important;
}

.create-user-account-create-button {
	font-weight: 500 !important;
	font-size: 15px !important;
	line-height: 22px !important;
}

.create-user-account-cancel-button {
	font-size: 13px !important;
	line-height: 22px !important;
}

.hovered-row {
	background-color: #e0e7ff !important;
}
</style>
