export const getMakretFactorText = (marketFactor) => {
    switch (marketFactor) {
        case 0:
            return 'Size & Growth';
        case 1:
            return 'Margin & Pricing Trends';
        case 2:
            return 'Opportunity';
        case 3:
            return 'Threats & Risk';
        case 4:
            return 'Supply Consolidation';
        case 5:
            return 'Vertical Integration';
        case 6:
            return 'Demand Consolidation';
        default:
            return null;
    }
};
