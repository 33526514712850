<template>
    <div class="market-settings-block">
        <div class="layout" v-if="!marketId"></div>
        <group-market @checkSteps="updateMarketSettings" />
        <standard-split @checkSteps="updateMarketSettings" />
        <transport-mode v-if="reInitTrasportMode" @checkSteps="updateMarketSettings" />
    </div>
</template>

<script>
import GroupMarket from './GroupMarket.vue';
import StandardSplit from './StandardSplit.vue';
import TransportMode from './TransportMode.vue';

export default {
    data() {
        return {
            reInitTrasportMode: true,
            marketSettings: {
                plantType: false,
                standardSplit: false,
                transportMode: false,
            },
        };
    },
    watch: {
        marketId() {
            this.reInitTrasportMode = false;
            setTimeout(() => {
                this.reInitTrasportMode = true;
            }, 1);
        },
    },
    methods: {
        updateMarketSettings(data) {
            this.marketSettings[data.name] = data.status;
            this.checkSteps();
        },
        checkSteps() {
            if (this.marketSettings.plantType && this.marketSettings.standardSplit && this.marketSettings.transportMode) {
                this.$store.commit('generalSettings/stepsDone', { name: 'Market Settings', status: true });
            } else {
                this.$store.commit('generalSettings/stepsDone', { name: 'Market Settings', status: false });
            }
        },
    },
    computed: {
        marketId() {
            return this.$store.getters['generalSettings/getMarketId'];
        },
    },
    components: {
        GroupMarket,
        StandardSplit,
        TransportMode,
    },
};
</script>
<style lang="scss">
.layout {
    background: rgba(247, 248, 252, 0.65);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    right: 0;
}
</style>
