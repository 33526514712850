<template>
  <section>
    <v-card class="ml-3" min-width="600" max-width="800">
      <v-container fluid pa-10>
        <v-row justify="start" class="pb-6">
          <div class="title bismark--text">Please select segments that he will be working on</div>
        </v-row>
        <v-row justify="start">
          <div class="content bermudaGray--text">
            <span style="text-transform: uppercase !important;">Segments types</span>
          </div>
        </v-row>
        <v-row class="pb-8">
          <v-autocomplete
            @change="onUpdateOtherMarketSegments()"
            v-model="selectedOtherMarketSegmets"
            :items="otherMarketSegmetData"
            :disabled="isLoadingData || isUpdatingMarketSegments || isClosingEditSegmentType"
            :loading="isLoadingData || isUpdatingMarketSegments"
            class="other-segment-type-setting-editable-text-field pt-4"
            color="regalBlue"
            background-color="whiteLilac"
            item-color="regalBlue"
            label="Select"
            item-text="segment.displayName"
            item-value="id"
            return-object
            multiple
            dense
            chips
            solo
          >
            <template v-slot:selection="data">
              <v-chip
                @click:close="removeSelectedOtherMarketSegmet(data.index)"
                :input-value="data.item.selected"
                style="border-radius: 4px;"
                class="pattensBlue my-1"
                close
              >
                <div style="max-width: 638px" class="d-flex align-center">
                  <span class="text-truncate">{{ data.item.segment.displayName }}</span>
                </div>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <div :class="{ 'pointer-events-none': isUpdatingMarketSegments }" style="max-width: 680px" class="d-flex align-center">
                <v-checkbox
                  v-model="selectedOtherMarketSegmets"
                  :value="data.item"
                  style="margin: 0px; padding: 0px;"
                  class="pointer-events-none"
                  color="mangoTango"
                  hide-details
                />
                <span class="text-truncate"> {{ data.item.segment.displayName }}</span>
              </div>
            </template>
            <template v-slot:append-item>
              <div class="pt-2" :class="[isDisplayCreateOtherMarketSegment ? 'px-2' : 'px-6']">
                <template v-if="isDisplayCreateOtherMarketSegment">
                  <v-text-field
                    v-model="creatingOtherMarketSegmentName"
                    @blur="isDisplayCreateOtherMarketSegment = false"
                    @change="onCreateMarketSegment()"
                    :loading="isCreatingOtherMarketSegment"
                    class="other-segment-type-setting-editable-text-field"
                    background-color="whiteLilac"
                    color="regalBlue"
                    clearable
                    hide-details
                    solo
                    dense
                  ></v-text-field>
                </template>
                <template v-else>
                  <span @click="isDisplayCreateOtherMarketSegment = true" class="cursor-pointer font-weight-medium">
                    <img src="@/assets/market_segments/plus.svg" style="width: 10px" class="mr-2" />
                    <span class="regalBlue--text">ADD SEGMENT</span>
                  </span>
                </template>
              </div>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row justify="end">
          <v-btn @click="onCloseEditSegmentTypeSetting()" :loading="isClosingEditSegmentType" color="mangoTango" width="150px" dark>
            <span class="close-text white--text">Close</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

import { REGEX_EMPTY_STRING_PATTERN } from '@/core/consts/const';

export default {
  name: 'OtherSegmentTypeSetting',
  data() {
    return {
      isLoadingData: false,

      creatingOtherMarketSegmentName: null,
      isDisplayCreateOtherMarketSegment: false,
      isCreatingOtherMarketSegment: false,

      isUpdatingMarketSegments: false,

      isClosingEditSegmentType: false,

      otherMarketSegmetData: [],
      selectedOtherMarketSegmets: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('marketAssessment', {
      market: 'market',
    }),
    ...mapGetters('supplier', {
      defaultOtherMarketSegments: 'defaultOtherMarketSegments',
      otherMarketSegments: 'otherMarketSegments',
    }),
  },
  methods: {
    ...mapMutations('supplier', {
      setOtherMarketSegments: 'setOtherMarketSegments',
    }),
    ...mapActions('supplier', {
      getDefaultOtherMarketSegments: 'getDefaultOtherMarketSegments',
      getOtherMarketSegments: 'getOtherMarketSegments',
      addOtherMarketSegment: 'addOtherMarketSegment',
      createOtherMarketSegment: 'createOtherMarketSegment',
      deleteOtherMarketSegment: 'deleteOtherMarketSegment',
    }),
    async init() {
      this.isLoadingData = true;
      await this.getDefaultOtherMarketSegments({ marketId: this.market.id });
      await this.getOtherMarketSegments({ marketId: this.market.id });
      this.otherMarketSegmetData = this.initOtherMarketSegmetData();
      this.selectedOtherMarketSegmets = this.otherMarketSegments;
      this.isLoadingData = false;
    },
    initOtherMarketSegmetData() {
      this.defaultOtherMarketSegments.forEach((item) => {
        item.id = item.segmentId;
      });
      const otherMarketSegmentIds = this.otherMarketSegments.map((otherMarketSegment) => {
        return otherMarketSegment.segmentId;
      });
      const defaultOtherMarketSegments = [];
      this.defaultOtherMarketSegments.forEach((defaultOtherMarketSegment) => {
        if (!otherMarketSegmentIds.includes(defaultOtherMarketSegment.segmentId)) {
          defaultOtherMarketSegments.push(defaultOtherMarketSegment);
        }
      });
      return this.otherMarketSegments.concat(defaultOtherMarketSegments);
    },
    removeSelectedOtherMarketSegmet(index) {
      this.isUpdatingMarketSegments = true;
      this.deleteOtherMarketSegment({
        marketSegmentId: this.selectedOtherMarketSegmets[index].id,
        marketId: this.market.id,
      }).then((res) => {
        if (res) {
          this.selectedOtherMarketSegmets.splice(index, 1);
          this.setOtherMarketSegments(cloneDeep(this.selectedOtherMarketSegmets));
          this.otherMarketSegmetData = this.initOtherMarketSegmetData();
        }
        this.isUpdatingMarketSegments = false;
      });
    },
    onCreateMarketSegment() {
      if (this.creatingOtherMarketSegmentName && !REGEX_EMPTY_STRING_PATTERN.test(this.creatingOtherMarketSegmentName)) {
        this.isCreatingOtherMarketSegment = true;
        this.createOtherMarketSegment({
          marketSegmentId: null,
          marketId: this.market.id,
          displayName: this.creatingOtherMarketSegmentName,
        }).then((res) => {
          if (res) {
            this.otherMarketSegmetData.push(res);
            this.selectedOtherMarketSegmets.push(res);
            this.isDisplayCreateOtherMarketSegment = false;
            this.creatingOtherMarketSegmentName = null;
          }
          this.isCreatingOtherMarketSegment = false;
        });
      } else {
        Vue.toasted.error('Other market segment name can not be empty');
      }
    },
    async onUpdateOtherMarketSegments() {
      if (!this.isUpdatingMarketSegments) {
        this.isUpdatingMarketSegments = true;
        const otherMarketSegmentIds = this.otherMarketSegments.map((item) => {
          return item.id;
        });
        const selectedOtherMarketSegmetIds = this.selectedOtherMarketSegmets.map((item) => {
          return item.id;
        });
        if (JSON.stringify(otherMarketSegmentIds) === JSON.stringify(selectedOtherMarketSegmetIds)) {
          this.isUpdatingMarketSegments = false;
          return;
        }
        const deleteOtherMarketSegmetIds = otherMarketSegmentIds.filter((id) => {
          return !selectedOtherMarketSegmetIds.includes(id);
        });
        const saveOtherMarketSegmetIds = selectedOtherMarketSegmetIds.filter((id) => {
          return !otherMarketSegmentIds.includes(id);
        });
        const otherMarketSegments = [];
        deleteOtherMarketSegmetIds.forEach((id) => {
          otherMarketSegments.push(
            this.deleteOtherMarketSegment({
              marketSegmentId: id,
              marketId: this.market.id,
            }),
          );
        });
        await Promise.all(otherMarketSegments);
        const selectedOtherMarketSegmets = [];
        saveOtherMarketSegmetIds.forEach((id) => {
          selectedOtherMarketSegmets.push(
            this.addOtherMarketSegment({
              segmentId: id,
              marketId: this.market.id,
            }),
          );
        });
        await Promise.all(selectedOtherMarketSegmets);
        await this.getOtherMarketSegments({ marketId: this.market.id });
        this.otherMarketSegmetData = this.initOtherMarketSegmetData();
        this.selectedOtherMarketSegmets = this.otherMarketSegments;
        this.isUpdatingMarketSegments = false;
      }
    },
    onCloseEditSegmentTypeSetting() {
      this.isClosingEditSegmentType = true;
      setTimeout(() => {
        this.isClosingEditSegmentType = false;
        this.$emit('close-edit-other-segment-type-setting');
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.content {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

.close-text {
  font-size: 15px !important;
  line-height: 22px !important;
}
</style>
