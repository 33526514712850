export const getApplicationsColors = (application) => {
    if (!application) {
        return {
            textColor: 'black',
            backgroundColor: 'white',
        };
    }
    switch (application.id) {
        case 1:
            return {
                textColor: 'mangoTango',
                backgroundColor: 'sazerac',
            };
        case 2:
            return {
                textColor: 'greenBlue',
                backgroundColor: 'azureishWhite',
            };
        case 3:
            return {
                textColor: 'burgundy',
                backgroundColor: 'ACbg',
            };
        case 4:
            return {
                textColor: 'burgundy',
                backgroundColor: 'bizarre',
            };
        case 5:
            return {
                textColor: 'concrete',
                backgroundColor: 'concrete',
            };
        case 6:
            return {
                textColor: 'white',
                backgroundColor: 'white',
            };
        default:
            return {
                textColor: 'black',
                backgroundColor: 'white',
            };
    }
};
