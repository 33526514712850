<template>
    <section>
        <div class="d-flex justify-end pb-5">
            <v-btn @click="exportPDF()" :disabled="!isInitedData || isLoadingData" color="mangoTango">
                <span class="white--text">Print</span>
            </v-btn>
        </div>
        <!--
    <div v-show="!isInitedData || isLoadingData" class="data-consolidation-wrapper d-flex justify-center align-center">
      <v-progress-circular color="regalBlue" :width="10" :size="150" indeterminate></v-progress-circular>
    </div>
    -->
        <div ref="reportstatic" class="data-consolidation-wrapper d-flex justify-center align-center" frameborder="0"></div>
    </section>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import axios from 'axios';
import * as pbi from 'powerbi-client';

export default {
    name: 'DataConsolidation',
    data() {
        return {
            isInitedData: false,
            isLoadingData: false,

            token: '',
            staticReportUrl: '',
            staticReportContainer: null,
            report: null,
        };
    },
    mounted() {
        if (this.market) {
            this.isInitedData = true;
            this.get();
        }
    },
    watch: {
        market() {
            this.isInitedData = true;
            this.get();
        },
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
    },
    methods: {
        exportPDF() {
            var report = powerbi.get(this.$refs.reportstatic);

            report.print();
        },
        get() {
            this.isLoadingData = false;
            this.isInitedData = true;
            var models = pbi.models;
            this.staticReportContainer = this.$refs.reportstatic;

            axios.get(`Embedded/GetReportConfig?marketId=${this.market.id}`).then((resp) => {
                var reportLoadConfig = {
                    type: 'report',
                    tokenType: models.TokenType.Embed,
                    accessToken: resp.data.accessToken,
                    embedUrl: resp.data.embedUrl,
                    settings: {
                        layoutType: models.LayoutType.Custom,
                        customLayout: {
                            pageSize: {
                                type: models.PageSizeType.Custom,
                                width: 1910,
                                height: 900,
                            },
                            displayOption: models.DisplayOption.FitToPage,
                        },
                        filterPaneEnabled: false,
                        navContentPaneEnabled: true,
                        /*
            visualSettings: {
              visualHeaders: [
                {
                  settings: {
                    visible: false,
                  },
                  
                },
              ],
            },
            */
                    },
                };

                /*
         visualSettings: {
              visualHeaders: [
                {
                  settings: {
                    visible: false,
                  },
                 
                },
              ],
            },
        */

                var report = powerbi.embed(this.staticReportContainer, reportLoadConfig);

                report.on('loaded', function(event) {
                    this.isLoadingData = false;
                    report.bookmarksManager.getBookmarks().then(function(bookmarks) {
                        bookmarks.forEach((bookmark) => {
                            resp.data.bookmarks.forEach((bookmarkToHide) => {
                                if (bookmark.displayName === bookmarkToHide) {
                                    report.bookmarksManager.apply(bookmark.name);
                                }
                            });
                            if (bookmark.children) {
                                bookmark.children.forEach((children) => {
                                    resp.data.bookmarks.forEach((bookmarkToHide) => {
                                        if (children.displayName === bookmarkToHide) {
                                            report.bookmarksManager.apply(children.name);
                                        }
                                    });
                                });
                            }
                        });

                        report.getPages().then((pages) => {
                            pages[0].setActive();
                        });
                    });
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.data-consolidation-wrapper {
    min-width: 1600px !important;
    height: 90vh;
    // width: calc(100vh * 1.56);
    background-color: #e6e6e6;
    border: 1px solid #e0e7ff;
}
</style>
