<template>
	<div class="d-flex justify-md-space-between" style="width: 100%">

		<div class="input-location" @click.stop :class="classEl">
			<label v-if="labelLon">{{ labelLon }}</label>
			<span class="lan_lot" v-if="latEdit" @click="latEdit = false, lonEdit = false, wrongLatLon = false">
				{{latitude}}
			</span>
			<input
				:class="{ dangerBorder: lonValidation }"
				type="text"
				:placeholder="placeholder"
				@input="searchLocation"
				@blur="onBlur()"
				v-model="searchLon"
				@keypress="isNumber($event)"
			/>
		</div>
		<div class="input-location" @click.stop :class="classEl" >
			<label v-if="labelLat">{{ labelLat }}</label>
			<span class="lan_lot" v-if="lonEdit" @click="latEdit = false, lonEdit = false, wrongLatLon = false">
				{{longitude}}
			</span>
			<input
				:class="{ dangerBorder: latValidation }"
				type="text"
				:placeholder="placeholder"
				@input="searchLocation"
				@blur="onBlur()"
				v-model="searchLat"
				@keypress="isNumber($event)"
			/>
		</div>
	</div>

</template>

<script>
export default {
	props: {
		labelLat: {
			type: String,
		},
		labelLon: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		classEl: {
			type: String,
		},
		latitude: {
			type: String,
		},
		longitude: {
			type: String,
		}
	},
	data() {
		return {
			wrongLatLon: '',
			data: null,
			searchLat: '',
			searchLon: '',
			show: false,
			latEdit: true,
			lonEdit: true,
			locations: [],
			lonValidation: false,
			latValidation: false,
		};
	},

	watch: {
		wrongLatLon: function() {
			this.$emit("wrong-lat-lon", this.wrongLatLon);
		},
		searchLat: function() {
			this.$emit("search-lat", this.searchLat);
		},
		searchLon: function() {
			this.$emit("search-lon", this.searchLon);
		}
	},


	mounted() {
		document.addEventListener('click', this.outsideClickEventListener);
	},

	methods: {
		isNumber(evt) {
			const charCode = evt.which ? evt.which : evt.keyCode;
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46 &&
				charCode !== 45
			) {
				evt.preventDefault();
			}
		},
		outsideClickEventListener() {
			if (this.show) {
				this.show = false;
			}

		},

		searchLocation() {
			this.wrongLatLon = false
			if (!this.latEdit && this.searchLat.length > 3 && this.searchLon.length > 3){
				this.wrongLatLon = true
			}
			this.latValidation = true
			this.lonValidation = true

			if(this.searchLat.includes('.') && this.searchLat.length > 3 && this.searchLon.includes('.') && this.searchLon.length > 3) {
				this.$store.dispatch('geocoding/searchLocation', this.searchLat + ',' + this.searchLon).then(() => {
					this.center = this.$store.getters['geocoding/getLocations'][0].center;
					this.data = this.$store.getters['geocoding/getLocations'][0];
					this.$emit('setLocation', this.data);
				});
				this.latValidation = false
				this.lonValidation = false
				if (this.data == null){
					this.latValidation = true
					this.lonValidation = true
				}

			} else {
				if(this.searchLat.includes('.') && this.searchLat.length > 3){
					this.latValidation = false
				}
				if(this.searchLon.includes('.') && this.searchLon.length > 3){
					this.lonValidation = false
				}
			}


		},

		getLocation(location) {
			this.$emit('setLocation', location);
			this.show = false;
		},
		onBlur() {
			this.$emit('onBlur');
			if (!this.latEdit){
				this.latEdit = false
				this.lonEdit = false
			}
		},
	},
};
</script>

<style lang="scss">

.lan_lot{
	position: absolute;
	font-size: 13px;
	top: 25px;
	padding-left: 17px;
	left: 0;
	width: 100%;
	height: 38px;
	display: flex;
	align-items: center;
	background-color: #f9faff;
}

.dangerBorder{
	border: 1px solid red !important;
}
</style>
