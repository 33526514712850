<template>
    <aggregate-info-wrapper @close="close">
        <template slot="title">{{ getStringWithoutUUID(popup.name) }}</template>
        <template slot="supply">{{ toFormatNumber(popup.selfSupply) }}</template>
        <template slot="group">{{ popup.groupName }}</template>
        <template slot="total-vol-title">Total prod. vol.</template>
        <template slot="total-vol">
            <b>{{ toFormatNumber(popup.totalProdVol) }}</b
            >t
        </template>

        <template slot="body">
            <div class="d-flex gap-5">
                <v-progress-circular :rotate="-90" :size="56" :value="popup.volSoldPer" :width="12" color="#fc8738" />
                <div class="text-no-wrap">
                    <span>Vol. sold in mkt.</span>
                    <p class="text-no-wrap">
                        <b>{{ toFormatNumber(popup.volSoldPer) }}</b>
                        % ({{ toFormatNumber((popup.totalProdVol / 100) * popup.volSoldPer) }} t)
                    </p>
                </div>
            </div>

            <template v-if="popup.topCustomers.length">
                <v-row dense>
                    <v-col>
                        <span class="top-customers-title bermudaGray--text">TOP 10 CUSTOMERS IN THE MARKET</span>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col v-for="topCustomer in popup.topCustomers" :key="topCustomer.id" cols="12">
                        <div class="d-flex align-center">
                            <img src="@/assets/map/map_point.svg" style="max-width: 25px; width: 100%" />
                            <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="text-truncate pl-3">
                                        {{ getStringWithoutUUID(topCustomer.customerPlant.investigatedCompany.name) }}
                                    </span>
                                </template>
                                <span>
                                    {{ getStringWithoutUUID(topCustomer.customerPlant.investigatedCompany.name) }}
                                </span>
                            </v-tooltip>
                            <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="text-truncate text-no-wrap pl-1">
                                        {{ topCustomer.customerPlant.marketSegment.segment.displayName }}
                                    </span>
                                </template>
                                <span> {{ topCustomer.customerPlant.marketSegment.segment.displayName }} </span>
                            </v-tooltip>
                            <span class="px-1">-</span>
                            <span class="text-no-wrap font-weight-bold">
                                {{
                                    toFormatNumber(
                                        ((popup.totalProdVol / 100) * popup.volSoldPer) / 100 === 0
                                            ? 0
                                            : roundNumber(
                                                  calculateAllocationOfProductTypes(topCustomer.allocationOfProductTypes) / (((popup.totalProdVol / 100) * popup.volSoldPer) / 100),
                                                  0,
                                              ),
                                    )
                                }}%
                            </span>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </template>
    </aggregate-info-wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { REGEX_INCLUDE_UUID_PATTERN } from '@/core/consts/const';

import aggregateInfoWrapper from './aggregateInfoWrapper.vue';

export default {
    components: {
        aggregateInfoWrapper,
    },
    props: {
        popup: {
            type: Object,
        },
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
    },
    methods: {
        roundNumber(value, power = 2) {
            return Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
        },
        formatNumber(number, fraction, separator = ' ') {
            let data = number.toLocaleString('en-US');
            data = data.replace(/\./, 'F');
            data = data.replace(/,/g, 'S');
            data = data.replace(/F/, fraction);
            data = data.replace(/S/g, separator);
            return data;
        },
        toFormatNumber(data) {
            return this.formatNumber(data, this.market.separatorType === 0 ? '.' : ',');
        },
        isIncludeUUID(data) {
            return data.match(REGEX_INCLUDE_UUID_PATTERN);
        },
        isIncludeCopyPlantType(data) {
            return data.includes('_copy');
        },
        substringCopyAfterIncludeUUID(data) {
            return data.substr(data.indexOf('_'));
        },
        getStringWithoutUUID(data) {
            if (data && !this.isIncludeUUID(data)) {
                return data;
            } else if (data && this.isIncludeUUID(data) && this.isIncludeCopyPlantType(data)) {
                return this.substringCopyAfterIncludeUUID(data);
            } else {
                return '-';
            }
        },
        calculateAllocationOfProductTypes(data) {
            let value = 0;

            data.forEach((item) => {
                value += item.volume;
            });

            return value;
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.top-customers-title {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}
</style>
