<template>
    <div class="input-chip-wrapper">
        <label>{{ label }}</label>
        <div class="input-chip">
            <input type="text" :value="value" :disabled="disabled" @input="$emit('input', $event.target.value)" @blur="$emit('focused')" />
            <div class="chip">
                <span>{{chip}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
        },
        value: {

        },
        chip: {
            type: String,
        },
        disabled: {
            type: Boolean,
        },
    },
    data() {
        return {};
    }
};
</script>
