<template>
    <base-layout :style="{ 'background-color': backgroundColor }" :fill-height="availableMarkets.length === 0">
        <v-container fluid>
            <template v-if="availableMarkets.length === 0">
                <empty-market-assessment></empty-market-assessment>
            </template>
            <template v-else>
                <v-row>
                    <v-col cols="12">
                        <div class="title regalBlue--text">Market Assessments</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-tabs v-model="currentTabIndex" background-color="whiteLilac" color="mangoTango" slider-color="mangoTango">
                            <v-tab
                                v-for="marketAssessment in marketAssessments"
                                :key="marketAssessment"
                                :style="{ 'background-color': backgroundColor }"
                                :disabled="isLoadingData || isLoadingEditDataToolbar"
								:value="marketAssessment"
                                class="tab-slider-bottom-highlighter"
                                ripple
                            >
                                {{ marketAssessment }}
                            </v-tab>
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="$router.push({ name: 'generalSettings', params: { newSettings: true } })"
                                :disabled="isLoadingData || isLoadingEditDataToolbar"
                                color="mangoTango"
								v-if="currentTabIndex === 0 || currentTabIndex === 1"
                            >
                                <img src="@/assets/icons/plus.svg" style="width: 18px" />
                                <span class="white--text pl-2">ADD NEW</span>
                            </v-btn>
                        </v-tabs>

						<v-tabs-items v-model="currentTabIndex">
							<v-tab-item v-for="marketAssessment in marketAssessments" :key="marketAssessment" :style="{ 'background-color': backgroundColor }">
								<template v-if="marketAssessment === 'My Markets' || marketAssessment === 'Archived'">
									<v-data-table
										v-model="selectedMarketAssessments"
										:style="{ 'background-color': backgroundColor }"
										:class="{ 'market-assessments-table-disabled-events': isLoadingEditDataToolbar }"
										:headers="marketAssessmentHeaders"
										:items="marketAssessmentData"
										:single-select="!isAdminRole"
										:items-per-page="-1"
										:mobile-breakpoint="-1"
										:loading="isLoadingData"
										class="market-assessments-table pt-10 pb-12"
										item-key="id"
										show-select
										hide-default-footer
									>
										<template v-slot:progress>
											<v-progress-linear color="regalBlue" indeterminate></v-progress-linear>
											<div class="pt-5 text-center">Loading... Please wait</div>
										</template>
										<template v-slot:body="{ items }">
											<tbody>
											<tr v-if="marketAssessmentData.length === 0 && !isLoadingData">
												<td colspan="13">
													<div class="text-center">No data</div>
												</td>
											</tr>
											<tr v-for="(item, index) in items" :key="item.id + getUniqueID" :class="{ 'pointer-events-none': isLoadingEditDataToolbar }">
												<td>
													<v-checkbox
														v-model="selectedMarketAssessments"
														:value="item"
														style="margin: 0px; padding: 0px;"
														class="pl-2"
														color="mangoTango"
														hide-details
														:disabled="!isMarketOwner(item.id) && !isMarketContribution(item.id) && !isAdminRole"
													/>
												</td>
												<td style="min-width: 100px; max-width: 200px" class="market-name-text сapeCod--text text-no-wrap">
													<div class="d-flex align-center justify-center">
                                                        <span @click="onSelectMarket(item)" class="cursor-pointer text-truncate">
                                                            {{ item.name }}
                                                        </span>
													</div>
												</td>
												<td style="min-width: 50px; max-width: 100px" class="year-name-text сapeCod--text text-no-wrap">
													<div class="d-flex align-center justify-center">
                                                        <span class="text-truncate">
                                                            {{ item.year }}
                                                        </span>
													</div>
												</td>
												<td style="min-width: 160px; max-width: 200px">
													<div class="d-flex align-center justify-center">
														<v-select
															:value="item.status"
															@change="onChangeMarketStatus(item, $event)"
															:items="filterMarketStatuses()"
															:loading="item.isChangeMarketStatusLoading"
															:disabled="(item.isChangeMarketStatusLoading || !isMarketOwner(item.id)) && !isAdminRole"
															:background-color="statusColors(item.status.value).backgroundColor"
															class="market-status-select"
															color="regalBlue"
															item-color="regalBlue"
															item-text="name"
															item-value="value"
															return-object
															hide-details
															dense
															solo
														>
															<template slot="selection" slot-scope>
                                                                <span :class="statusColors(item.status.value).textColor + '--text'">
                                                                    {{ item.status.name }}
                                                                </span>
															</template>
														</v-select>
													</div>
												</td>
												<td style="min-width: 100px; max-width: 200px" class="ownership-name-text сapeCod--text text-no-wrap">
													<div class="d-flex align-center justify-center">
														<span v-if="item.owner" class="text-truncate"> {{ item.owner.firstName }} {{ item.owner.lastName }} </span>
													</div>
												</td>
												<td
													@click="editMarketCollaborators(index, item)"
													@mouseover="item.isDisplayEditCollaboratorsIcon = true"
													@mouseleave="item.isDisplayEditCollaboratorsIcon = false"
													style="max-width: 900px"
													:class="[
														'cursor-pointer hovered-collaborators normal-name-text сapeCod--text text-no-wrap',
														{
															'edit-collaborators-disable': !isMarketOwner(item.id) && !isAdminRole
														}
                                                    ]"
												>
													<div class="d-flex align-center">
														<v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
															<template v-slot:activator="{ on, attrs }">
																<img
																	v-show="item.collaborators.length"
																	v-bind="attrs"
																	v-on="on"
																	src="@/assets/market_assessments/collaborators.svg"
																	style="width: 25px"
																	class="pr-2"
																/>
																<span class="text-truncate">
                                                                    {{ collaboratorsList(item.collaborators) }}
                                                                </span>
															</template>
														</v-tooltip>
														<div v-show="!item.isDisplayEditCollaboratorsIcon" style="width: 20px"></div>
														<img v-show="item.isDisplayEditCollaboratorsIcon" src="@/assets/icons/edit_pencil.svg" style="width: 20px" class="pl-2" />
													</div>
													<v-dialog v-model="item.isDisplayEditCollaboratorsDialog" persistent max-width="600px">
														<v-card>
															<v-card-title>
																<v-spacer></v-spacer>
																<v-btn
																	@click="item.isDisplayEditCollaboratorsDialog = false"
																	:disabled="isEditMarketCollaboratorsLoading"
																	small
																	text
																	fab
																>
																	<img src="@/assets/icons/close.svg" />
																</v-btn>
															</v-card-title>
															<v-card-text>
																<v-container px-10>
																	<v-row justify="center" class="pb-5">
																		<span class="edit-collaborators-title regalBlue--text">Market access</span>
																	</v-row>
																	<v-row justify="center">
																		<v-col>
																			<span class="edit-collaborators-add-people-text casper--text">ADD PEOPLE</span>
																			<v-select
																				v-model="item.selectedCollaborator"
																				@change="onSelectCollaborator(index), (item.selectedCollaborator = {})"
																				:items="filterCollaborators(item.nonSavedCollaborators)"
																				:disabled="(isEditMarketCollaboratorsLoading || !isMarketOwner(item.id)) && !isAdminRole"
																				:loading="isEditMarketCollaboratorsLoading"
																				background-color="antiFlashWhite"
																				class="market-assessments-collaborator-select"
																				color="regalBlue"
																				item-color="regalBlue"
																				item-value="id"
																				return-object
																				hide-details
																				dense
																				solo
																			>
																				<template slot="selection" slot-scope="data">
                                                                                    <span class="white--text"
																					>{{ data.item.firstName }} {{ data.item.lastName }} - {{ data.item.email }}</span
																					>
																				</template>
																				<template slot="item" slot-scope="data">
																					{{ data.item.firstName }} {{ data.item.lastName }} - {{ data.item.email }}
																				</template>
																			</v-select>
																		</v-col>
																	</v-row>
																	<v-row justify="center">
																		<v-col>
																			<span class="edit-collaborators-who-has-access-text casper--text">WHO HAS ACCESS</span>
																			<non-button-block v-if="item.nonSavedCollaborators.length === 0" color="gallery" :is-fill-width="true">
																				<span class="darkLiver--text">No selected collaborators</span>
																			</non-button-block>
																			<template v-if="!item.isChangedCollaboratorsState">
																				<div
																					v-for="(collaborator, collaboratorIndex) in item.nonSavedCollaborators"
																					:key="collaborator.id + getUniqueID"
																					class="py-2"
																				>
																					<template v-if="item.owner.id === collaborator.id">
																						<v-row no-gutters style="height: 36px" class="d-flex align-center">
																							<v-col cols="6" class="text-left text-truncate pl-4 pr-2">
                                                                                                <span class="edit-collaborators-name darkLiver--text">
                                                                                                    {{ collaborator.firstName }} {{ collaborator.lastName }} (Owner)
                                                                                                </span>
																							</v-col>
																							<v-col cols="6" class="text-right text-truncate pl-2 pr-4">
                                                                                                <span class="edit-collaborators-email darkLiver--text">{{
																										collaborator.email
																									}}</span>
																							</v-col>
																						</v-row>
																					</template>
																					<template v-else>
																						<v-row
																							@mouseover="
                                                                                                (collaborator.isDisplayRemoveCollaboratorIcon = true),
                                                                                                    (item.isChangedCollaboratorsState = true),
                                                                                                    updateCollaboratorsState(index)
                                                                                            "
																							@mouseleave="
                                                                                                (collaborator.isDisplayRemoveCollaboratorIcon = false),
                                                                                                    (item.isChangedCollaboratorsState = true),
                                                                                                    updateCollaboratorsState(index)
                                                                                            "
																							no-gutters
																							style="border-radius: 4px; height: 36px"
																							class="d-flex align-center"
																							:class="collaborator.isDisplayRemoveCollaboratorIcon ? 'pattensBlue' : 'gallery'"
																						>
																							<v-col cols="6" class="text-left text-truncate pl-4 pr-2">
                                                                                                <span class="edit-collaborators-name darkLiver--text">
                                                                                                    {{ collaborator.firstName }} {{ collaborator.lastName }}
                                                                                                </span>
																							</v-col>
																							<v-col cols="6" class="text-right text-truncate pl-2 pr-4">
                                                                                                <span class="edit-collaborators-email darkLiver--text">{{
																										collaborator.email
																									}}</span>
																							</v-col>
																						</v-row>
																						<v-btn
																							@mouseover="
                                                                                                (collaborator.isDisplayRemoveCollaboratorIcon = true),
                                                                                                    (item.isChangedCollaboratorsState = true),
                                                                                                    updateCollaboratorsState(index)
                                                                                            "
																							@mouseleave="
                                                                                                (collaborator.isDisplayRemoveCollaboratorIcon = false),
                                                                                                    (item.isChangedCollaboratorsState = true),
                                                                                                    updateCollaboratorsState(index)
                                                                                            "
																							v-show="collaborator.isDisplayRemoveCollaboratorIcon"
																							@click="onRemoveCollaborator(index, collaboratorIndex)"
																							:disabled="isEditMarketCollaboratorsLoading"
																							style="position: absolute; right: 0px; margin-top: -36px"
																							color="regalBlue"
																							text
																						>
																							<img src="@/assets/market_assessments/trash.svg" />
																						</v-btn>
																					</template>
																				</div>
																			</template>
																		</v-col>
																	</v-row>
																	<v-row justify="center">
																		<v-col cols="6" class="pb-8">
																			<v-btn
																				@click="onSaveCollaborators(index)"
																				:loading="isEditMarketCollaboratorsLoading"
																				color="mangoTango"
																				block
																				dark
																			>
																				<span class="edit-collaborators-save-button">Save</span>
																			</v-btn>
																		</v-col>
																	</v-row>
																	<v-row justify="center" class="pb-5">
																		<v-btn
																			@click="item.isDisplayEditCollaboratorsDialog = false"
																			:disabled="isEditMarketCollaboratorsLoading"
																			text
																		>
                                                                            <span
																				class="edit-collaborators-close-button"
																				:class="{ 'regalBlue--text': !isEditMarketCollaboratorsLoading }"
																			>
                                                                                Close
                                                                            </span>
																		</v-btn>
																	</v-row>
																</v-container>
															</v-card-text>
														</v-card>
													</v-dialog>
												</td>
											</tr>
											</tbody>
										</template>
									</v-data-table>
									<edit-data-toolbar
										v-if="marketAssessment === 'My Markets'"
										:data-count="selectedMarketAssessments.length"
										override-delete-button-text="ARCHIVE"
										@cancel="onCancelEditData()"
										@duplicate="onDuplicateEditData()"
										@delete="onDeleteEditData()"
									></edit-data-toolbar>
									<edit-data-toolbar
										v-else
										:data-count="selectedMarketAssessments.length"
										override-delete-button-text="DELETE"
										@cancel="onCancelEditData()"
										@duplicate="onDuplicateEditData()"
										@delete="isDeleteArchiveMarket = true"
									></edit-data-toolbar>
								</template>
								<template v-if="marketAssessment === 'Map'">
									<report-map></report-map>
								</template>
								<template v-if="marketAssessment === 'Analysis'">
									<data-consolidation-market-assessment></data-consolidation-market-assessment>
								</template>
							</v-tab-item>
						</v-tabs-items>


                    </v-col>
                </v-row>

				<v-dialog v-model="isDeleteArchiveMarket" width="512" persistent>
					<v-card>
						<v-card-title class="headline regalBlue white--text">
							Delete market
						</v-card-title>
						<v-card-text class="text-center pt-5">
							Are you sure you want to delete market?
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="isDeleteArchiveMarket = false" color="mangoTango" text>
								<span>No</span>
							</v-btn>
							<span class="px-2"></span>
							<v-btn @click="onDeleteArchiveEditData()" color="regalBlue" dark>
								<span class="white--text">Yes</span>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</template>
        </v-container>

    </base-layout>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { cloneDeep, size } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getMarketStatusColors } from '@/core/helpers/marketStatusColorHelper';
import { getMarketStatusText } from '@/core/helpers/marketStatusTextHelper';
import { getMarketAssessmentTableHeaders } from '@/core/helpers/marketAssessmentTableHeaderHelper';
import { MarketStatus } from '@/core/enums/marketStatus';
import { MarketPermission } from '@/core/enums/marketPermission';
import ReportMap from '@/components/dashboard/ReportMap.vue';

import EmptyMarketAssessment from '@/components/marketAssessment/EmptyMarketAssessment.vue';
import NonButtonBlock from '@/components/nonButtonBlock/NonButtonBlock.vue';
import EditDataToolbar from '@/components/editDataToolbar/EditDataToolbar.vue';
import {userOwnerMarketIds} from "@/store/account/getters";
import {UserRole} from "@/core/enums/userRole";
import {removeArchiveEditData} from "../../store/marketAssessment/actions";
import DataConsolidationMarketAssessment from '@/components/marketAssessment/DataConsolidationMarketAssessment.vue';

export default {
    name: 'MarketAssessment',
    components: {
        EmptyMarketAssessment,
        NonButtonBlock,
        EditDataToolbar,
		DataConsolidationMarketAssessment,
		ReportMap
    },
    data() {
        return {
            marketAssessments: ['My Markets', 'Archived', 'Map', 'Analysis'],
			isDeleteArchiveMarket: false,

            isLoadingData: false,
            isEditMarketCollaboratorsLoading: false,
            isLoadingEditDataToolbar: false,
            currentTabIndex: 0,

            marketAssessmentHeaders: getMarketAssessmentTableHeaders(),
            selectedMarketAssessments: [],
            marketAssessmentData: [],

            marketStatus: MarketStatus,
            marketPermission: MarketPermission,

            isInitedData: false,

			enumUserRole: UserRole,
		};
    },
    mounted() {
        if (this.market) {
            if (this.availableMarkets.length) {
                this.setIsDisplayPaginationToolbar(true);
                this.setIsDisabledPaginationToolbarAddDataButton(true);
                this.initPagination();
            }
            this.init();
            this.isInitedData = true;
        }
    },
	computed: {
		...mapGetters('paginationToolbar', {
			pageSize: 'pageSize',
			page: 'page',
		}),
		...mapGetters('marketAssessment', {
			availableMarkets: 'availableMarkets',
			markets: 'markets',
			market: 'market',
			users: 'users',
		}),
		...mapGetters('account', {
			userRole: 'userRole',
			userOwnerMarketIds: 'userOwnerMarketIds',
			userContributorMarketIds: 'userContributorMarketIds',
		}),
		backgroundColor() {
			return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
		},
		getUniqueID() {
			return uuidv4();
		},
		isMarketOwner() {
			return (id) => this.userOwnerMarketIds.includes(id);
		},
		isMarketContribution() {
			return (id) => this.userContributorMarketIds.includes(id);
		},
		isAdminRole() {
			return this.userRole === this.enumUserRole.CompanyAdmin || this.userRole === this.enumUserRole.Administrator;
		}
	},
	watch: {
        pageSize() {
            if (this.markets.length) {
                this.initPagination();
                this.reInitMarketAssessmentData();
            }
        },
        page() {
            this.reInitMarketAssessmentData();
        },
        currentTabIndex(data) {
            this.initPagination();
            this.reInitMarketAssessmentData();
            if (data === 0) {
                this.setIsDisabledEditDataToolbarDeleteButton(false);
            } else {
                this.setIsDisabledEditDataToolbarDeleteButton(true);
            }

			if (data === 2 || data === 3){
				this.setIsDisplayPaginationToolbar(false);
				this.setIsDisabledPaginationToolbarAddDataButton(false);
			} else{
				this.setIsDisplayPaginationToolbar(true);
				this.setIsDisabledPaginationToolbarAddDataButton(true);
			}
        },
        selectedMarketAssessments(data) {
            if (data.length) {
                this.setIsDisplayEditDataToolbar(true);

				if (this.isAdminRole) {
					this.setIsDisabledEditDataToolbarDeleteButton(false);
					return
				}

				for (let id of this.userContributorMarketIds) {
					if (data.map(item => item.id === id).includes(true)) {
						this.setIsDisabledEditDataToolbarDeleteButton(true);
						break
					} else {
						this.setIsDisabledEditDataToolbarDeleteButton(false);
					}
				}
            } else {
                this.setIsDisplayEditDataToolbar(false);
            }
        },
        market() {
            if (!this.isInitedData) {
                this.setIsDisplayPaginationToolbar(true);
                this.setIsDisabledPaginationToolbarAddDataButton(true);
                this.initPagination();
                this.init();
                this.isInitedData = true;
            }
        }
    },
    methods: {
        onSelectMarket(data) {
            this.setMarket(data);
            localStorage.setItem('newSettings', false);
            this.$router.push({ name: 'dashboard' });
			this.$router
				.push({ name: 'dashboard' })
				.then(() => { this.$router.go() })
		},
        onCancelEditData() {
            this.selectedMarketAssessments = [];
        },
        onDuplicateEditData() {
            this.isLoadingEditDataToolbar = true;
            this.setIsLoadingEditDataToolbarDuplicate(true);
            const selectedMarketAssessmentIds = this.selectedMarketAssessments.map((item) => {
                return item.id;
            });
            const copiedMarkets = [];
            selectedMarketAssessmentIds.forEach((id) => {
                this.copyMarket({ marketId: id }).then((res) => {
                    copiedMarkets.push({ id, copiedId: res });
                    if (copiedMarkets.length === selectedMarketAssessmentIds.length) {
                        this.finishDuplicateData(copiedMarkets);
                    }
                });
            });
        },
        finishDuplicateData(copiedMarkets) {
            const data = [];
            this.markets.forEach((item) => {
                copiedMarkets.forEach((copiedMarket) => {
                    if (copiedMarket.id === item.id) {
                        const market = cloneDeep(item);
                        market.id = copiedMarket.copiedId;
                        market.name = item.name + '_copy';
                        market.contributors = [];
                        data.push(market);
                    }
                });
            });
            data.forEach((item) => {
                this.pushAvailableMarkets(item);
                this.pushMarkets(item);
            });
            this.marketAssessmentData = this.initMarketAssessmentData();
            this.selectedMarketAssessments = [];
            this.isLoadingEditDataToolbar = false;
            this.setIsLoadingEditDataToolbarDuplicate(false);
        },
        async onDeleteEditData() {
			this.isLoadingEditDataToolbar = true;
            this.setIsLoadingEditDataToolbarDelete(true);
            const archivedMarketAssessmentIds = [];
            this.selectedMarketAssessments.forEach(async (item, index) => {
                const permission = await this.checkUserPermissions({ marketId: item.id });
				this.reInitMarketAssessmentData()
				if ([this.marketPermission.Edit, this.marketPermission.Owner].includes(permission)) {
                    archivedMarketAssessmentIds.push(item.id);
                    this.changeMarketStatus({
                        marketId: item.id,
                        marketStatus: this.marketStatus.Archived,
                    });
                } else {
                    Vue.toasted.error('Not have permission to edit ' + item.name + ' market');
                }
                if (index === this.selectedMarketAssessments.length - 1) {
                    this.finishDeleteData(archivedMarketAssessmentIds);
				}
            });
			setTimeout(async () => {
				this.reInitMarketAssessmentData()
			}, 500);
			setTimeout(async () => {
				location.reload()
			}, 1000);

		},

		onDeleteArchiveEditData () {
			const selectedMarketAssessmentIds = this.selectedMarketAssessments.map((item) => {
				return item.id;
			});
			const pairs = selectedMarketAssessmentIds.map(function (value) { return "ids=" + encodeURIComponent(value) });
			const query_string = pairs.join("&");
			this.isDeleteArchiveMarket = false
			setTimeout(async () => {
				await this.removeArchiveEditData({
					query_string
				});
				this.reInitMarketAssessmentData()
			}, 500);
		},

        finishDeleteData(archivedMarketAssessmentIds) {
            this.markets.forEach((item) => {
                if (archivedMarketAssessmentIds.includes(item.id)) {
                    item.status = this.marketStatus.Archived;
                }
            });
            this.marketAssessmentData = this.initMarketAssessmentData();
            this.selectedMarketAssessments = [];
            this.isLoadingEditDataToolbar = false;
            this.setIsLoadingEditDataToolbarDelete(false);
        },
        ...mapMutations('paginationToolbar', {
            setIsDisplayPaginationToolbar: 'setIsDisplayPaginationToolbar',
            setIsLoadingPaginationToolbar: 'setIsLoadingPaginationToolbar',
            setIsDisabledPaginationToolbarAddDataButton: 'setIsDisabledPaginationToolbarAddDataButton',
            initPagination: 'initPagination',
        }),
        ...mapMutations('editDataToolbar', {
            setIsDisplayEditDataToolbar: 'setIsDisplayEditDataToolbar',
            setIsLoadingEditDataToolbar: 'setIsLoadingEditDataToolbar',
            setIsLoadingEditDataToolbarDuplicate: 'setIsLoadingEditDataToolbarDuplicate',
            setIsLoadingEditDataToolbarDelete: 'setIsLoadingEditDataToolbarDelete',
            setIsDisabledEditDataToolbarDeleteButton: 'setIsDisabledEditDataToolbarDeleteButton',
        }),
        ...mapMutations('marketAssessment', {
            pushAvailableMarkets: 'pushAvailableMarkets',
            pushMarkets: 'pushMarkets',
            setMarket: 'setMarket',
        }),
        ...mapActions('marketAssessment', {
            getMarkets: 'getMarkets',
            copyMarket: 'copyMarket',
            getUsers: 'getUsers',
            shareMarket: 'shareMarket',
            removeMarketContributor: 'removeMarketContributor',
            changeMarketStatus: 'changeMarketStatus',
            checkUserPermissions: 'checkUserPermissions',
			removeArchiveEditData: 'removeArchiveEditData'
        }),
        async init() {
            this.isLoadingData = true;
            const getUsers = this.getUsers();
            const getMarkets = this.getMarkets({
                isArchived: !!this.currentTabIndex,
                page: this.page,
            });
            await Promise.all([getUsers, getMarkets]);
            this.marketAssessmentData = this.initMarketAssessmentData();
            this.selectedMarketSegments = [];
            this.isLoadingData = false;
        },
        initMarketAssessmentData() {
            return this.markets.map((market) => {
                market.contributors.forEach((item) => {
                    Object.assign(item, {
                        isDisplayRemoveCollaboratorIcon: false,
                    });
                });
                return {
                    id: market.id,
                    name: market.name,
                    year: market.year,
                    status: { name: getMarketStatusText(market.status), value: market.status },
                    owner: market.owner,
                    collaborators: market.contributors,
                    nonSavedCollaborators: [],
                    isChangeMarketStatusLoading: false,
                    isChangedCollaboratorsState: false,
                    isDisplayEditCollaboratorsIcon: false,
                    isDisplayEditCollaboratorsDialog: false,
                    selectedCollaborator: null,
                };
            });
        },
        reInitMarketAssessmentData() {
            this.isLoadingData = true;
            this.setIsLoadingPaginationToolbar(true);
            this.setIsLoadingEditDataToolbar(true);
            this.marketAssessmentData = [];
            setTimeout(async () => {
                await this.getMarkets({
                    isArchived: !!this.currentTabIndex,
                    page: this.page,
                });
                this.marketAssessmentData = this.initMarketAssessmentData();
                this.selectedMarketAssessments = [];
                this.isLoadingData = false;
                this.setIsLoadingPaginationToolbar(false);
                this.setIsLoadingEditDataToolbar(false);
			}, 500);
        },
        updateCollaboratorsState(index) {
            this.marketAssessmentData[index].isChangedCollaboratorsState = false;
        },
        filterMarketStatuses() {
            const marketStatuses = [];
            for (let index = 0; index < size(this.marketStatus); index++) {
                marketStatuses.push({ name: getMarketStatusText(index), value: index });
            }
            return marketStatuses;
        },
        statusColors(status) {
            return getMarketStatusColors(status);
        },
        collaboratorsList(data) {
            return data
                .map((item) => {
                    return item.firstName + ' ' + item.lastName;
                })
                .join(', ');
        },
        filterCollaborators(data) {
            const existedCollaboratorIds = data.map((item) => {
                return item.id;
            });
            return this.users.filter((user) => {
                return !existedCollaboratorIds.includes(user.id);
            });
        },
        editMarketCollaborators(index, item) {
            this.marketAssessmentData[index].nonSavedCollaborators = cloneDeep(this.marketAssessmentData[index].collaborators);
			item.isDisplayEditCollaboratorsDialog = !item.isDisplayEditCollaboratorsDialog;
        },
        async onChangeMarketStatus(item, data) {
            item.isChangeMarketStatusLoading = true;
            const permission = await this.checkUserPermissions({ marketId: item.id });
            if ([this.marketPermission.Edit, this.marketPermission.Owner].includes(permission)) {
                this.changeMarketStatus({
                    marketId: item.id,
                    marketStatus: data.value,
                }).then((res) => {
                    if (res) {
                        item.status = data;
                        if (this.currentTabIndex === 0) {
                            this.selectedMarketAssessments = this.selectedMarketAssessments.filter((market) => {
                                return !(market.id === item.id && market.status.value === MarketStatus.Archived);
                            });
                        } else {
                            this.selectedMarketAssessments = this.selectedMarketAssessments.filter((market) => {
                                return market.id !== item.id;
                            });
                        }
                    }
                    item.isChangeMarketStatusLoading = false;
					location.reload()
				});
            } else {
                item.isChangeMarketStatusLoading = false;
                Vue.toasted.error('No permission to edit ' + item.name + ' market');
            }
		},
        onRemoveCollaborator(index, userIndex) {
            this.marketAssessmentData[index].nonSavedCollaborators.splice(userIndex, 1);
        },
        onSelectCollaborator(index) {
            const selectedCollaborator = this.marketAssessmentData[index].selectedCollaborator;
            this.marketAssessmentData[index].nonSavedCollaborators.push(selectedCollaborator);
        },
        async onSaveCollaborators(index) {
            this.isEditMarketCollaboratorsLoading = true;
            const permission = await this.checkUserPermissions({ marketId: this.marketAssessmentData[index].id });
            if ([this.marketPermission.Edit, this.marketPermission.Owner].includes(permission)) {
                const collaboratorIds = this.marketAssessmentData[index].collaborators.map((item) => {
                    return item.id;
                });
                const nonSavedCollaboratorIds = this.marketAssessmentData[index].nonSavedCollaborators.map((item) => {
                    return item.id;
                });
                if (JSON.stringify(collaboratorIds) === JSON.stringify(nonSavedCollaboratorIds)) {
                    this.marketAssessmentData[index].isDisplayEditCollaboratorsDialog = false;
                    this.isEditMarketCollaboratorsLoading = false;
                    return;
                }
                const deleteCollaboratorIds = collaboratorIds.filter((id) => {
                    return !nonSavedCollaboratorIds.includes(id);
                });
                const saveCollaboratorIds = nonSavedCollaboratorIds.filter((id) => {
                    return !collaboratorIds.includes(id);
                });
                const collaborators = [];
                deleteCollaboratorIds.forEach((id) => {
                    collaborators.push(
                        this.removeMarketContributor({
                            userId: id,
                            marketId: this.marketAssessmentData[index].id,
                        }),
                    );
                });
                await Promise.all(collaborators);
                const nonSavedCollaborators = [];
                saveCollaboratorIds.forEach((id) => {
                    nonSavedCollaborators.push(
                        this.shareMarket({
                            userId: id,
                            marketId: this.marketAssessmentData[index].id,
                        }),
                    );
                });
                await Promise.all(nonSavedCollaborators);
                this.marketAssessmentData[index].collaborators = cloneDeep(this.marketAssessmentData[index].nonSavedCollaborators);
                this.marketAssessmentData[index].isDisplayEditCollaboratorsDialog = false;
                this.isEditMarketCollaboratorsLoading = false;
            } else {
                this.isEditMarketCollaboratorsLoading = false;
                Vue.toasted.error('No permission to edit ' + this.marketAssessmentData[index].name + ' market');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 32px !important;
    line-height: 38px !important;
    letter-spacing: -0.114286px !important;
}

.tab-slider-bottom-highlighter {
    border-bottom: 2px solid #e7f1fd !important;
}

.market-name-text,
.year-name-text,
.ownership-name-text {
    font-size: 12px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
}

.normal-text {
    font-size: 12px !important;
    line-height: 18px !important;
}

.hovered-collaborators:hover {
    background-color: #f0f8ff;
}

.edit-collaborators-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
}

.edit-collaborators-add-people-text,
.edit-collaborators-who-has-access-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
}

.edit-collaborators-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
}

.edit-collaborators-email {
    font-size: 12px;
    line-height: 14px;
}

.edit-collaborators-save-button {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
}

.edit-collaborators-close-button {
    font-size: 13px;
    line-height: 22px;
}

.edit-collaborators-disable {
	pointer-events: none;
}
</style>
