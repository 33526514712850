export const getOtherSegmentTableHeaders = () => {
    return [
        {
            text: 'Company &\n' +
				'group name',
            align: 'left',
            sortable: false,
            value: 'companyName',
        },
        {
            text: 'PLANT NAME',
            align: 'center',
            sortable: false,
            value: 'plantName',
        },
        {
            text: 'Geology &\n' +
				'plant type',
            align: 'left',
            sortable: false,
            value: 'plantType.id',
        },
        {
            text: 'volume sold\n' +
				'& produced',
            align: 'left',
            sortable: false,
            value: 'annualAggregatesProduction',
        },
        // {
        //     text: 'VOLUME SOLD IN THE MKT.',
        //     align: 'center',
        //     sortable: true,
        //     value: 'volumeSoldInMarket',
        // },
        {
            text: '',
            align: '',
            sortable: false,
            value: '',
			class: 'other-segments-table-empty-th'
        },
    ];
};
