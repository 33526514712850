<template>
  <div class="input-location" @click.stop :class="classEl">
    <label v-if="label">{{ label }}</label>
    <input type="text" :placeholder="placeholder" @input="searchLocation" @blur="onBlur()" v-model="search" @click="show = true" />
    <div class="input-location__options" v-if="locations.length && show">
      <ul>
        <li v-for="location in locations" :key="location.id" @click="getLocation(location)">
          {{ location.place_name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    classEl: {
      type: String,
    },
    value: {
      type: String,
    }
  },
  data() {
    return {
      search: '',
      // search: this.value,
      show: false,
      locations: [],
    };
  },
  watch: {
    value: function (value) {
      this.search = value;
    }
  },
  // computed: {
  //   search: {
  //     get: function () {
  //       return this.value
  //     },
  //     set: function (newValue) {
  //       this.value = newValue;
  //     }
  //   }
  // },
  mounted() {
    document.addEventListener('click', this.outsideClickEventListener);
  },
  methods: {
    outsideClickEventListener() {
      if (this.show) {
        this.show = false;
      }
    },
    searchLocation() {
		this.$store.dispatch('geocoding/searchLocation', this.search).then(() => {
			this.locations = this.$store.getters['geocoding/getLocations'];
		});
    },
    getLocation(location) {
      this.$emit('setLocation', location);
      this.show = false;
	},
    onBlur() {
      this.$emit('onBlur');
    },
  },
};
</script>

<style scoped></style>
