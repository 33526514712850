<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <span class="modal-default-button" @click="$emit('close')"></span>
          <div class="modal-header">
            <slot name="header"> </slot>
          </div>

          <div class="modal-body">
            <slot name="body"> </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14, 63, 102, 0.5);
  box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.03);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-header {
  margin-bottom: 35px;
  .title {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #0e3f66;
    text-align: center;
    letter-spacing: -0.114286px;
  }
}

.modal-container {
  width: 100%;
  height: 100vh;
  margin: 0px auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  box-sizing: border-box;
  position: relative;
  overflow-y: scroll;
}

.modal-default-button {
  position: absolute;
  right: 22px;
  top: 22px;
  width: 16px;
  height: 16px;
  opacity: 1;
  cursor: pointer;
  &:hover {
    &::before {
      transform: rotate(135deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
  &::before,
  &::after {
    position: absolute;
    content: '';
    height: 16px;
    right: 6px;
    width: 3px;
    background-color: #b0bac9;
    transition: 0.2s ease-in-out;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.overflow {
  overflow: hidden;
  height: 100vh;
}
</style>
