<template>
    <v-card class="ml-3" min-width="600" max-width="1500">
        <v-container fluid pa-10>
            <v-row justify="start" class="pb-5">
                <div class="title bismark--text">Average Market Prices</div>
            </v-row>
            <v-row justify="start" class="pb-8">
                <div class="d-flex align-center content martinique--text">
                    <span>That {{ marketSegments[currentTabIndex].segment.displayName }} customers pay at a quarry gate (applicable geologies only)</span>
                </div>
            </v-row>
            <v-row justify="center" class="pb-8">
                <v-data-table class="market-segments-average-market-price-table" :class="{'other-segments-average-market-price-table' : isCustomSegment}" :headers="averageMarketPriceHeaders" item-key="id" hide-default-footer>
                    <template v-slot:body>
                        <tbody>
                            <template v-if="isLoadingData">
                                <tr>
                                    <td :colspan="averageMarketPriceHeaders.length" class="сapeCod--text text-no-wrap">
                                        <div><v-progress-linear color="regalBlue" indeterminate></v-progress-linear></div>
                                        <div class="pt-5 text-center">Loading... Please wait</div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <template v-if="isReadyMixConcreteSegment || isAsphaltSegment || isConcreteProductsSegment">
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(1).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(1).textColor + '--text']">
                                                                Sand & Gravel
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice">
                                                    <v-text-field
                                                        :value="readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice"
                                                        @change="
                                                            (readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice = parseFloat($event)
                                                                ? roundNumber($event)
                                                                : 0),
                                                                onSaveAverageMarketPrice(1)
                                                        "
                                                        @keydown.enter="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice
                                                        "
                                                        @blur="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice
                                                        "
                                                        :disabled="isLoadingData"
                                                        return-masked-value
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice
                                                        "
                                                    >
                                                        {{ readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice">
                                                    <v-text-field
                                                        :value="readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice"
                                                        @change="
                                                            (readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice = parseFloat($event)
                                                                ? roundNumber($event)
                                                                : 0),
                                                                onSaveAverageMarketPrice(1)
                                                        "
                                                        @keydown.enter="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice
                                                        "
                                                        @blur="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice
                                                        "
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice
                                                        "
                                                    >
                                                        {{ readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(3).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(3).textColor + '--text']">
                                                                Limestone
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice">
                                                    <v-text-field
                                                        :value="readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice"
                                                        @change="
                                                            (readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice = parseFloat($event) ? roundNumber($event) : 0),
                                                                onSaveAverageMarketPrice(3)
                                                        "
                                                        @keydown.enter="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice
                                                        "
                                                        @blur="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice
                                                        "
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice
                                                        "
                                                    >
                                                        {{ readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice">
                                                    <v-text-field
                                                        :value="readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice"
                                                        @change="
                                                            (readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice = parseFloat($event) ? roundNumber($event) : 0),
                                                                onSaveAverageMarketPrice(3)
                                                        "
                                                        @keydown.enter="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice
                                                        "
                                                        @blur="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice
                                                        "
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice
                                                        "
                                                    >
                                                        {{ readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(2).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(2).textColor + '--text']">
                                                                Hard Rock
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice">
                                                    <v-text-field
                                                        :value="readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice"
                                                        @change="
                                                            (readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice = parseFloat($event) ? roundNumber($event) : 0),
                                                                onSaveAverageMarketPrice(2)
                                                        "
                                                        @keydown.enter="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice
                                                        "
                                                        @blur="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice
                                                        "
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice
                                                        "
                                                    >
                                                        {{ readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice">
                                                    <v-text-field
                                                        :value="readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice"
                                                        @change="
                                                            (readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice = parseFloat($event) ? roundNumber($event) : 0),
                                                                onSaveAverageMarketPrice(2)
                                                        "
                                                        @keydown.enter="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice
                                                        "
                                                        @blur="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice
                                                        "
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="
                                                            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice = !isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice
                                                        "
                                                    >
                                                        {{ readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="isRailwaySegment">
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(3).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(3).textColor + '--text']">
                                                                Limestone
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditRailwaySegmentLimestoneBallastPrice">
                                                    <v-text-field
                                                        :value="railwaySegmentLimestoneBallastPrice"
                                                        @change="(railwaySegmentLimestoneBallastPrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(3)"
                                                        @keydown.enter="isEditRailwaySegmentLimestoneBallastPrice = !isEditRailwaySegmentLimestoneBallastPrice"
                                                        @blur="isEditRailwaySegmentLimestoneBallastPrice = !isEditRailwaySegmentLimestoneBallastPrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditRailwaySegmentLimestoneBallastPrice = !isEditRailwaySegmentLimestoneBallastPrice"
                                                    >
                                                        {{ railwaySegmentLimestoneBallastPrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(2).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(2).textColor + '--text']">
                                                                Hard Rock
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditRailwaySegmentHardRockBallastPrice">
                                                    <v-text-field
                                                        :value="railwaySegmentHardRockBallastPrice"
                                                        @change="(railwaySegmentHardRockBallastPrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(2)"
                                                        @keydown.enter="isEditRailwaySegmentHardRockBallastPrice = !isEditRailwaySegmentHardRockBallastPrice"
                                                        @blur="isEditRailwaySegmentHardRockBallastPrice = !isEditRailwaySegmentHardRockBallastPrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditRailwaySegmentHardRockBallastPrice = !isEditRailwaySegmentHardRockBallastPrice"
                                                    >
                                                        {{ railwaySegmentHardRockBallastPrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="isCustomSegment">
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(1).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(1).textColor + '--text']">
                                                                Sand & Gravel
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentSandAndGravelCoarsePrice">
                                                    <v-text-field
                                                        :value="customSegmentSandAndGravelCoarsePrice"
                                                        @change="
                                                            (customSegmentSandAndGravelCoarsePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(1)
                                                        "
                                                        @keydown.enter="isEditCustomSegmentSandAndGravelCoarsePrice = !isEditCustomSegmentSandAndGravelCoarsePrice"
                                                        @blur="isEditCustomSegmentSandAndGravelCoarsePrice = !isEditCustomSegmentSandAndGravelCoarsePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentSandAndGravelCoarsePrice = !isEditCustomSegmentSandAndGravelCoarsePrice"
                                                    >
                                                        {{ customSegmentSandAndGravelCoarsePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentSandAndGravelFinePrice">
                                                    <v-text-field
                                                        :value="customSegmentSandAndGravelFinePrice"
                                                        @change="(customSegmentSandAndGravelFinePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(1)"
                                                        @keydown.enter="isEditCustomSegmentSandAndGravelFinePrice = !isEditCustomSegmentSandAndGravelFinePrice"
                                                        @blur="isEditCustomSegmentSandAndGravelFinePrice = !isEditCustomSegmentSandAndGravelFinePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentSandAndGravelFinePrice = !isEditCustomSegmentSandAndGravelFinePrice"
                                                    >
                                                        {{ customSegmentSandAndGravelFinePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentSandAndGravelFillBasePrice">
                                                    <v-text-field
                                                        :value="customSegmentSandAndGravelFillBasePrice"
                                                        @change="
                                                            (customSegmentSandAndGravelFillBasePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(1)
                                                        "
                                                        @keydown.enter="isEditCustomSegmentSandAndGravelFillBasePrice = !isEditCustomSegmentSandAndGravelFillBasePrice"
                                                        @blur="isEditCustomSegmentSandAndGravelFillBasePrice = !isEditCustomSegmentSandAndGravelFillBasePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentSandAndGravelFillBasePrice = !isEditCustomSegmentSandAndGravelFillBasePrice"
                                                    >
                                                        {{ customSegmentSandAndGravelFillBasePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentSandAndGravelOtherPrice">
                                                    <v-text-field
                                                        :value="customSegmentSandAndGravelOtherPrice"
                                                        @change="(customSegmentSandAndGravelOtherPrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(1)"
                                                        @keydown.enter="isEditCustomSegmentSandAndGravelOtherPrice = !isEditCustomSegmentSandAndGravelOtherPrice"
                                                        @blur="isEditCustomSegmentSandAndGravelOtherPrice = !isEditCustomSegmentSandAndGravelOtherPrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentSandAndGravelOtherPrice = !isEditCustomSegmentSandAndGravelOtherPrice"
                                                    >
                                                        {{ customSegmentSandAndGravelOtherPrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(3).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(3).textColor + '--text']">
                                                                Limestone
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentLimestoneCoarsePrice">
                                                    <v-text-field
                                                        :value="customSegmentLimestoneCoarsePrice"
                                                        @change="(customSegmentLimestoneCoarsePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(3)"
                                                        @keydown.enter="isEditCustomSegmentLimestoneCoarsePrice = !isEditCustomSegmentLimestoneCoarsePrice"
                                                        @blur="isEditCustomSegmentLimestoneCoarsePrice = !isEditCustomSegmentLimestoneCoarsePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentLimestoneCoarsePrice = !isEditCustomSegmentLimestoneCoarsePrice"
                                                    >
                                                        {{ customSegmentLimestoneCoarsePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentLimestoneFinePrice">
                                                    <v-text-field
                                                        :value="customSegmentLimestoneFinePrice"
                                                        @change="(customSegmentLimestoneFinePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(3)"
                                                        @keydown.enter="isEditCustomSegmentLimestoneFinePrice = !isEditCustomSegmentLimestoneFinePrice"
                                                        @blur="isEditCustomSegmentLimestoneFinePrice = !isEditCustomSegmentLimestoneFinePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentLimestoneFinePrice = !isEditCustomSegmentLimestoneFinePrice"
                                                    >
                                                        {{ customSegmentLimestoneFinePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentLimestoneFillBasePrice">
                                                    <v-text-field
                                                        :value="customSegmentLimestoneFillBasePrice"
                                                        @change="(customSegmentLimestoneFillBasePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(3)"
                                                        @keydown.enter="isEditCustomSegmentLimestoneFillBasePrice = !isEditCustomSegmentLimestoneFillBasePrice"
                                                        @blur="isEditCustomSegmentLimestoneFillBasePrice = !isEditCustomSegmentLimestoneFillBasePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentLimestoneFillBasePrice = !isEditCustomSegmentLimestoneFillBasePrice"
                                                    >
                                                        {{ customSegmentLimestoneFillBasePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentLimestoneOtherPrice">
                                                    <v-text-field
                                                        :value="customSegmentLimestoneOtherPrice"
                                                        @change="(customSegmentLimestoneOtherPrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(3)"
                                                        @keydown.enter="isEditCustomSegmentLimestoneOtherPrice = !isEditCustomSegmentLimestoneOtherPrice"
                                                        @blur="isEditCustomSegmentLimestoneOtherPrice = !isEditCustomSegmentLimestoneOtherPrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentLimestoneOtherPrice = !isEditCustomSegmentLimestoneOtherPrice"
                                                    >
                                                        {{ customSegmentLimestoneOtherPrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <span class="text-truncate">
                                                    <span class="text-truncate">
                                                        <non-button-block :color="geologyTypeColors(2).backgroundColor">
                                                            <span class="text-truncate" :class="[geologyTypeColors(2).textColor + '--text']">
                                                                Hard Rock
                                                            </span>
                                                        </non-button-block>
                                                    </span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentHardRockCoarsePrice">
                                                    <v-text-field
                                                        :value="customSegmentHardRockCoarsePrice"
                                                        @change="(customSegmentHardRockCoarsePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(2)"
                                                        @keydown.enter="isEditCustomSegmentHardRockCoarsePrice = !isEditCustomSegmentHardRockCoarsePrice"
                                                        @blur="isEditCustomSegmentHardRockCoarsePrice = !isEditCustomSegmentHardRockCoarsePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentHardRockCoarsePrice = !isEditCustomSegmentHardRockCoarsePrice"
                                                    >
                                                        {{ customSegmentHardRockCoarsePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentHardRockFinePrice">
                                                    <v-text-field
                                                        :value="customSegmentHardRockFinePrice"
                                                        @change="(customSegmentHardRockFinePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(2)"
                                                        @keydown.enter="isEditCustomSegmentHardRockFinePrice = !isEditCustomSegmentHardRockFinePrice"
                                                        @blur="isEditCustomSegmentHardRockFinePrice = !isEditCustomSegmentHardRockFinePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentHardRockFinePrice = !isEditCustomSegmentHardRockFinePrice"
                                                    >
                                                        {{ customSegmentHardRockFinePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentHardRockFillBasePrice">
                                                    <v-text-field
                                                        :value="customSegmentHardRockFillBasePrice"
                                                        @change="(customSegmentHardRockFillBasePrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(2)"
                                                        @keydown.enter="isEditCustomSegmentHardRockFillBasePrice = !isEditCustomSegmentHardRockFillBasePrice"
                                                        @blur="isEditCustomSegmentHardRockFillBasePrice = !isEditCustomSegmentHardRockFillBasePrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentHardRockFillBasePrice = !isEditCustomSegmentHardRockFillBasePrice"
                                                    >
                                                        {{ customSegmentHardRockFillBasePrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                        <td class="td-min-max-width сapeCod--text text-no-wrap">
                                            <div class="row-min-height d-flex align-center justify-center">
                                                <template v-if="isEditCustomSegmentHardRockOtherPrice">
                                                    <v-text-field
                                                        :value="customSegmentHardRockOtherPrice"
                                                        @change="(customSegmentHardRockOtherPrice = parseFloat($event) ? roundNumber($event) : 0), onSaveAverageMarketPrice(2)"
                                                        @keydown.enter="isEditCustomSegmentHardRockOtherPrice = !isEditCustomSegmentHardRockOtherPrice"
                                                        @blur="isEditCustomSegmentHardRockOtherPrice = !isEditCustomSegmentHardRockOtherPrice"
                                                        :disabled="isLoadingData"
                                                        class="market-segments-editable-text-field-value"
                                                        background-color="whiteLilac"
                                                        type="number"
                                                        :suffix="getCurrency + '/t'"
                                                        hide-details
                                                        solo
                                                        dense
                                                    ></v-text-field>
                                                </template>
                                                <template v-else>
                                                    <span
                                                        class="text-truncate bermudaGray--text"
                                                        @click="isEditCustomSegmentHardRockOtherPrice = !isEditCustomSegmentHardRockOtherPrice"
                                                    >
                                                        {{ customSegmentHardRockOtherPrice.toFixed(2) }}
                                                    </span>
                                                    <span class="bermudaGray--text pl-2">{{ getCurrency }}/t</span>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </template>
                </v-data-table>
            </v-row>
            <v-row justify="end">
                <v-btn
                    @click="onCloseAverageMarketPrice()"
                    :loading="isClosingEditAverageMarketPrice"
                    :disabled="isLoadingData"
                    :dark="!isLoadingData"
                    color="mangoTango"
                    width="150px"
                >
                    <span class="close-text white--text">Close</span>
                </v-btn>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { MarketSegmentType } from '@/core/enums/marketSegmentType';
import { getGeologyTypeColors } from '@/core/helpers/geologyTypeColorHelper';

import NonButtonBlock from '@/components/nonButtonBlock/NonButtonBlock.vue';

export default {
    name: 'AverageMarketPrice',
    components: {
        NonButtonBlock,
    },
    props: {
        currentTabIndex: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isLoadingData: false,
            isClosingEditAverageMarketPrice: false,

            readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelId: null,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice: 0,
            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice: false,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice: 0,
            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice: false,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneId: null,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice: 0,
            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice: false,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice: 0,
            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice: false,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice: 0,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockId: null,
            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice: false,
            readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice: 0,
            isEditReadyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice: false,

            railwaySegmentLimestoneId: null,
            railwaySegmentLimestoneBallastPrice: 0,
            isEditRailwaySegmentLimestoneBallastPrice: false,
            railwaySegmentHardRockId: null,
            railwaySegmentHardRockBallastPrice: 0,
            isEditRailwaySegmentHardRockBallastPrice: false,

            customSegmentSandAndGravelId: null,
            customSegmentSandAndGravelCoarsePrice: 0,
            isEditCustomSegmentSandAndGravelCoarsePrice: false,
            customSegmentSandAndGravelFinePrice: 0,
            isEditCustomSegmentSandAndGravelFinePrice: false,
            customSegmentSandAndGravelFillBasePrice: 0,
            isEditCustomSegmentSandAndGravelFillBasePrice: false,
            customSegmentSandAndGravelOtherPrice: 0,
            isEditCustomSegmentSandAndGravelOtherPrice: false,
            customSegmentLimestoneId: null,
            customSegmentLimestoneCoarsePrice: 0,
            isEditCustomSegmentLimestoneCoarsePrice: false,
            customSegmentLimestoneFinePrice: 0,
            isEditCustomSegmentLimestoneFinePrice: false,
            customSegmentLimestoneFillBasePrice: 0,
            isEditCustomSegmentLimestoneFillBasePrice: false,
            customSegmentLimestoneOtherPrice: 0,
            isEditCustomSegmentLimestoneOtherPrice: false,
            customSegmentHardRockId: null,
            customSegmentHardRockCoarsePrice: 0,
            isEditCustomSegmentHardRockCoarsePrice: false,
            customSegmentHardRockFinePrice: 0,
            isEditCustomSegmentHardRockFinePrice: false,
            customSegmentHardRockFillBasePrice: 0,
            isEditCustomSegmentHardRockFillBasePrice: false,
            customSegmentHardRockOtherPrice: 0,
            isEditCustomSegmentHardRockOtherPrice: false,

            marketSegmentType: MarketSegmentType,
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
        ...mapGetters('marketSegment', {
            marketSegments: 'marketSegments',
        }),
        getCurrency() {
			// let currencyId = !!this.market.currency  ? 4 : this.market.currency.id;
            let currencyId = this.market.currency === null ? 4 : this.market.currency.id;
            switch (currencyId) {
                case 1:
                    return 'NZD';
                case 2:
                    return 'AUD';
                case 3:
                    return 'EUR';
                case 4:
                    return 'USD';
            }
        },
        averageMarketPriceHeaders() {
            if (this.isReadyMixConcreteSegment || this.isAsphaltSegment || this.isConcreteProductsSegment) {
                return [
                    {
                        text: 'GEOLOGY',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                    {
                        text: 'COARSE AGGREGATES',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                    {
                        text: 'FINE AGGREGATES',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                ];
            }
            if (this.isRailwaySegment) {
                return [
                    {
                        text: 'GEOLOGY',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                    {
                        text: 'RAILWAY BALLAST',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                ];
            }
            if (this.isCustomSegment) {
                return [
                    {
                        text: 'GEOLOGY',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                    {
                        text: 'COARSE AGGREGATES',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                    {
                        text: 'FINE AGGREGATES',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                    {
                        text: 'FILL/BASE AGGREGATES',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                    {
                        text: 'OTHER AGGREGATES',
                        align: 'center',
                        sortable: false,
                        value: '',
                    },
                ];
            }
        },
        isReadyMixConcreteSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.ReadyMixConcrete].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isAsphaltSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.Asphalt].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isConcreteProductsSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.ConcreteProducts].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isRailwaySegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.Railway].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isCustomSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.Custom].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
    },
    methods: {
        ...mapActions('marketSegment', {
            getAverageMarketPrices: 'getAverageMarketPrices',
            saveAverageMarketPrice: 'saveAverageMarketPrice',
        }),
        async init() {
            this.isLoadingData = true;
            setTimeout(async () => {
                await this.getAverageMarketPrices({ marketSegmentId: this.marketSegments[this.currentTabIndex].id }).then((res) => {
                    res.forEach((item) => {
                        if (this.isReadyMixConcreteSegment || this.isAsphaltSegment || this.isConcreteProductsSegment) {
                            if (item.geologyTypeId === 1) {
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelId = item.id;
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice = item.coarsePrice;
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice = item.finePrice;
                            }
                            if (item.geologyTypeId === 2) {
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockId = item.id;
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice = item.coarsePrice;
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice = item.finePrice;
                            }
                            if (item.geologyTypeId === 3) {
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneId = item.id;
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice = item.coarsePrice;
                                this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice = item.finePrice;
                            }
                        }
                        if (this.isRailwaySegment) {
                            if (item.geologyTypeId === 2) {
                                this.railwaySegmentHardRockId = item.id;
                                this.railwaySegmentHardRockBallastPrice = item.coarsePrice;
                            }
                            if (item.geologyTypeId === 3) {
                                this.railwaySegmentLimestoneId = item.id;
                                this.railwaySegmentLimestoneBallastPrice = item.coarsePrice;
                            }
                        }
                        if (this.isCustomSegment) {
                            if (item.geologyTypeId === 1) {
                                this.customSegmentSandAndGravelId = item.id;
                                this.customSegmentSandAndGravelCoarsePrice = item.coarsePrice;
                                this.customSegmentSandAndGravelFinePrice = item.finePrice;
                                this.customSegmentSandAndGravelFillBasePrice = item.fillBasePrice;
                                this.customSegmentSandAndGravelOtherPrice = item.otherPrice;
                            }
                            if (item.geologyTypeId === 2) {
                                this.customSegmentHardRockId = item.id;
                                this.customSegmentHardRockCoarsePrice = item.coarsePrice;
                                this.customSegmentHardRockFinePrice = item.finePrice;
                                this.customSegmentHardRockFillBasePrice = item.fillBasePrice;
                                this.customSegmentHardRockOtherPrice = item.otherPrice;
                            }
                            if (item.geologyTypeId === 3) {
                                this.customSegmentLimestoneId = item.id;
                                this.customSegmentLimestoneCoarsePrice = item.coarsePrice;
                                this.customSegmentLimestoneFinePrice = item.finePrice;
                                this.customSegmentLimestoneFillBasePrice = item.fillBasePrice;
                                this.customSegmentLimestoneOtherPrice = item.otherPrice;
                            }
                        }
                    });
                });
                this.isLoadingData = false;
            }, 500);
        },
        roundNumber(value, power = 2) {
            return Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
        },
        geologyTypeColors(geologyTypeId) {
            return getGeologyTypeColors(geologyTypeId);
        },
        onSaveAverageMarketPrice(geologyTypeId) {
            let id = null;
            let coarsePrice = null;
            let finePrice = null;
            let fillBasePrice = null;
            let otherPrice = null;
            if (this.isReadyMixConcreteSegment || this.isAsphaltSegment || this.isConcreteProductsSegment) {
                if (geologyTypeId === 1) {
                    id = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelId;
                    coarsePrice = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelCoarsePrice;
                    finePrice = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelFinePrice;
                }
                if (geologyTypeId === 2) {
                    id = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockId;
                    coarsePrice = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockCoarsePrice;
                    finePrice = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockFinePrice;
                }
                if (geologyTypeId === 3) {
                    id = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneId;
                    coarsePrice = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneCoarsePrice;
                    finePrice = this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneFinePrice;
                }
            }
            if (this.isRailwaySegment) {
                if (geologyTypeId === 2) {
                    id = this.railwaySegmentHardRockId;
                    coarsePrice = this.railwaySegmentHardRockBallastPrice;
                }
                if (geologyTypeId === 3) {
                    id = this.railwaySegmentLimestoneId;
                    coarsePrice = this.railwaySegmentLimestoneBallastPrice;
                }
            }
            if (this.isCustomSegment) {
                if (geologyTypeId === 1) {
                    id = this.customSegmentSandAndGravelId;
                    coarsePrice = this.customSegmentSandAndGravelCoarsePrice;
                    finePrice = this.customSegmentSandAndGravelFinePrice;
                    fillBasePrice = this.customSegmentSandAndGravelFillBasePrice;
                    otherPrice = this.customSegmentSandAndGravelOtherPrice;
                }
                if (geologyTypeId === 2) {
                    id = this.customSegmentHardRockId;
                    coarsePrice = this.customSegmentHardRockCoarsePrice;
                    finePrice = this.customSegmentHardRockFinePrice;
                    fillBasePrice = this.customSegmentHardRockFillBasePrice;
                    otherPrice = this.customSegmentHardRockOtherPrice;
                }
                if (geologyTypeId === 3) {
                    id = this.customSegmentLimestoneId;
                    coarsePrice = this.customSegmentLimestoneCoarsePrice;
                    finePrice = this.customSegmentLimestoneFinePrice;
                    fillBasePrice = this.customSegmentLimestoneFillBasePrice;
                    otherPrice = this.customSegmentLimestoneOtherPrice;
                }
            }
            this.saveAverageMarketPrice({
                id,
                coarsePrice,
                finePrice,
                finePrice,
                fillBasePrice,
                otherPrice,
                geologyTypeId,
                marketSegmentId: this.marketSegments[this.currentTabIndex].id,
            }).then((res) => {
                if (res) {
                    if (this.isReadyMixConcreteSegment || this.isAsphaltSegment || this.isConcreteProductsSegment) {
                        if (geologyTypeId === 1) {
                            this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentSandAndGravelId = res;
                        }
                        if (geologyTypeId === 2) {
                            this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentHardRockId = res;
                        }
                        if (geologyTypeId === 3) {
                            this.readyMixConcreteOrAsphaltOrConcreteProductsSegmentLimestoneId = res;
                        }
                    }
                    if (this.isRailwaySegment) {
                        if (geologyTypeId === 2) {
                            this.railwaySegmentHardRockId = res;
                        }
                        if (geologyTypeId === 3) {
                            this.railwaySegmentLimestoneId = res;
                        }
                    }
                    if (this.isCustomSegment) {
                        if (geologyTypeId === 1) {
                            this.customSegmentSandAndGravelId = res;
                        }
                        if (geologyTypeId === 2) {
                            this.customSegmentHardRockId = res;
                        }
                        if (geologyTypeId === 3) {
                            this.customSegmentLimestoneId = res;
                        }
                    }
                }
            });
        },
        onCloseAverageMarketPrice() {
            this.isClosingEditAverageMarketPrice = true;
            setTimeout(() => {
                this.isClosingEditAverageMarketPrice = false;
                this.$emit('close-edit-average-market-price');
            }, 500);
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: -0.114286px !important;
}

.content {
    font-size: 12px !important;
    line-height: 18px !important;
}
.close-text {
    font-size: 15px !important;
    line-height: 22px !important;
}

.td-min-max-width {
    width: 100% !important;
    min-width: 250px !important;
    max-width: 250px !important;
}

.row-min-height {
    min-height: 38px !important;
}
</style>
