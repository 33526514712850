<template>
    <div class="map-location">
        <div class="style-block" v-if="showMapStyle">
            <v-radio-group v-model="radioGroup" row>
                <v-radio v-for="(style, i) in styles" :key="i" :label="style" :value="i" @change="changeStyle = style"></v-radio>
            </v-radio-group>
        </div>
        <Map
            v-if="center.length || markers.length"
            :center="center.length ? center : firstMarker"
			:type="type"
			:markers="getMapAggregates.concat(markers)"
			:hover="hover"
			:boundary="boundaryCoordinates"
			:changeStyle="changeStyle.toLowerCase()"
			@addMarker="addMarker"
			v-model="data"
		/>
    </div>
</template>

<script>
import Map from '@/components/map';
import { buildProducers } from '@/utils/buildProducersAndCustomers';
import { getCenterBoundary } from '@/utils/getCenterBoundary';
import { iconColor, iconImg } from '@/utils/buildProducersAndCustomers';
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    props: {
        type: {
            type: String, //'single', 'list' or not send at all
            default: 'default',
        },
        latitude: {
            type: Number,
            default: null,
        },
        longitude: {
            type: Number,
            default: null,
        },
        listMarkers: {
            type: Array,
        },
        // customer: {
        //   type: Array
        // },
        customer: {
            type: Object,
        },
        item: {
            type: Object,
        },
        hover: {
            type: Boolean,
            default: false,
        },
        customIcon: {
            type: Boolean,
            default: false,
        },
        boundary: {
            type: Boolean,
            default: false,
        },
        showMapStyle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            data: null,
            markers: [],
            center: this.longitude ? [this.longitude, this.latitude] : [],
            boundaryCoordinates: null,
            radioGroup: 0,
            styles: ['Light', 'Streets', 'Satellite'],
            changeStyle: 'Light',
			mapp: null
        };
    },
    async mounted() {
		const getMapAggregatesProducers = this.getMapAggregatesProducers({ marketId: this.market.id });
		await Promise.all([getMapAggregatesProducers]);

		if (this.boundary) {
            await this.$store.dispatch('location/getMarketBoundary').then(() => {
                if (this.$store.getters['location/getBoundary']) {
                    this.boundaryCoordinates = this.$store.getters['location/getBoundary'].coordinates;
                }
            });

            if (this.boundaryCoordinates) {
                this.center = getCenterBoundary(this.boundaryCoordinates);
            }
        }

        if (this.listMarkers && this.listMarkers.length) {
            if (this.customer) {
                this.markers.push({
                    type: 'customers',
                    iconStyle: iconColor(this.customer.marketSegment.segment).join(' '),
                    iconImg: iconImg(this.customer.marketSegment.segment.id),
                    coordinates: [this.customer.longitude, this.customer.latitude],
                });
            }
            if (this.customIcon) {
                const markers = buildProducers(this.listMarkers, 'producers');
                markers.forEach((marker) => this.markers.push(marker));
            } else {
                this.listMarkers.forEach((marker) => this.markers.push({ coordinates: [marker.longitude, marker.latitude] }));
            }
        } else {
            this.markers.push({
                coordinates: [this.longitude ? this.longitude : null, this.latitude ? this.latitude : null],
            });
        }
    },
	watch: {
		getMapAggregates(aggregates) {
			this.selectedMarker = null;
			this.$store.commit('filters/clearFilter');
			this.$store.commit('producersAndCustomersMap/setFilteredAggregates', aggregates);
		},
	},
    methods: {
        addMarker(coordinates) {
            if (this.type !== 'list') {
                const data = `${coordinates.lng},${coordinates.lat}`;
				this.$store.dispatch('geocoding/searchLocation', data).then(() => {
                    this.center = this.$store.getters['geocoding/getLocations'][0].center;
                    this.data = this.$store.getters['geocoding/getLocations'][0];
                    this.$emit('location', this.data);
                });
            } else {
				this.$emit('location', coordinates);
            }
        },
		// ...mapMutations('producersAndCustomersMap', {
		// 	resetAggregates: 'resetAggregates',
		// }),
		...mapActions('producersAndCustomersMap', {
			getMapAggregatesProducers: 'getMapAggregatesProducers',
			getMapCustomers: 'getMapCustomers',
		}),
    },
    computed: {
        firstMarker() {
            return this.markers[0].coordinates;
        },
		...mapGetters('marketAssessment', {
			market: 'market',
		}),
		...mapGetters('producersAndCustomersMap', {
			getMapAggregates: 'getMapAggregates',
			getFilteredMapAggregates: 'getFilteredMapAggregates',
		}),
    },
    components: {
        Map,
    },
};
</script>

<style scoped>
.mgl-map-wrapper {
    height: 65vh;
}
.v-input--selection-controls {
    margin-top: -15px;
}
</style>
