<template>
    <div class="legend-list">
        <h3>Aggregates producer sites:</h3>
        <ul>
            <li v-for="(item, i) in aggregatesProducer" :key="i + getUniqueID()" :class="item.class">
                <img :src="`./legend/icons/${item.icon}.svg`" />
                {{ item.name }}
            </li>
        </ul>
        <h3>Primary application for aggregate sites:</h3>
        <ul>
            <li v-for="(item, i) in primaryApplication" :key="i + getUniqueID()" :class="item.class">
                <img :src="`./legend/icons/${item.icon}.svg`" />
                {{ item.name }}
            </li>
        </ul>
        <h3>Aggregates markets - customers' sites:</h3>
        <ul>
            <li v-for="(item, i) in aggregatesMarkets" :key="i + getUniqueID()" :class="item.class">
                <img :src="`./legend/icons/${item.icon}.svg`" />
                {{ item.name }}
            </li>
            <li v-for="(item, i) in customMarketSegmentNames" :key="i + getUniqueID()" class="padd">
                <img :src="`./legend/icons/icon-16.svg`" />
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            aggregatesProducer: [
                {
                    icon: 'icon-1',
                    name: 'Sand & Gravel Pit or Quarry',
                },
                {
                    icon: 'icon-2',
                    name: 'Limestone Quarry',
                },
                {
                    icon: 'icon-3',
                    name: 'Hard Rock Quarry',
                },
                {
                    icon: 'icon-4',
                    name: 'Rec. Facility',
                    class: 'padd',
                },
                {
                    icon: 'icon-17',
                    name: 'Greenfield',
                },
                {
                    icon: 'icon-18',
                    name: 'Inactive Quarry',
                },
                {
                    icon: 'icon-7',
                    name: 'Terminal, Warf, Depot',
                },
            ],
            primaryApplication: [
                {
                    icon: 'icon-8',
                    name: 'Asphalt',
                    class: 'padd',
                },
                {
                    icon: 'icon-9',
                    name: 'Concrete',
                    class: 'padd',
                },
                {
                    icon: 'icon-10',
                    name: 'Asphalt & Concrete',
                    class: 'padd',
                },
                {
                    icon: 'icon-11',
                    name: 'Project & Base',
                    class: 'padd',
                },
            ],
            aggregatesMarkets: [
                {
                    icon: 'icon-12',
                    name: 'Ready Mix Plant',
                    class: 'padd',
                },
                {
                    icon: 'icon-13',
                    name: 'Asphalt Plant',
                    class: 'padd',
                },
                {
                    icon: 'icon-14',
                    name: 'Concrete products',
                    class: 'padd',
                },
                {
                    icon: 'icon-15',
                    name: 'Railway',
                    class: 'padd',
                },
                // {
                //   icon: 'icon-16',
                //   name: 'Other segment',
                //   class: 'padd',
                // },
            ],
        };
    },
    mounted() {
        if (this.market) {
            this.init();
        }
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
        ...mapGetters('marketSegment', {
            customMarketSegmentNames: 'customMarketSegmentNames',
        }),
    },
    methods: {
        ...mapActions('marketSegment', {
            getCustomMarketSegmentNames: 'getCustomMarketSegmentNames',
        }),
        async init() {
            await this.getCustomMarketSegmentNames({ marketId: this.market.id });
        },
        getUniqueID() {
            return uuidv4();
        },
    },
};
</script>

<style lang="scss" scoped>
.legend-icon {
    width: 56px;
    height: 56px;
    border-radius: 5px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.legend-list {
    width: 240px;
    border-radius: 5px;
    background: #fff;

    h2 {
        font-size: 14px;
        font-weight: 500;
        color: #0e3f66;
    }

    h3 {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase !important;
        color: #6e8ca3;
    }

    ul {
        list-style-type: none;
        padding: 0px;
        margin-bottom: 20px;

        li {
            font-size: 12px;
            line-height: 22px;
            display: flex;

            &.padd {
                img {
                    margin-left: -3px;
                    margin-right: 12px;
                }
            }

            img {
                margin-right: 14px;
            }
        }
    }
}
</style>
