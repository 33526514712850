export const getMarketStatusText = (status) => {
    switch (status) {
        case 0:
            return 'In progress';
        case 1:
            return 'Released';
        case 2:
            return 'Canceled';
        case 3:
            return 'Archived';
        case 4:
            return 'Private';
        default:
            return null;
    }
};
