<template>
  <button class="btn" :class="btnStyle">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    btnStyle: {
      type: String,
    },
  },
};
</script>
