<template>
    <base-layout :style="{ 'background-color': backgroundColor }" :fill-height="isEmptyMarketSegments">
        <div class="page-header-container">
            <empty-market-segment v-if="isEmptyMarketSegments" />

            <template v-else>
                <!-- Title -->
                <div class="d-flex justify-space-between market-segments-top">
					<div class="d-flex align-center justify-space-between w-90">
						<div>
							<div class="title regalBlue--text">Key Market Segments</div>
							<div class="sub-title martinique--text pt-2"> Complete all key segment sections applicable to your market </div>
						</div>
						<div class="split-menu-box__results pl-5">
							<div class="d-flex align-center">
								<v-progress-circular
									:size="60"
									:width="15"
									:rotate="-90"
									:color="'#0E3F66'"
									:value="typeof producerRate == 'number' ? producerRate : 0"
								>
								</v-progress-circular>
								<div class="pl-5">
									<p>
										Completion rate
									</p>
									<span v-if="typeof producerRate == 'number'">
										{{ producerRate }} %
									</span>
								</div>
							</div>
						</div>
					</div>

					<div class="ml-auto d-flex justify-end" v-if="!this.isOtherSegments">
						<v-btn
							@click="visibleRateSidebar=false; openRateMarketVolume(market.id)"
							class="split-menu-view py-5"
							width="100"
						>
							<template
								v-if="visibleRateSidebar"
							>
								<img src="@/assets/pagination_toolbar/arrow_open_close.svg" class="mr-2">
								MORE
							</template>
							<v-progress-circular
								v-else
								indeterminate
								color="white"
							></v-progress-circular>
						</v-btn>
					</div>
                    <div
                        v-if="currentTabIndex === 0 || currentTabIndex === 1 || currentTabIndex === 2"
                        :class="{
                            'pointer-events-none':
                                isLoadingData ||
                                isFormulaSelected ||
                                isOtherSegmentSettingSelected ||
                                isCompetitivePositionSelected ||
                                isCompetitivePositionSettingSelected ||
                                isAverageMarketPriceSelected ||
                                isMarketAttractivenessSelected ||
                                isLoadingEditDataToolbar,
                        }"
                    >
						<div class="import-exel">
							<ImportExcel @onImport="onImportCustomerPlants" :preset="currentTabIndex + 1" :marketSegmentId="marketSegments[currentTabIndex].id"></ImportExcel>
						</div>
                    </div>
                </div>

                <!-- Tabs -->
                <div class="tabs-container">
                    <v-tabs v-model="currentTabIndex" class="market-segments-tabs" background-color="white" color="mangoTango" slider-color="mangoTango" hide-slider show-arrows>
                        <v-tab
                            v-for="marketSegment in marketSegments"
                            :key="marketSegment.id + getUniqueID"
                            :style="{ 'background-color': 'white' }"
                            :disabled="
                                isLoadingData ||
                                isOtherSegmentSettingSelected ||
                                isCompetitivePositionSettingSelected ||
                                (isFormulaSelected && marketSegment.segment.marketSegmentType === marketSegmentType.Custom) ||
                                (marketSegment.segment.marketSegmentType === marketSegmentType.OtherSegments &&
                                    (isAverageMarketPriceSelected || isMarketAttractivenessSelected || isCompetitivePositionSelected || isCompetitivePositionSettingSelected)) ||
                                isLoadingEditDataToolbar
                            "
                            @click="            reInitCustomerPlantData(), showRateLeftSidebar=false,
                                isFormulaSelected =
                                    (marketSegment.segment.marketSegmentType === marketSegmentType.ConcreteProducts ||
                                        marketSegment.segment.marketSegmentType === marketSegmentType.Railway ||
                                        marketSegment.segment.marketSegmentType === marketSegmentType.OtherSegments ||
                                        marketSegment.segment.marketSegmentType === marketSegmentType.Custom) === true
                                        ? false
                                        : isFormulaSelected
                            "
                            class="tab-slider-bottom-highlighter"
                            ripple
                        >
                            {{ marketSegment.segment.displayName }}
                        </v-tab>
                    </v-tabs>

                    <div class="tabs-container-actions">
                        <!-- Compititive Settings -->
                        <template v-if="isCompetitivePositionSelected">
                            <v-btn
                                @click="(isCompetitivePositionSettingSelected = true), setIsDisplayPaginationToolbar(false)"
                                :style="{ opacity: isLoadingData ? 0.3 : 1 }"
                                :class="{ 'pointer-events-none': isCompetitivePositionSettingSelected }"
                                :disabled="isLoadingData"
                                small
                                text
                                fab
                            >
                                <img v-if="isCompetitivePositionSettingSelected" src="@/assets/market_segments/wrench_selected.svg" style="width: 25px" />
                                <img v-else src="@/assets/market_segments/wrench.svg" style="width: 25px" />
                            </v-btn>
                        </template>

                        <!-- Other Segment Settings -->
<!--                        <template v-if="isOtherSegments">-->
<!--                            <div :class="`subheader-item${isOtherSegmentSettingSelected ? ' selected' : ''}`">-->
<!--                                <div @click="(isOtherSegmentSettingSelected = true), setIsDisplayPaginationToolbar(false)" :disabled="isLoadingData" class="d-flex">-->
<!--                                    <img v-if="isOtherSegmentSettingSelected" src="@/assets/market_segments/wrench_selected.svg" style="width: 25px" />-->
<!--                                    <img v-else src="@/assets/market_segments/wrench.svg" style="width: 25px" />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </template>-->

                        <!-- Custom segment Actions -->
                        <template v-if="isCustomSegment && !isAverageMarketPriceSelected && !isMarketAttractivenessSelected && !isCompetitivePositionSelected">
                            <v-btn
                                @click="(isDisplayChangeMarketSegmentDialog = true), (editingMarketSegmentName = marketSegment.segment.displayName)"
                                :disabled="isLoadingData || isAverageMarketPriceSelected || isMarketAttractivenessSelected || isCompetitivePositionSelected"
                                :style="{ opacity: isLoadingData ? 0.3 : 1 }"
                                small
                                text
                                fab
                            >
                                <img src="@/assets/market_segments/edit_pencil.svg" style="width: 18px" />
                            </v-btn>
                            <v-btn
                                @click="isDisplayDeleteMarketSegmentDialog = true"
                                :disabled="isLoadingData || isAverageMarketPriceSelected || isMarketAttractivenessSelected || isCompetitivePositionSelected"
                                :style="{ opacity: isLoadingData ? 0.3 : 1 }"
                                small
                                text
                                fab
                            >
                                <img src="@/assets/market_segments/trash.svg" style="width: 18px" />
                            </v-btn>
                        </template>

                        <!-- Add Segment -->
                        <v-dialog v-model="isDisplayAddMarketSegmentDialog" width="400" persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="
                                        !isFormulaSelected &&
                                        !isOtherSegmentSettingSelected &&
                                        !isCompetitivePositionSelected &&
                                        !isCompetitivePositionSettingSelected &&
                                        !isAverageMarketPriceSelected &&
                                        !isMarketAttractivenessSelected
                                    "
                                    :style="{ opacity: isLoadingData ? 0.3 : 1, backgroundColor: 'rgb(255, 137, 58)', borderColor: 'rgb(255, 137, 58)' }"
                                    :disabled="
                                        isLoadingData ||
                                        isFormulaSelected ||
                                        isOtherSegmentSettingSelected ||
                                        isCompetitivePositionSelected ||
                                        isCompetitivePositionSettingSelected ||
                                        isAverageMarketPriceSelected ||
                                        isMarketAttractivenessSelected ||
                                        isLoadingEditDataToolbar
                                    "
                                    text
                                >
                                    <img src="@/assets/market_segments/plus.svg" style="width: 18px" />
                                    <span class="white--text pl-2">ADD SEGMENT</span>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="headline regalBlue white--text"> Add market segment </v-card-title>
                                <v-card-text class="pt-5">
                                    <v-text-field
                                        v-model="editingMarketSegmentName"
                                        :disabled="isAddingMarketSegment"
                                        class="market-segments-editable-text-field"
                                        background-color="whiteLilac"
                                        color="regalBlue"
                                        hide-details
                                        dense
                                        solo
                                    ></v-text-field>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="(isDisplayAddMarketSegmentDialog = false), (editingMarketSegmentName = null)"
                                        :disabled="isAddingMarketSegment"
                                        color="mangoTango"
                                        text
                                    >
                                        <span>Cancel</span>
                                    </v-btn>
                                    <span class="px-2"></span>
                                    <v-btn @click="onAddMarketSegment()" :loading="isAddingMarketSegment" color="regalBlue" dark>
                                        <span class="white--text">ADD</span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </div>

                <!-- Subtabs -->
                <v-tabs-items v-model="currentTabIndex" :key="currentTabIndex">
                    <v-tab-item
                        v-for="(marketSegment, marketSegmentTabIndex) in marketSegments"
                        :key="marketSegment.id + getUniqueID"
                        :style="{ 'background-color': backgroundColor }"
                    >
                        <!-- Subtab headers -->
                        <div class="subheader">
                            <!-- Formula -->
                            <template v-if="isReadyMixConcreteSegment || isAsphaltSegment">
                                <div :class="`subheader-item${isFormulaSelected ? ' selected' : ''}`">
                                    <div :class="`subheader-item-text${isFormulaTabDisabled ? ' disabled' : ''}`" @click="formulaTabSelected()">
                                        <v-icon>mdi-math-integral</v-icon>
                                        <span class="formula-text"> Aggregates Content Formula </span>
                                    </div>
                                    <div class="subheader-separator" />
                                </div>
                            </template>
                            <!-- Demand & Supply -->
                            <div v-if="!isOtherSegments" :class="`subheader-item${isDemandAndSupplySelected ? ' selected' : ''}`">
                                <div :class="`subheader-item-text${isDemandAndSupplyDisabled ? ' disabled' : ''}`" @click="demandAndSupplyTabSelected()">
                                    <v-icon>mdi-routes</v-icon>
                                    <span class="formula-text"> Demand & Supply </span>
                                </div>
                                <div class="subheader-separator" />
                            </div>
                            <!-- Average Market Prices -->
                            <template v-if="isReadyMixConcreteSegment || isAsphaltSegment || isConcreteProductsSegment || isRailwaySegment || isCustomSegment">
                                <div :class="`subheader-item${isAverageMarketPriceSelected ? ' selected' : ''}`">
                                    <div @click="averageMarketPricesTabSelected()" :class="`subheader-item-text${isAverageMarketPricesTabDisabled ? ' disabled' : ''}`">
                                        <v-icon>mdi-currency-usd</v-icon>
                                        <span class="formula-text"> Average Market Prices </span>
                                    </div>
                                    <div class="subheader-separator" />
                                </div>
                            </template>
                            <!-- Market Attractiveness -->
                            <template v-if="isReadyMixConcreteSegment || isAsphaltSegment || isConcreteProductsSegment || isRailwaySegment || isCustomSegment">
                                <div :class="`subheader-item${isMarketAttractivenessSelected ? ' selected' : ''}`">
                                    <div @click="marketAttractivenessTabSelected()" :class="`subheader-item-text${isMarketAttractivenessTabDisabled ? ' disabled' : ''}`">
                                        <v-icon>mdi-finance</v-icon>
                                        <span class="formula-text"> Market Attractiveness </span>
                                    </div>
                                    <div class="subheader-separator" />
                                </div>
                            </template>
                            <!-- Competitive Position -->
                            <template v-if="isReadyMixConcreteSegment || isAsphaltSegment || isConcreteProductsSegment || isRailwaySegment || isCustomSegment">
                                <div :class="`subheader-item${isCompetitivePositionSelected ? ' selected' : ''}`">
                                    <div @click="competitivePositionTabSelected()" :class="`subheader-item-text${isCompetitivePositionTabDisabled ? ' disabled' : ''}`">
                                        <v-icon>mdi-account-multiple-outline</v-icon>
                                        <span class="formula-text"> Competitive Position </span>
                                    </div>
                                    <div class="subheader-separator" />
                                </div>
                            </template>
                        </div>

                        <!-- Subtab content -->
                        <div class="pb-1 pt-3">
                            <template v-if="isFormulaSelected && currentTabIndex === marketSegmentTabIndex">
                                <formula
                                    :current-tab-index="currentTabIndex"
                                    @cancel-edit-formula="
                                        (isFormulaSelected = false),
                                            setIsDisplayPaginationToolbar(true),
                                            setIsDisabledPaginationToolbarAddDataButton(false),
                                            initPagination(),
                                            reInitCustomerPlantData()
                                    "
                                    @accept-edit-formula="
                                        reCalculateMarketSegmentData(),
                                            (isFormulaSelected = false),
                                            setIsDisplayPaginationToolbar(true),
                                            setIsDisabledPaginationToolbarAddDataButton(false),
                                            initPagination(),
                                            reInitCustomerPlantData()
                                    "
                                />
                            </template>
                            <template
                                v-else-if="
                                    isOtherSegments &&
                                    currentTabIndex === marketSegmentTabIndex &&
                                    !isAverageMarketPriceSelected &&
                                    !isMarketAttractivenessSelected &&
                                    !isCompetitivePositionSelected
                                "
                            >
                                <other-segment-type-setting
                                    v-if="isOtherSegmentSettingSelected"
                                    @close-edit-other-segment-type-setting="(isOtherSegmentSettingSelected = false), setIsDisplayPaginationToolbar(true)"
                                />
                                <other-segment v-else @start-loading-data="isLoadingData = true" @finish-loading-data="isLoadingData = false" />
                            </template>
                            <template v-else-if="isCompetitivePositionSettingSelected && currentTabIndex === marketSegmentTabIndex">
                                <competitive-position-setting
                                    :original-market-segment-id="marketSegment.id"
                                    @close-edit-competitive-position-setting="(isCompetitivePositionSettingSelected = false), setIsDisplayPaginationToolbar(true)"
                                />
                            </template>
                            <template v-else-if="isAverageMarketPriceSelected && currentTabIndex === marketSegmentTabIndex">
                                <average-market-price
                                    :current-tab-index="currentTabIndex"
                                    @close-edit-average-market-price="
                                        (isAverageMarketPriceSelected = false),
                                            setIsDisplayPaginationToolbar(true),
                                            setIsDisabledPaginationToolbarAddDataButton(false),
                                            initPagination(),
                                            reInitCustomerPlantData()
                                    "
                                />
                            </template>
                            <template v-else-if="isMarketAttractivenessSelected && currentTabIndex === marketSegmentTabIndex">
                                <market-attractiveness
                                    :current-tab-index="currentTabIndex"
                                    @close-edit-market-attractiveness="
                                        (isMarketAttractivenessSelected = false),
                                            setIsDisplayPaginationToolbar(true),
                                            setIsDisabledPaginationToolbarAddDataButton(false),
                                            initPagination(),
                                            reInitCustomerPlantData()
                                    "
                                />
                            </template>
                            <template v-else-if="isCompetitivePositionSelected && currentTabIndex === marketSegmentTabIndex">
                                <competitive-position
                                    :original-market-segment-id="marketSegment.id"
                                    @start-loading-data="isLoadingData = true"
                                    @finish-loading-data="isLoadingData = false"
                                />
                            </template>
                            <template v-else>
                                <v-data-table
                                    v-model="selectedCustomerPlants"
                                    :style="{ 'background-color': backgroundColor }"
                                    :class="{
                                        'market-segments-table-ready-mix-concrete': isReadyMixConcreteSegment,
                                        'market-segments-table-asphalt': isAsphaltSegment,
                                        'market-segments-table-concrete-products': isConcreteProductsSegment,
                                        'market-segments-table-railway': isRailwaySegment,
                                        'market-segments-table-custom': isCustomSegment,
                                        'market-segments-table-disabled-events': isLoadingEditDataToolbar,
                                    }"
                                    :headers="customerPlantHeaders"
                                    :items="customerPlantData"
                                    :single-select="false"
                                    :items-per-page="-1"
                                    :mobile-breakpoint="-1"
                                    :loading="isLoadingData"
                                    class="market-segment-table pb-12"
                                    height="57vh"
                                    item-key="id"
                                    show-select
                                    fixed-header
                                    hide-default-footer
                                >
                                    <template v-slot:progress>
                                        <v-progress-linear color="regalBlue" indeterminate></v-progress-linear>
                                        <div class="pt-5 text-center">Loading... Please wait</div>
                                    </template>
                                    <template v-slot:body="{ items }">
                                        <tbody>
                                            <tr v-if="customerPlantData.length === 0 && !isLoadingData">
                                                <td colspan="13" style="border-left: 1px solid #e2e9f9 !important">
                                                    <div class="text-center">No data</div>
                                                </td>
                                            </tr>
                                            <tr v-for="(item, index) in items" :key="item.id + getUniqueID" :class="{ 'pointer-events-none': isLoadingEditDataToolbar }">
                                                <td
                                                    :style="{
                                                        'position': 'relative'
                                                    }"
                                                    class="td-checkbox"
                                                >
                                                    <div class="market-segments-tooltip">
                                                        <div class="market-segments-tooltip-percents">
                                                            {{
                                                                Math.abs(
                                                                    (item.company.name ? 20 : 0) +
                                                                        (item.location ? 20 : 0) +
                                                                        (getStringWithoutUUID(item.plantName) !== '-' ? 20 : 0) +
                                                                        (item.annualProduction ? 20 : 0) +
                                                                        (aggregateSupplierList(item.aggregateSuppliers) &&
                                                                        !isRailwaySegment &&
                                                                        !isCustomSegment &&
                                                                        calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers) === 0
                                                                            ? 10
                                                                            : 0) +
                                                                        (aggregateSupplierList(item.aggregateSuppliers) &&
                                                                        isRailwaySegment &&
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) === item.annualProduction
                                                                            ? 20
                                                                            : 0) +
                                                                        (aggregateSupplierList(item.aggregateSuppliers) &&
                                                                        isConcreteProductsSegment &&
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) + calculateAbsoluteFineTon(item.aggregateSuppliers) ===
                                                                            item.annualProduction
                                                                            ? 20
                                                                            : 0) +
                                                                        (aggregateSupplierList(item.aggregateSuppliers) &&
                                                                        isCustomSegment &&
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteFineTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteFillBaseTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteOtherTon(item.aggregateSuppliers) ===
                                                                            item.annualProduction
                                                                            ? 20
                                                                            : 0) +
                                                                        (aggregateSupplierList(item.aggregateSuppliers) &&
                                                                        !isRailwaySegment &&
                                                                        !isCustomSegment &&
                                                                        calculateMissedFineTon(item.totalTon, item.aggregateSuppliers) === 0
                                                                            ? 10
                                                                            : 0),
                                                                )
                                                            }}%
                                                        </div>
                                                        <div class="market-segments-tooltip-progress">
                                                            <span v-if="item.company.name" style="height: 20%"></span>
                                                            <span v-if="item.location" style="height: 20%"></span>
                                                            <span v-if="getStringWithoutUUID(item.plantName) !== '-'" style="height: 20%"></span>
                                                            <span v-if="item.annualProduction" style="height: 20%"></span>

                                                            <span
                                                                :style="{
                                                                    height: calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers) === 0 ? '10%' : '0%',
                                                                }"
                                                                v-if="aggregateSupplierList(item.aggregateSuppliers) && !isRailwaySegment && !isCustomSegment"
                                                            >
                                                            </span>
                                                            <span
                                                                :style="{
                                                                    height: calculateAbsoluteCoarseTon(item.aggregateSuppliers) === item.annualProduction ? '20%' : '0%',
                                                                }"
                                                                v-if="aggregateSupplierList(item.aggregateSuppliers) && isRailwaySegment"
                                                            >
                                                            </span>
                                                            <span
                                                                :style="{
                                                                    height:
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) + calculateAbsoluteFineTon(item.aggregateSuppliers) ===
                                                                        item.annualProduction
                                                                            ? '20%'
                                                                            : '0%',
                                                                }"
                                                                v-if="aggregateSupplierList(item.aggregateSuppliers) && isConcreteProductsSegment"
                                                            >
                                                            </span>
                                                            <span
                                                                :style="{
                                                                    height:
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteFineTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteFillBaseTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteOtherTon(item.aggregateSuppliers) ===
                                                                        item.annualProduction
                                                                            ? '20%'
                                                                            : '0%',
                                                                }"
                                                                v-if="aggregateSupplierList(item.aggregateSuppliers) && isCustomSegment"
                                                            >
                                                            </span>
                                                            <span
                                                                :style="{
                                                                    height: calculateMissedFineTon(item.totalTon, item.aggregateSuppliers) === 0 ? '10%' : '0%',
                                                                }"
                                                                v-if="aggregateSupplierList(item.aggregateSuppliers) && !isRailwaySegment && !isCustomSegment"
                                                            >
                                                            </span>

                                                            <!--														<div class="market-segments-progress-tooltip">-->

                                                            <!--															{{ Math.abs(-->
                                                            <!--															(item.company.name ? 20 : 0) +-->
                                                            <!--															(item.location ? 20 : 0) +-->
                                                            <!--															(getStringWithoutUUID(item.plantName) !== '-' ? 20 : 0) +-->
                                                            <!--															(item.annualProduction ? 20 : 0) +-->
                                                            <!--															(aggregateSupplierList(item.aggregateSuppliers) && !isRailwaySegment && !isCustomSegment && calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers) === 0 ? 10 : 0) +-->
                                                            <!--															(aggregateSupplierList(item.aggregateSuppliers) && isRailwaySegment && calculateAbsoluteCoarseTon(item.aggregateSuppliers) === item.annualProduction ? 20 : 0) +-->
                                                            <!--															(aggregateSupplierList(item.aggregateSuppliers) && isConcreteProductsSegment && (calculateAbsoluteCoarseTon(item.aggregateSuppliers) + calculateAbsoluteFineTon(item.aggregateSuppliers) === item.annualProduction) ? 20 : 0) +-->
                                                            <!--															(aggregateSupplierList(item.aggregateSuppliers) && isCustomSegment && (calculateAbsoluteCoarseTon(item.aggregateSuppliers) + calculateAbsoluteFineTon(item.aggregateSuppliers) + calculateAbsoluteFillBaseTon(item.aggregateSuppliers) + calculateAbsoluteOtherTon(item.aggregateSuppliers) === item.annualProduction) ? 20 : 0) +-->
                                                            <!--															(aggregateSupplierList(item.aggregateSuppliers) && !isRailwaySegment && !isCustomSegment && calculateMissedFineTon(item.totalTon, item.aggregateSuppliers) === 0 ? 10 : 0)-->
                                                            <!--														)}}-->
                                                            <!--														</div>-->
                                                        </div>
                                                    </div>

                                                    <v-checkbox v-model="selectedCustomerPlants" :value="item" style="margin: 0px; padding: 0px" color="mangoTango" hide-details />
                                                </td>
                                                <td
                                                    @click="(item.searchCompany = item.company.name), (item.isEditCompany = true)"
                                                    :style="{
                                                        backgroundColor:
                                                            !item.company || !item.company.name || item.company.name === 'n/a' || item.company.name === '-'
                                                                ? '#ffede1 !important'
                                                                : '',
                                                    }"
                                                    style="min-width: 250px"
                                                    class="company-name-text сapeCod--text text-no-wrap cursor-pointer"
                                                >
                                                    <div class="column-max-width d-flex align-center justify-center">
                                                        <template v-if="item.isEditCompany">
                                                            <v-combobox
                                                                v-model="item.searchCompany"
                                                                @blur="(item.company.name = item.searchCompany), (item.isEditCompany = false)"
																:style="{ 'display': item.isEditCompany ? 'block':'none' }"
																:id="`companyName${item.id}`"
                                                                :items="item.foundedCompanies"
                                                                :loading="item.isSearchingCompanies"
                                                                :search-input.sync="item.searchCompany"
                                                                class="market-segments-editable-text-field"
                                                                background-color="whiteLilac"
                                                                color="regalBlue"
                                                                item-color="regalBlue"
                                                                item-text="name"
                                                                item-value="id"
                                                                label="Add or search company"
                                                                hide-details
                                                                hide-selected
                                                                solo
                                                            >
                                                            </v-combobox>
                                                        </template>
                                                        <template v-else>
                                                            <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <span class="text-truncate" v-bind="attrs"
																		  @click="openEditTextField(item, 'companyName')"
																		  v-on="on" ref="company_name">
                                                                        <template v-if="item.company.name">{{ item.company.name }}</template>
                                                                        <template v-else>-</template>
                                                                    </span>
                                                                </template>
                                                                <span>
                                                                    <div>Group: {{ item.group.name }}</div>
                                                                    <div>Location: {{ item.location ? item.location : '-' }}</div>
                                                                    <div>Plant name: {{ getStringWithoutUUID(item.plantName) }}</div>
                                                                    <div
                                                                        >Annual production volume:
                                                                        {{
                                                                            `${toFormatNumber(item.annualProduction)} ${
                                                                                isReadyMixConcreteSegment && market.metric ? market.metric.volume : 't'
                                                                            }`
                                                                        }}</div
                                                                    >
                                                                    <div>Plant type: {{ item.plantType && item.plantType.displayName }}</div>
                                                                </span>
                                                            </v-tooltip>
                                                        </template>
                                                        <template v-if="!item.comment || (item.comment && !item.comment.length)">
                                                            <img
                                                                @click="(item.isEditComment = !item.isEditComment), setTimeOutIsEditCompany(item)"
                                                                src="@/assets/comment/comment_plus.svg"
                                                                class="cursor-pointer pl-3"
                                                            />
                                                        </template>
                                                        <template v-else>
                                                            <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <img
                                                                        @click="
                                                                            (item.isEditComment = !item.isEditComment),
                                                                                (item.nonSavedComment = item.comment),
                                                                                setTimeOutIsEditCompany(item)
                                                                        "
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        src="@/assets/comment/comment_completed.svg"
                                                                        class="cursor-pointer pl-5"
                                                                    />
                                                                </template>
                                                                <span>{{ item.comment }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <v-dialog :key="item.isEditComment" v-model="item.isEditComment" persistent max-width="800px">
                                                            <v-card>
                                                                <v-card-title>
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn @click="item.isEditComment = false" small text fab>
                                                                        <img src="@/assets/icons/close.svg" />
                                                                    </v-btn>
                                                                </v-card-title>
                                                                <v-card-text class="pb-0">
                                                                    <v-container>
                                                                        <v-row justify="center" class="pb-8">
                                                                            <span class="comment-title regalBlue--text">Comment</span>
                                                                        </v-row>
                                                                        <v-row justify="center">
                                                                            <v-col>
                                                                                <v-textarea
                                                                                    v-model="item.nonSavedComment"
                                                                                    class="market-segments-comment-text-area"
                                                                                    background-color="ghostWhite"
                                                                                    hide-details
                                                                                    solo
                                                                                >
                                                                                </v-textarea>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row justify="center" class="pt-5">
                                                                            <v-col cols="4">
                                                                                <v-btn
                                                                                    @click="(item.isEditComment = false), (item.comment = item.nonSavedComment)"
                                                                                    color="mangoTango"
                                                                                    large
                                                                                    block
                                                                                >
                                                                                    <span class="comment-save-button white--text">Save</span>
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-text>
                                                                <v-card-actions>
                                                                    <v-container>
                                                                        <v-row justify="center" class="pb-5">
                                                                            <v-btn @click="item.isEditComment = false" text>
                                                                                <span class="comment-cancel-button regalBlue--text"> Cancel </span>
                                                                            </v-btn>
                                                                        </v-row>
                                                                    </v-container>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-dialog>
                                                    </div>
                                                </td>
                                                <td
                                                    :style="{
                                                        backgroundColor: !item.group || groups.map((x) => x.id).includes(item.group.id) === false ? '#ffede1 !important' : '',
                                                    }"
                                                    style="min-width: 250px"
                                                    class="normal-text сapeCod--text text-no-wrap"
                                                >
                                                    <div class="d-flex align-center justify-center">
                                                        <v-select
                                                            v-model="item.group"
                                                            :items="groups"
                                                            :background-color="!item.group || groups.map((x) => x.id).includes(item.group.id) === false ? '#ffede1' : ''"
                                                            class="market-segments-group-select"
                                                            color="regalBlue"
                                                            item-color="regalBlue"
                                                            item-text="name"
                                                            item-value="id"
                                                            return-object
                                                            hide-details
                                                            dense
                                                            solo
                                                        >
                                                        </v-select>
                                                    </div>
                                                </td>

												<td
													:style="{ backgroundColor: !item.location || item.location === 'n/a' ? '#ffede1 !important' : '' }"
													class="normal-text сapeCod--text text-no-wrap"
													@click.stop
												>
													<div class="column-width d-flex align-center justify-center" :style="{ maxWidth: '260px' }">
														<img @click="item.isEditLocationByHand = !item.isEditLocationByHand" src="@/assets/map/map_point.svg" style="width: 15px" class="mr-3" />

														<template v-if="openTextLocation && item.id === openTextLocation">
															<input-location
																style="background-color: #ffffff"
																:placeholder="'Search address'"
																:classEl="
                                                                    (customerPlantData[customerPlantData.length - 1].id === item.id ||
                                                                        customerPlantData[customerPlantData.length - 2].id === item.id) &&
                                                                    customerPlantData.length <= 2
                                                                        ? 'sm-el'
                                                                        : customerPlantData[customerPlantData.length - 1].id === item.id ||
                                                                          customerPlantData[customerPlantData.length - 2].id === item.id
                                                                        ? 'last-el'
                                                                        : ''
                                                                "
																@setLocation="onChangeEditLocation(item, $event, index)"
															/>


														</template>


														<template v-else>
															<span class="text-truncate">
																<span
																	@click="
																		openTextLocation = item.id;
																		item.isEditLocation = !item.isEditLocation;
																	"

																>
																	<template v-if="item.location">{{ item.location }}</template>
																	<template v-else>-</template>
																</span>
															</span>
														</template>



														<v-dialog :key="item.longitude" v-model="item.isEditLocationByHand" max-width="85%" persistent>
															<v-card>
																<v-card-title>
																	<v-spacer></v-spacer>
																	<v-btn @click="item.isEditLocationByHand = false" small text fab>
																		<img src="@/assets/icons/close.svg" />
																	</v-btn>
																</v-card-title>
																<v-card-text>
																	<v-container>
																		<map-location
																			v-if="item.isEditLocationByHand"
																			:type="'single'"
																			:boundary="true"
																			:showMapStyle="true"
																			:list-markers="[{ longitude: item.longitude, latitude: item.latitude }]"
																			@location="onLocationSelected(index, $event)"
																		/>
																	</v-container>
																</v-card-text>
															</v-card>
														</v-dialog>

													</div>
												</td>

                                                <td
                                                    @click="
                                                        (item.isEditPlantName = true), isIncludeUUID(item.plantName) ? (item.plantName = null) : (item.plantName = item.plantName)
                                                    "
                                                    :style="{
                                                        backgroundColor: !item.plantName || getStringWithoutUUID(item.plantName) === '-' ? '#ffede1 !important' : '',
                                                    }"
                                                    style="min-width: 140px"
                                                    class="normal-text сapeCod--text cursor-pointer relative"
                                                >
                                                    <div class="column-max-width d-flex align-center justify-center">
                                                        <template>
                                                            <v-text-field
                                                                :value="item.plantName"
                                                                @keydown.enter="item.isEditPlantName = false"
                                                                @blur="item.isEditPlantName = false"
																:style="{ 'display': item.isEditPlantName ? 'block':'none' }"
																:id="`plantName${item.id}`"
                                                                @change="onChangeEditPlantName(item, $event)"
                                                                class="market-segments-editable-text-field textFields"
                                                                hide-details
                                                                dense
                                                                solo
                                                            ></v-text-field>
															<div class="open-edit-field"
																 @click="openEditTextField(item, 'plantName')"
																 :style="{ 'display': !item.isEditPlantName ? 'block':'none' }"
															>
																<span>
																	{{ getStringWithoutUUID(item.plantName) }}
																</span>
															</div>

                                                        </template>
                                                    </div>
                                                </td>
                                                <td
                                                    @click="item.isEditAnnualProduction = true"
                                                    :style="{
                                                        backgroundColor: item.annualProduction <= 0 ? '#ffede1 !important' : '',
                                                    }"
                                                    style="min-width: 190px"
                                                    class="normal-text сapeCod--text text-no-wrap cursor-pointer relative"
                                                >
                                                    <div class="d-flex align-center justify-center">
                                                        <template>
                                                            <v-text-field
                                                                :value="item.annualProduction === 0 ? null : item.annualProduction"
                                                                @keydown.enter="item.isEditAnnualProduction = false"
                                                                @blur="item.isEditAnnualProduction = false"
                                                                @change="onChangeAnnualProduction(item, $event)"
																oninput="this.value = Math.abs(this.value)"
																:style="{ 'display': item.isEditAnnualProduction ? 'block':'none' }"
																:id="`annualProduction${item.id}`"
																class="market-segments-editable-text-field textFields"
                                                                type="number"
                                                                hide-details
                                                                dense
                                                                solo
                                                            ></v-text-field>
															<div class="open-edit-field"
																 @click="openEditTextField(item, 'annualProduction')"
																 :style="{ 'display': !item.isEditAnnualProduction ? 'block':'none' }"
															>
																<span>
																	{{ toFormatNumber(item.annualProduction) }}
																	<span v-if="isReadyMixConcreteSegment && market.metric" class="pl-2">{{ market.metric.volume }}</span>
                                                        			<span v-else class="pl-2">t</span>
																</span>
															</div>

														</template>
														<template v-if="item.isEditAnnualProduction">
															<span v-if="isReadyMixConcreteSegment && market.metric" class="pl-2">{{ market.metric.volume }}</span>
															<span v-else class="pl-2">t</span>
														</template>

                                                    </div>
                                                </td>
                                                <td
                                                    v-if="isReadyMixConcreteSegment || isAsphaltSegment || isConcreteProductsSegment"
                                                    class="normal-text сapeCod--text text-no-wrap"
                                                >
                                                    <div class="d-flex align-center justify-center">
                                                        <v-select
                                                            v-model="item.plantType"
                                                            :items="filterPlantTypes()"
                                                            class="market-segments-plant-type-select"
                                                            color="regalBlue"
                                                            item-color="regalBlue"
                                                            item-text="displayName"
                                                            item-value="id"
                                                            return-object
                                                            hide-details
                                                            dense
                                                            solo
                                                        >
                                                        </v-select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-center justify-center">
                                                        <v-select
                                                            v-model="item.tag"
                                                            :items="filterTags()"
                                                            :background-color="tagColors(item.tag).backgroundColor"
                                                            class="tag-select"
                                                            color="regalBlue"
                                                            item-color="regalBlue"
                                                            item-text="name"
                                                            item-value="id"
                                                            return-object
                                                            hide-details
                                                            dense
                                                            solo
                                                        >
															<template slot="selection" slot-scope>
                                                                    <span :class="tagColors(item.tag).textColor + '--text'">
                                                                        {{ item.tag ? item.tag.name : 'None' }}
                                                                    </span>
															</template>
                                                        </v-select>
                                                    </div>
                                                </td>

                                                <td class="normal-text сapeCod--text cursor-pointer">
                                                    <template>
                                                        <div class="d-flex align-center" style="height: 100%">
                                                            <span
                                                                :style="{
                                                                    'background-color':
                                                                        calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers) === 0
                                                                            ? 'rgb(236,255,245) !important'
                                                                            : 'rgb(255,232,230) !important',
                                                                    padding: '0 10px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }"
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                v-if="
                                                                    aggregateSupplierList(item.aggregateSuppliers) &&
                                                                    !isCustomSegment &&
                                                                    !isConcreteProductsSegment &&
                                                                    !isRailwaySegment
                                                                "
                                                            >
																<span class="supplier-name">
																	{{ aggregateSupplierList(item.aggregateSuppliers) }}
																</span>
                                                            </span>

                                                            <span
                                                                :style="{
                                                                    'background-color':
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteFineTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteFillBaseTon(item.aggregateSuppliers) +
                                                                            calculateAbsoluteOtherTon(item.aggregateSuppliers) ===
                                                                        item.annualProduction
                                                                            ? 'rgb(236,255,245) !important'
                                                                            : 'rgb(255,232,230) !important',
                                                                    padding: '0 10px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }"
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                v-else-if="aggregateSupplierList(item.aggregateSuppliers) && isCustomSegment"
                                                            >
                                                                <span class="supplier-name">{{ aggregateSupplierList(item.aggregateSuppliers) }}</span>
                                                            </span>
                                                            <span
                                                                :style="{
                                                                    'background-color':
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) + calculateAbsoluteFineTon(item.aggregateSuppliers) ===
                                                                        item.annualProduction
                                                                            ? 'rgb(236,255,245) !important'
                                                                            : 'rgb(255,232,230) !important',
                                                                    padding: '0 10px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }"
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                v-else-if="aggregateSupplierList(item.aggregateSuppliers) && isConcreteProductsSegment"
                                                            >
                                                                <span class="supplier-name">{{ aggregateSupplierList(item.aggregateSuppliers) }}</span>
                                                            </span>
                                                            <span
                                                                :style="{
                                                                    'background-color':
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) === item.annualProduction
                                                                            ? 'rgb(236,255,245) !important'
                                                                            : 'rgb(255,232,230) !important',
                                                                    padding: '0 10px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }"
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                v-else-if="aggregateSupplierList(item.aggregateSuppliers) && isRailwaySegment"
                                                            >
                                                                <span class="supplier-name">{{ aggregateSupplierList(item.aggregateSuppliers) }}</span>
                                                            </span>
                                                            <v-btn
                                                                v-else
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                class="add-supplier-button mx-auto"
                                                                style="background: #dbedff"
                                                            >
                                                                <img src="@/assets/icons/search_supplier.svg" class="mr-2" />
                                                                Add supplier
                                                            </v-btn>
                                                        </div>
                                                    </template>
                                                </td>

                                                <td class="normal-text сapeCod--text cursor-pointer" v-if="!isRailwaySegment && !isCustomSegment">
                                                    <template>
														<div class="d-flex align-center" style="height: 100%">
                                                            <span
                                                                :style="{
                                                                    'background-color':
                                                                        calculateMissedFineTon(item.totalTon, item.aggregateSuppliers) === 0
                                                                            ? 'rgb(236,255,245) !important'
                                                                            : 'rgb(255,232,230) !important',
                                                                    padding: '0 10px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }"
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                v-if="aggregateSupplierList(item.aggregateSuppliers) && !isConcreteProductsSegment"
                                                            >
																<span class="supplier-name">
																	{{ aggregateSupplierList(item.aggregateSuppliers) }}
																</span>
                                                            </span>
                                                            <span
                                                                :style="{
                                                                    'background-color':
                                                                        calculateAbsoluteCoarseTon(item.aggregateSuppliers) + calculateAbsoluteFineTon(item.aggregateSuppliers) ===
                                                                        item.annualProduction
                                                                            ? 'rgb(236,255,245) !important'
                                                                            : 'rgb(255,232,230) !important',
                                                                    padding: '0 10px',
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
															}"
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                v-else-if="aggregateSupplierList(item.aggregateSuppliers) && isConcreteProductsSegment"
                                                            >
																<span class="supplier-name">
																	{{ aggregateSupplierList(item.aggregateSuppliers) }}
																</span>
															</span>
                                                            <v-btn
                                                                v-else
                                                                @click="item.isExpanded = !item.isExpanded"
                                                                class="add-supplier-button mx-auto"
                                                                style="background: #dbedff"
                                                            >
                                                                <img src="@/assets/icons/search_supplier.svg" class="mr-2" />
                                                                Add supplier
                                                            </v-btn>
                                                        </div>
                                                    </template>
                                                </td>

                                                <td
                                                    v-if="isCustomSegment"
                                                    style="min-width: 200px"
                                                    class="number-text bismark--text text-no-wrap"
                                                    :style="{
                                                        'background-color':
                                                            calculateAbsoluteCoarseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFineTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFillBaseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteOtherTon(item.aggregateSuppliers) ===
                                                            item.annualProduction
                                                                ? 'rgb(236,255,245) !important'
                                                                : 'rgb(255,232,230) !important',
                                                    }"
                                                >
                                                    <div class="d-flex align-center justify-center">
                                                        <template v-if="isReadyMixConcreteSegment || isAsphaltSegment">
                                                            {{ toFormatNumber(item.coarseTon) }}
                                                        </template>
                                                        <template v-else>{{ toFormatNumber(calculateAbsoluteCoarseTon(item.aggregateSuppliers)) }}</template>
                                                        <span class="pl-2">t</span>
                                                    </div>
                                                </td>

                                                <td
                                                    v-if="isCustomSegment"
                                                    style="min-width: 200px"
                                                    class="number-text bismark--text text-no-wrap"
                                                    :style="{
                                                        'background-color':
                                                            calculateAbsoluteCoarseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFineTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFillBaseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteOtherTon(item.aggregateSuppliers) ===
                                                            item.annualProduction
                                                                ? 'rgb(236,255,245) !important'
                                                                : 'rgb(255,232,230) !important',
                                                    }"
                                                >
                                                    <div class="d-flex align-center justify-center">
                                                        <template v-if="isReadyMixConcreteSegment || isAsphaltSegment">
                                                            {{ toFormatNumber(item.fineTon) }}
                                                        </template>
                                                        <template v-else>{{ toFormatNumber(calculateAbsoluteFineTon(item.aggregateSuppliers)) }}</template>
                                                        <span class="pl-2">t</span>
                                                    </div>
                                                </td>

                                                <td
                                                    v-if="isCustomSegment"
                                                    style="min-width: 200px"
                                                    class="number-text bismark--text text-no-wrap"
                                                    :style="{
                                                        'background-color':
                                                            calculateAbsoluteCoarseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFineTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFillBaseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteOtherTon(item.aggregateSuppliers) ===
                                                            item.annualProduction
                                                                ? 'rgb(236,255,245) !important'
                                                                : 'rgb(255,232,230) !important',
                                                    }"
                                                >
                                                    <template>
                                                        <div class="d-flex align-center justify-center">
                                                            {{ toFormatNumber(calculateAbsoluteFillBaseTon(item.aggregateSuppliers)) }}
                                                            <span class="pl-2">t</span>
                                                        </div>
                                                    </template>
                                                </td>

                                                <td
                                                    v-if="isCustomSegment"
                                                    style="min-width: 200px"
                                                    class="number-text bismark--text text-no-wrap"
                                                    :style="{
                                                        'background-color':
                                                            calculateAbsoluteCoarseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFineTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteFillBaseTon(item.aggregateSuppliers) +
                                                                calculateAbsoluteOtherTon(item.aggregateSuppliers) ===
                                                            item.annualProduction
                                                                ? 'rgb(236,255,245) !important'
                                                                : 'rgb(255,232,230) !important',
                                                    }"
                                                >
                                                    <template>
                                                        <div class="d-flex align-center justify-center">
                                                            {{ toFormatNumber(calculateAbsoluteOtherTon(item.aggregateSuppliers)) }}
                                                            <span class="pl-2">t</span>
                                                        </div>
                                                    </template>
                                                </td>

                                                <template>
                                                    <v-dialog v-model="item.isExpanded" max-width="720px" persistent :retain-focus="false">
                                                        <v-card>
                                                            <v-card-title style="background-color: #0e3f66; color: white; word-break: normal; flex-wrap: nowrap">
                                                                <v-spacer
                                                                    >Aggregates Supplier to {{ item.company.name }}
                                                                    /
                                                                    {{ getStringWithoutUUID(item.plantName) }}
                                                                </v-spacer>
                                                                <v-btn @click="item.isExpanded = false" small text fab>
                                                                    <img src="@/assets/icons/close.svg" />
                                                                </v-btn>
                                                            </v-card-title>

                                                            <table class="add-supplier-table">
                                                                <tbody>
                                                                    <tr>
                                                                        <td :rowspan="isCustomSegment || isRailwaySegment || isConcreteProductsSegment ? 1 : 2">
                                                                            <div>
                                                                                <non-button-block :is-fill-width="true" :is-text="true" size="small" class="btn-aggregate-head">
                                                                                    {{ toFormatNumber(item.totalTon) }}
                                                                                </non-button-block>
                                                                            </div>
                                                                        </td>
                                                                        <td colspan="2" v-if="!isCustomSegment && !isRailwaySegment && !isConcreteProductsSegment">
                                                                            <span class="aggregates-supplier-head"> COARSE AGGREGATE </span>
                                                                        </td>
                                                                        <td colspan="2" v-if="!isCustomSegment && !isRailwaySegment && !isConcreteProductsSegment">
                                                                            <span class="aggregates-supplier-head"> FINE AGGREGATE </span>
                                                                        </td>
                                                                        <td v-if="isCustomSegment">
                                                                            <span class="aggregates-supplier-head"> TOTAL COARSE </span>
                                                                        </td>
                                                                        <td v-if="isCustomSegment">
                                                                            <span class="aggregates-supplier-head"> TOTAL FINE </span>
                                                                        </td>
                                                                        <td v-if="isCustomSegment">
                                                                            <span class="aggregates-supplier-head"> TOTAL FILL/BASE </span>
                                                                        </td>
                                                                        <td v-if="isCustomSegment">
                                                                            <span class="aggregates-supplier-head"> TOTAL OTHER </span>
                                                                        </td>
                                                                        <td v-if="isRailwaySegment">
                                                                            <span class="aggregates-supplier-head"> TOTAL RAILWAY BALLAST </span>
                                                                        </td>
                                                                        <td v-if="isConcreteProductsSegment">
                                                                            <span class="aggregates-supplier-head"> TOTAL COARSE </span>
                                                                        </td>
                                                                        <td v-if="isConcreteProductsSegment">
                                                                            <span class="aggregates-supplier-head"> TOTAL FINE </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-if="!isRailwaySegment && !isConcreteProductsSegment && !isCustomSegment">
                                                                        <td colspan="2">
                                                                            <non-button-block
                                                                                class="btn-aggregate-head"
                                                                                style="height: auto; border-radius: 0"
                                                                                :color="
                                                                                    calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers) === 0
                                                                                        ? 'emerald'
                                                                                        : 'terraCotta'
                                                                                "
                                                                                :is-fill-width="true"
                                                                                size="small"
                                                                            >
                                                                                <span style="color: #ffffff; padding: 7px">
                                                                                    <template v-if="isReadyMixConcreteSegment || isAsphaltSegment">
                                                                                        {{
                                                                                            toFormatNumber(
																								Math.ceil((item.totalTon / 100) * marketSegments[currentTabIndex].coarseAggregatesPercent)
                                                                                            )
                                                                                        }}
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        {{ toFormatNumber(calculateCoarseTon(item.aggregateSuppliers)) }}
                                                                                    </template>
                                                                                </span>
                                                                            </non-button-block>
                                                                        </td>
                                                                        <td colspan="2">
                                                                            <non-button-block
                                                                                class="btn-aggregate-head"
                                                                                style="height: auto; border-radius: 0"
                                                                                :color="
                                                                                    calculateMissedFineTon(item.totalTon, item.aggregateSuppliers) === 0 ? 'emerald' : 'terraCotta'
                                                                                "
                                                                                :is-fill-width="true"
                                                                                size="small"
                                                                            >
                                                                                <span style="color: #ffffff; padding: 7px">
                                                                                    <template v-if="isReadyMixConcreteSegment || isAsphaltSegment">
                                                                                        {{
                                                                                            toFormatNumber(
																								Math.floor((item.totalTon / 100) * marketSegments[currentTabIndex].fineAggregatesPercent)
                                                                                            )
                                                                                        }}
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        {{ toFormatNumber(calculateFineTon(item.aggregateSuppliers)) }}
                                                                                    </template>
                                                                                </span>
                                                                            </non-button-block>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-if="!isCustomSegment && !isRailwaySegment && !isConcreteProductsSegment">
                                                                        <td>
                                                                            <span class="aggregates-supplier-allocated"> To allocate </span>
                                                                        </td>
                                                                        <td class="px-0 py-0">
                                                                            <div
                                                                                :class="[
                                                                                    calculateMissedCoarsePercent(item.aggregateSuppliers) === 0
                                                                                        ? 'expanded-text-without-value'
                                                                                        : 'expanded-text-with-value d-flex align-center justify-center',
                                                                                ]"
                                                                            >
                                                                                <span v-if="calculateMissedCoarsePercent(item.aggregateSuppliers) !== 0" class="terraCotta--text">
                                                                                    {{ toFormatNumber(Math.abs(calculateMissedCoarsePercent(item.aggregateSuppliers))) }}%
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-0 py-0">
                                                                            <div
                                                                                v-if="isReadyMixConcreteSegment || isAsphaltSegment"
                                                                                :class="[
                                                                                    calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers) === 0
                                                                                        ? 'expanded-text-without-value'
                                                                                        : 'expanded-text-with-value d-flex align-center justify-center',
                                                                                ]"
                                                                            >
                                                                                <template v-if="calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers) !== 0">
                                                                                    <span class="terraCotta--text">
                                                                                        {{
                                                                                            toFormatNumber(
                                                                                                Math.abs(calculateMissedCoarseTon(item.totalTon, item.aggregateSuppliers)),
                                                                                            )
                                                                                        }}
                                                                                    </span>
                                                                                    <span class="terraCotta--text pl-2">t</span>
                                                                                </template>
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-0 py-0">
                                                                            <div
                                                                                :class="[
                                                                                    calculateMissedFinePercent(item.aggregateSuppliers) === 0
                                                                                        ? 'expanded-text-without-value'
                                                                                        : 'expanded-text-with-value d-flex align-center justify-center',
                                                                                ]"
                                                                            >
                                                                                <span v-if="calculateMissedFinePercent(item.aggregateSuppliers) !== 0" class="terraCotta--text">
                                                                                    {{ Math.abs(toFormatNumber(calculateMissedFinePercent(item.aggregateSuppliers))) }}%
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="px-0 py-0">
                                                                            <div
                                                                                v-if="isReadyMixConcreteSegment || isAsphaltSegment"
                                                                                :class="[
                                                                                    calculateMissedFineTon(item.totalTon, item.aggregateSuppliers) === 0
                                                                                        ? 'expanded-text-without-value'
                                                                                        : 'expanded-text-with-value d-flex align-center justify-center',
                                                                                ]"
                                                                            >
                                                                                <template v-if="calculateMissedFineTon(item.totalTon, item.aggregateSuppliers) !== 0">
                                                                                    <span class="terraCotta--text">
                                                                                        {{
                                                                                            toFormatNumber(Math.abs(calculateMissedFineTon(item.totalTon, item.aggregateSuppliers)))
                                                                                        }}
                                                                                    </span>
                                                                                    <span class="terraCotta--text pl-2">t</span>
                                                                                </template>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div
                                                                                v-for="(data, indexSupplier) in item.aggregateSuppliers"
                                                                                :key="data.id + getUniqueID"
                                                                                class="d-flex align-center expanded-block-supplier"
                                                                            >
                                                                                <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <div v-if="!data.sameGroup" style="width: 25px"></div>
                                                                                        <img
                                                                                            v-if="data.sameGroup"
                                                                                            v-bind="attrs"
                                                                                            v-on="on"
                                                                                            src="@/assets/icons/dot_circle.svg"
                                                                                            style="width: 25px"
                                                                                            class="pr-2"
                                                                                        />
                                                                                    </template>
                                                                                    <span>Self-supply</span>
                                                                                </v-tooltip>
                                                                                <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                        <span v-bind="attrs" v-on="on">
                                                                                            <non-button-block
                                                                                                :color="
                                                                                                    geologyTypeColors(data.aggregatesProducerGeology.geologySubType.geologyTypeId).backgroundColor
                                                                                                "
                                                                                                size="small"
                                                                                                style="letter-spacing: 0"
                                                                                            >
                                                                                                <span
                                                                                                    style="max-width: 105px"
                                                                                                    class="text-truncate"
                                                                                                    :class="[
                                                                                                        geologyTypeColors(
                                                                                                            data.aggregatesProducerGeology.geologySubType.geologyTypeId,
                                                                                                        ).textColor + '--text',
                                                                                                    ]"
                                                                                                >
                                                                                                    {{ getCustomerShortName(data.aggregatesProducer.name) }}
                                                                                                </span>
                                                                                            </non-button-block>
                                                                                        </span>
                                                                                    </template>
                                                                                    <span>
                                                                                        {{ data.aggregatesProducer.name }}
                                                                                    </span>
                                                                                </v-tooltip>
                                                                                <v-btn
                                                                                    @click="onRemoveSupplier(index, indexSupplier)"
                                                                                    class="ml-4 btn-allocated-user"
                                                                                    small
                                                                                    text
                                                                                    fab
                                                                                >
                                                                                    <img src="@/assets/icons/close.svg" />
                                                                                </v-btn>
                                                                            </div>
                                                                        </td>
                                                                        <td
                                                                            v-if="isReadyMixConcreteSegment || isAsphaltSegment"
                                                                            :class="{ 'expanded-td': item.isExpanded }"
                                                                            class="number-text bismark--text text-no-wrap"
                                                                        >
                                                                            <template v-if="item.isExpanded">
                                                                                <template v-if="!item.isChangedAggregateSupplierState">
                                                                                    <div
                                                                                        v-for="data in item.aggregateSuppliers"
                                                                                        :key="data.id + getUniqueID"
                                                                                        :class="{
                                                                                            white: isProduceAggregateSupplierCoarseValue(
                                                                                                data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                            ),
                                                                                        }"
                                                                                        class="expanded-block-supplier d-flex align-center justify-center"
                                                                                    >
                                                                                        <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                                <img
                                                                                                    v-show="
                                                                                                        !isProduceAggregateSupplierCoarseValue(
                                                                                                            data.aggregatesProducerGeology.primaryApplication
                                                                                                                .percentageOfProductTypes,
                                                                                                        )
                                                                                                    "
                                                                                                    v-bind="attrs"
                                                                                                    v-on="on"
                                                                                                    src="@/assets/market_segments/red_alert.svg"
                                                                                                    style="width: 25px"
                                                                                                    class="pr-2"
                                                                                                />
                                                                                            </template>
                                                                                            <span
                                                                                                >This supplier does not produce this material. Please review this supplier at
                                                                                                Aggregate Producers page</span
                                                                                            >
                                                                                        </v-tooltip>
                                                                                        <template v-if="data.isEditCoarsePercent">
                                                                                            <v-text-field
                                                                                                :value="data.nonSavedCoarsePercent === 0 ? null : data.nonSavedCoarsePercent"
																								oninput="this.value = Math.abs(this.value)"
																								@keydown.enter="
                                                                                                    changeCoarsePercent(data, index),
                                                                                                        (data.isEditCoarsePercent = !data.isEditCoarsePercent),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
                                                                                                @blur="
                                                                                                    changeCoarsePercent(data, index),
                                                                                                        (data.isEditCoarsePercent = !data.isEditCoarsePercent),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
																								:id="`isEditCoarsePercent${data.id}`"
                                                                                                @change="onChangeNonSavedCoarsePercent(data, $event)"
                                                                                                class="market-segments-editable-text-field"
                                                                                                background-color="whiteLilac"
                                                                                                type="number"
                                                                                                hide-details
                                                                                                dense
                                                                                                solo
                                                                                            ></v-text-field>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span
                                                                                                v-if="
                                                                                                    !isProduceAggregateSupplierCoarseValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateCoarsePercent(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                            <span
                                                                                                v-else
                                                                                                @click="
                                                                                                    (data.nonSavedCoarsePercent = calculateCoarsePercent(
                                                                                                        data.allocationOfProductTypes,
                                                                                                    )),
                                                                                                        (data.isEditCoarsePercent = !data.isEditCoarsePercent),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index),
                                                                                                        openEditTextField(data, 'isEditCoarsePercent')
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateCoarsePercent(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                        </template>
                                                                                        <span :class="{ 'pl-2': data.isEditCoarsePercent }">%</span>
                                                                                    </div>
                                                                                </template>
                                                                            </template>
                                                                        </td>
                                                                        <td
                                                                            v-if="
                                                                                isReadyMixConcreteSegment ||
                                                                                isAsphaltSegment ||
                                                                                isConcreteProductsSegment ||
                                                                                isRailwaySegment ||
                                                                                isCustomSegment
                                                                            "
                                                                            :class="{
                                                                                'none-left-border none-right-border': !item.isExpanded,
                                                                                'expanded-td': item.isExpanded,
                                                                            }"
                                                                            class="number-text bismark--text text-no-wrap"
                                                                        >
                                                                            <template v-if="!item.isExpanded">
                                                                                <div class="d-flex align-center justify-center">
                                                                                    <template v-if="isReadyMixConcreteSegment || isAsphaltSegment">
                                                                                        {{ toFormatNumber(item.coarseTon) }}
                                                                                    </template>
                                                                                    <template v-else>{{
                                                                                        toFormatNumber(calculateAbsoluteCoarseTon(item.aggregateSuppliers))
                                                                                    }}</template>
                                                                                    <span class="pl-2">t</span>
                                                                                </div>
                                                                            </template>
                                                                            <template v-else>
                                                                                <template v-if="!item.isChangedAggregateSupplierState">
                                                                                    <div
                                                                                        v-for="data in item.aggregateSuppliers"
                                                                                        :key="data.id + getUniqueID"
                                                                                        :class="{
                                                                                            white: isProduceAggregateSupplierCoarseValue(
                                                                                                data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                            ),
                                                                                        }"
                                                                                        class="expanded-block-supplier d-flex align-center justify-center"
                                                                                    >
                                                                                        <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                                <img
                                                                                                    v-show="
                                                                                                        !isProduceAggregateSupplierCoarseValue(
                                                                                                            data.aggregatesProducerGeology.primaryApplication
                                                                                                                .percentageOfProductTypes,
                                                                                                        )
                                                                                                    "
                                                                                                    v-bind="attrs"
                                                                                                    v-on="on"
                                                                                                    src="@/assets/market_segments/red_alert.svg"
                                                                                                    style="width: 25px"
                                                                                                    class="pr-2"
                                                                                                />
                                                                                            </template>
                                                                                            <span
                                                                                                >This supplier does not produce this material. Please review this supplier at
                                                                                                Aggregate Producers page</span
                                                                                            >
                                                                                        </v-tooltip>
                                                                                        <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                                <img
                                                                                                    v-show="
                                                                                                        isOutOfAllocatingAggregateSupplierCoarseValue(data) &&
                                                                                                        isProduceAggregateSupplierCoarseValue(
                                                                                                            data.aggregatesProducerGeology.primaryApplication
                                                                                                                .percentageOfProductTypes,
                                                                                                        )
                                                                                                    "
                                                                                                    v-bind="attrs"
                                                                                                    v-on="on"
                                                                                                    src="@/assets/market_segments/red_alert.svg"
                                                                                                    style="width: 25px"
                                                                                                    class="pr-2"
                                                                                                />
                                                                                            </template>
                                                                                            <span
                                                                                                >This supplier is out of coarse material to allocate. Please check Aggregate
                                                                                                Producers page</span
                                                                                            >
                                                                                        </v-tooltip>
                                                                                        <template v-if="data.isEditCoarseTon">
                                                                                            <v-text-field
                                                                                                :value="data.nonSavedCoarseTon === 0 ? null : data.nonSavedCoarseTon"
																								oninput="this.value = Math.abs(this.value)"
																								@keydown.enter="
                                                                                                    changeCoarseTon(data, index),
                                                                                                        (data.isEditCoarseTon = !data.isEditCoarseTon),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
                                                                                                @blur="
                                                                                                    changeCoarseTon(data, index),
                                                                                                        (data.isEditCoarseTon = !data.isEditCoarseTon),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
																								:id="`isEditCoarseTon${data.id}`"
                                                                                                @change="onChangeNonSavedCoarseTon(data, $event)"
                                                                                                class="market-segments-editable-text-field"
                                                                                                background-color="whiteLilac"
                                                                                                type="number"
                                                                                                hide-details
                                                                                                dense
                                                                                                solo
                                                                                            ></v-text-field>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span
                                                                                                v-if="
                                                                                                    !isProduceAggregateSupplierCoarseValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateCoarseTon(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                            <span
                                                                                                v-else
                                                                                                @click="
                                                                                                    (data.nonSavedCoarseTon = calculateCoarseTon(data.allocationOfProductTypes)),
                                                                                                        (data.isEditCoarseTon = !data.isEditCoarseTon),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index),
                                                                                                        openEditTextField(data, 'isEditCoarseTon')
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateCoarseTon(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                        </template>
                                                                                        <span class="pl-2">t</span>
                                                                                    </div>
                                                                                </template>
                                                                            </template>
                                                                        </td>
                                                                        <td
                                                                            v-if="isReadyMixConcreteSegment || isAsphaltSegment"
                                                                            :class="{ 'expanded-td': item.isExpanded }"
                                                                            class="none-left-border none-right-border number-text bismark--text text-no-wrap"
                                                                        >
                                                                            <template v-if="item.isExpanded">
                                                                                <template v-if="!item.isChangedAggregateSupplierState">
                                                                                    <div
                                                                                        v-for="data in item.aggregateSuppliers"
                                                                                        :key="data.id + getUniqueID"
                                                                                        :class="{
                                                                                            white: isProduceAggregateSupplierFineValue(
                                                                                                data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                            ),
                                                                                        }"
                                                                                        class="expanded-block-supplier d-flex align-center justify-center"
                                                                                    >
                                                                                        <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                                <img
                                                                                                    v-show="
                                                                                                        !isProduceAggregateSupplierFineValue(
                                                                                                            data.aggregatesProducerGeology.primaryApplication
                                                                                                                .percentageOfProductTypes,
                                                                                                        )
                                                                                                    "
                                                                                                    v-bind="attrs"
                                                                                                    v-on="on"
                                                                                                    src="@/assets/market_segments/red_alert.svg"
                                                                                                    style="width: 25px"
                                                                                                    class="pr-2"
                                                                                                />
                                                                                            </template>
                                                                                            <span
                                                                                                >This supplier does not produce this material. Please review this supplier at
                                                                                                Aggregate Producers page</span
                                                                                            >
                                                                                        </v-tooltip>
                                                                                        <template v-if="data.isEditFinePercent">
                                                                                            <v-text-field
                                                                                                :value="data.nonSavedFinePercent === 0 ? null : data.nonSavedFinePercent"
																								oninput="this.value = Math.abs(this.value)"
																								@keydown.enter="
                                                                                                    changeFinePercent(data, index),
                                                                                                        (data.isEditFinePercent = !data.isEditFinePercent),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
                                                                                                @blur="
                                                                                                    changeFinePercent(data, index),
                                                                                                        (data.isEditFinePercent = !data.isEditFinePercent),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
																								:id="`isEditFinePercent${data.id}`"
																								@change="onChangeNonSavedFinePercent(data, $event)"
                                                                                                class="market-segments-editable-text-field"
                                                                                                background-color="whiteLilac"
                                                                                                type="number"
                                                                                                hide-details
                                                                                                dense
                                                                                                solo
                                                                                            ></v-text-field>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span
                                                                                                v-if="
                                                                                                    !isProduceAggregateSupplierFineValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateFinePercent(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                            <span
                                                                                                v-else
                                                                                                @click="
                                                                                                    (data.nonSavedFinePercent = calculateFinePercent(
                                                                                                        data.allocationOfProductTypes,
                                                                                                    )),
                                                                                                        (data.isEditFinePercent = !data.isEditFinePercent),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index),
                                                                                                        openEditTextField(data, 'isEditFinePercent')
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateFinePercent(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                        </template>
                                                                                        <span :class="{ 'pl-2': data.isEditFinePercent }">%</span>
                                                                                    </div>
                                                                                </template>
                                                                            </template>
                                                                        </td>
                                                                        <td
                                                                            v-if="isReadyMixConcreteSegment || isAsphaltSegment || isConcreteProductsSegment || isCustomSegment"
                                                                            :class="{
                                                                                'none-left-border': !item.isExpanded,
                                                                                'expanded-td': item.isExpanded,
                                                                            }"
                                                                            class="number-text bismark--text text-no-wrap"
                                                                        >
                                                                            <template v-if="!item.isExpanded">
                                                                                <div class="d-flex align-center justify-center">
                                                                                    <template v-if="isReadyMixConcreteSegment || isAsphaltSegment">
                                                                                        {{ toFormatNumber(item.fineTon) }}
                                                                                    </template>
                                                                                    <template v-else>{{
                                                                                        toFormatNumber(calculateAbsoluteFineTon(item.aggregateSuppliers))
                                                                                    }}</template>
                                                                                    <span class="pl-2">t</span>
                                                                                </div>
                                                                            </template>
                                                                            <template v-else>
                                                                                <template v-if="!item.isChangedAggregateSupplierState">
                                                                                    <div
                                                                                        v-for="data in item.aggregateSuppliers"
                                                                                        :key="data.id + getUniqueID"
                                                                                        :class="{
                                                                                            white: isProduceAggregateSupplierFineValue(
                                                                                                data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                            ),
                                                                                        }"
                                                                                        class="expanded-block-supplier d-flex align-center justify-center"
                                                                                    >
                                                                                        <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                                <img
                                                                                                    v-show="
                                                                                                        !isProduceAggregateSupplierFineValue(
                                                                                                            data.aggregatesProducerGeology.primaryApplication
                                                                                                                .percentageOfProductTypes,
                                                                                                        )
                                                                                                    "
                                                                                                    v-bind="attrs"
                                                                                                    v-on="on"
                                                                                                    src="@/assets/market_segments/red_alert.svg"
                                                                                                    style="width: 25px"
                                                                                                    class="pr-2"
                                                                                                />
                                                                                            </template>
                                                                                            <span
                                                                                                >This supplier does not produce this material. Please review this supplier at
                                                                                                Aggregate Producers page</span
                                                                                            >
                                                                                        </v-tooltip>
                                                                                        <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                                <img
                                                                                                    v-show="
                                                                                                        isOutOfAllocatingAggregateSupplierFineValue(data) &&
                                                                                                        isProduceAggregateSupplierFineValue(
                                                                                                            data.aggregatesProducerGeology.primaryApplication
                                                                                                                .percentageOfProductTypes,
                                                                                                        )
                                                                                                    "
                                                                                                    v-bind="attrs"
                                                                                                    v-on="on"
                                                                                                    src="@/assets/market_segments/red_alert.svg"
                                                                                                    style="width: 25px"
                                                                                                    class="pr-2"
                                                                                                />
                                                                                            </template>
                                                                                            <span
                                                                                                >This supplier is out of fine material to allocate. Please check Aggregate Producers
                                                                                                page</span
                                                                                            >
                                                                                        </v-tooltip>
                                                                                        <template v-if="data.isEditFineTon">
                                                                                            <v-text-field
                                                                                                :value="data.nonSavedFineTon === 0 ? null : data.nonSavedFineTon"
																								oninput="this.value = Math.abs(this.value)"
																								@keydown.enter="
                                                                                                    changeFineTon(data, index),
                                                                                                        (data.isEditFineTon = !data.isEditFineTon),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
                                                                                                @blur="
                                                                                                    changeFineTon(data, index),
                                                                                                        (data.isEditFineTon = !data.isEditFineTon),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index)
                                                                                                "
																								:id="`isEditFineTon${data.id}`"
																								@change="onChangeNonSavedFineTon(data, $event)"
                                                                                                class="market-segments-editable-text-field"
                                                                                                background-color="whiteLilac"
                                                                                                type="number"
                                                                                                hide-details
                                                                                                dense
                                                                                                solo
                                                                                            ></v-text-field>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span
                                                                                                v-if="
                                                                                                    !isProduceAggregateSupplierFineValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateFineTon(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                            <span
                                                                                                v-else
                                                                                                @click="
                                                                                                    (data.nonSavedFineTon = calculateFineTon(data.allocationOfProductTypes)),
                                                                                                        (data.isEditFineTon = !data.isEditFineTon),
                                                                                                        (item.isChangedAggregateSupplierState = true),
                                                                                                        updateAggregateSupplierState(index),
                                                                                                        openEditTextField(data, 'isEditFineTon')
                                                                                                "
                                                                                            >
                                                                                                {{ toFormatNumber(calculateFineTon(data.allocationOfProductTypes)) }}
                                                                                            </span>
                                                                                        </template>
                                                                                        <span class="pl-2">t</span>
                                                                                    </div>
                                                                                </template>
                                                                            </template>
                                                                        </td>

                                                                        <td v-if="isCustomSegment" class="px-0 py-0 number-text">
                                                                            <template v-if="!item.isChangedAggregateSupplierState">
                                                                                <div
                                                                                    v-for="data in item.aggregateSuppliers"
                                                                                    :key="data.id + getUniqueID"
                                                                                    :class="{
                                                                                        white: isProduceAggregateSupplierFillBaseValue(
                                                                                            data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                        ),
                                                                                    }"
                                                                                    class="expanded-block-supplier d-flex align-center justify-center"
                                                                                >
                                                                                    <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                            <img
                                                                                                v-show="
                                                                                                    !isProduceAggregateSupplierFillBaseValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                                src="@/assets/market_segments/red_alert.svg"
                                                                                                style="width: 25px"
                                                                                                class="pr-2"
                                                                                            />
                                                                                        </template>
                                                                                        <span
                                                                                            >This supplier does not produce this material. Please review this supplier at Aggregate
                                                                                            Producers page</span
                                                                                        >
                                                                                    </v-tooltip>
                                                                                    <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                            <img
                                                                                                v-show="
                                                                                                    isOutOfAllocatingAggregateSupplierFillBaseValue(data) &&
                                                                                                    isProduceAggregateSupplierFillBaseValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                                src="@/assets/market_segments/red_alert.svg"
                                                                                                style="width: 25px"
                                                                                                class="pr-2"
                                                                                            />
                                                                                        </template>
                                                                                        <span
                                                                                            >This supplier is out of fill/base material to allocate. Please check Aggregate
                                                                                            Producers page</span
                                                                                        >
                                                                                    </v-tooltip>
                                                                                    <template v-if="data.isEditFillBaseTon">
                                                                                        <v-text-field
                                                                                            :value="data.nonSavedFillBaseTon === 0 ? null : data.nonSavedFillBaseTon"
																							oninput="this.value = Math.abs(this.value)"
																							@keydown.enter="
                                                                                                changeFillBaseTon(data, index),
                                                                                                    (data.isEditFillBaseTon = !data.isEditFillBaseTon),
                                                                                                    (item.isChangedAggregateSupplierState = true),
                                                                                                    updateAggregateSupplierState(index)
                                                                                            "
                                                                                            @blur="
                                                                                                changeFillBaseTon(data, index),
                                                                                                    (data.isEditFillBaseTon = !data.isEditFillBaseTon),
                                                                                                    (item.isChangedAggregateSupplierState = true),
                                                                                                    updateAggregateSupplierState(index)
                                                                                            "
																							:id="`isEditFillBaseTon${data.id}`"
																							@change="onChangeNonSavedFillBaseTon(data, $event)"
                                                                                            class="market-segments-editable-text-field"
                                                                                            background-color="whiteLilac"
                                                                                            type="number"
                                                                                            hide-details
                                                                                            dense
                                                                                            solo
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span
                                                                                            v-if="
                                                                                                !isProduceAggregateSupplierFillBaseValue(
                                                                                                    data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                )
                                                                                            "
                                                                                        >
                                                                                            {{ toFormatNumber(calculateFillBaseTon(data.allocationOfProductTypes)) }}
                                                                                        </span>
                                                                                        <span
                                                                                            v-else
                                                                                            @click="
                                                                                                (data.nonSavedFillBaseTon = calculateFillBaseTon(data.allocationOfProductTypes)),
                                                                                                    (data.isEditFillBaseTon = !data.isEditFillBaseTon),
                                                                                                    (item.isChangedAggregateSupplierState = true),
                                                                                                    updateAggregateSupplierState(index),
                                                                                                    openEditTextField(data, 'isEditFillBaseTon')
                                                                                            "
                                                                                        >
                                                                                            {{ toFormatNumber(calculateFillBaseTon(data.allocationOfProductTypes)) }}
                                                                                        </span>
                                                                                    </template>
                                                                                    <span class="pl-2">t</span>
                                                                                </div>
                                                                            </template>
                                                                        </td>

                                                                        <td v-if="isCustomSegment" class="px-0 py-0 number-text">
                                                                            <template v-if="!item.isChangedAggregateSupplierState">
                                                                                <div
                                                                                    v-for="data in item.aggregateSuppliers"
                                                                                    :key="data.id + getUniqueID"
                                                                                    :class="{
                                                                                        white: isProduceAggregateSupplierOtherValue(
                                                                                            data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                        ),
                                                                                    }"
                                                                                    class="expanded-block-supplier d-flex align-center justify-center"
                                                                                >
                                                                                    <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                            <img
                                                                                                v-show="
                                                                                                    !isProduceAggregateSupplierOtherValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                                src="@/assets/market_segments/red_alert.svg"
                                                                                                style="width: 25px"
                                                                                                class="pr-2"
                                                                                            />
                                                                                        </template>
                                                                                        <span
                                                                                            >This supplier does not produce this material. Please review this supplier at Aggregate
                                                                                            Producers page</span
                                                                                        >
                                                                                    </v-tooltip>
                                                                                    <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                                                                        <template v-slot:activator="{ on, attrs }">
                                                                                            <img
                                                                                                v-show="
                                                                                                    isOutOfAllocatingAggregateSupplierOtherValue(data) &&
                                                                                                    isProduceAggregateSupplierOtherValue(
                                                                                                        data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                    )
                                                                                                "
                                                                                                v-bind="attrs"
                                                                                                v-on="on"
                                                                                                src="@/assets/market_segments/red_alert.svg"
                                                                                                style="width: 25px"
                                                                                                class="pr-2"
                                                                                            />
                                                                                        </template>
                                                                                        <span
                                                                                            >This supplier is out of other material to allocate. Please check Aggregate Producers
                                                                                            page</span
                                                                                        >
                                                                                    </v-tooltip>
                                                                                    <template v-if="data.isEditOtherTon">
                                                                                        <v-text-field
                                                                                            :value="data.nonSavedOtherTon === 0 ? null : data.nonSavedOtherTon"
																							oninput="this.value = Math.abs(this.value)"
																							@keydown.enter="
                                                                                                changeOtherTon(data, index),
                                                                                                    (data.isEditOtherTon = !data.isEditOtherTon),
                                                                                                    (item.isChangedAggregateSupplierState = true),
                                                                                                    updateAggregateSupplierState(index)
                                                                                            "
                                                                                            @blur="
                                                                                                changeOtherTon(data, index),
                                                                                                    (data.isEditOtherTon = !data.isEditOtherTon),
                                                                                                    (item.isChangedAggregateSupplierState = true),
                                                                                                    updateAggregateSupplierState(index)
                                                                                            "
																							:id="`isEditOtherTon${data.id}`"
																							@change="onChangeNonSavedOtherTon(data, $event)"
                                                                                            class="market-segments-editable-text-field"
                                                                                            background-color="whiteLilac"
                                                                                            type="number"
                                                                                            hide-details
                                                                                            dense
                                                                                            solo
                                                                                        ></v-text-field>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span
                                                                                            v-if="
                                                                                                !isProduceAggregateSupplierOtherValue(
                                                                                                    data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes,
                                                                                                )
                                                                                            "
                                                                                        >
                                                                                            {{ toFormatNumber(calculateOtherTon(data.allocationOfProductTypes)) }}
                                                                                        </span>
                                                                                        <span
                                                                                            v-else
                                                                                            @click="
                                                                                                (data.nonSavedOtherTon = calculateOtherTon(data.allocationOfProductTypes)),
                                                                                                    (data.isEditOtherTon = !data.isEditOtherTon),
                                                                                                    (item.isChangedAggregateSupplierState = true),
                                                                                                    updateAggregateSupplierState(index),
                                                                                                    openEditTextField(data, 'isEditOtherTon')
                                                                                            "
                                                                                        >
                                                                                            {{ toFormatNumber(calculateOtherTon(data.allocationOfProductTypes)) }}
                                                                                        </span>
                                                                                    </template>
                                                                                    <span class="pl-2">t</span>
                                                                                </div>
                                                                            </template>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="d-flex align-center justify-center py-3">
                                                                                <div class="market-segments-supplier ml-6">
                                                                                    <img src="@/assets/icons/search_supplier.svg" class="mr-2" />
                                                                                    <v-select
                                                                                        v-model="item.selectedSupplier"
                                                                                        @change="onSelectSupplier(index), (item.selectedSupplier = {})"
                                                                                        :items="filterSuppliers(item.aggregateSuppliers)"
                                                                                        background-color="#dbedff"
                                                                                        class="market-segments-supplier-select pr-2"
                                                                                        color="regalBlue"
                                                                                        item-color="regalBlue"
                                                                                        item-text="aggregatesProducer.name"
                                                                                        item-value="id"
                                                                                        label="Add supplier"
                                                                                        return-object
                                                                                        hide-details
                                                                                        :clearable="false"
                                                                                        dense
                                                                                        solo
                                                                                    >
																						<template v-slot:item="{item}">
																							<div class="market-segments-supplier-select-option">
																								<div class="pie-details">
																									<div class="pie"
																										 :style="{ background: `conic-gradient(
																										 #9BCAFF ${item.recalculatedPercentageOfProductTypes[0].percentage}%,
																										 #53B3FF ${item.recalculatedPercentageOfProductTypes[0].percentage}% ${item.recalculatedPercentageOfProductTypes[0].percentage + item.recalculatedPercentageOfProductTypes[1].percentage}%,
																										 #0078FF ${item.recalculatedPercentageOfProductTypes[0].percentage + item.recalculatedPercentageOfProductTypes[1].percentage}% ${item.recalculatedPercentageOfProductTypes[0].percentage + item.recalculatedPercentageOfProductTypes[1].percentage + item.recalculatedPercentageOfProductTypes[2].percentage}%,
																										 #0E3F66 ${item.recalculatedPercentageOfProductTypes[3].percentage}%
																										 )`}"
																									></div>
																									<div class="details">
																										<p><span class="other_rate"></span>{{item.recalculatedPercentageOfProductTypes[0].name}} - {{item.recalculatedPercentageOfProductTypes[0].percentage}}%</p>
																										<p><span class="fill_rate"></span>{{item.recalculatedPercentageOfProductTypes[1].name}} - {{item.recalculatedPercentageOfProductTypes[1].percentage}}%</p>
																										<p><span class="fine_rate"></span>{{item.recalculatedPercentageOfProductTypes[2].name}} - {{item.recalculatedPercentageOfProductTypes[2].percentage}}%</p>
																										<p><span class="coarse_rate"></span>{{item.recalculatedPercentageOfProductTypes[3].name}} - {{item.recalculatedPercentageOfProductTypes[3].percentage}}%</p>
																									</div>
																								</div>

																								<div>
																									<template v-if="item.aggregatesProducer.name">
																										{{item.aggregatesProducer.name}}
																									</template>
																									<template v-if="item.aggregatesProducer.investigatedCompany.name !== 'n/a'">
																									<span class="market-segments-supplier-select-company-name">
																										({{item.aggregatesProducer.investigatedCompany.name}})
																									</span>
																									</template>
																									<template v-if="getGroupWithId(item.aggregatesProducer.groupId)">
																									<span class="market-segments-supplier-select-group-name">
																										{{getGroupWithId(item.aggregatesProducer.groupId).name}}
																									</span>
																									</template>
																								</div>
																							</div>
																						</template>
                                                                                    </v-select>
                                                                                </div>
                                                                                <img
                                                                                    @click="item.isSelectingSupplierLocation = true"
                                                                                    src="@/assets/icons/choose_on_map.svg"
                                                                                    style="width: 30px"
                                                                                    class="cursor-pointer ml-1 mr-2"
                                                                                />
                                                                                <v-dialog v-model="item.isSelectingSupplierLocation" max-width="85%" persistent>
                                                                                    <v-card>
                                                                                        <v-card-title>
                                                                                            <v-spacer></v-spacer>
                                                                                            <v-btn @click="item.isSelectingSupplierLocation = false, supplierPercentage = false" small text fab>
                                                                                                <img src="@/assets/icons/close.svg" />
                                                                                            </v-btn>
                                                                                        </v-card-title>
                                                                                        <v-card-text class="pa-0">
																								<div style="position: relative">
																									<div class="supplier-info">
																										<div v-if="supplierPercentage" class="supplier-info__details" v-for="(itemPercentage, i) in supplierPercentage" :key="i">
																											<p>{{ itemPercentage.name }}</p>
																											<p>{{ itemPercentage.volume }} t</p>
																										</div>
																									</div>
																									<div v-if="supplierPercentage" class="supplier-info__buttons">
																										<v-btn
																											:style="{backgroundColor: '#0E3F66'}"
																											text
																											@click="item.isSelectingSupplierLocation = false, supplierPercentage = false"
																										>
																											<span class="white--text">Cancel</span>
																										</v-btn>
																										<v-btn
																											class="ml-3"
																											:style="{backgroundColor: 'rgb(255, 137, 58)'}"
																											text
																											@click="onSupplierLocationSelected(supplierPercentageIndex, supplierPercentageLocation, 1)"
																										>
																											<span class="white--text">Select</span>
																										</v-btn>
																									</div>
																									<map-location
																										v-if="item.isSelectingSupplierLocation"
																										:type="'list'"
																										:hover="true"
																										:customIcon="true"
																										:list-markers="filterSupplierCoordinates(item.aggregateSuppliers)"
																										:customer="item"
																										@location="onSupplierLocationSelected(index, $event)"
																									/>
																								</div>
                                                                                        </v-card-text>
                                                                                    </v-card>
                                                                                </v-dialog>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                        <td v-if="!isRailwaySegment"></td>
                                                                        <td v-if="!isRailwaySegment && !isConcreteProductsSegment"></td>
                                                                        <td v-if="!isRailwaySegment && !isConcreteProductsSegment"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </v-card>
                                                    </v-dialog>
                                                </template>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-data-table>
                            </template>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </div>

		<template v-if="showRateLeftSidebar">
			<div class="other-segments-table-split-menu">
				<v-btn
					small text fab
					@click="hideRateMarketVolume()"
					class="split-menu-close"
				>
					<img src="@/assets/market_segments/close.svg">
				</v-btn>
				<div class="split-menu-box">
					<div class="split-menu-box__top">
						<span>More information</span>
						<span></span>
					</div>
					<div class="split-menu-box__results d-flex align-center">
						<div class="d-flex align-center">
							<v-progress-circular
								:size="60"
								:width="15"
								:rotate="-90"
								:color="'#0E3F66'"
								:value="typeof competitivePositionRate == 'number' ? competitivePositionRate : 0"
							></v-progress-circular>
							<div class="pl-7">
								<p>
									Completion rate
								</p>
								<span v-if="typeof competitivePositionRate == 'number'">
									{{ competitivePositionRate }} %
						  		</span>
							</div>
						</div>
						<div class="results-box__data ml-15">
							<div class="d-flex align-center">
								<v-progress-circular
									:size="60"
									:width="15"
									:rotate="-90"
									:color="levelOfConfidence < 50 ? '#F7685B' : levelOfConfidence > 80 ? '#2ED47A' : '#FA771E'"
									:value="typeof levelOfConfidence == 'number' ? levelOfConfidence : 0"
								></v-progress-circular>
								<div class="pl-7">
									<p>
										Level of Confidence
									</p>
									<span v-if="typeof levelOfConfidence == 'number'">
									{{ levelOfConfidence }} %
						  		</span>
								</div>
							</div>
						</div>
					</div>
					<div class="split-menu-box__table">
						<table>
							<thead>
							<tr>
								<th>Segment name</th>
<!--								<th>% of total</th>-->
								<th>Demand & Supply</th>
								<th>Average Market</th>
								<th>Market Attract.</th>
								<th>Competitive Position</th>
							</tr>
							</thead>

							<tbody>

							<tr v-for="item in detailedMarketRates.marketSegments" :key="item.name" v-if="item.name !== 'Other segments'">
								<td>
									<template v-if="item.name">
										{{item.name}}
									</template>
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.demandAndSupplyRate*100) < 50 ? '#F7685B' : Math.trunc(item.demandAndSupplyRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.demandAndSupplyRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.demandAndSupplyRate*100)}}%
						  				</span>
									</div>
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.avarageMarketPriceRate*100) < 50 ? '#F7685B' : Math.trunc(item.avarageMarketPriceRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.avarageMarketPriceRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.avarageMarketPriceRate*100)}}%
						  				</span>
									</div>
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.marketAttractivenessRate*100) < 50 ? '#F7685B' : Math.trunc(item.marketAttractivenessRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.marketAttractivenessRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.marketAttractivenessRate*100)}}%
						  				</span>
									</div>
								</td>
								<td>
									<div class="d-flex align-center justify-center split-menu-box__details">
										<v-progress-circular
											:size="60"
											:width="12"
											:rotate="-90"
											:color="Math.trunc(item.competitivePositionRate*100) < 50 ? '#F7685B' : Math.trunc(item.competitivePositionRate*100) > 80 ? '#2ED47A' : '#FA771E'"
											:value="Math.trunc(item.competitivePositionRate*100)"
										></v-progress-circular>
										<span>
											{{Math.trunc(item.competitivePositionRate*100)}}%
						  				</span>
									</div>
								</td>
							</tr>

<!--							<tr v-for="marketSegment in marketSegments">-->
<!--								<template v-if="marketSegment.segment.displayName !== 'Other segments'">-->
<!--									<td>{{ marketSegment.segment.displayName}}</td>-->
<!--									<td>-->
<!--										<div class="d-flex align-center justify-center">-->
<!--										<span class="text-truncate">-->
<!--											{{ marketSegment.segment.id}}-->
<!--										</span>-->
<!--											<span>-->
<!--												{{ getDemandAndSupplyRate(marketSegment.segment.id) }}-->
<!--											</span>-->
<!--										</div>-->
<!--									</td>-->
<!--								</template>-->
<!--							</tr>-->

<!--							<tr v-for="customHeader in getCustomSegmentHeaders">-->
<!--								<td>{{customHeader.name}}</td>-->
<!--								<td>-->
<!--									<div class="d-flex align-center justify-center" v-if="calculateTotalTonOfCustomSegment(customHeader.id)">-->
<!--									  <span class="text-truncate">-->
<!--										  {{ toFormatNumber(calculateTotalTonOfCustomSegment(customHeader.id)) }} t-->
<!--									  </span>-->
<!--									</div>-->
<!--								</td>-->
<!--								<td>-->
<!--									<div class="d-flex align-center justify-center" v-if="calculateTotalVolumeSoldInTheMarket()">-->
<!--									  <span class="text-truncate">-->
<!--													  <template>-->
<!--														  {{ ((-->
<!--														  calculateTotalTonCoarseCustomSegment(customHeader.id) +-->
<!--														  calculateTotalTonFineCustomSegment(customHeader.id) +-->
<!--														  calculateTotalTonFillBaseCustomSegment(customHeader.id) +-->
<!--														  calculateTotalTonOtherCustomSegment(customHeader.id)-->
<!--													  )/calculateTotalVolumeSoldInTheMarket()*100).toFixed(0)}}-->
<!--													  </template>-->
<!--										  %-->
<!--									  </span>-->

<!--									</div>-->
<!--								</td>-->
<!--								<td :key="customHeader.name + getUniqueID()">-->
<!--									<div class="d-flex align-center justify-center" v-if="calculateTotalTonCoarseCustomSegment(customHeader.id)">-->
<!--									  <span class="text-truncate">-->
<!--										{{ toFormatNumber(calculateTotalTonCoarseCustomSegment(customHeader.id)) }}&nbsp;t-->
<!--									  </span>-->
<!--									</div>-->
<!--								</td>-->
<!--								<td :key="customHeader.name + getUniqueID()">-->
<!--									<div class="d-flex align-center justify-center" v-if="calculateTotalTonFineCustomSegment(customHeader.id)">-->
<!--									  <span class="text-truncate">-->
<!--										{{ toFormatNumber(calculateTotalTonFineCustomSegment(customHeader.id)) }}&nbsp;t-->
<!--									  </span>-->
<!--									</div>-->
<!--								</td>-->
<!--								<td :key="customHeader.name + getUniqueID()">-->
<!--									<div class="d-flex align-center justify-center" v-if="calculateTotalTonFillBaseCustomSegment(customHeader.id)">-->
<!--									  <span class="text-truncate">-->
<!--										{{ toFormatNumber(calculateTotalTonFillBaseCustomSegment(customHeader.id)) }}&nbsp;t-->
<!--									  </span>-->
<!--									</div>-->
<!--								</td>-->
<!--								<td :key="customHeader.name + getUniqueID()">-->
<!--									<div class="d-flex align-center justify-center" v-if="calculateTotalTonOtherCustomSegment(customHeader.id)">-->
<!--									  <span class="text-truncate">-->
<!--										{{ toFormatNumber(calculateTotalTonOtherCustomSegment(customHeader.id)) }}&nbsp;t-->
<!--									  </span>-->
<!--									</div>-->
<!--								</td>-->
<!--							</tr>-->

							</tbody>
						</table>
					</div>
				</div>
			</div>
		</template>

		<v-dialog v-model="isDisplayChangeMarketSegmentDialog" width="400" persistent>
            <v-card>
                <v-card-title class="headline regalBlue text-center white--text"> Change market segment </v-card-title>
                <v-card-text class="pt-5">
                    <v-text-field
                        v-model="editingMarketSegmentName"
                        :disabled="isChangingMarketSegment"
                        class="market-segments-editable-text-field"
                        background-color="whiteLilac"
                        color="regalBlue"
                        hide-details
                        dense
                        solo
                    ></v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="(isDisplayChangeMarketSegmentDialog = false), (editingMarketSegmentName = null)" :disabled="isChangingMarketSegment" color="mangoTango" text>
                        <span>Cancel</span>
                    </v-btn>
                    <span class="px-2"></span>
                    <v-btn @click="onChangeMarketSegment()" :loading="isChangingMarketSegment" color="regalBlue" dark>
                        <span class="white--text">CHANGE</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="isDisplayDeleteMarketSegmentDialog" width="400" persistent>
            <v-card>
                <v-card-title class="headline regalBlue white--text"> Delete market segment </v-card-title>
                <v-card-text class="text-center pt-5">
                    Are you sure that you want to delete
                    <span class="font-weight-bold">"{{ marketSegments[currentTabIndex] && marketSegments[currentTabIndex].segment.displayName }}"</span>
                    market segment?
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="isDisplayDeleteMarketSegmentDialog = false" :disabled="isDeletingMarketSegment" color="mangoTango" text>
                        <span>Cancel</span>
                    </v-btn>
                    <span class="px-2"></span>
                    <v-btn @click="onDeleteMarketSegment()" :loading="isDeletingMarketSegment" color="regalBlue" dark>
                        <span class="white--text">DELETE</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <edit-data-toolbar :data-count="selectedCustomerPlants.length" @cancel="onCancelEditData()" @duplicate="onDuplicateEditData()" @delete="onDeleteEditData()" />
    </base-layout>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { cloneDeep, debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getGeologyTypeColors } from '@/core/helpers/geologyTypeColorHelper';
import { getTagColors } from '@/core/helpers/tagColorHelper';
import {
    getCustomerPlantTableReadyMixConcreateHeaders,
    getCustomerPlantTableAsplhaltHeaders,
    getCustomerPlantTableConcreteProductsHeaders,
    getCustomerPlantTableRailwayHeaders,
    getCustomerPlantTableHeaders,
} from '@/core/helpers/customerPlantTableHeaderHelper';
import { MarketSegmentType } from '@/core/enums/marketSegmentType';
import { PlantType } from '@/core/enums/plantType';

import EmptyMarketSegment from '@/components/marketSegment/EmptyMarketSegment.vue';
import Formula from '@/components/marketSegment/Formula.vue';
import OtherSegmentTypeSetting from '@/components/marketSegment/OtherSegmentTypeSetting.vue';
import CompetitivePositionSetting from '@/components/marketSegment/CompetitivePositionSetting.vue';
import AverageMarketPrice from '@/components/marketSegment/AverageMarketPrice.vue';
import MarketAttractiveness from '@/components/marketSegment/MarketAttractiveness.vue';
import OtherSegment from '@/components/marketSegment/OtherSegment.vue';
import CompetitivePosition from '@/components/marketSegment/CompetitivePosition.vue';
import NonButtonBlock from '@/components/nonButtonBlock/NonButtonBlock.vue';
import MapLocation from '@/components/lib/mapLocation.vue';
import EditDataToolbar from '@/components/editDataToolbar/EditDataToolbar.vue';
import InputLocation from '@/components/lib/inputLocation';
import ImportExcel from '@/components/importExcel/index.vue';

import { REGEX_EMPTY_STRING_PATTERN, REGEX_INCLUDE_UUID_PATTERN } from '@/core/consts/const';
import axios from "axios";

export default {
    name: 'MarketSegments',
    components: {
        EmptyMarketSegment,
        Formula,
        OtherSegmentTypeSetting,
        CompetitivePositionSetting,
        AverageMarketPrice,
        MarketAttractiveness,
        OtherSegment,
        CompetitivePosition,
        NonButtonBlock,
        MapLocation,
        EditDataToolbar,
        InputLocation,
        ImportExcel,
    },
    data() {
        return {
			visibleRateSidebar: true,
			supplierPercentageIndex: false,
			supplierPercentageLocation: false,
			supplierPercentage: false,
			producerRate: 0,
			detailedMarketRates: null,
			competitivePositionRate: null,
			levelOfConfidence: 0,
			showRateLeftSidebar: false,
			isEmptyMarketSegments: true,
            isFormulaSelected: false,
            isDemandAndSupplySelected: false,
            isOtherSegmentSettingSelected: false,
            isCompetitivePositionSettingSelected: false,
            isAverageMarketPriceSelected: false,
            isMarketAttractivenessSelected: false,
            isCompetitivePositionSelected: false,
            isLoadingData: false,
            currentTabIndex: 0,

            isLoadingEditDataToolbar: false,
            customerPlantData: [],
            comparableCustomerPlantData: [],
            selectedCustomerPlants: [],

            editingMarketSegmentName: null,

            isDisplayAddMarketSegmentDialog: false,
            isAddingMarketSegment: false,

            isDisplayChangeMarketSegmentDialog: false,
            isChangingMarketSegment: false,

            isDisplayDeleteMarketSegmentDialog: false,
            isDeletingMarketSegment: false,

            marketSegmentType: MarketSegmentType,
            plantType: PlantType,

            openTextLocation: null,
        };
    },
	async mounted() {
        if (this.market) {
            this.setIsDisplayPaginationToolbar(false);
            this.initPagination();
            this.init();
		}
		await this.getRate()
		document.addEventListener('click', this.outsideClickEventListener);

		// document.addEventListener('click', this.outsideClickEventListener);
    },
    watch: {
        isLoadedAvailableMarkets() {
            if (this.market === null) {
                this.$router.push({ path: '/' });
            }
        },
        pageSize() {
            if (!this.isOtherSegments && !this.isCompetitivePositionSelected) {
                if (this.customers.length) {
                    this.initPagination();
                    this.reInitCustomerPlantData();
                }
            }
        },
        page() {
            if (!this.isOtherSegments && !this.isCompetitivePositionSelected) {
                this.reInitCustomerPlantData();
            }
        },
        currentTabIndex(data) {
            if (!this.isEmptyMarketSegments) {
                if (
                    !this.isOtherSegments &&
                    !this.isFormulaSelected &&
                    !this.isAverageMarketPriceSelected &&
                    !this.isMarketAttractivenessSelected &&
                    !this.isCompetitivePositionSelected &&
                    !this.isDemandAndSupplySelected
                ) {
                    this.setIsDisabledPaginationToolbarAddDataButton(false);
                    this.initPagination();
                    this.reInitCustomerPlantData();
                    if (
                        (this.isReadyMixConcreteSegment || this.isAsphaltSegment) &&
                        (this.marketSegments[this.currentTabIndex].coarseAggregatesPercent === null || this.marketSegments[this.currentTabIndex].fineAggregatesPercent === null)
                    ) {
                        this.isFormulaSelected = true;
                        this.setIsDisplayPaginationToolbar(false);
                    } else {
                        this.setIsDisplayPaginationToolbar(true);
                    }
                } else {
                    if ((this.isReadyMixConcreteSegment || this.isAsphaltSegment) && this.isNaviagtionDisabled) {
                        this.isFormulaSelected = true;
                        this.isAverageMarketPriceSelected = false;
                        this.isMarketAttractivenessSelected = false;
                        this.isCompetitivePositionSelected = false;
                        this.isDemandAndSupplySelected = false;
                    }
                    this.customerPlantData = [];
                    this.comparableCustomerPlantData = [];
                    this.selectedCustomerPlants = [];
                    this.setIsDisabledPaginationToolbarAddDataButton(true);
                }
            }
        },
        market() {
            this.isEmptyMarketSegments = true;
            this.isFormulaSelected = false;
            this.isDemandAndSupplySelected = false;
            this.isOtherSegmentSettingSelected = false;
            this.isCompetitivePositionSettingSelected = false;
            this.isAverageMarketPriceSelected = false;
            this.isMarketAttractivenessSelected = false;
            this.isCompetitivePositionSelected = false;
            this.isLoadingData = false;
            this.currentTabIndex = 0;
            this.customerPlantData = [];
            this.comparableCustomerPlantData = [];
            this.selectedCustomerPlants = [];
            this.setIsDisplayPaginationToolbar(false);
            this.initPagination();
            this.init();
			this.getRate()
        },
        selectedCustomerPlants(data) {
            if (data.length) {
                this.setIsDisplayEditDataToolbar(true);
            } else {
                this.setIsDisplayEditDataToolbar(false);
            }
        },
        triggerAddData() {
            if (!this.isOtherSegments) {
                if (this.groups.length) {
                    this.setIsLoadingPaginationToolbar(true);
                    this.setIsLoadingEditDataToolbar(true);
                    this.setIsLoadingPaginationToolbarAddData(true);
                    const getDefaultGroup = () => {
                        const group = this.groups.find((item) => {
                            return !item.isGroup;
                        });
                        if (!group) {
                            return this.groups[0];
                        }
                        return group;
                    };
                    const customerPlant = {
                        id: null,
                        marketSegment: this.marketSegments[this.currentTabIndex],
                        comment: null,
                        company: { name: '' },
                        group: { id: getDefaultGroup().id, name: '' },
                        location: null,
                        latitude: 0,
                        longitude: 0,
                        plantName: uuidv4(),
                        annualProduction: 0,
                        plantType: this.isRailwaySegment || this.isCustomSegment ? null : { id: this.filterPlantTypes()[1].id },
                        aggregateSuppliers: [],
                        tag: null,
                        locationItems: [],
                        nonSavedComment: null,
                        selectedSupplier: null,
                        isEditCompany: false,
                        isEditLocation: false,
                        isEditLocationByHand: false,
                        isEditPlantName: false,
                        isEditAnnualProduction: false,
                        isEditComment: false,
                        isExpanded: false,
                        isSelectingSupplierLocation: false,
                        isChangedAggregateSupplierState: false,
                        isSearchingCompanies: false,
                        searchCompany: uuidv4(),
                        foundedCompanies: [],
                        coarseTon: 0,
                        fineTon: 0,
                        fillBaseTon: 0,
                        otherTon: 0,
                        totalTon: 0,
                    };
                    this.saveCustomerPlant({
                        marketId: this.market.id,
                        groupId: customerPlant.group ? customerPlant.group.id : null,
                        customerPlantTypeId: customerPlant.plantType ? customerPlant.plantType.id : null,
                        customerTagId: customerPlant.tag ? customerPlant.tag.id : null,
                        marketSegmentId: this.marketSegments[this.currentTabIndex].id,
                        annualProduction: parseFloat(customerPlant.annualProduction),
                        companyName: customerPlant.company ? customerPlant.company.name : null,
                        customerPlantName: customerPlant.plantName,
                        address: customerPlant.location,
                        comment: customerPlant.comment,
                        latitude: customerPlant.latitude,
                        longitude: customerPlant.longitude,
                    }).then((res) => {
                        if (res) {
                            customerPlant.id = res;
                            this.customerPlantData.unshift(customerPlant);
                            this.comparableCustomerPlantData = cloneDeep(this.customerPlantData);
                        }
                        this.setIsLoadingPaginationToolbar(false);
                        this.setIsLoadingEditDataToolbar(false);
                        this.setIsLoadingPaginationToolbarAddData(false);
                    });
                } else {
                    Vue.toasted.error('Can not init default group');
                }
            }
        },
        customerPlantData: {
            handler(newValue, oldValue) {
                let data = oldValue;
                data = newValue;

                const searchedCompanies = data.filter((item, index) => {
                    return Object.keys(item).some((key) => {
                        if (['isSearchingCompanies', 'foundedCompanies'].includes(key)) {
                            return false;
                        }
                        if (['searchCompany'].includes(key)) {
                            return JSON.stringify(item[key]) !== JSON.stringify(this.comparableCustomerPlantData[index][key]);
                        }
                        return false;
                    });
                });
                if (searchedCompanies.length) {
                    this.debounceSearchCompanies(this, searchedCompanies);
                } else {
                    let differentSuppliers = [];
                    for (let externalIndex = 0; externalIndex < data.length; externalIndex++) {
                        const suppliers = data[externalIndex].aggregateSuppliers.filter((item, index) => {
                            return Object.keys(item).some((key) => {
                                if (
                                    [
                                        'isEditCoarsePercent',
                                        'isEditCoarseTon',
                                        'isEditFinePercent',
                                        'isEditFineTon',
                                        'isEditFillBaseTon',
                                        'isEditOtherTon',
                                        'nonSavedCoarsePercent',
                                        'nonSavedCoarseTon',
                                        'nonSavedFinePercent',
                                        'nonSavedFineTon',
                                        'nonSavedFillBaseTon',
                                        'nonSavedOtherTon',
                                    ].includes(key)
                                ) {
                                    return false;
                                }
                                if (this.comparableCustomerPlantData[externalIndex].aggregateSuppliers[index] === undefined) {
                                    return true;
                                }
                                return JSON.stringify(item[key]) !== JSON.stringify(this.comparableCustomerPlantData[externalIndex].aggregateSuppliers[index][key]);
                            });
                        });
                        if (suppliers.length) {
                            differentSuppliers.push({ customerId: data[externalIndex].id, suppliers });
                        }
                    }
                    if (differentSuppliers.length) {
                        this.debounceSaveCustomerSupplier(this, differentSuppliers);
                    }
                    const differentMarketSegmentData = data.filter((item, index) => {
                        return Object.keys(item).some((key) => {
                            if (
                                [
                                    'nonSavedComment',
                                    'isEditCompany',
                                    'isEditLocation',
                                    'isEditLocationByHand',
                                    'locationItems',
                                    'isEditPlantName',
                                    'isEditAnnualProduction',
                                    'isEditComment',
                                    'isExpanded',
                                    'isSelectingSupplierLocation',
                                    'aggregateSuppliers',
                                    'selectedSupplier',
                                    'isSearchingCompanies',
                                    'searchCompany',
                                    'foundedCompanies',
                                ].includes(key)
                            ) {
                                return false;
                            }
                            if (this.comparableCustomerPlantData[index] === undefined) {
                                return true;
                            }
                            return JSON.stringify(item[key]) !== JSON.stringify(this.comparableCustomerPlantData[index][key]);
                        });
                    });
                    if (differentMarketSegmentData.length) {
                        differentMarketSegmentData.forEach((item) => {
                            this.reCalculateMarketSegmentData(item);
                        });
                        this.debounceSaveCustomerPlant(this, differentMarketSegmentData);
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
		// getDemandAndSupplyRate(id){
		// 	this.showRateLeftSidebar = true
		// 	return function (id) {
		// 		axios.get(`CalculationRate/${id}/GetDetailedMarketRates`).then((res) => {
		// 			this.detailedMarketRates = res.data
		// 		});
		// 	}
		// },

        ...mapGetters('marketAssessment', {
            isLoadedAvailableMarkets: 'isLoadedAvailableMarkets',
            market: 'market',
        }),
        ...mapGetters('paginationToolbar', {
            triggerAddData: 'triggerAddData',
            pageSize: 'pageSize',
            page: 'page',
        }),
        ...mapGetters('marketSegment', {
            marketSegments: 'marketSegments',
            companies: 'companies',
            groups: 'groups',
            plantTypes: 'plantTypes',
            tags: 'tags',
            geologies: 'geologies',
            productTypes: 'productTypes',
            availableSuppliers: 'availableSuppliers',
            customers: 'customers',
        }),
        ...mapGetters('geocoding', {
            getLocations: 'getLocations',
        }),

        // tableData() {
        // 	return this.items.map(i=>({
        // 		...i,
        // 		progressData
        // 	}))
        // },
        backgroundColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
        },
        getUniqueID() {
            return uuidv4();
        },
        customerPlantHeaders() {
            if (this.isReadyMixConcreteSegment) {
                return getCustomerPlantTableReadyMixConcreateHeaders();
            }
            if (this.isAsphaltSegment) {
                return getCustomerPlantTableAsplhaltHeaders();
            }
            if (this.isConcreteProductsSegment) {
                return getCustomerPlantTableConcreteProductsHeaders();
            }
            if (this.isRailwaySegment) {
                return getCustomerPlantTableRailwayHeaders();
            }
            return getCustomerPlantTableHeaders();
        },
        getCoarseProductTypeId() {
            const coarse = this.productTypes.filter((type) => {
                return type.name.toLowerCase().includes('coarse');
            });
            if (coarse.length) {
                return coarse[0].id;
            }
            return 0;
        },
        getFineProductTypeId() {
            const fine = this.productTypes.filter((type) => {
                return type.name.toLowerCase().includes('fine');
            });
            if (fine.length) {
                return fine[0].id;
            }
            return 0;
        },
        getFileBaseProductTypeId() {
            const fine = this.productTypes.filter((type) => {
                return type.name.toLowerCase().includes('fill') || type.name.toLowerCase().includes('base');
            });
            if (fine.length) {
                return fine[0].id;
            }
            return 0;
        },
        getOtherProductTypeId() {
            const fine = this.productTypes.filter((type) => {
                return type.name.toLowerCase().includes('other');
            });
            if (fine.length) {
                return fine[0].id;
            }
            return 0;
        },
        isReadyMixConcreteSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.ReadyMixConcrete].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isAsphaltSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.Asphalt].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isConcreteProductsSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.ConcreteProducts].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isRailwaySegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.Railway].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isOtherSegments() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.OtherSegments].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isCustomSegment() {
            if (this.marketSegments.length) {
                if (this.marketSegments[this.currentTabIndex]) {
                    return [this.marketSegmentType.Custom].includes(this.marketSegments[this.currentTabIndex].segment.marketSegmentType);
                }
                return false;
            }
            return false;
        },
        isNaviagtionDisabled() {
            if (this.marketSegments.length) {
                return (
                    this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit === null ||
                    this.marketSegments[this.currentTabIndex].coarseAggregatesPercent === null ||
                    this.marketSegments[this.currentTabIndex].fineAggregatesPercent === null ||
                    this.marketSegments[this.currentTabIndex].contentPerUnit === null
                );
            }
            return true;
        },
        isAverageMarketPricesTabDisabled() {
            return this.isLoadingData || this.isCompetitivePositionSettingSelected || ((this.isReadyMixConcreteSegment || this.isAsphaltSegment) && this.isNaviagtionDisabled);
        },
        isFormulaTabDisabled() {
            return this.isLoadingData || this.isCompetitivePositionSettingSelected;
        },
        isDemandAndSupplyDisabled() {
            return this.isLoadingData || this.isCompetitivePositionSettingSelected;
        },
        isMarketAttractivenessTabDisabled() {
            return this.isLoadingData || this.isCompetitivePositionSettingSelected || ((this.isReadyMixConcreteSegment || this.isAsphaltSegment) && this.isNaviagtionDisabled);
        },
        isCompetitivePositionTabDisabled() {
            return this.isLoadingData || this.isCompetitivePositionSettingSelected || ((this.isReadyMixConcreteSegment || this.isAsphaltSegment) && this.isNaviagtionDisabled);
        },
    },
    methods: {
        // outsideClickEventListener() {
        //   if (this.openTextLocation) {
        //     this.openTextLocation = null;
        //   }
        // },

// /api/CalculationRate/{marketSegmentId}/GetDemandAndSupplyRate

		// getDemandAndSupplyRate(id){
		// 	axios.get(`CalculationRate/${id}/GetDemandAndSupplyRate`).then((resp) => {
		// 		return resp.data
		// 	});
		//
		// },

		outsideClickEventListener() {
			if (this.openTextLocation) {
				this.openTextLocation = null;
			}
		},

		openEditTextField(item, name) {
			const focusElement = (elementId) => {
				this.$nextTick(() => {
					document.getElementById(elementId).focus();
				});
			};

			switch (name) {
				case 'plantName':
					item.isEditPlantName = !item.isEditPlantName;
					focusElement(`plantName${item.id}`);
					break;

				case 'annualProduction':f
					item.isEditAnnualProduction = !item.isEditAnnualProduction;
					focusElement(`annualProduction${item.id}`);
					break;

				case 'isEditCoarsePercent':
					focusElement(`isEditCoarsePercent${item.id}`);
					break;

				case 'isEditCoarseTon':
					focusElement(`isEditCoarseTon${item.id}`);
					break;

				case 'isEditFinePercent':
					focusElement(`isEditFinePercent${item.id}`);
					break;

				case 'isEditFineTon':
					focusElement(`isEditFineTon${item.id}`);
					break;

				case 'isEditFillBaseTon':
					focusElement(`isEditFillBaseTon${item.id}`);
					break;

				case 'isEditOtherTon':
					focusElement(`isEditOtherTon${item.id}`);
					break;

				case 'companyName':
					item.isEditCompany = !item.isEditCompany;
					setTimeout(() => {
						document.getElementById(`companyName${item.id}`).focus();
					}, 1000);
					break;

				case 'location':
					item.isEditLocationByHand = !item.isEditLocationByHand;
					document.getElementById(`location${item.id}`).querySelector('input').focus();
					break;

				default:
					break;
			}
		},

		getRate(){
			this.producerRate = this.$store.dispatch('marketSegment/getMarketSegmentsRate').then(() => {
				this.producerRate = ''
				this.producerRate = Math.trunc(this.$store.getters['marketSegment/producersRate']*100);
			});
		},

		async openRateMarketVolume(id) {
			this.visibleRateSidebar = false
			this.detailedMarketRates = null
			this.competitivePositionRate = null
			this.levelOfConfidence = null
			await axios.get(`CompletionRate/${id}/GetDetailedMarketRates`).then((res) => {
				this.detailedMarketRates = res.data
				this.showRateLeftSidebar = true
			});
			axios.get(`CompletionRate/${this.marketSegments[this.currentTabIndex].id}/GetSpecificMarketSegmentRate`).then((res) => {
				this.competitivePositionRate = Math.trunc(res.data*100)
				this.showRateLeftSidebar = true
			});
			axios.get(`CompletionRate/${id}/GetLevelOfConfidence`).then((res) => {
				this.levelOfConfidence = Math.trunc(res.data)
				this.showRateLeftSidebar = true
			});
			axios.get(`CompletionRate/${id}/GetLevelOfConfidence`).then((res) => {
				this.levelOfConfidence = Math.trunc(res.data)
			});
		},
		hideRateMarketVolume() {
			this.showRateLeftSidebar = false
			this.visibleRateSidebar = true
		},
		getGroupWithId(id){
			const groupsCopy = this.groups
			return groupsCopy.find(element => element.id === id)
		},

        formulaTabSelected() {
            if (this.isFormulaTabDisabled) return;

            this.isFormulaSelected = true;
            this.isDemandAndSupplySelected = false;
            this.isAverageMarketPriceSelected = false;
            this.isMarketAttractivenessSelected = false;
            this.isCompetitivePositionSelected = false;
            this.setIsDisplayPaginationToolbar(false);
        },
        demandAndSupplyTabSelected() {
            if (this.isDemandAndSupplyDisabled) {
                return;
            }
            this.reInitCustomerPlantData();

            this.isDemandAndSupplySelected = true;
            this.isFormulaSelected = false;
            this.isAverageMarketPriceSelected = false;
            this.isMarketAttractivenessSelected = false;
            this.isCompetitivePositionSelected = false;

            this.setIsDisplayPaginationToolbar(true);
        },
        averageMarketPricesTabSelected() {
            if (this.isAverageMarketPricesTabDisabled) return;

            this.isAverageMarketPriceSelected = true;
            this.isFormulaSelected = false;
            this.isDemandAndSupplySelected = false;
            this.isMarketAttractivenessSelected = false;
            this.isCompetitivePositionSelected = false;
            this.setIsDisplayPaginationToolbar(false);
        },
        marketAttractivenessTabSelected() {
            if (this.isMarketAttractivenessTabDisabled) return;

            this.isMarketAttractivenessSelected = true;
            this.isFormulaSelected = false;
            this.isDemandAndSupplySelected = false;
            this.isAverageMarketPriceSelected = false;
            this.isCompetitivePositionSelected = false;
            this.setIsDisplayPaginationToolbar(false);
        },
        competitivePositionTabSelected() {
            if (this.isCompetitivePositionTabDisabled) return;

            this.isCompetitivePositionSelected = !this.isCompetitivePositionSelected;
            this.isFormulaSelected = false;
            this.isAverageMarketPriceSelected = false;
            this.isMarketAttractivenessSelected = false;
            this.isDemandAndSupplySelected = false;
            this.setIsDisplayPaginationToolbar(true);
            this.ghostLoading();
        },
        closeFormula() {
            this.isFormulaSelected = false;
            this.isAverageMarketPriceSelected = false;
            this.isMarketAttractivenessSelected = false;
            this.isCompetitivePositionSelected = false;
            this.isCompetitivePositionSettingSelected = false;
            this.reInitCustomerPlantData();
        },
        searchingLocation(data) {
            if (this.searchQueryTimeout) {
                clearTimeout(this.searchQueryTimeout);
            }
            this.searchQueryTimeout = setTimeout(() => {
                this.searchLocation(data.location).then(() => {
                    data.locationItems = this.getLocations;
                    this.searchQueryTimeout = null;
                });
            }, 800);
        },
        roundNumber(value, power = 0) {
            return Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
        },
        ghostLoading() {
            if (!this.isCompetitivePositionSelected) {
                this.isLoadingData = true;
                this.setIsDisabledPaginationToolbarAddDataButton(false);
                const temporaryCustomerPlantData = cloneDeep(this.customerPlantData);
                this.customerPlantData = [];
                setTimeout(() => {
                    this.customerPlantData = temporaryCustomerPlantData;
                    this.reInitCustomerPlantData();
                }, 500);
            }
        },
        formatNumber(number, fraction, separator = ' ') {
            let data = number.toLocaleString('en-US');
            data = data.replace(/\./, 'F');
            data = data.replace(/,/g, 'S');
            data = data.replace(/F/, fraction);
            data = data.replace(/S/g, separator);
            return data;
        },
        toFormatNumber(data) {
            return this.formatNumber(data, this.market.separatorType === 0 ? '.' : ',');
        },
        getLocationPlace(data) {
            let place = null;
            const { context } = data;
            for (var i = 0; i < context.length; i++) {
                if (context[i].id.split('.')[0] === 'place') {
                    return context[i].text;
                } else if (context[i].id.split('.')[0] === 'locality') {
                    return context[i].text;
                } else {
                    place = data.place_name;
                }
            }
            return place;
        },
        onCancelEditData() {
            if (!this.isOtherSegments) {
                this.selectedCustomerPlants = [];
            }
        },
        onDuplicateEditData() {
            if (!this.isOtherSegments) {
                this.isLoadingEditDataToolbar = true;
                this.setIsLoadingEditDataToolbarDuplicate(true);
                const selectedCustomerPlantIds = this.selectedCustomerPlants.map((item) => {
                    return item.id;
                });
                const copiedCustomerPlants = [];
                selectedCustomerPlantIds.forEach((id) => {
                    this.copyCustomerPlant({ marketId: this.market.id, customerPlantId: id }).then((res) => {
                        copiedCustomerPlants.push({ id, copiedId: res });
                        if (copiedCustomerPlants.length === selectedCustomerPlantIds.length) {
                            this.finishDuplicateData(copiedCustomerPlants);
                        }
                    });
                });
            }
        },
        finishDuplicateData(copiedCustomerPlants) {
            const data = [];
            this.customerPlantData.forEach((item) => {
                copiedCustomerPlants.forEach((copiedCustomerPlant) => {
                    if (copiedCustomerPlant.id === item.id) {
                        const customerPlant = cloneDeep(item);
                        customerPlant.id = copiedCustomerPlant.copiedId;
                        customerPlant.plantName = item.plantName + '_copy';
                        customerPlant.isExpanded = false;
                        customerPlant.aggregateSuppliers = [];
                        data.push(customerPlant);
                    }
                });
            });
            data.forEach((item) => {
                this.customerPlantData.unshift(item);
            });
            this.comparableCustomerPlantData = cloneDeep(this.customerPlantData);
            this.selectedCustomerPlants = [];
            this.isLoadingEditDataToolbar = false;
            this.setIsLoadingEditDataToolbarDuplicate(false);
        },
        async onDeleteEditData() {
            if (!this.isOtherSegments) {
                this.isLoadingEditDataToolbar = true;
                this.setIsLoadingEditDataToolbarDelete(true);
                const selectedCustomerPlantIds = this.selectedCustomerPlants.map((item) => {
                    return item.id;
                });
                const customerPlants = [];
                selectedCustomerPlantIds.forEach((id) => {
                    customerPlants.push(
                        this.removeCustomerPlant({
                            marketId: this.market.id,
                            customerPlantId: id,
                        }),
                    );
                });
                await Promise.all([customerPlants]);
                for (let index = this.customerPlantData.length - 1; index >= 0; index -= 1) {
                    if (selectedCustomerPlantIds.includes(this.customerPlantData[index].id)) {
                        this.customerPlantData.splice(index, 1);
                    }
                }
                this.comparableCustomerPlantData = cloneDeep(this.customerPlantData);
                this.selectedCustomerPlants = [];
                this.isLoadingEditDataToolbar = false;
                this.setIsLoadingEditDataToolbarDelete(false);
            }
        },
        ...mapMutations('paginationToolbar', {
            setIsDisplayPaginationToolbar: 'setIsDisplayPaginationToolbar',
            setIsLoadingPaginationToolbar: 'setIsLoadingPaginationToolbar',
            setIsLoadingPaginationToolbarAddData: 'setIsLoadingPaginationToolbarAddData',
            setIsDisabledPaginationToolbarAddDataButton: 'setIsDisabledPaginationToolbarAddDataButton',
            initPagination: 'initPagination',
        }),
        ...mapMutations('editDataToolbar', {
            setIsDisplayEditDataToolbar: 'setIsDisplayEditDataToolbar',
            setIsLoadingEditDataToolbar: 'setIsLoadingEditDataToolbar',
            setIsLoadingEditDataToolbarDuplicate: 'setIsLoadingEditDataToolbarDuplicate',
            setIsLoadingEditDataToolbarDelete: 'setIsLoadingEditDataToolbarDelete',
        }),
        ...mapMutations('importExcelDialog', {
            setIsDataImporting: 'setIsDataImporting',
        }),
        ...mapActions('marketSegment', {
            getMarketSegments: 'getMarketSegments',
            getCompanies: 'getCompanies',
            searchCompanies: 'searchCompanies',
            getGroups: 'getGroups',
            getPlantTypes: 'getPlantTypes',
            getTags: 'getTags',
            getGeologies: 'getGeologies',
            getProductTypes: 'getProductTypes',
            getAvailableSuppliers: 'getAvailableSuppliers',
            saveCustomerSupplier: 'saveCustomerSupplier',
            removeCustomerSupplier: 'removeCustomerSupplier',
            importCustomerPlants: 'importCustomerPlants',
            getCustomers: 'getCustomers',
            saveCustomerPlant: 'saveCustomerPlant',
            removeCustomerPlant: 'removeCustomerPlant',
            copyCustomerPlant: 'copyCustomerPlant',
            createMarketSegment: 'createMarketSegment',
            deleteMarketSegment: 'deleteMarketSegment',
        }),
        ...mapActions('geocoding', {
            searchLocation: 'searchLocation',
            searchLocationWithResult: 'searchLocationWithResult',
        }),
        async init() {
            this.isLoadingData = true;
            this.setIsLoadingPaginationToolbar(true);
            await this.getMarketSegments({ marketId: this.market.id });
            if (this.marketSegments.length) {
                this.isEmptyMarketSegments = false;
                if (
                    (this.isReadyMixConcreteSegment || this.isAsphaltSegment) &&
                    (this.marketSegments[this.currentTabIndex].coarseAggregatesPercent === null || this.marketSegments[this.currentTabIndex].fineAggregatesPercent === null)
                ) {
                    this.isFormulaSelected = true;
                    this.setIsDisplayPaginationToolbar(false);
                } else {
                    this.setIsDisplayPaginationToolbar(true);
                }
                const getCompanies = this.getCompanies();
                const getGroups = this.getGroups({ marketId: this.market.id });
                const getPlantTypes = this.getPlantTypes();
                const getTags = this.getTags();
                const getGeologies = this.getGeologies({ marketId: this.market.id });
                const getProductTypes = this.getProductTypes({ marketId: this.market.id });
                const getAvailableSuppliers = this.getAvailableSuppliers({ marketId: this.market.id });
                await Promise.all([getCompanies, getGroups, getPlantTypes, getTags, getGeologies, getProductTypes, getProductTypes, getAvailableSuppliers]);
                if (this.marketSegments[this.currentTabIndex]) {
                    await this.getCustomers({
                        marketId: this.market.id,
                        marketSegmentId: this.marketSegments[this.currentTabIndex].id,
                        page: this.page,
                    });
                    this.customerPlantData = this.initCustomerPlantData();
                    this.comparableCustomerPlantData = cloneDeep(this.customerPlantData);
                }
                this.selectedCustomerPlants = [];
            }
            this.isLoadingData = false;
            this.setIsLoadingPaginationToolbar(false);
        },
        initCustomerPlantData() {
            return this.customers.map((customer) => {
                customer.customerSuppliers.forEach((item) => {
                    Object.assign(item, {
                        isEditCoarsePercent: false,
                        isEditCoarseTon: false,
                        isEditFinePercent: false,
                        isEditFineTon: false,
                        isEditFillBaseTon: false,
                        isEditOtherTon: false,
                        nonSavedCoarsePercent: 0,
                        nonSavedCoarseTon: 0,
                        nonSavedFinePercent: 0,
                        nonSavedFineTon: 0,
                        nonSavedFillBaseTon: 0,
                        nonSavedOtherTon: 0,
                    });
                });
                return {
                    id: customer.id,
                    marketSegment: customer.marketSegment,
                    comment: customer.comment,
                    company: customer.investigatedCompany,
                    group: customer.group,
                    location: customer.address,
                    latitude: customer.latitude,
                    longitude: customer.longitude,
                    plantName: customer.name,
                    annualProduction: customer.annualProduction,
                    plantType: customer.customerPlantType,
                    aggregateSuppliers: customer.customerSuppliers,
                    tag: customer.customerTag,
                    locationItems: [],
                    nonSavedComment: null,
                    selectedSupplier: null,
                    isEditCompany: false,
                    isEditLocation: false,
                    isEditLocationByHand: false,
                    isEditPlantName: false,
                    isEditAnnualProduction: false,
                    isEditComment: false,
                    isExpanded: false,
                    isSelectingSupplierLocation: false,
                    isChangedAggregateSupplierState: false,
                    isSearchingCompanies: false,
                    searchCompany: uuidv4(),
                    foundedCompanies: [],
                    totalTon:
                        this.isReadyMixConcreteSegment || this.isAsphaltSegment
                            ? this.roundNumber(this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * customer.annualProduction)
                            : customer.annualProduction,
                    coarseTon:
                        this.isReadyMixConcreteSegment || this.isAsphaltSegment
                            ? this.roundNumber(
                                  ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * customer.annualProduction) / 100) *
                                      this.marketSegments[this.currentTabIndex].coarseAggregatesPercent,
                              )
                            : this.calculateCoarseTon(customer.customerSuppliers),
                    fineTon:
                        this.isReadyMixConcreteSegment || this.isAsphaltSegment
                            ? this.roundNumber(
                                  ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * customer.annualProduction) / 100) *
                                      this.marketSegments[this.currentTabIndex].fineAggregatesPercent,
                              )
                            : this.calculateFineTon(customer.customerSuppliers),
                    fillBaseTon: this.calculateFillBaseTon(customer.customerSuppliers),
                    otherTon: this.calculateOtherTon(customer.customerSuppliers),
                };
            });
        },
        reInitCustomerPlantData() {
            this.isLoadingData = true;
            this.setIsLoadingPaginationToolbar(true);
            this.setIsLoadingEditDataToolbar(true);
            this.customerPlantData = [];
            this.comparableCustomerPlantData = [];
            setTimeout(async () => {
                if (this.marketSegments.length) {
                    if (this.marketSegments[this.currentTabIndex]) {
                        await this.getCustomers({
                            marketId: this.market.id,
                            marketSegmentId: this.marketSegments[this.currentTabIndex].id,
                            page: this.page,
                        });
                        this.customerPlantData = this.initCustomerPlantData();
                        this.comparableCustomerPlantData = cloneDeep(this.customerPlantData);
                    }
                }
                this.selectedCustomerPlants = [];
                this.isLoadingData = false;
                this.setIsLoadingPaginationToolbar(false);
                this.setIsLoadingEditDataToolbar(false);
            }, 500);
        },
        reCalculateMarketSegmentData(item) {
            if (item) {
                item.totalTon =
                    this.isReadyMixConcreteSegment || this.isAsphaltSegment
                        ? this.roundNumber(this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction)
                        : item.annualProduction;
                item.coarseTon =
                    this.isReadyMixConcreteSegment || this.isAsphaltSegment
                        ? this.roundNumber(
                              ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction) / 100) *
                                  this.marketSegments[this.currentTabIndex].coarseAggregatesPercent,
                          )
                        : this.calculateCoarseTon(item.aggregateSuppliers);
                item.fineTon =
                    this.isReadyMixConcreteSegment || this.isAsphaltSegment
                        ? this.roundNumber(
                              ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction) / 100) *
                                  this.marketSegments[this.currentTabIndex].fineAggregatesPercent,
                          )
                        : this.calculateFineTon(item.aggregateSuppliers);
                item.fillBaseTon = this.calculateFillBaseTon(item.aggregateSuppliers);
                item.otherTon = this.calculateOtherTon(item.aggregateSuppliers);
            } else {
                this.customerPlantData.forEach((item) => {
                    item.totalTon =
                        this.isReadyMixConcreteSegment || this.isAsphaltSegment
                            ? this.roundNumber(this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction)
                            : item.annualProduction;
                    item.coarseTon =
                        this.isReadyMixConcreteSegment || this.isAsphaltSegment
                            ? this.roundNumber(
                                  ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction) / 100) *
                                      this.marketSegments[this.currentTabIndex].coarseAggregatesPercent,
                              )
                            : this.calculateCoarseTon(item.aggregateSuppliers);
                    item.fineTon =
                        this.isReadyMixConcreteSegment || this.isAsphaltSegment
                            ? this.roundNumber(
                                  ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction) / 100) *
                                      this.marketSegments[this.currentTabIndex].fineAggregatesPercent,
                              )
                            : this.calculateFineTon(item.aggregateSuppliers);
                    item.fillBaseTon = this.calculateFillBaseTon(item.aggregateSuppliers);
                    item.otherTon = this.calculateOtherTon(item.aggregateSuppliers);
                });
                this.comparableCustomerPlantData = cloneDeep(this.customerPlantData);
            }
        },
        updateAggregateSupplierState(index) {
            this.customerPlantData[index].isChangedAggregateSupplierState = false;
        },
        onChangeEditLocation(item, location, index) {
            item.latitude = location.geometry.coordinates[1];
            item.longitude = location.geometry.coordinates[0];
            item.location = this.getLocationPlace(location);
            // this.customerPlantData[index].location = this.getLocationPlace(location);;
			item.isEditLocation = false;
			item.isEditLocationByHand = false;
            this.openTextLocation = null;
        },
        onChangeEditPlantName(item, data) {
            item.plantName = data === null ? '-' : data;
        },
        onChangeAnnualProduction(item, data) {
            if (parseFloat(data)) {
                item.annualProduction = this.roundNumber(data);
            } else {
                item.annualProduction = 0;
            }
            if (this.isReadyMixConcreteSegment || this.isAsphaltSegment) {
                item.isChangedAggregateSupplierState = true;
                item.aggregateSuppliers.forEach((aggregateSupplier) => {
                    aggregateSupplier.allocationOfProductTypes.forEach((allocation) => {
                        if (allocation.productTypeId === this.getCoarseProductTypeId) {
                            const coarseTon = this.roundNumber(
                                ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction) / 100) *
                                    this.marketSegments[this.currentTabIndex].coarseAggregatesPercent,
                            );
                            allocation.volume = this.roundNumber((coarseTon / 100) * allocation.percentage);
                        }
                        if (allocation.productTypeId === this.getFineProductTypeId) {
                            const coarseTon = this.roundNumber(
                                ((this.marketSegments[this.currentTabIndex].tonsOfAggregatesPerUnit * item.annualProduction) / 100) *
                                    this.marketSegments[this.currentTabIndex].fineAggregatesPercent,
                            );
                            allocation.volume = this.roundNumber((coarseTon / 100) * allocation.percentage);
                        }
                    });
                });
                item.isChangedAggregateSupplierState = false;
            }
        },
        aggregateSupplierList(data) {
            return data
                .map((item) => {
                    return this.getCustomerShortName(item.aggregatesProducer.name);
                })
                .join(', ');
        },
        calculateCoarsePercent(data) {
            let coarsePercent = 0;
            data.forEach((item) => {
                if (item.productTypeId === this.getCoarseProductTypeId) {
                    coarsePercent = item.percentage;
                }
            });
            return this.roundNumber(coarsePercent);
        },
        calculateCoarseTon(data) {
            let coarseTon = 0;
            data.forEach((item) => {
                if (item.productTypeId === this.getCoarseProductTypeId) {
                    coarseTon = item.volume;
                }
            });
            return this.roundNumber(coarseTon);
        },
        calculateAbsoluteCoarseTon(data) {
            let coarseTon = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getCoarseProductTypeId) {
                        coarseTon += allocation.volume;
                    }
                });
            });
            return this.roundNumber(coarseTon);
        },
        calculateFinePercent(data) {
            let finePercent = 0;
            data.forEach((item) => {
                if (item.productTypeId === this.getFineProductTypeId) {
                    finePercent = item.percentage;
                }
            });
            return this.roundNumber(finePercent);
        },
        calculateFineTon(data) {
            let fineTon = 0;
            data.forEach((item) => {
                if (item.productTypeId === this.getFineProductTypeId) {
                    fineTon = item.volume;
                }
            });
            return this.roundNumber(fineTon);
        },
        calculateFillBaseTon(data) {
            let fillBaseTon = 0;
            data.forEach((item) => {
                if (item.productTypeId === this.getFileBaseProductTypeId) {
                    fillBaseTon = item.volume;
                }
            });
            return this.roundNumber(fillBaseTon);
        },
        calculateOtherTon(data) {
            let otherTon = 0;
            data.forEach((item) => {
                if (item.productTypeId === this.getOtherProductTypeId) {
                    otherTon = item.volume;
                }
            });
            return this.roundNumber(otherTon);
        },
        calculateAbsoluteFineTon(data) {
            let fineTon = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getFineProductTypeId) {
                        fineTon += allocation.volume;
                    }
                });
            });
            return this.roundNumber(fineTon);
        },
        calculateAbsoluteFillBaseTon(data) {
            let fillBaseTon = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getFileBaseProductTypeId) {
                        fillBaseTon += allocation.volume;
                    }
                });
            });
            return this.roundNumber(fillBaseTon);
        },
        calculateAbsoluteOtherTon(data) {
            let otherTon = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getOtherProductTypeId) {
                        otherTon += allocation.volume;
                    }
                });
            });
            return this.roundNumber(otherTon);
        },
        changeCoarsePercent(data, index) {
            data.allocationOfProductTypes.forEach((item) => {
                if (item.productTypeId === this.getCoarseProductTypeId) {
                    item.percentage = this.roundNumber(data.nonSavedCoarsePercent);
                    item.volume = this.roundNumber((this.customerPlantData[index].coarseTon / 100) * parseFloat(data.nonSavedCoarsePercent));
                }
            });
        },
        onChangeNonSavedCoarsePercent(item, data) {
            if (parseFloat(data)) {
                item.nonSavedCoarsePercent = this.roundNumber(data);
            } else {
                item.nonSavedCoarsePercent = 0;
            }
        },
        changeCoarseTon(data, index) {
            data.allocationOfProductTypes.forEach((item) => {
                if (item.productTypeId === this.getCoarseProductTypeId) {
                    item.volume = this.roundNumber(data.nonSavedCoarseTon);
                    item.percentage = this.roundNumber(parseFloat(data.nonSavedCoarseTon) / (this.customerPlantData[index].coarseTon / 100));
                }
            });
        },
        onChangeNonSavedCoarseTon(item, data) {
            if (parseFloat(data)) {
                item.nonSavedCoarseTon = this.roundNumber(data);
            } else {
                item.nonSavedCoarseTon = 0;
            }
        },
        changeFinePercent(data, index) {
            data.allocationOfProductTypes.forEach((item) => {
                if (item.productTypeId === this.getFineProductTypeId) {
                    item.percentage = this.roundNumber(data.nonSavedFinePercent);
                    item.volume = this.roundNumber((this.customerPlantData[index].fineTon / 100) * parseFloat(data.nonSavedFinePercent));
                }
            });
        },
        onChangeNonSavedFinePercent(item, data) {
            if (parseFloat(data)) {
                item.nonSavedFinePercent = this.roundNumber(data);
            } else {
                item.nonSavedFinePercent = 0;
            }
        },
        changeFineTon(data, index) {
            data.allocationOfProductTypes.forEach((item) => {
                if (item.productTypeId === this.getFineProductTypeId) {
                    item.volume = this.roundNumber(data.nonSavedFineTon);
                    item.percentage = this.roundNumber(parseFloat(data.nonSavedFineTon) / (this.customerPlantData[index].fineTon / 100));
                }
            });
        },
        onChangeNonSavedFineTon(item, data) {
            if (parseFloat(data)) {
                item.nonSavedFineTon = this.roundNumber(data);
            } else {
                item.nonSavedFineTon = 0;
            }
        },
        changeFillBaseTon(data, index) {
            data.allocationOfProductTypes.forEach((item) => {
                if (item.productTypeId === this.getFileBaseProductTypeId) {
                    item.volume = this.roundNumber(data.nonSavedFillBaseTon);
                    item.percentage = this.roundNumber(parseFloat(data.nonSavedFillBaseTon) / (this.customerPlantData[index].fillBaseTon / 100));
                }
            });
        },
        onChangeNonSavedFillBaseTon(item, data) {
            if (parseFloat(data)) {
                item.nonSavedFillBaseTon = this.roundNumber(data);
            } else {
                item.nonSavedFillBaseTon = 0;
            }
        },
        changeOtherTon(data, index) {
            data.allocationOfProductTypes.forEach((item) => {
                if (item.productTypeId === this.getOtherProductTypeId) {
                    item.volume = this.roundNumber(data.nonSavedOtherTon);
                    item.percentage = this.roundNumber(parseFloat(data.nonSavedOtherTon) / (this.customerPlantData[index].otherTon / 100));
                }
            });
        },
        onChangeNonSavedOtherTon(item, data) {
            if (parseFloat(data)) {
                item.nonSavedOtherTon = this.roundNumber(data);
            } else {
                item.nonSavedOtherTon = 0;
            }
        },
        calculateMissedCoarsePercent(data) {
            let sumCoarsePercent = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getCoarseProductTypeId) {
                        sumCoarsePercent += allocation.percentage;
                    }
                });
            });
            return this.roundNumber(sumCoarsePercent - 100);
        },
        calculateMissedCoarseTon(totalTon, data) {
            let sumCoarseTon = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getCoarseProductTypeId) {
                        sumCoarseTon += allocation.volume;
                    }
                });
            });
            if (this.isReadyMixConcreteSegment || this.isAsphaltSegment) {
                return this.roundNumber(sumCoarseTon - (totalTon / 100) * this.marketSegments[this.currentTabIndex].coarseAggregatesPercent);
            }
            return this.roundNumber(sumCoarseTon - this.calculateCoarseTon(data));
        },
        calculateMissedFinePercent(data) {
            let sumFinePercent = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getFineProductTypeId) {
                        sumFinePercent += allocation.percentage;
                    }
                });
            });
            return this.roundNumber(sumFinePercent - 100);
        },
        calculateMissedFineTon(totalTon, data) {
            let sumFineTon = 0;
            data.forEach((item) => {
                item.allocationOfProductTypes.forEach((allocation) => {
                    if (allocation.productTypeId === this.getFineProductTypeId) {
                        sumFineTon += allocation.volume;
                    }
                });
            });
            if (this.isReadyMixConcreteSegment || this.isAsphaltSegment) {
                return this.roundNumber(sumFineTon - (totalTon / 100) * this.marketSegments[this.currentTabIndex].fineAggregatesPercent);
            }
            return this.roundNumber(sumFineTon - this.calculateFineTon(data));
        },
        geologyTypeColors(geologyTypeId) {
            return getGeologyTypeColors(geologyTypeId);
        },
        tagColors(tag) {
            return getTagColors(tag);
        },
        getCustomerShortName(name) {

            let index = name.indexOf('/');

			if (index > 0){
				var data = name.substring(0, index);
				if(name === 'n/a'){
					return '-'
				}
				return data
			} else return name

        },
        filterPlantTypes() {
            return this.plantTypes.filter((item) => {
                if (this.isReadyMixConcreteSegment) {
                    if ([this.plantType.Fixed, this.plantType.Mobile].includes(item.type)) {
                        return true;
                    }
                    return false;
                }
                if (this.isAsphaltSegment) {
                    if ([this.plantType.Fixed, this.plantType.Mobile].includes(item.type)) {
                        return true;
                    }
                    return false;
                }
                if (this.isConcreteProductsSegment) {
                    if ([this.plantType.Pipe, this.plantType.Block, this.plantType.Tile, this.plantType.Precast].includes(item.type)) {
                        return true;
                    }
                    return false;
                }
                if (this.isRailwaySegment) {
                    return false;
                }
                if (this.isCustomSegment) {
                    if ([this.plantType.Pipe, this.plantType.Block, this.plantType.Tile, this.plantType.Precast].includes(item.type)) {
                        return true;
                    }
                    return false;
                }
                return true;
            });
        },
        filterTags() {
            const tags = [];
            tags.push({ id: null, index: null, name: 'None' });
            return tags.concat(this.tags);
        },
        filterSuppliers(data) {
            const existedSupplierIds = data.map((item) => {
                return item.aggregatesProducer.id;
            });
            return this.availableSuppliers.filter((supplier) => {
                return !existedSupplierIds.includes(supplier.aggregatesProducer.id);
            });
        },
        filterSupplierCoordinates(data) {
            const existedSupplierIds = data.map((item) => {
                return item.aggregatesProducer.id;
            });
            const availableSuppliers = this.availableSuppliers.filter((supplier) => {
                return !existedSupplierIds.includes(supplier.aggregatesProducer.id);
            });

			return availableSuppliers.map((availableSupplier) => {
                return {
                    ...availableSupplier.aggregatesProducer,
                    aggregatesProducerGeologies: [
                        {
                            primaryApplication: availableSupplier.aggregatesProducerGeology.primaryApplication,
                            geologySubType: availableSupplier.aggregatesProducerGeology.geologySubType,
							recalculatedPercentageOfProductTypes: availableSupplier.recalculatedPercentageOfProductTypes
                        },
                    ],
                };

				// return {
                //   latitude: availableSupplier.aggregatesProducer.longitude,
                //   longitude: availableSupplier.aggregatesProducer.latitude,
                // };
            });
        },
        isProduceAggregateSupplierCoarseValue(data) {
            const result = data.find((item) => {
                return item.productTypeId === this.getCoarseProductTypeId && item.isActive;
            });
            if (result) {
                return true;
            }
            return false;
        },
        isProduceAggregateSupplierFineValue(data) {
            const result = data.find((item) => {
                return item.productTypeId === this.getFineProductTypeId && item.isActive;
            });
            if (result) {
                return true;
            }
            return false;
        },
        isProduceAggregateSupplierFillBaseValue(data) {
            const result = data.find((item) => {
                return item.productTypeId === this.getFileBaseProductTypeId && item.isActive;
            });
            if (result) {
                return true;
            }
            return false;
        },
        isProduceAggregateSupplierOtherValue(data) {
            const result = data.find((item) => {
                return item.productTypeId === this.getOtherProductTypeId && item.isActive;
            });
            if (result) {
                return true;
            }
            return false;
        },
        isOutOfAllocatingAggregateSupplierCoarseValue(data) {
            const coarseTon = this.calculateCoarseTon(data.allocationOfProductTypes);
            const result = data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes.find((item) => {
                return item.productTypeId === this.getCoarseProductTypeId && item.isActive;
            });
            if (result) {
                return coarseTon > (data.aggregatesProducerGeology.annualAggregatesProduction / 100) * result.percentage;
            }
            return true;
        },
        isOutOfAllocatingAggregateSupplierFineValue(data) {
            const fineTon = this.calculateFineTon(data.allocationOfProductTypes);
            const result = data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes.find((item) => {
                return item.productTypeId === this.getFineProductTypeId && item.isActive;
            });
            if (result) {
                return fineTon > (data.aggregatesProducerGeology.annualAggregatesProduction / 100) * result.percentage;
            }
            return true;
        },
        isOutOfAllocatingAggregateSupplierFillBaseValue(data) {
            const fillBaseTon = this.calculateFillBaseTon(data.allocationOfProductTypes);
            const result = data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes.find((item) => {
                return item.productTypeId === this.getFileBaseProductTypeId && item.isActive;
            });
            if (result) {
                return fillBaseTon > (data.aggregatesProducerGeology.annualAggregatesProduction / 100) * result.percentage;
            }
            return true;
        },
        isOutOfAllocatingAggregateSupplierOtherValue(data) {
            const otherTon = this.calculateOtherTon(data.allocationOfProductTypes);
            const result = data.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes.find((item) => {
                return item.productTypeId === this.getOtherProductTypeId && item.isActive;
            });
            if (result) {
                return otherTon > (data.aggregatesProducerGeology.annualAggregatesProduction / 100) * result.percentage;
            }
            return true;
        },
        onLocationSelected(index, location) {
            this.customerPlantData[index].latitude = location.geometry.coordinates[1];
            this.customerPlantData[index].longitude = location.geometry.coordinates[0];
            this.customerPlantData[index].location = this.getLocationPlace(location);
			this.openTextLocation = false;
			this.customerPlantData[index].isEditLocationByHand = false;

        },
        onSupplierLocationSelected(index, location, select) {
            const supplier = this.filterSuppliers(this.customerPlantData[index].aggregateSuppliers).find((availableSupplier) => {
                return availableSupplier.aggregatesProducer.latitude === location.lng && availableSupplier.aggregatesProducer.longitude === location.lat;
            });

			this.supplierPercentage = supplier.recalculatedPercentageOfProductTypes

			this.supplierPercentageIndex = index
			this.supplierPercentageLocation = location

			if (select) {
				if (supplier) {
					const allocationOfProductTypes = [];
					supplier.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes.forEach((product) => {
						allocationOfProductTypes.push({
							customerSupplierId: supplier.aggregatesProducer.id,
							id: null,
							percentage: 0,
							productTypeId: product.productTypeId,
							volume: 0,
							isActive: product.isActive,
						});
					});
					if (supplier.aggregatesProducerGeology.geologySubType) {
						this.customerPlantData[index].isSelectingSupplierLocation = false;
						Object.assign(supplier.aggregatesProducerGeology, {
							geologySubType: { geologyTypeId: supplier.aggregatesProducerGeology.geologySubType.geologyTypeId },
						});
						this.customerPlantData[index].aggregateSuppliers.push({
							id: null,
							aggregatesProducer: supplier.aggregatesProducer,
							aggregatesProducerGeology: supplier.aggregatesProducerGeology,
							sameGroup: false,
							isEditCoarsePercent: false,
							isEditCoarseTon: false,
							isEditFinePercent: false,
							isEditFineTon: false,
							nonSavedCoarsePercent: 0,
							nonSavedCoarseTon: 0,
							nonSavedFinePercent: 0,
							nonSavedFineTon: 0,
							allocationOfProductTypes,
						});
						this.supplierPercentage = false
					} else {
						Vue.toasted.error('Supplier without geology');
					}
				}
			}

        },
        onRemoveSupplier(index, indexSupplier) {
            this.removeCustomerSupplier({
                marketId: this.customerPlantData[index].aggregateSuppliers[indexSupplier].aggregatesProducer.marketId,
                customerSupplierId: this.customerPlantData[index].aggregateSuppliers[indexSupplier].id,
            }).then((res) => {
                if (res) {
                    this.customerPlantData[index].aggregateSuppliers.splice(indexSupplier, 1);
                    this.comparableCustomerPlantData = cloneDeep(this.customerPlantData);
                }
            });
        },
        onSelectSupplier(index) {
            const allocationOfProductTypes = [];
            this.customerPlantData[index].selectedSupplier.aggregatesProducerGeology.primaryApplication.percentageOfProductTypes.forEach((product) => {
                allocationOfProductTypes.push({
                    customerSupplierId: this.customerPlantData[index].selectedSupplier.aggregatesProducer.id,
                    id: null,
                    percentage: 0,
                    productTypeId: product.productTypeId,
                    volume: 0,
                    isActive: product.isActive,
                });
            });
            if (this.customerPlantData[index].selectedSupplier.aggregatesProducerGeology.geologySubType) {
                Object.assign(this.customerPlantData[index].selectedSupplier.aggregatesProducerGeology, {
                    geologySubType: {
                        geologyTypeId: this.customerPlantData[index].selectedSupplier.aggregatesProducerGeology.geologySubType.geologyTypeId,
                    },
                });
                this.customerPlantData[index].aggregateSuppliers.push({
                    id: null,
                    aggregatesProducer: this.customerPlantData[index].selectedSupplier.aggregatesProducer,
                    aggregatesProducerGeology: this.customerPlantData[index].selectedSupplier.aggregatesProducerGeology,
                    sameGroup: false,
                    isEditCoarsePercent: false,
                    isEditCoarseTon: false,
                    isEditFinePercent: false,
                    isEditFineTon: false,
                    nonSavedCoarsePercent: 0,
                    nonSavedCoarseTon: 0,
                    nonSavedFinePercent: 0,
                    nonSavedFineTon: 0,
                    allocationOfProductTypes,
                });
            } else {
                Vue.toasted.error('Supplier without geology');
            }
        },
        isIncludeUUID(data) {
            return data.match(REGEX_INCLUDE_UUID_PATTERN);
        },
        isIncludeCopyPlantType(data) {
            return data.includes('_copy');
        },
        substringCopyAfterIncludeUUID(data) {
            return data.substr(data.indexOf('_'));
        },
        getStringWithoutUUID(data) {
            if (data && !this.isIncludeUUID(data)) {
                return data;
            } else if (data && this.isIncludeUUID(data) && this.isIncludeCopyPlantType(data)) {
                return this.substringCopyAfterIncludeUUID(data);
            } else {
                return '-';
            }
        },
        debounceSearchCompanies: debounce((context, data) => {
            context.comparableCustomerPlantData = cloneDeep(context.customerPlantData);
            data.forEach((item) => {
                context.customerPlantData.forEach((marketSegment) => {
                    if (marketSegment.id === item.id) {
                        marketSegment.isSearchingCompanies = true;
                    }
                });
                context
                    .searchCompanies({
                        term: item.searchCompany,
                        marketId: context.market.id,
                    })
                    .then((res) => {
                        if (res) {
                            context.customerPlantData.forEach((marketSegment) => {
                                if (marketSegment.id === item.id) {
                                    marketSegment.foundedCompanies = res;
                                }
                            });
                        }
                    })
                    .finally(() => {
                        context.customerPlantData.forEach((marketSegment) => {
                            if (marketSegment.id === item.id) {
                                marketSegment.isSearchingCompanies = false;
                            }
                        });
                    });
            });
        }, 500),
        debounceSaveCustomerSupplier: debounce((context, data) => {
            data.forEach((item) => {
                item.suppliers.forEach((supplier) => {
                    const allocationOfProductTypes = [];
                    supplier.allocationOfProductTypes.forEach((allocation) => {
                        allocationOfProductTypes.push({
                            percentage: allocation.percentage && allocation.percentage !== Infinity ? allocation.percentage : 0,
                            volume: allocation.volume && allocation.volume !== Infinity ? allocation.volume : 0,
                            productTypeId: allocation.productTypeId,
                        });
                    });
                    context
                        .saveCustomerSupplier({
                            customerSupplierId: supplier.id,
                            marketId: supplier.aggregatesProducer.marketId,
                            aggregatesProducerGeologyId: supplier.aggregatesProducerGeology.id,
                            customerId: item.customerId,
                            allocationOfProductTypes,
                        })
                        .then((res) => {
                            if (res) {
                                context.customerPlantData.forEach((marketSegment) => {
                                    if (marketSegment.id === item.customerId) {
                                        marketSegment.aggregateSuppliers.forEach((aggregateSupplier) => {
                                            if (aggregateSupplier.id === null && aggregateSupplier.aggregatesProducer.name === supplier.aggregatesProducer.name) {
                                                aggregateSupplier.id = res;
                                            }
                                        });
                                    }
                                });
                                context.comparableCustomerPlantData = cloneDeep(context.customerPlantData);
                            }
                        });
                });
            });
        }, 500),
        debounceSaveCustomerPlant: debounce((context, data) => {
            data.forEach((item) => {
                context
                    .saveCustomerPlant({
                        customerPlantId: item.id,
                        marketId: context.market.id,
                        groupId: item.group ? item.group.id : null,
                        customerPlantTypeId: context.isRailwaySegment ? null : item.plantType ? item.plantType.id : null,
                        customerTagId: item.tag ? item.tag.id : null,
                        marketSegmentId: context.marketSegments[context.currentTabIndex].id,
                        annualProduction: parseFloat(item.annualProduction),
                        companyName: item.company ? item.company.name : null,
                        customerPlantName: item.plantName,
                        address: item.location,
                        comment: item.comment,
                        latitude: item.latitude,
                        longitude: item.longitude,
                    })
                    .then((res) => {
                        if (res) {
                            context.comparableCustomerPlantData = cloneDeep(context.customerPlantData);
                        }
                    });
            });
        }, 500),
        onAddMarketSegment() {
            if (this.editingMarketSegmentName && !REGEX_EMPTY_STRING_PATTERN.test(this.editingMarketSegmentName)) {
                this.isAddingMarketSegment = true;
                this.createMarketSegment({ marketId: this.market.id, displayName: this.editingMarketSegmentName }).then((res) => {
                    if (res) {
                        this.marketSegments.push(res);
                        this.editingMarketSegmentName = null;
                        this.isDisplayAddMarketSegmentDialog = false;
                        this.currentTabIndex = this.marketSegments.length - 1;
                    }
                    setTimeout(() => {
                        this.isAddingMarketSegment = false;
                    }, 500);
                });
            } else {
                Vue.toasted.error('Market segment name can not be empty');
            }
        },
        onChangeMarketSegment() {
            if (this.editingMarketSegmentName && !REGEX_EMPTY_STRING_PATTERN.test(this.editingMarketSegmentName)) {
                this.isChangingMarketSegment = true;
                this.createMarketSegment({
                    marketId: this.marketSegments[this.currentTabIndex].marketId,
                    marketSegmentId: this.marketSegments[this.currentTabIndex].id,
                    displayName: this.editingMarketSegmentName,
                }).then((res) => {
                    if (res) {
                        this.marketSegments[this.currentTabIndex].segment.displayName = this.editingMarketSegmentName;
                        this.editingMarketSegmentName = null;
                        this.isDisplayChangeMarketSegmentDialog = false;
                    }
                    setTimeout(() => {
                        this.isChangingMarketSegment = false;
                    }, 500);
                });
            } else {
                Vue.toasted.error('Market segment name can not be empty');
            }
        },
        onDeleteMarketSegment() {
            this.isDeletingMarketSegment = true;
            this.deleteMarketSegment({
                marketId: this.marketSegments[this.currentTabIndex].marketId,
                marketSegmentId: this.marketSegments[this.currentTabIndex].id,
            }).then((res) => {
                if (res) {
                    this.marketSegments.splice(this.currentTabIndex, 1);
                    this.isDisplayDeleteMarketSegmentDialog = false;
                    this.currentTabIndex--;
                }
                setTimeout(() => {
                    this.isDeletingMarketSegment = false;
                }, 500);
            });
        },
        setTimeOutIsEditCompany(data) {
            setTimeout(() => {
                data.isEditCompany = false;
            }, 0);
        },
        onImportCustomerPlants(values) {
            this.setIsDataImporting(true);

            const data = cloneDeep(values);
            const customerPlants = [];

            let customIndex = 0;

            const getDefaultGroup = () => {
                const group = this.groups.find((item) => {
                    return !item.isGroup;
                });
                if (!group) {
                    return this.groups[0];
                }
                return group;
            };

            data.forEach(async (x) => {
                let latitude = 0;
                let longitude = 0;

                if (x.latitude && parseFloat(x.latitude)) {
                    latitude = parseFloat(x.latitude);
                }

                if (x.longitude && parseFloat(x.longitude)) {
                    longitude = parseFloat(x.longitude);
                }

                let annualProductionVolume = 0;

                if (x.annualProductionVolume) {
                    const indexOfT = x.annualProductionVolume.toString().toLowerCase().indexOf('t');
                    const indexOfM = x.annualProductionVolume.toString().toLowerCase().indexOf('m');

                    if (indexOfT !== -1) {
                        x.annualProductionVolume = x.annualProductionVolume.toString().substring(0, indexOfT);
                    }

                    if (indexOfM !== -1) {
                        x.annualProductionVolume = x.annualProductionVolume.toString().substring(0, indexOfM);
                    }

                    annualProductionVolume = parseFloat(x.annualProductionVolume) ? parseFloat(x.annualProductionVolume) : 0;
                }

                if (x.plantLocationAddress) {
                    const result = await this.searchLocationWithResult(x.plantLocationAddress.toString());

                    if (result[0]) {
                        x.plantLocationAddress = this.getLocationPlace(result[0]);

                        if (x.plantLocationAddress.includes(',')) {
                            x.plantLocationAddress = x.plantLocationAddress.substring(0, x.plantLocationAddress.indexOf(','));
                        }

                        latitude = result[0].geometry.coordinates[1];
                        longitude = result[0].geometry.coordinates[0];
                    }
                }

                customerPlants.push({
                    comment: x.comment ? x.comment.toString() : null,
                    companyName: x.companyName ? x.companyName.toString() : null,
                    groupId: getDefaultGroup().id,
                    address: x.plantLocationAddress ? x.plantLocationAddress.toString() : null,
                    latitude: latitude,
                    longitude: longitude,
                    plantName: x.plantName ? x.plantName.toString() : uuidv4(),
                    annualProduction: annualProductionVolume,
                });

                customIndex++;

                if (customIndex == data.length) {
                    this.importCustomerPlants({
                        marketId: this.market.id,
                        plantTypeId: this.isRailwaySegment || this.isCustomSegment ? null : this.filterPlantTypes()[1].id,
                        marketSegmentId: this.marketSegments[this.currentTabIndex].id,
                        customerPlants: customerPlants,
                    }).then((result) => {
                        if (result) {
                            location.reload();
                        } else {
                            this.setIsDataImporting(false);
                        }
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss">
.v-slide-group__content.v-tabs-bar__content {
    & > :first-child {
        border-top-left-radius: 8px;
    }
}
</style>

<style lang="scss" scoped>
@import './style';

.page-header-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .tabs-container {
        display: flex;
        background-color: white;
        align-items: center;
        border-radius: 8px;
        padding-right: 15px;

        .market-segments-tabs {
            border-top-left-radius: 8px;
            width: 80%;
        }

        .tabs-container-actions {
            display: flex;
            align-items: center;
            gap: 15px;
			width: 20%;
			justify-content: end;
		}
    }

    .subheader {
        display: flex;
        align-items: center;

        & > :first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            .subheader-item-text {
                padding-left: 10px !important;
            }
        }

        & > :last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            .subheader-item-text {
                padding-right: 15px !important;
            }

            .subheader-separator {
                display: none;
            }
        }

        .subheader-item {
            display: flex;
            align-items: center;
            height: 40px;
            background-color: #dbedff;

            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 1.3px;
            text-transform: uppercase;
            color: #0e3f66;

            i {
                color: #0e3f66;
            }

            .subheader-item-text {
                display: flex;
                align-items: center;
                gap: 5px;
                padding-left: 25px;
                cursor: pointer;
                user-select: none;

                &.disabled {
                    opacity: 0.3;
                    cursor: auto;
                }
            }

            &.selected {
                color: white;
                background-color: #0c3f66;

                i {
                    color: white;
                }

                .subheader-separator:after {
                    border-left: 20px solid #0c3f66;
                }
            }
        }

        .subheader-separator {
            width: 3px;
            height: 40px;
            position: relative;
            background: white;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 20px solid #dbedff;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
            }

            &:before {
                content: '';
                position: absolute;
                right: -20px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 20px solid white;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
            }
        }
    }
}

.market-segments-top{
	padding: 15px 20px;
	background: #FFFFFF;
	box-shadow: 0 0 10px rgba(110, 140, 163, 0.15);
	border-radius: 5px;
	margin-bottom: 25px;
	position: relative;
}

.import-exel{
	position: absolute;
	right: 195px;
	z-index: 9;
	top: 139px;
}

.split-menu-view {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	height: 60px !important;
	color: #ffffff;
	background-color: #0E3F66 !important;
	border-radius: 5px;
	box-shadow: none;
}

.open-edit-field{
	height: 100%;
	position: absolute;
	width: 100%;
}
.open-edit-field span{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
</style>

