<template>
    <v-container v-if="isDisplayEditDataToolbar" style="z-index: 10; position: fixed; right: 24px; bottom: 24px; width: 650px; background-color: #0e3f66; border-radius: 5px">
        <v-row no-gutters>
            <v-col cols="1" style="display: flex; align-items: center; justify-content: start;">
                <v-btn
                    @click="onCancel()"
                    :class="{ 'pointer-events-none': isLoadingEditDataToolbar || isLoadingEditDataToolbarDuplicate || isLoadingEditDataToolbarDelete }"
                    color="#f7685b"
                    x-small
                    text
                    fab
                >
                    <img src="@/assets/icons/close_rounded.svg" style="width: 16px" class="cursor-pointer" />
                </v-btn>
            </v-col>
            <v-col cols="4" style="display: flex; align-items: center; justify-content: start;">
                <div class="edit-data-toolbar-block">
                    <span style="padding: 0 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ dataCount }}</span>
                </div>
                <span style="padding-left: 12px; color: #8798ad;"> item<template v-if="dataCount > 1">s</template> selected </span>
            </v-col>
            <v-col cols="1" style="display: flex; align-items: center; justify-content: start;">
                <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                    <template v-slot:activator="{ on, attrs }">
                        <img v-bind="attrs" v-on="on" src="@/assets/icons/copy_or_share.svg" style="width: 25px" class="cursor-pointer" />
                    </template>
                    <span>In development</span>
                </v-tooltip>
            </v-col>
            <v-col cols="3" style="display: flex; align-items: center; justify-content: center;" class="pr-2">
                <v-btn
                    @click="onDuplicate()"
                    :style="{ opacity: isLoadingEditDataToolbar && !isLoadingEditDataToolbarDelete && !isDisabledEditDataToolbarDuplicateButton ? 0.3 : 1 }"
                    :class="{ 'pointer-events-none': isLoadingEditDataToolbar }"
                    :loading="isLoadingEditDataToolbarDuplicate"
                    :disabled="isLoadingEditDataToolbarDelete || isDisabledEditDataToolbarDuplicateButton"
                    color="#ff893a"
                    block
                    dark
                >
                    <img src="@/assets/icons/duplicate.svg" style="width: 20px" />
                    <span style="padding-left: 8px; color: #ffffff">{{ overrideDuplicateButtonText }}</span>
                </v-btn>
            </v-col>
            <v-col cols="3" style="display: flex; align-items: center; justify-content: center;" class="pl-2" v-if="onShowDelete">
                <v-btn
                    @click="onDelete()"
                    :style="{ opacity: isLoadingEditDataToolbar && !isLoadingEditDataToolbarDuplicate && !isDisabledEditDataToolbarDeleteButton ? 0.3 : 1 }"
                    :class="{ 'pointer-events-none': isLoadingEditDataToolbar }"
                    :loading="isLoadingEditDataToolbarDelete"
                    :disabled="isLoadingEditDataToolbarDuplicate || isDisabledEditDataToolbarDeleteButton"
                    color="#f7685b"
                    block
                    dark
                >
                    <img src="@/assets/icons/trash.svg" style="width: 16px" />
                    <span style="padding-left: 8px; color: #ffffff">{{ overrideDeleteButtonText }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'EditDataToolbar',
    props: {
        dataCount: {
            type: Number,
            required: true,
        },
        overrideDuplicateButtonText: {
            type: String,
            default: 'DUPLICATE',
        },
        overrideDeleteButtonText: {
            type: String,
            default: 'DELETE',
        },
		onShowDelete : {
			type: Boolean,
			default: true
		}
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters('editDataToolbar', {
            isDisplayEditDataToolbar: 'isDisplayEditDataToolbar',
            isLoadingEditDataToolbar: 'isLoadingEditDataToolbar',
            isLoadingEditDataToolbarDuplicate: 'isLoadingEditDataToolbarDuplicate',
            isLoadingEditDataToolbarDelete: 'isLoadingEditDataToolbarDelete',
            isDisabledEditDataToolbarDuplicateButton: 'isDisabledEditDataToolbarDuplicateButton',
            isDisabledEditDataToolbarDeleteButton: 'isDisabledEditDataToolbarDeleteButton',
        }),

    },
	methods: {
        onCancel() {
            this.$emit('cancel');
        },
        onDuplicate() {
            this.$emit('duplicate');
        },
		onDelete() {
			this.$emit('delete');
		},
    },



};
</script>

<style lang="scss" scoped>
.edit-data-toolbar-block {
    height: 36px;
    width: 44px;
    background: #0b3252;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
