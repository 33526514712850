<template>
    <aggregate-info-wrapper @close="close">
        <template slot="title"> {{ popup.company.name }} - {{ getStringWithoutUUID(popup.name) }} </template>
        <template slot="supply">{{ toFormatNumber(popup.selfSupply) }}</template>
        <template slot="group">{{ popup.groupName }}</template>
        <template slot="total-vol-title">{{ getProductVolTitle() }}</template>
        <template slot="total-vol">
            <b>{{ getProductVol() }}</b>
            <template v-if="popup.marketSegmentType === marketSegmentType.ReadyMixConcrete">m<sup>3</sup></template>
            <template v-else>t</template>
        </template>

        <template slot="body">
            <div>
                <!-- Coarse supplies -->
                <div v-if="popup.marketSegmentType !== marketSegmentType.Custom">
                    <!-- Coarse chart -->
                    <div class="d-flex gap-5">
                        <v-progress-circular
                            :rotate="-90"
                            :size="56"
                            :value="!popup.totalProdVol || popup.totalProdVol === 0 ? 0 : popup.volOfCoarse"
                            :width="12"
                            color="mangoTango"
                        />
                        <div>
                            <span>
                                Volume of
                                <span
                                    v-if="
                                        popup.marketSegmentType === marketSegmentType.ReadyMixConcrete ||
                                        popup.marketSegmentType === marketSegmentType.Asphalt ||
                                        popup.marketSegmentType === marketSegmentType.ConcreteProducts ||
                                        popup.marketSegmentType === marketSegmentType.Custom
                                    "
                                >
                                    coarse aggregates
                                </span>
                                <span v-else-if="popup.marketSegmentType === marketSegmentType.Railway">Railway Ballast</span>
                                <span v-else>coarse</span>
                            </span>
                            <p class="text-no-wrap">
                                <b>{{ toFormatNumber(roundNumber((popup.totalProdVol / 100) * popup.volOfCoarse), 0) }}</b>
                                t
                            </p>
                        </div>
                    </div>

                    <!-- Top 10 Railway/Coarse supplies  -->
                    <template v-if="popup.topCustomerSuppliers && popup.topCustomerSuppliers.length > 0">
                        <span v-if="popup.marketSegmentType === marketSegmentType.Railway" class="top-customers-title bermudaGray--text"> TOP 10 RAILWAY BALLAST SUPPLIERS </span>
                        <span v-else class="top-customers-title bermudaGray--text">TOP 10 COARSE SUPPLIERS</span>

                        <div v-for="topCustomerSupplier in popup.topCustomerSuppliers" :key="topCustomerSupplier.id" class="d-flex align-center pt-1">
                            <img src="@/assets/map/map_point.svg" style="max-width: 25px; width: 100%" />
                            <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="text-truncate pl-3">{{ topCustomerSupplier.aggregatesProducer.investigatedCompany.name }}</span>
                                </template>
                                <span>
                                    {{ topCustomerSupplier.aggregatesProducer.investigatedCompany.name }}
                                </span>
                            </v-tooltip>
                            <span class="font-weight-bold px-1">-</span>
                            <span class="text-no-wrap font-weight-bold">
                                {{
                                    toFormatNumber(
                                        ((popup.totalProdVol / 100) * popup.volOfCoarse) / 100 === 0
                                            ? 0
                                            : roundNumber(calculateCoarseTon(topCustomerSupplier) / (((popup.totalProdVol / 100) * popup.volOfCoarse) / 100), 0),
                                        0,
                                    )
                                }}%
                            </span>
                        </div>
                    </template>
                </div>

                <!-- Fine supplies -->
                <div v-if="popup.marketSegmentType !== marketSegmentType.Railway" class="pt-5">
                    <!-- Fine chart -->
                    <div class="d-flex gap-5">
                        <v-progress-circular
                            :rotate="-90"
                            :size="56"
                            :value="!popup.totalProdVol || popup.totalProdVol === 0 ? 0 : popup.volOfFine"
                            :width="12"
                            color="mangoTango"
                        />
                        <div>
                            <span>
                                Volume of
                                <span
                                    v-if="
                                        popup.marketSegmentType === marketSegmentType.ReadyMixConcrete ||
                                        popup.marketSegmentType === marketSegmentType.Asphalt ||
                                        popup.marketSegmentType === marketSegmentType.ConcreteProducts ||
                                        popup.marketSegmentType === marketSegmentType.Railway ||
                                        popup.marketSegmentType === marketSegmentType.Custom
                                    "
                                >
                                    fine aggregates
                                </span>
                                <span v-else>fine</span>
                            </span>
                            <p class="text-no-wrap">
                                <b>{{ toFormatNumber(roundNumber((popup.totalProdVol / 100) * popup.volOfFine), 0) }}</b>
                                t
                            </p>
                        </div>
                    </div>

                    <!-- Top 10 fine supplies -->
                    <template v-if="popup.topCustomerSuppliers && popup.topCustomerSuppliers.length > 0">
                        <span v-if="popup.marketSegmentType === marketSegmentType.Custom" class="top-customers-title bermudaGray--text">
							TOP 10 COARSE SUPPLIERS
						</span>
						<span v-else class="top-customers-title bermudaGray--text">TOP 10 FINE SUPPLIERS</span>

                        <div v-for="topCustomerSupplier in popup.topCustomerSuppliers" :key="topCustomerSupplier.id" class="d-flex align-center pt-1">
                            <img src="@/assets/map/map_point.svg" style="max-width: 25px; width: 100%" />
                            <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="text-truncate pl-3">{{ topCustomerSupplier.aggregatesProducer.investigatedCompany.name }}</span>
                                </template>
                                <span>
                                    {{ topCustomerSupplier.aggregatesProducer.investigatedCompany.name }}
                                </span>
                            </v-tooltip>
                            <span class="font-weight-bold px-1">-</span>
                            <span class="text-no-wrap font-weight-bold">
                                {{
                                    toFormatNumber(
                                        ((popup.totalProdVol / 100) * popup.volOfFine) / 100 === 0
                                            ? 0
                                            : roundNumber(calculateFineTon(topCustomerSupplier) / (((popup.totalProdVol / 100) * popup.volOfFine) / 100), 0),
                                        0,
                                    )
                                }}%
                            </span>
                        </div>
                    </template>
                </div>

                <!-- Top 10 supplies -->
                <template v-if="popup.marketSegmentType === marketSegmentType.Custom && popup.topCustomerSuppliers && popup.topCustomerSuppliers.length > 0">
					<span class="top-customers-title bermudaGray--text">
						TOP 10 FINE SUPPLIERS
					</span>

                    <div v-for="topCustomerSupplier in popup.topCustomerSuppliers" :key="topCustomerSupplier.id">
                        <img src="@/assets/map/map_point.svg" style="max-width: 25px; width: 100%" />
                        <v-tooltip content-class="regalBlue--text" color="ghostWhite" top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="text-truncate pl-3">{{ topCustomerSupplier.aggregatesProducer.investigatedCompany.name }}</span>
                            </template>
                            <span>
                                {{ topCustomerSupplier.aggregatesProducer.investigatedCompany.name }}
                            </span>
                        </v-tooltip>
                        <span class="font-weight-bold px-1">-</span>
                        <span class="text-no-wrap font-weight-bold">
                            {{
                                toFormatNumber(
                                    ((popup.totalProdVol / 100) * (popup.volOfCoarse + popup.volOfFine + popup.volOfFillBase + popup.volOfOther)) / 100 === 0
                                        ? 0
                                        : roundNumber(
                                              calculateTotalTon(topCustomerSupplier) /
                                                  (((popup.totalProdVol / 100) * (popup.volOfCoarse + popup.volOfFine + popup.volOfFillBase + popup.volOfOther)) / 100),
                                              0,
                                          ),
                                )
                            }}%
                        </span>
                    </div>
                </template>
            </div>
        </template>
    </aggregate-info-wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import { MarketSegmentType } from '@/core/enums/marketSegmentType';
import { REGEX_INCLUDE_UUID_PATTERN } from '@/core/consts/const';

import aggregateInfoWrapper from './aggregateInfoWrapper.vue';

export default {
    components: { aggregateInfoWrapper },
    props: {
        popup: {
            type: Object,
        },
    },
    data() {
        return {
            marketSegmentType: MarketSegmentType,
        };
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
    },
    methods: {
        roundNumber(value, power = 2) {
            var qq = Math.round(parseFloat(value) * Math.pow(10, power)) / Math.pow(10, power);
            return qq;
        },
        formatNumber(number, fraction, separator = ' ') {
            let data = number.toLocaleString('en-US');
            data = data.replace(/\./, 'F');
            data = data.replace(/,/g, 'S');
            data = data.replace(/F/, fraction);
            data = data.replace(/S/g, separator);
            return data;
        },
        toFormatNumber(data) {
            return this.formatNumber(data, this.market.separatorType === 0 ? '.' : ',');
        },
        isIncludeUUID(data) {
            return data.match(REGEX_INCLUDE_UUID_PATTERN);
        },
        isIncludeCopyPlantType(data) {
            return data.includes('_copy');
        },
        substringCopyAfterIncludeUUID(data) {
            return data.substr(data.indexOf('_'));
        },
        getStringWithoutUUID(data) {
            if (data && !this.isIncludeUUID(data)) {
                return data;
            } else if (data && this.isIncludeUUID(data) && this.isIncludeCopyPlantType(data)) {
                return this.substringCopyAfterIncludeUUID(data);
            } else {
                return '-';
            }
        },
        getProductVolTitle() {
            if (this.popup.marketSegmentType === this.marketSegmentType.ReadyMixConcrete) return 'Annual prod. vol.';

            if (
                this.popup.marketSegmentType === this.marketSegmentType.ConcreteProducts ||
                this.popup.marketSegmentType === this.marketSegmentType.Railway ||
                this.popup.marketSegmentType === this.marketSegmentType.Custom
            ) {
                return 'Annual vol. of agg.';
            }

            return 'Total prod. vol.';
        },
        getProductVol() {
            return this.toFormatNumber(this.popup.marketSegmentType === this.marketSegmentType.ReadyMixConcrete ? this.popup.annualProduction : this.popup.totalProdVol);
        },
        getProductVolMetric() {},
        calculateCoarseTon(data) {
            let tons = 0;
            data.allocationOfProductTypes.forEach((allocation) => {
                if (allocation.productTypeId === 4) {
                    tons = allocation.volume;
                }
            });
            return tons;
        },
        calculateFineTon(data) {
            let tons = 0;
            data.allocationOfProductTypes.forEach((allocation) => {
                if (allocation.productTypeId === 3) {
                    tons = allocation.volume;
                }
            });
            return tons;
        },
        calculateFillBaseTon(data) {
            let tons = 0;
            data.allocationOfProductTypes.forEach((allocation) => {
                if (allocation.productTypeId === 2) {
                    tons = allocation.volume;
                }
            });
            return tons;
        },
        calculateOtherTon(data) {
            let tons = 0;
            data.allocationOfProductTypes.forEach((allocation) => {
                if (allocation.productTypeId === 1) {
                    tons = allocation.volume;
                }
            });
            return tons;
        },
        calculateTotalTon(data) {
            let tons = 0;
            data.allocationOfProductTypes.forEach((allocation) => {
                tons += allocation.volume;
            });
            return tons;
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.top-customers-title {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}
</style>
