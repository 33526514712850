<template>
    <div v-if="getSelectedFilter.length" class="selected-filters-container">
        <div class="selected-filter-list">
            <template v-for="(filter, i) in getSelectedFilter">
                <div v-if="filter.type !== 'aggregateHide'" :key="i" class="filter-list-item" :style="filter.option.style || ''">
                    {{ filter.option.name }}
                    <img src="@/assets/lib/x.svg" @click="deleteFilter(filter)" />
                </div>
            </template>
        </div>
        <div class="actions">
            <v-btn v-if="getSelectedFilter.length" icon @click="clearFilters">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    methods: {
        clearFilters() {
            this.$store.commit('filters/clearFilter');
            this.startFiltering();
        },
        deleteFilter(filter) {
            this.$store.commit('filters/deleteFilter', filter);
            this.startFiltering();
        },
        startFiltering() {
            this.$store.commit('producersAndCustomersMap/startFiltering', this.$store.getters['filters/getSelectedFilter']);
        },
    },
    computed: {
        ...mapGetters('marketAssessment', {
            market: 'market',
        }),
        getSelectedFilter() {
            // Return selected filters without 'aggregateHide' because it's not needed
            return this.$store.getters['filters/getSelectedFilter'].filter((x) => x.type !== 'aggregateHide');
        },
    },
};
</script>

<style lang="scss" scoped>
.selected-filters-container {
    width: 100%;
    height: 60px;
    background: #fff;
    box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;

    .selected-filter-list {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        overflow-x: auto;

        .filter-list-item {
            height: 40px;
            padding: 5px 10px 5px 10px;
            border-radius: 4px;
            color: rgb(112, 112, 112);
            background: rgb(233, 233, 233);
            white-space: nowrap;
            display: flex;
            align-items: center;
            font-weight: 500;
            gap: 5px;

            img {
                width: 18px;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
}
</style>
